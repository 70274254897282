import moment from 'moment';
import qs from 'query-string';
import Big from 'big.js';
import { useEffect, useState } from 'react';
import { CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import { USER_ADMIN_ROLES, USER_ADMIN_ROLES_LABEL } from 'admin/assets/js/constants';
import apiClient from 'admin/assets/js/lib/apiClient';
import authProvider from 'admin/assets/js/lib/authProvider';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import history from 'admin/assets/js/lib/history';
import { adminApiOrganizationsListUrl, adminRootUrl } from 'admin/urls';
import { CURRENCY, DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { formatNumberWithSeparatorsAccordingToLocale } from 'core/assets/js/lib/utils';

Big.DP = 2;
Big.RM = 1;

/**
 * Calculate exchange rate from a source and target amount.
 * @param {number} sourceAmount - source amount.
 * @param {number} targetAmount - target amount.
 * @return {number} exchange rate.
 */
export const calcExchangeRate = (sourceAmount, targetAmount) => {
  const value = targetAmount / sourceAmount;
  return (Number.isNaN(value) || !Number.isFinite(value))
    ? 0
    : value.toFixed(2);
};

/**
 *
 * @param {Date} date
 * @param {String} dateFormat
 * @param {Boolean} includeTime
 * @returns {String}
 */
export const formatDate = (date, dateFormat = DATE_FORMAT_DEFAULT, includeTime = false) => {
  if (!date || !dateFormat) {
    return '-';
  }
  if (includeTime) {
    return moment(date).format(`${DATE_FORMAT_DEFAULT} HH:mm`);
  }
  return moment(date).format(dateFormat);
};

/**
 * Adds a reload query string param, which ApiTable looks for and reloads the current table
 */
export const reloadTable = () => {
  const { push, location: { pathname, search } } = history;
  // history has basename set to adminRootUrl, so remove it
  const newPathName = pathname.replace(new RegExp(`^${adminRootUrl}`, ''));
  const newQueryString = qs.stringify({ ...qs.parse(search), reload: Date.now() });
  const newUrl = `${newPathName}?${newQueryString}`;
  push(newUrl);
};

export const formatCurrency = currency => CURRENCY_SYMBOL[(currency || '').toLowerCase()];

/**
 * Format a currency amount for display along with the currency symbol.
 * @param {string} amount - currency amount to display, ie '0.00',
 * @param {string} currency - name of currency to use symbol for, ie 'gbp'.
 * @return {string} formatted string with currency symbol, ie '£0.00' for (0,'gbp').
 */
export const formatAmountString = (amount, currency) => `${
  CURRENCY_SYMBOL[(currency || '').toLowerCase()]
}${
  formatNumberWithSeparatorsAccordingToLocale(Big(Math.abs(parseFloat(amount || '0.00')))
    .toFixed(2))
}`;

/**
 * Determine the colour to display a currency string in, red or black.
 * Negative numbers are indicated as red and positive as black.
 *
 * It uses the first character of the amount string to check if the number is negative,
 * ie first if the first character is '-' it is deemed negative, else its deemed positive.
 * @param {string} amount  - currency amount to display, ie '0.00' or '-0.00'.
 * @return {string} colour name to use, either 'red' or 'black'.
 */
export const determineAmountColour = amount => ((amount || [])[0] === '-' ? 'red' : 'black');

/**
 * React hook for determining if the admin user is a super admin
 *
 * @returns {Boolean}
 */
export const getIsSuperAdmin = () => {
  const [role, setRole] = useState(null);

  useEffect(() => {
    (async () => {
      const permissions = await authProvider.getPermissions();
      setRole(permissions.role);
    })();
  }, []);

  return role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER];
};

/**
 * React hook for getting a simple list of active organizations for dropdowns
 *
 * @param {Boolean} keyById - If the result should be keyed by id
 * @returns {{ ids: Number[], keyedById: Object, options: {label: String, value: Number }[] }}
 */
export const getOrganizationsList = () => {
  const [options, setOptions] = useState([]);
  const [keyedById, setKeyedById] = useState({});
  const [ids, setIds] = useState([]);
  const notifications = getNotifications();

  useEffect(() => {
    apiClient({ url: adminApiOrganizationsListUrl() })
      .then(response => {
        setOptions(response.data);
        const thisIds = [];
        setKeyedById(response.data.reduce(
          (acc, option) => {
            thisIds.push(option.value);
            acc[option.value] = option.label;
            return acc;
          },
          {},
        ));
        setIds(thisIds);
      })
      .catch(e => {
        notifications.error(e.message);
      });
  }, []);

  return { ids, keyedById, options };
};

export const getTransactionLimitAmount = (currency) => {
  switch (currency) {
    case CURRENCY.USD:
    case CURRENCY.EUR:
      return 8;
    case CURRENCY.GBP:
      return 7;
    default:
      return null;
  }
};

export const transactionHasHighFees = (val, currency) => {
  const limitAmount = getTransactionLimitAmount(currency);
  if (typeof limitAmount !== 'number') {
    return false;
  }
  return new Money(val, currency).cmp(limitAmount) >= 0;
};
