import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import InvoicingFeeScheme from 'finance/assets/js/lib/InvoicingFeeScheme';
import BankFeesModal from 'finance/assets/js/components/invoicing/BankFeesModal.jsx';
import SalesTaxRechargeBreakdown from 'finance/assets/js/components/invoicing/SalesTaxRechargeBreakdown.jsx';
import FeeAnalysisModal from 'finance/assets/js/components/invoicing/FeeAnalysisModal.jsx';
import BillableSubtotalModal from 'finance/assets/js/components/invoicing/BillableSubtotalModal.jsx';
import { COMPANY_TAX_SYSTEM_INFO } from 'settings/assets/js/constants';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';

class InvoiceMainTableTotals extends React.PureComponent {
  render() {
    const {
      withFeeModal, invoicingSettings, amounts, owner, month, isOutbound, invoiceDate,
    } = this.props;
    if (!amounts) {
      return null;
    }

    if (!invoicingSettings) {
      throw new Error('undefined invoicing settings');
    }

    if (!invoicingSettings.feeScheme) {
      throw new Error('undefined fee scheme');
    }

    const ownerFE = new FinancialEntity(owner);

    const invoiceAmounts = new InvoiceAmounts(amounts);

    const {
      billable, childrenBankFees, credit, currency, fee, inTargetCurrency: { vat: targetVat },
      licenceFee, salesTaxRecharge, targetCurrency, targetTotal, total, vat, vatPercent,
      subscriptionFee,
    } = invoiceAmounts;
    const currencySymbol = CURRENCY_SYMBOL[currency];
    const targetCurrencySymbol = CURRENCY_SYMBOL[targetCurrency];
    const taxSystemType = get(owner, 'company.tax_system_type');
    let taxSystemLabel = 'VAT';
    if (taxSystemType) {
      taxSystemLabel = COMPANY_TAX_SYSTEM_INFO[taxSystemType].label;
    }

    const hasFee = parseFloat(fee) > 0;
    const scheme = new InvoicingFeeScheme(invoicingSettings.feeScheme);
    const hasBillableRow = hasFee
      && billable && billable !== credit && !scheme.getProcessingScheme().scale.isZero();
    const showBillableRow = new Money(billable, currency).lt(credit);
    const hasCredit = credit && !new Money(credit, currency).isZero();
    const hasSalesTaxRecharge = salesTaxRecharge && !new Money('0.00', currency).eq(salesTaxRecharge);
    const hasAppliedFloor = fee === scheme.floor && !hasCredit;

    let serviceFeesLabel;
    if (licenceFee && !new Money(licenceFee, currency).isZero()) {
      serviceFeesLabel = 'Licence fees';
    } else if (subscriptionFee && !new Money(subscriptionFee, currency).isZero()) {
      serviceFeesLabel = 'Subscription fees';
    } else {
      serviceFeesLabel = 'Payment processing fees';
    }

    const floorFeeLabel = 'Floored payment processing fee';

    const isShowingTwoTotals = targetCurrency && currency && targetCurrency !== currency;
    const isTotalInPaymentDetailsCurrency = currency === ownerFE.getBankCurrency();
    const shouldSwapTotals = isOutbound && isShowingTwoTotals && isTotalInPaymentDetailsCurrency;

    const firstTotalLabel = (!isTotalInPaymentDetailsCurrency && isOutbound && isShowingTwoTotals) ? 'Total' : 'Total amount due';
    const secondTotalLabel = (!isTotalInPaymentDetailsCurrency && isOutbound && isShowingTwoTotals) ? 'Total amount due' : 'Total in local currency';
    const firstTotalStyle = (!isTotalInPaymentDetailsCurrency && isOutbound && isShowingTwoTotals) ? '' : 'finance-report__summary-total-amount';

    const showExchangeRate = isOutbound
      && targetCurrency
      && currency
      && targetCurrency !== currency;

    return (
      <div className="finance-report__totals">
        <div className="finance-report__summary finance-report__summary--invoice">
          {hasCredit && (
            <div data-testid="invoice-main-table-totals-subtotal">
              <label>Subtotal</label>
              <NumberTpl prefix={currencySymbol} value={credit} />
            </div>
          )}

          {hasBillableRow && showBillableRow && (
            <div>
              <label>
                Billable subtotal
                {' '}
                <BillableSubtotalModal />
              </label>
              <NumberTpl prefix={currencySymbol} value={billable} />
            </div>
          )}

          {hasFee && (
            <React.Fragment>
              {hasAppliedFloor && !scheme.isFixed() && (
                <div>
                  <label>{floorFeeLabel}</label>
                  <NumberTpl prefix={currencySymbol} value={scheme.floor} />
                </div>
              )}

              {!hasAppliedFloor && (
                <div data-testid="invoice-main-table-totals-fees">
                  <label>
                    {serviceFeesLabel}
                    {' '}
                    {withFeeModal && (
                      <FeeAnalysisModal
                        invoicingSettings={invoicingSettings}
                        amounts={amounts}
                        month={month}
                        invoiceDate={invoiceDate}
                      />
                    )}
                  </label>
                  <NumberTpl prefix={currencySymbol} value={fee} />
                </div>
              )}
            </React.Fragment>
          )}

          {childrenBankFees > 0
            && (
              <div>
                <label>
                  Bank fees
                  {' '}
                  <BankFeesModal
                    amounts={amounts}
                  />
                </label>
                <NumberTpl prefix={currencySymbol} value={childrenBankFees} />
              </div>
            )
          }
          {hasSalesTaxRecharge && (
            <div>
              <label>
                Sales tax recharge
                {' '}
                <SalesTaxRechargeBreakdown
                  amounts={amounts}
                />
              </label>
              <NumberTpl prefix={currencySymbol} value={salesTaxRecharge} />
            </div>
          )}

          <div>
            <label>{`${taxSystemLabel} ${vatPercent ? `${vatPercent}%` : '0%'}`}</label>
            <NumberTpl prefix={currencySymbol} value={vat} />
          </div>
          { targetVat
            && !new Money(targetVat, targetCurrency).isZero()
            && targetCurrency
            && currency
            && targetCurrency !== currency
            && (
              <div className="pt-0">
                <label>{' '}</label>
                <span>
                  (
                  <NumberTpl prefix={targetCurrencySymbol} value={targetVat} />
                  )
                </span>
              </div>
            )
          }

          {shouldSwapTotals ? (
            <>
              <div>
                <label>{owner.isSystem ? secondTotalLabel : 'Estimated total in local currency'}</label>
                <NumberTpl prefix={targetCurrencySymbol} value={targetTotal} suffix={showExchangeRate ? '*' : ''} />
              </div>
              <div className={firstTotalStyle}>
                <label>{firstTotalLabel}</label>
                <NumberTpl prefix={currencySymbol} value={total} suffix={showExchangeRate ? '**' : ''} />
              </div>
            </>
          ) : (
            <>
              <div className={firstTotalStyle}>
                <label>{firstTotalLabel}</label>
                <NumberTpl prefix={currencySymbol} value={total} />
              </div>
              {isShowingTwoTotals && (
                <div className="finance-report__summary-local-amount">
                  <label>{owner.isSystem ? secondTotalLabel : 'Estimated total in local currency'}</label>
                  <NumberTpl prefix={targetCurrencySymbol} value={targetTotal} suffix={showExchangeRate ? '*' : ''} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

InvoiceMainTableTotals.propTypes = {
  amounts: PropTypes.object.isRequired,
  invoicingSettings: PropTypes.object.isRequired,
  month: PropTypes.string.isRequired,
  withFeeModal: PropTypes.bool,
  owner: PropTypes.object.isRequired,
  isOutbound: PropTypes.bool.isRequired,
  invoiceDate: PropTypes.string.isRequired,
};

InvoiceMainTableTotals.defaultProps = {
  withFeeModal: true,
};

export default InvoiceMainTableTotals;
