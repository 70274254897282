/* eslint import/prefer-default-export: "warn" */
import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';

import MonthlyReportButton from 'admin/assets/js/components/buttons/MonthlyReportButton.jsx';
import FeeMarginReportButton from 'admin/assets/js/components/buttons/FeeMarginReportButton.jsx';
import ReportButton from 'admin/assets/js/components/buttons/ReportButton.jsx';
import AORTalentdeskInvoicesReportButton from 'admin/assets/js/components/buttons/AORTalentdeskInvoicesReportButton.jsx';
import TenNinetyNineReportButton from 'admin/assets/js/components/buttons/TenNinetyNineReportButton.jsx';
import ProvidersForCountryButton from 'admin/assets/js/components/buttons/ProvidersForCountryReportButton.jsx';
import DistinctCurrenciesButton from 'admin/assets/js/components/buttons/DistinctCurrenciesButton.jsx';
import ViaFundsFlowButton from 'admin/assets/js/components/buttons/ViaFundsFlowButton.jsx';
import InvoicingCurrenciesButton from 'admin/assets/js/components/buttons/InvoicingCurrenciesButton.jsx';
import SeatUsageButton from 'admin/assets/js/components/buttons/SeatUsageButton.jsx';
import RunPaymentAnalysisETLButton from 'admin/assets/js/components/buttons/RunPaymentAnalysisETLButton.jsx';
import RunRemoteFeesETLButton from 'admin/assets/js/components/buttons/RunRemoteFeesETLButton.jsx';
import RunAmortizedSubscriptionFeesETLButton from 'admin/assets/js/components/buttons/RunAmortizedSubscriptionFeesETLButton.jsx';
import OrgReportButton from 'admin/assets/js/components/buttons/OrgReportButton.jsx';
import YearlyReportButton from 'admin/assets/js/components/buttons/YearlyReportButton.jsx';
import DateRangeReportButton from 'admin/assets/js/components/buttons/DateRangeReportButton.jsx';

const DownloadList = () => (
  <Card>
    <CardHeader title="Reports" />
    <CardContent>
      <MonthlyReportButton
        title="Download monthly transactions report"
        label="Monthly transactions"
        reportType="monthly-transactions"
        fileName="monthly-transactions-report.csv"
      />
      <MonthlyReportButton
        title="Download monthly payment status report"
        label="Monthly payment status"
        reportType="monthly-payment-status"
        fileName="monthly-payment-status-report.csv"
      />
      <ReportButton
        label="Aging"
        reportType="aging"
        fileName="aging-report.csv"
      />
      <ReportButton
        label="Client status"
        reportType="client-status"
        fileName="client-status-report.csv"
      />
      <MonthlyReportButton
        title="Download monthly fx reporting report"
        label="Monthly FX reporting"
        reportType="monthly-fx-reporting"
        fileName="monthly-fx-reporting-report.csv"
      />
      <ReportButton
        label="Incomplete payments"
        reportType="incomplete-payments"
        fileName="incomplete-payments-report.csv"
      />
      <TenNinetyNineReportButton />
      <ProvidersForCountryButton />
      <InvoicingCurrenciesButton />
      <DistinctCurrenciesButton />
      <ViaFundsFlowButton />
      <SeatUsageButton />
      <RunPaymentAnalysisETLButton />
      <RunRemoteFeesETLButton />
      <RunAmortizedSubscriptionFeesETLButton />
      <MonthlyReportButton
        title="Download monthly profit and loss per organization report"
        label="Profit and loss per organization"
        reportType="pandl-per-org"
        fileName="profit-and-loss-per-organization-report.csv"
      />
      <MonthlyReportButton
        title="Download monthly profit and loss per currency pair"
        label="Profit and loss per currency pair"
        reportType="pandl-per-currency-pair"
        fileName="profit-and-loss-per-currency-pair-report.csv"
      />
      <ReportButton
        label="Active organizations currencies and licence fee currencies"
        reportType="active-orgs-currency-vs-licence-fee-currency"
        fileName="active-orgs-currency-vs-licence-fee-currency-report.csv"
      />
      <MonthlyReportButton
        title="Download monthly trailing liabilities report"
        label="Monthly trailing liabilities"
        reportType="monthly-trailing-liabilities"
        fileName="monthly-trailing-liabilities.csv"
      />
      <OrgReportButton
        title="Download a report of provider payment methods for an organization"
        label="Provider payment methods for an organization"
        reportType="org-contractor-payment-method-report"
        fileName="org-contractor-payment-method-report.csv"
      />
      <ReportButton
        label="Active Provider payment methods for all organizations"
        reportType="orgs-active-provider-payment-methods-report"
        fileName="orgs-active-provider-payment-methods-report.csv"
      />
      <ReportButton
        label="Invoices addressed to Talentdesk Services LTD"
        reportType="invoices-for-talentdesk-services"
        fileName="invoices-for-talentdesk-services.csv"
      />
      <AORTalentdeskInvoicesReportButton />
      <ReportButton
        label="Providers account types per currency"
        reportType="providers-account-types-per-currency-report"
        fileName="provider-account-types-per-currency-report.csv"
      />
      <YearlyReportButton
        title="Download yearly cancelled and voided invoices report"
        label="Yearly cancelled and voided invoices"
        reportType="yearly-cancelled-voided-invoices-report"
        fileName="yearly-cancelled-voided-invoices-report.csv"
      />
      <DateRangeReportButton
        title="Unpaid contractor invoice total per org for year report"
        label="Unpaid contractor invoice total per org"
        reportType="unpaid-contractor-invoice-total-per-org-report"
        fileName="unpaid-contractor-invoice-total-per-org-report.csv"
        note="Note, dates are inclusive."
      />
      <ReportButton
        label="Paypal Providers"
        reportType="paypal-contractor-report"
        fileName="paypal-contractor-report.csv"
      />
      <FeeMarginReportButton
        title="Client processing fee margin"
        label="Client processing fee margin"
        reportType="fee-margin-report"
        fileName="fee-margin-report"
      />
    </CardContent>
  </Card>
);

export default DownloadList;
