import { isEmpty, get, set, uniq } from 'lodash';
import { assert, getReverseRate } from 'td-finance-ts';

import { assertAllKeysPresent, cartesianProduct } from 'core/assets/js/lib/utils';

export class Conversion {
  /**
   * Creates a Conversion from an instance of money
   *
   * @param {Money} fromMoney - the money to convert from
   * @param {Number} rate - the rate of the conversion
   * @param {BANK_CURRENCY} toCurrency - the target currency of the conversion
   * @returns {Conversion} a conversion
   */
  static fromMoneyRate(fromMoney, rate, toCurrency) {
    return new Conversion(fromMoney.getCurrency(), rate, toCurrency, {
      testFromAmount: fromMoney.toString(),
      testToAmount: fromMoney.convert(toCurrency, { rate }).toString(),
    });
  }

  static fromIdentity(currency) {
    return new Conversion(currency, 1.0, currency);
  }

  constructor(fromCurrency, rate, toCurrency, { testFromAmount, testToAmount } = {}) {
    assert(fromCurrency, 'fromCurrency is required');
    assert(toCurrency, 'toCurrency is required');
    assert(rate, `unknown rate for ${fromCurrency.toUpperCase()}${toCurrency.toUpperCase()}`);

    this.from = fromCurrency.toUpperCase();
    this.to = toCurrency.toUpperCase();
    this.rate = rate;
    this.testFromAmount = testFromAmount;
    this.testToAmount = testToAmount;
  }

  changeBase(fromCurrency, rate, { testFromAmount } = {}) {
    if (!fromCurrency) {
      throw new Error('fromCurrency is required');
    }
    if (!rate) {
      throw new Error('rate is required');
    }
    return new Conversion(fromCurrency, rate, this.to, {
      testFromAmount, testToAmount: this.testToAmount,
    });
  }

  /**
   * Returns whether the conversion contains any of the given currencies
   *
   * @param {BANK_CURRENCY[]} currencies - the currencies to test against
   * @returns {Boolean} whether the conversion contains any of the currencies
   */
  consistsOfAny(currencies) {
    const all = currencies.map(c => c.toUpperCase());
    return all.includes(this.from.toUpperCase()) && all.includes(this.to.toUpperCase());
  }
}

class RateMap {
  static _getFXMap(baseCurrency, allRates) {
    const fxMap = allRates.reduce((acc, { rate, testFromAmount, testToAmount, from, to }) => {
      const reverseRate = getReverseRate(rate, {
        testFromAmount, testToAmount,
      });

      if (rate === 0) {
        throw new Error('rate cannot be 0');
      }

      if (reverseRate === 0) {
        throw new Error(`reverse rate of ${rate} cannot be 0`);
      }

      if (!get(acc, `${from}.${to}`)) {
        if (acc[from]) {
          set(acc, `${from}.${to}`, parseFloat(rate));
        } else {
          set(acc, from, { [from]: 1, [to]: parseFloat(rate) });
        }
      }

      if (!get(acc, `${to}.${from}`)) {
        if (get(acc, to)) {
          set(acc, `${to}.${from}`, parseFloat(reverseRate));
        } else {
          set(acc, to, { [to]: 1, [from]: parseFloat(reverseRate) });
        }
      }

      return acc;
    }, {});

    cartesianProduct(allRates, allRates).forEach(([first, second]) => {
      const fwd = `${first.to}.${second.to}`;
      const bwd = `${second.to}.${first.to}`;
      if (!get(fxMap, fwd) && !get(fxMap, bwd)) {
        // cross currency triangulation
        // https://www.investopedia.com/articles/forex/09/currency-cross-triangulation.asp

        const base = baseCurrency.toUpperCase();

        const fwdRate = fxMap[first.to][base] / fxMap[second.to][base];
        const bwdRate = fxMap[second.to][base] / fxMap[first.to][base];

        set(fxMap, fwd, fwdRate);
        set(fxMap, bwd, bwdRate);
      }
    });

    return fxMap;
  }

  static fromSingleSourceRates(sourceCurrency, rates, { limitCurrencies } = {}) {
    const conversions = Object.entries(rates).map(
      ([targetCurrency, rate]) => (new Conversion(sourceCurrency, rate, targetCurrency)),
    ).filter(
      conversion => !limitCurrencies
      || isEmpty(limitCurrencies)
      || conversion.consistsOfAny(limitCurrencies),
    );
    return new RateMap(conversions);
  }

  static fromIdentity(currency) {
    return new RateMap([Conversion.fromIdentity(currency)]);
  }

  constructor(conversions) {
    this.init(conversions);
  }

  init(conversions) {
    if (!conversions) {
      throw new Error('conversions array is required');
    }
    if (!Array.isArray(conversions)) {
      throw new Error('expecting an array of conversions');
    }
    if (conversions.length === 0) {
      throw new Error('expecting at least one conversion');
    }
    if (!conversions.every(c => c instanceof Conversion)) {
      throw new Error('expecting each item to be an instance of Conversion');
    }
    const currencies = uniq(conversions.map(c => c.from));
    if (currencies.length !== 1) {
      throw new Error('RateMap expects an array of conversions from the same currency');
    }
    const [{ from: baseCurrency }] = conversions;
    if (!baseCurrency) {
      throw new Error('base currency is required');
    }
    this._conversions = conversions;
    this._baseCurrency = baseCurrency.toUpperCase();
    this._fxMap = RateMap._getFXMap(baseCurrency, conversions);
  }

  copy() {
    return new RateMap(this.getConversions());
  }

  getBaseCurrency() {
    return this._baseCurrency;
  }

  getBaseAmount() {
    const conversions = this.getConversions();
    const amounts = uniq(conversions.map(c => c.testFromAmount).filter(a => !!a));
    if (amounts.length > 1) {
      throw new Error('RateMap expects an array of conversions from the same amount');
    }
    const [{ testFromAmount: baseAmount }] = conversions;
    return baseAmount;
  }

  getConversions() {
    return this._conversions;
  }

  getRates(fromCurrency, { hintConversion } = {}) {
    let rates = this._fxMap[fromCurrency.toUpperCase()];
    if (!rates && hintConversion) {
      const { from, rate, to } = hintConversion;
      if (from !== fromCurrency.toUpperCase()) {
        throw new Error(`hint conversion ${from} does not match base currency ${fromCurrency}`);
      }
      // try to triangulate using common target currency
      rates = this.triangulateRates(to, rate);
    }
    return rates;
  }

  hasRate(fromCurrency, toCurrency) {
    assertAllKeysPresent({ fromCurrency, toCurrency });
    const rates = this.getRates(fromCurrency);
    if (!rates) {
      return false;
    }
    if (!rates[toCurrency.toUpperCase()]) {
      return false;
    }
    return true;
  }

  getRate(fromCurrency, toCurrency) {
    assertAllKeysPresent({ fromCurrency, toCurrency });
    const rates = this.getRates(fromCurrency);
    if (!rates) {
      throw new Error(`cannot convert from currency ${fromCurrency}`);
    }
    return rates[toCurrency.toUpperCase()];
  }

  triangulateRates(targetCurrency, targetRate) {
    const targetCurrencyRates = this.getRates(targetCurrency);
    if (!targetCurrencyRates) {
      throw new Error('unknown currencies');
    }

    // triangulate rates from base currency
    return Object.keys(targetCurrencyRates).reduce((acc, key) => {
      const throughToTargetRate = this.getRate(key, targetCurrency);
      Object.assign(acc, { [key]: targetRate / throughToTargetRate });
      return acc;
    }, {});
  }

  canChangeBase(newBaseCurrency) {
    const rates = this._fxMap[newBaseCurrency.toUpperCase()];
    return !!rates;
  }

  changeBase(newBaseCurrency, { hintConversion } = {}) {
    const from = get(hintConversion, 'from');
    const testFromAmount = get(hintConversion, 'testFromAmount');
    if (from && from !== newBaseCurrency.toUpperCase()) {
      throw new Error(`hint conversion ${from} does not match base currency ${newBaseCurrency}`);
    }
    const testToAmount = this.getBaseAmount();

    const originalConversions = this.getConversions();
    const originalCurrency = this.getBaseCurrency();
    if (newBaseCurrency.toUpperCase() === originalCurrency) {
      if (hintConversion) {
        return new RateMap([hintConversion, ...originalConversions]);
      }
      return new RateMap(originalConversions);
    }
    const newBaseCurrencyRates = this.getRates(newBaseCurrency, {
      hintConversion,
    });
    if (!newBaseCurrencyRates) {
      throw new Error(`cannot change to base ${newBaseCurrency}`);
    }
    const conversions = hintConversion ? [hintConversion] : [];
    conversions.push(...[
      new Conversion(
        newBaseCurrency, newBaseCurrencyRates[originalCurrency], originalCurrency,
        { testFromAmount, testToAmount },
      ),
      ...originalConversions.map(c => c.changeBase(
        newBaseCurrency, newBaseCurrencyRates[c.to.toUpperCase()],
        { testFromAmount },
      )),
    ]);
    return new RateMap(conversions);
  }

  changeBaseAccordingToConversion(newConversion) {
    return this.changeBase(newConversion.from, {
      hintConversion: newConversion,
    });
  }

  changeBaseAccordingToRateMap(rateMap) {
    const [baseConversion] = rateMap.getConversions();
    const rebased = this.changeBaseAccordingToConversion(baseConversion);
    const extended = rebased.extend(rateMap);
    return extended;
  }

  addConversion({ from, rate, to }) {
    const baseCurrency = this.getBaseCurrency();
    if (from.toUpperCase() !== baseCurrency.toUpperCase()) {
      throw new Error('expecting conversion from same currency');
    }
    this.init([
      new Conversion(from, rate, to),
      ...this.getConversions(),
    ]);
    return this;
  }

  extend(otherRateMap) {
    if (this.getBaseCurrency() !== otherRateMap.getBaseCurrency()) {
      throw new Error('cannot extend rate map with a different currency');
    }
    return new RateMap([
      ...otherRateMap.getConversions(),
      ...this.getConversions(),
    ]);
  }

  backfill(otherRateMap) {
    if (this.getBaseCurrency() !== otherRateMap.getBaseCurrency()) {
      if (otherRateMap.canChangeBase(this.getBaseCurrency())) {
        return this.backfill(otherRateMap.changeBase(this.getBaseCurrency()));
      }
      return this;
    }
    return new RateMap([
      ...this.getConversions(),
      ...otherRateMap.getConversions(),
    ]);
  }

  /**
   * Returns a copy of this rate map that contains just the given currencies
   *
   * @param {BANK_CURRENCY[]} currencies - the currencies to test against
   * @returns {RateMap} a copy of this rate map that contains just the given currencies
   */
  getRateMapOfCurrencies(currencies) {
    return new RateMap([...this.getConversions().filter(c => c.consistsOfAny(currencies))]);
  }

  /**
   * Converts money to another currency
   *
   * @param {Money} money - the money to convert
   * @param {BANK_CURRENCY} toCurrency - the currency to convert to
   * @returns {Money} the converted money
   */
  convert({ money, toCurrency }) {
    assertAllKeysPresent({ money, toCurrency });
    const reverseRate = this.getRate(toCurrency, money.getCurrency());
    if (!reverseRate) {
      throw new Error(`rateMap does not have rate from ${money.getCurrency()} to ${toCurrency}`);
    }
    return money.convert(toCurrency, { reverseRate });
  }
}

export default RateMap;
