import React from 'react';
import { Link } from 'react-router-dom';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import DeactivateOrgButton from 'admin/assets/js/components/buttons/DeactivateOrgButton.jsx';
import { useStyles } from 'admin/assets/js/layout/themes';
import { formatDate, getIsSuperAdmin } from 'admin/assets/js/lib/utils';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { SUBSCRIPTION_PLAN_LABELS } from 'finance/assets/js/constants';
import InvoicingFrequency from 'finance/assets/js/lib/InvoicingFrequency';

export const OrganizationsToBeDeactivatedView = () => {
  const classes = useStyles();
  const isSuperAdmin = getIsSuperAdmin();
  if (!isSuperAdmin) {
    return null;
  }
  return (
    <ApiTable
      columns={[
        {
          label: 'Name',
          name: 'name',
          options: {
            cellRender: (_, org) => {
              const invoicingFrequencyPOJO = InvoicingFrequency.fromDump(
                org.invoicing_frequency_dump,
              );
              return (
                <div>
                  <Link to={`/organizations/${org.id}/show`}>
                    <strong>{`#${org.id} ${org.name}`}</strong>
                  </Link>
                  <br />
                  <span className={classes.discreet}>
                    {org.unique_alias}
                    {' '}
                  </span>
                  <div className={classes.discreet}>
                    {`
                    (${org.invoicing_mode}
                      /
                    ${invoicingFrequencyPOJO.toHumanizedString()}
                    )
                    `}
                  </div>
                </div>
              );
            },
            downloadCellRender: name => name,
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Created at',
          name: 'created_at',
          options: {
            cellRender: createdAt => formatDate(createdAt),
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Subscription',
          name: 'subscription_plan',
          options: {
            cellRender: plan => SUBSCRIPTION_PLAN_LABELS[plan],
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Providers',
          name: 'providers_count',
          options: { filter: false, sort: true },
        },
        {
          label: 'Managers',
          name: 'managers_count',
          options: { filter: false, sort: true },
        },
        {
          label: 'Last invoice MRR',
          name: 'last_invoice_date',
          options: {
            cellRender: (_, { lastInvoice }) => {
              if (!lastInvoice) {
                return '';
              }
              return (
                <div className="d-flex flex-column">
                  <span>{formatDate(lastInvoice.created_at)}</span>
                  <span>
                    <NumberTpl
                      prefix={CURRENCY_SYMBOL[lastInvoice.currency]}
                      value={lastInvoice.total}
                    />
                  </span>
                  <span>
                    <Link to={`/children_invoices?filter=%7B"parent_id"%3A${lastInvoice.id}%7D`}>
                      View invoice
                    </Link>
                  </span>
                </div>
              );
            },
            customFilterListOptions: {
              render: value => `${value} months since last invoice`,
            },
            downloadCellRender: date => date,
            filter: true,
            filterOptions: {
              names: [4, 5, 6, 7],
              renderValue: value => `${value} months`,
            },
            sort: true,
          },
        },
        {
          label: 'Last approved service order',
          name: 'last_approved_service_order',
          options: {
            cellRender: date => formatDate(date),
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Actions',
          name: 'last_approved_service_order',
          options: {
            cellRender: (_, record) => (
              <DeactivateOrgButton
                label="Deactivate"
                record={record}
                reloadApiTable
                resource="organizations"
              />
            ),
            filter: false,
            sort: false,
          },
        },
      ]}
      defaultSortBy="name"
      defaultSortDirection="ASC"
      resource="organizations-to-be-deactivated"
    />
  );
};

export default OrganizationsToBeDeactivatedView;
