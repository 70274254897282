import React from 'react';
import PropTypes from 'prop-types';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';

const InvoiceSingleAmountField = ({ amount, currency }) => {
  if (!amount || !currency) {
    return null;
  }
  const symbol = CURRENCY_SYMBOL[currency];
  return (
    <NumberTpl value={amount} prefix={symbol} />
  );
};

InvoiceSingleAmountField.propTypes = {
  amount: PropTypes.string,
  currency: PropTypes.string,
};

InvoiceSingleAmountField.defaultProps = {
  amount: null,
  currency: null,
};

export default InvoiceSingleAmountField;
