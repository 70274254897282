import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isArray, pick, startCase } from 'lodash';
import queryString from 'query-string';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import { USER_TYPE, USER_TYPE_LABEL, ORDERING_DIRECTION } from 'core/assets/js/constants';
import { parseQueryArrayIds } from 'core/assets/js/lib/utils';
import { RATE_UNIT } from 'rates/assets/js/constants';
import { languageOptions } from 'core/assets/js/lib/isoLanguages';
import { USER_EMPLOYMENT_TYPE, USER_EMPLOYMENT_TYPE_LABEL } from 'people/assets/js/constants';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import RangeFilterField from 'core/assets/js/components/FinalFormFilterFields/RangeFilterField.jsx';
import SkillsFilterField from 'core/assets/js/components/FinalFormFilterFields/SkillsFilterField.jsx';
import TagsFilterField from 'core/assets/js/components/FinalFormFilterFields/TagsFilterField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import { PROJECT_MEMBER_STATUS, PROJECT_MEMBER_STATUS_LABEL } from 'projects/assets/js/constants';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import { SKILL_IDS_MATCH } from 'skills/assets/js/constants';

class ProjectMemberSearch extends React.Component {
  constructor(props) {
    super(props);
    this.getSearchSpec = this.getSearchSpec.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
  }

  getInitialValues() {
    const { location } = this.props;
    const searchSpec = this.getSearchSpec();


    const filtersList = [];
    searchSpec.filters.forEach((f) => {
      // Add all paramNames if is array
      if (isArray(f.paramName)) {
        f.paramName.forEach(paramName => filtersList.push(paramName));
      }

      filtersList.push(f.paramName);
    });

    let values = queryString.parse(location.search);
    values = pick(values, ['kw', 'ordering', 'skillIdsMatch'].concat(filtersList));
    values.skillIds = parseQueryArrayIds(values.skillIds).map(skillId => ({ value: skillId }));
    values.skillIdsMatch = values.skillIdsMatch || SKILL_IDS_MATCH.OR;

    return values;
  }

  getSearchSpec() {
    // Prepare Status filter options
    const statusOptions = Object.keys(PROJECT_MEMBER_STATUS).map(status => ({
      text: PROJECT_MEMBER_STATUS_LABEL[PROJECT_MEMBER_STATUS[status]],
      value: PROJECT_MEMBER_STATUS[status],
    }));
    const employmentTypeOptions = Object.values(USER_EMPLOYMENT_TYPE).map(type => ({
      text: startCase(USER_EMPLOYMENT_TYPE_LABEL[type]),
      value: type,
    }));
    const userTypeOptions = Object.values(USER_TYPE).map(type => ({
      text: startCase(USER_TYPE_LABEL[type]),
      value: type,
    }));
    const { activeOrg, rateLimits } = this.props;
    const rateUnit = activeOrg.default_rate_unit;
    const isCommissionBased = rateUnit === RATE_UNIT.COMMISSION;

    const filters = [
      {
        label: 'Rate',
        paramName: 'rate',
        fieldComponent: props => (
          <AllowAccessToManager>
            <RangeFilterField {...props} />
          </AllowAccessToManager>
        ),
        min: rateLimits.min,
        max: rateLimits.max,
        step: rateLimits.step,
        symbol: isCommissionBased ? '%' : CURRENCY_SYMBOL[activeOrg.currency],
      },
      {
        label: 'Status',
        paramName: 'status',
        fieldComponent: props => (
          <AllowAccessToManager>
            <SelectableListFilterField {...props} />
          </AllowAccessToManager>
        ),
        multiple: true,
        options: statusOptions,
      },
      {
        label: 'User Type',
        paramName: 'ofType',
        fieldComponent: SelectableListFilterField,
        multiple: false,
        options: userTypeOptions,
      },
      {
        label: 'Language',
        paramName: 'languageCode',
        fieldComponent: TagsFilterField,
        inputClassName: 'col-12 col-md-6',
        options: languageOptions,
        multiple: true,
      },
      {
        label: 'Employment Type',
        paramName: 'employmentType',
        fieldComponent: SelectableListFilterField,
        multiple: false,
        options: employmentTypeOptions,
      },
      {
        fieldComponent: SkillsFilterField,
        label: 'Skills',
        multiple: true,
        paramName: 'skillIds',
      },
    ];

    const spec = {
      defaultOrdering: {
        direction: ORDERING_DIRECTION.DESC,
        sortBy: 'joined_at',
      },
      orderingOptions: [
        { text: 'Join Date', value: 'joined_at' },
        { text: 'Last Name', value: 'last_name' },
      ],
      searchTerm: {
        placeholder: 'Search members',
        paramName: 'kw',
        component: TextInputField,
      },
      filters,
    };

    return spec;
  }

  render() {
    const { onFiltersToggle, filtersOpen } = this.props;

    return (
      <SearchFinalForm
        extraResetFilterParamNames={['skillIdsMatch']}
        filtersOpen={filtersOpen}
        initialValues={this.getInitialValues()}
        name="projectMembers"
        onFiltersToggle={onFiltersToggle}
        parseFilters={filtersIn => {
          const filters = JSON.parse(JSON.stringify(filtersIn));
          if (Array.isArray(filters.skillIds) && filters.skillIds.length > 0) {
            filters.skillIds = filters.skillIds.map(s => s.value);
          }
          return filters;
        }}
        searchSpec={this.getSearchSpec()}
      />
    );
  }
}

ProjectMemberSearch.propTypes = {
  activeOrg: orgSpec.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  rateLimits: PropTypes.object,
};

ProjectMemberSearch.defaultProps = {
  rateLimits: {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});
const ProjectMemberSearchConnect = connect(
  mapStateToProps,
)(ProjectMemberSearch);

export default withRouter(ProjectMemberSearchConnect);
