import React from 'react';
import { NumberInput, SelectInput } from 'react-admin';
import { Icon } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Money } from 'td-finance-ts';

import { CURRENCY_LABEL, ICON } from 'core/assets/js/constants';
import {
  INVOICING_MODE,
  INVOICING_MODE_LABEL,
  TRANSACTION_METHOD,
  BILL_PAYMENTS_TOLERANCE_PERCENTAGE,
} from 'finance/assets/js/constants';
import TransactionFormAmountsPreviewComponent from 'admin/assets/js/components/transactions/TransactionFormAmountsPreviewComponent.jsx';
import TransactionAmounts from 'finance/assets/js/lib/TransactionAmounts';
import { shouldCreateBillPaymentInboundTransOnPay } from 'finance/assets/js/lib/utils';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';

const currencyOptions = Object.keys(CURRENCY_LABEL).map(k => ({
  id: k, name: CURRENCY_LABEL[k],
}));

const TransactionFormAmountsComponent = ({
  disabled, formData, invoice, cashoutInvoices, exchangeRates,
  mode,
}) => {
  const isGroupOfInvoices = !isEmpty(cashoutInvoices);
  const invoiceAmounts = isGroupOfInvoices
    ? cashoutInvoices.map(inv => new InvoiceAmounts(inv.amounts))
    : new InvoiceAmounts(invoice.amounts);


  if (isEmpty(invoiceAmounts)) {
    return null;
  }

  const { method } = formData;

  let serializedAmounts;
  if (isGroupOfInvoices) {
    serializedAmounts = TransactionAmounts.fromMultipleInvoiceAmounts(invoiceAmounts).serialize();
  } else {
    try {
      serializedAmounts = disabled
        ? formData.amounts
        : TransactionAmounts.createFromFormData({
          formData, invoiceAmounts, exchangeRates,
        }).serialize();
    } catch (e) {
      serializedAmounts = null;
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  const feeLabel = 'Fee';
  let amountSourcePath;
  let outgoingAmountLabel;
  let netOutgoingAmountLabel;
  switch (method) {
    case TRANSACTION_METHOD.WORLDPAY:
      outgoingAmountLabel = 'Debit amount';
      netOutgoingAmountLabel = 'Debit amount';
      amountSourcePath = 'amounts.inBalanceCurrency.netOutgoingAmount';
      break;
    default:
      amountSourcePath = 'amounts.inBalanceCurrency.outgoingAmount';
      outgoingAmountLabel = 'Amount sent (fee included)';
      netOutgoingAmountLabel = 'Amount sent (fee excluded)';
      break;
  }

  let contractorCharges;
  if (!isGroupOfInvoices) {
    contractorCharges = invoiceAmounts.getContractorCharges();
  } else {
    contractorCharges = invoiceAmounts.reduce((acc, curr) => {
      const currentAmount = curr.getContractorCharges();
      return acc.add(currentAmount);
    }, new Money(0, invoiceAmounts[0].getCurrency())).toString();
  }
  const amountToBeRecorded = get(formData, amountSourcePath);
  let showBillPaymentsTransMsg = false;

  if (amountToBeRecorded && contractorCharges) {
    showBillPaymentsTransMsg = !shouldCreateBillPaymentInboundTransOnPay(
      mode,
      contractorCharges,
      amountToBeRecorded,
    );
  }

  // note - the first column, the currency select, has a min width setting
  //        which will override any flex portion :/
  let columnWidths;
  switch (method) {
    case TRANSACTION_METHOD.TRANSFERWISE:
    case TRANSACTION_METHOD.REVOLUT:
    case TRANSACTION_METHOD.PAYPAL:
      // need to allow for wider column title for TrW ( middle column )
      columnWidths = [{ flex: 1 }, { flex: 1.1 }, { flex: 1 }];
      break;
    case TRANSACTION_METHOD.REALNET:
    case TRANSACTION_METHOD.STRIPE:
      // need to allow for wider column title for RealNet ( middle column )
      columnWidths = [{ flex: 1 }, { flex: 1.1 }, { flex: 1 }, { flex: 1 }];
      break;
    default:
      columnWidths = [{ flex: 1 }, { flex: 4 }];
      break;
  }

  // Note - we swap the labels for Outgoing and Net when the transaction method is PayPal,
  // in PayPal terminology, Net includes Fee, in our system it does not

  return (
    <>
      <div className="d-flex justify-content-center align-items-center mb-4">
        <Icon className={`font-weight-normal ${ICON.CALCULATOR} mr-2`} />
        <span className="text-uppercase">Transaction amounts</span>
      </div>
      <p className="hint">NOTICE: values may change if they differ from the Payments Provider records</p>
      {
        showBillPaymentsTransMsg && (
          <p className="danger">
            {`
              IMPORTANT: The mode of this invoice is designated as ${INVOICING_MODE_LABEL[INVOICING_MODE.BILL_PAYMENTS]}. Due to a significant discrepancy between the transaction amount and the contractor charges, no inbound transactions will be generated when recording this transaction.
              To create the inbound transactions, an amount close to ${100 - BILL_PAYMENTS_TOLERANCE_PERCENTAGE}% of the invoice's amount should be recorded.
            `}
          </p>
        )
      }
      <div className="d-flex align-items-center mb-2">
        <SelectInput
          disabled={disabled}
          style={columnWidths[0]}
          label="Currency"
          source="amounts.inBalanceCurrency.currency"
          choices={currencyOptions}
        />
        {method !== TRANSACTION_METHOD.WORLDPAY && (
          <NumberInput
            disabled={disabled}
            style={columnWidths[1]}
            source="amounts.inBalanceCurrency.outgoingAmount"
            label={outgoingAmountLabel}
            step={0.01}
            validate={(value) => {
              if (!value) {
                return 'The amount is malformed';
              }
              return undefined;
            }}
          />
        )}
        {[
          TRANSACTION_METHOD.REALNET,
          TRANSACTION_METHOD.STRIPE,
        ].includes(method) && (
          <NumberInput
            disabled={disabled}
            style={columnWidths[3]}
            source="amounts.targetRate"
            label="Exchange rate"
            step={0.01}
          />
        )}
        {[
          TRANSACTION_METHOD.WORLDPAY,
        ].includes(method) && (
          <NumberInput
            disabled={disabled}
            style={columnWidths[2]}
            source="amounts.inBalanceCurrency.netOutgoingAmount"
            label={netOutgoingAmountLabel}
            step={0.01}
            validate={(value) => {
              if (!value) {
                return 'The amount is malformed';
              }
              return undefined;
            }}
          />
        )}
        {![
          TRANSACTION_METHOD.WORLDPAY,
          TRANSACTION_METHOD.PAYONEER,
        ].includes(method) && (
          <NumberInput
            disabled={disabled}
            style={{ flex: 1 }}
            source="amounts.inBalanceCurrency.totalFee"
            label={feeLabel}
            step={0.01}
            validate={(value) => {
              if (!value) {
                return 'The amount is malformed';
              }
              return undefined;
            }}
          />
        )}
      </div>
      {serializedAmounts && <TransactionFormAmountsPreviewComponent amounts={serializedAmounts} />}
    </>
  );
};

TransactionFormAmountsComponent.propTypes = {
  formData: PropTypes.object.isRequired,
  invoice: PropTypes.object,
  cashoutInvoices: PropTypes.array,
  exchangeRates: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  mode: PropTypes.string.isRequired,
};


TransactionFormAmountsComponent.defaultProps = {
  invoice: {},
  cashoutInvoices: [],
  disabled: false,
};

export default TransactionFormAmountsComponent;
