import { omit } from 'lodash';
import { Money } from 'td-finance-ts';

import { TRANSACTION_MODE, WISE_PAYOUT_MODE } from 'finance/assets/js/constants';
import { assertAllKeysPresent } from 'core/assets/js/lib/utils';

// represents whether we use a source amount
// or target amount when ordering a transaction
const ORDER_SUBJECT = {
  SOURCE: 'source_amount',
  TARGET: 'target_amount',
};

/**
 * A class to encapsulate all details for ordering a remote transaction
 */
class TransactionOrder {
  /**
   * It returns the payout that will be used when creating a quote.
   *
   * @private
   * @see this.getWiseRequestParams
   * @param {object} options
   * @param {boolean} options.shouldForceSWIFT - whether quote should be forced to be a SWIFT one
   *
   * @returns {string|undefined} - the payout that will be used for the Quote
   * Undefined is returned when no specific payOut should be set to the Quote
   * in which case, the default one is used by Wise.
   */
  static getPayOut({ shouldForceSWIFT = false } = {}) {
    if (shouldForceSWIFT) {
      return WISE_PAYOUT_MODE.SWIFT;
    }
    return undefined;
  }

  /**
   * Utility method to resolve the subject of an order given the transaction mode
   * and its currencies
   *
   * @param {TRANSACTION_MODE} transactionMode - the transaction mode of the order
   * @param {BANK_CURRENCY} invoiceCurrency - the invoice currency
   * @param {BANK_CURRENCY} sourceCurrency - the source currency of the order
   * @param {BANK_CURRENCY} targetCurrency - the target currency of the order
   *
   * @returns {ORDER_SUBJECT} - the subject of the order
   */
  static _getSubject({
    transactionMode,
    invoiceCurrency,
    sourceCurrency,
    targetCurrency,
  }) {
    // when we pass fees to contractor, we should always use the source amount
    if (transactionMode === TRANSACTION_MODE.FIXED_SO_AMOUNT) {
      return ORDER_SUBJECT.SOURCE;
    }

    // we need to select whether we will use a source amount or a target
    // amount in our order. Using target amount means that any fees will be
    // absorbed by Talentdesk, using source amount means that any fees will
    // be absorbed by the contractors
    if (sourceCurrency === targetCurrency) {
      return ORDER_SUBJECT.TARGET;
    }
    switch (transactionMode) {
      case TRANSACTION_MODE.FIXED_INVOICE:
        if (invoiceCurrency === targetCurrency) {
          return ORDER_SUBJECT.TARGET;
        }
        if (invoiceCurrency === sourceCurrency) {
          return ORDER_SUBJECT.SOURCE;
        }
        return ORDER_SUBJECT.TARGET;
      default:
        return ORDER_SUBJECT.SOURCE;
    }
  }

  /**
   * Returns the currencies that will be used for an order depending on
   * the transaction mode and the invoice currencies
   *
   * @param {TRANSACTION_MODE} transactionMode - the transaction mode of the order
   * @param {Object} invoiceCurrencies - the invoice currencies
   * @param {Boolean} ignoreBankCurrency - whether we should respect or ignore
   *                                       the bank currency of the invoice when
   *                                       selecting the currencies for the order
   *
   * @returns {Object} - the currencies and subject of the order
   */
  static selectCurrenciesForTransactionMode({
    transactionMode,
    invoiceCurrencies: {
      currency: invoiceCurrency,
      targetCurrency: bankCurrency,
      balanceCurrency: orgBalanceCurrency,
    },
    ignoreBankCurrency = false,
  } = {}) {
    assertAllKeysPresent({
      transactionMode, invoiceCurrency, bankCurrency, orgBalanceCurrency, ignoreBankCurrency,
    });

    let sourceCurrency;
    let targetCurrency;

    switch (transactionMode) {
      case TRANSACTION_MODE.FIXED_INVOICE:
        // by default we use the predefined balance currency from org settings
        sourceCurrency = orgBalanceCurrency;

        if (ignoreBankCurrency) {
          // we attempt to send funds towards the invoice currency,
          // ignoring temporarily the local bank currency, in case the
          // bank can handle incoming funds different than its base currency
          targetCurrency = invoiceCurrency;
        } else {
          // fall back to using predefined local bank currency from invoice amounts
          targetCurrency = bankCurrency;
        }
        break;
      default:
        // by default we use the predefined balance currency from org settings
        sourceCurrency = orgBalanceCurrency;
        // by default we attempt to send towards the local bank currency
        targetCurrency = bankCurrency;
    }
    const orderSubject = TransactionOrder._getSubject({
      transactionMode,
      invoiceCurrency,
      sourceCurrency,
      targetCurrency,
    });
    return { sourceCurrency, targetCurrency, orderSubject };
  }

  /**
   * Creates a transaction order from some invoiced money
   *
   * @param {Money} owedMoney - the total money we want to move, in an arbitrary currency,
   *                            usually calculated from the invoiced money in a fixed
   *                            currency
   * @param {TRANSACTION_MODE} transactionMode - the transaction mode of the order
   * @param {BANK_CURRENCY} invoiceCurrency - the currency of the respective invoice
   * @param {BANK_CURRENCY} bankCurrency - the currency of the bank account we target
   * @param {BANK_CURRENCY} orgBalanceCurrency - the balance currency of the respective organization
   * @param {TransactionRateMap} rateMapAtTransactionTime - the rate map at the time
   *                                                        of transaction (usually now)
   * @param {Boolean} ignoreBankCurrency - whether we should respect or ignore
   *                                       the bank currency of the invoice when
   *                                       selecting the currencies for the order
   *
   * @returns {TransactionOrder} - the transaction order
   */
  static fromOwedMoney({
    owedMoney,
    transactionMode,
    rateMapAtTransactionTime,
    currencies: {
      invoiceCurrency,
      bankCurrency,
      orgBalanceCurrency,
    },
    ignoreBankCurrency,
  }) {
    assertAllKeysPresent({
      owedMoney,
      transactionMode,
      invoiceCurrency,
      bankCurrency,
      orgBalanceCurrency,
      rateMapAtTransactionTime,
      ignoreBankCurrency,
    });

    const {
      sourceCurrency,
      targetCurrency,
      orderSubject,
    } = TransactionOrder.selectCurrenciesForTransactionMode({
      transactionMode,
      invoiceCurrencies: {
        currency: invoiceCurrency,
        targetCurrency: bankCurrency,
        balanceCurrency: orgBalanceCurrency,
      },
      ignoreBankCurrency,
    });

    return new TransactionOrder({
      sourceCurrency,
      targetCurrency,
      orderSubject,
      sourceAmount: rateMapAtTransactionTime.convert({
        money: owedMoney,
        toCurrency: sourceCurrency,
      }).toString(),
      targetAmount: rateMapAtTransactionTime.convert({
        money: owedMoney,
        toCurrency: targetCurrency,
      }).toString(),
    });
  }

  /**
   * Constructor.
   * @param  {...any} args - instance values.
   */
  constructor(...args) {
    this.init(...args);
  }

  init({
    sourceCurrency,
    sourceAmount,
    targetCurrency,
    targetAmount,
    orderSubject: predefinedQuoteSubject,
  }) {
    assertAllKeysPresent({ sourceCurrency, targetCurrency });
    let orderSubject = predefinedQuoteSubject;
    if (!orderSubject) {
      if (!sourceAmount && !targetAmount) {
        throw new Error('either source amount or target amount is required');
      }
      // if (sourceAmount && targetAmount) {
      //   throw new Error('cannot set both source amount and target amount');
      // }
      orderSubject = sourceAmount ? ORDER_SUBJECT.SOURCE : ORDER_SUBJECT.TARGET;
    }

    if (orderSubject === ORDER_SUBJECT.SOURCE && !sourceCurrency) {
      throw new Error('sourceCurrency is required');
    }
    if (orderSubject === ORDER_SUBJECT.TARGET && !targetCurrency) {
      throw new Error('targetCurrency is required');
    }
    const details = {
      sourceCurrency: sourceCurrency.toLowerCase(),
      sourceAmount: orderSubject === ORDER_SUBJECT.SOURCE
        ? new Money(sourceAmount, sourceCurrency).toString()
        : null,
      targetCurrency: targetCurrency.toLowerCase(),
      targetAmount: orderSubject === ORDER_SUBJECT.TARGET
        ? new Money(targetAmount, targetCurrency).toString()
        : null,
      orderSubject,
    };
    Object.assign(this, { details });
  }

  serialize() {
    const { orderSubject } = this.details;
    return omit(this.details, ['orderSubject', orderSubject === ORDER_SUBJECT.SOURCE ? 'targetAmount' : 'sourceAmount']);
  }

  copy() {
    return new TransactionOrder(this.serialize());
  }

  getSourceCurrency() {
    const { sourceCurrency } = this.details;
    return sourceCurrency;
  }

  getTargetCurrency() {
    const { targetCurrency } = this.details;
    return targetCurrency;
  }

  getSourceAmount() {
    const { orderSubject, sourceAmount } = this.details;
    if (orderSubject !== ORDER_SUBJECT.SOURCE) {
      return null;
    }
    return sourceAmount;
  }

  getSourceMoney() {
    const { orderSubject, sourceCurrency, sourceAmount } = this.details;
    if (orderSubject !== ORDER_SUBJECT.SOURCE) {
      return null;
    }
    return new Money(sourceAmount, sourceCurrency);
  }

  getTargetAmount() {
    const { orderSubject, targetAmount } = this.details;
    if (orderSubject !== ORDER_SUBJECT.TARGET) {
      return null;
    }
    return targetAmount;
  }

  getTargetMoney() {
    const { orderSubject, targetCurrency, targetAmount } = this.details;
    if (orderSubject !== ORDER_SUBJECT.TARGET) {
      return null;
    }
    return new Money(targetAmount, targetCurrency);
  }

  getSubject() {
    const { orderSubject } = this.details;
    return orderSubject;
  }

  /**
   * Returns all parameters for creating a remote order
   * @returns {object} all parameters for creating a remote order
   */
  _getRequestParams() {
    const {
      orderSubject,
      sourceCurrency,
      sourceAmount,
      targetCurrency,
      targetAmount,
    } = this.details;
    if (orderSubject === ORDER_SUBJECT.SOURCE) {
      return {
        sourceCurrency,
        sourceAmount,
        targetCurrency,
      };
    }
    return {
      sourceCurrency,
      targetCurrency,
      targetAmount,
    };
  }

  /**
   * Returns all parameters for creating a remote quote for Wise
   *
   * @param {object} options
   * @param {boolean} options.shouldForceSWIFT - whether quote should be forced to be a SWIFT one
   *
   * @returns {object} all parameters for creating a remote quote
   */
  getWiseRequestParams({ shouldForceSWIFT = false } = {}) {
    return {
      ...this._getRequestParams(),
      payOut: TransactionOrder.getPayOut({ shouldForceSWIFT }),
    };
  }

  /**
   * Adjusts the order according the expected fee that it will have
   *
   * Depending on who absorbs the fee, an order amount may be increased or
   * decreased to accommodate for the fee. This method calculates the
   * adjusted order
   *
   * @param {String} expectedFee - the expected fee for this order
   * @param {BANK_CURRENCY} expectedFeeCurrency - the currency of the expected fee
   * @param {boolean} providerAbsorbsFees - whether the provider should absorb the fees
   * @param {boolean} isFeeChargedInFutureStep - whether the fee is charged within the
   *                                             order or in a future step (e.g. Wise vs Payoneer)
   *
   * @returns {TransactionOrder} the adjusted transaction order, accounting for the expected fee
   */
  adjustAccordingToFee({
    expectedFee, expectedFeeCurrency, providerAbsorbsFees = false, isFeeChargedInFutureStep = false,
  } = {}) {
    assertAllKeysPresent({
      expectedFee, expectedFeeCurrency, providerAbsorbsFees, isFeeChargedInFutureStep,
    });
    const { sourceCurrency, targetCurrency, orderSubject } = this.details;

    if (expectedFeeCurrency !== this.getSourceCurrency()) {
      throw new Error(`fee currency is not matching outgoing currency (${expectedFeeCurrency} != ${this.getSourceCurrency()})`);
    }

    const sourceMoney = this.getSourceMoney();
    if (sourceMoney) {
      // we may adjust how much we send only if we control the source amount

      if (providerAbsorbsFees && isFeeChargedInFutureStep) {
        // provider absorbs the fee, so remove it from the amount we are expected to send
        return new TransactionOrder({
          sourceCurrency, targetCurrency, orderSubject,
          sourceAmount: sourceMoney.sub(expectedFee).toString(),
        });
      }

      if (!providerAbsorbsFees && !isFeeChargedInFutureStep) {
        // TD absorbs the fee, so add it to the amount we are expected to send
        return new TransactionOrder({
          sourceCurrency, targetCurrency, orderSubject,
          sourceAmount: sourceMoney.add(expectedFee).toString(),
        });
      }
    }

    return this.copy();
  }
}

export default TransactionOrder;
