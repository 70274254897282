import { isEmpty } from 'lodash';
import { CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import { SYSTEM, CURRENCY } from 'core/assets/js/constants';
import AORFeeAnalysis from 'finance/assets/js/lib/fees/AORFeeAnalysis';

class AORFeeScheme {
  static getDefaultSystemReg() {
    return SYSTEM.AOR_FEES_REGISTRATION_NUMBER;
  }

  static getEmpty(hasSpec = true) {
    return new AORFeeScheme({
      currency: CURRENCY.USD,
      perAORSeat: '0.00',
      hasSpec,
    });
  }

  static getDefault() {
    return new AORFeeScheme({
      currency: CURRENCY.USD,
      perAORSeat: '99.00',
    });
  }

  static fromFormValues(values) {
    const { currency, price, systemReg } = values;
    return new AORFeeScheme({
      currency,
      perAORSeat: new Money(price, currency).toString(),
      systemReg,
    });
  }

  /**
   * It is used for de-serializing a AORFeeScheme serialized object
   *
   * @param {Object} serialized
   *
   * @returns {AORFeeScheme}
   */
  constructor(serialized) {
    if (isEmpty(serialized)) {
      return AORFeeScheme.getEmpty(false);
    }
    if (serialized instanceof AORFeeScheme) {
      return new AORFeeScheme(serialized.serialize());
    }
    this.init(serialized);
  }

  /**
   * @param {Object} params
   * @param {String} params.currency
   * @param {String} params.perAORSeat
   * @param {Boolean} params.hasSpec=true
   */
  init({
    currency,
    perAORSeat = '0.00',
    hasSpec = true,
  } = {}) {
    if (!currency) {
      throw new Error('currency is required');
    }
    if (!perAORSeat) {
      throw new Error('perAORSeat is required');
    }
    const systemReg = AORFeeScheme.getDefaultSystemReg();
    this.hasSpec = hasSpec;
    this.details = {
      currency,
      perAORSeat,
      systemReg,
    };
  }

  copy() {
    return new AORFeeScheme(this.serialize());
  }

  serialize() {
    if (this.isEmpty()) {
      return null;
    }
    return {
      ...this.details,
    };
  }

  getCurrency() {
    const { currency } = this.details;
    return currency;
  }

  getCurrencySymbol() {
    return CURRENCY_SYMBOL[this.getCurrency()];
  }

  getPerAORSeat() {
    const { perAORSeat } = this.details;
    return perAORSeat;
  }

  getSystemRegistrationNumber() {
    const { systemReg } = this.details;
    return systemReg;
  }

  isEmpty() {
    return !this.hasSpec;
  }

  isZero() {
    const { perAORSeat } = this.details;
    return new Money(perAORSeat, this.getCurrency()).isZero();
  }

  hasFee() {
    return !this.isZero();
  }

  /**
   * This method applies the metrics passed to the settings set by the AOR scheme
   * The result is the applied charges
   *
   * @param {Object} metrics (The metrics for the AOR services)
   * @param {Number} metrics.numAORSeats=0 - number of user seats that are using AOR
   * @param {Boolean} metrics.hasBeenEnabled=false - if the service has been enabled in an org level
   * charges.
   * @param {String} metrics.systemRegistrationNumber - The system reg number used when applying AOR
   * charges
   *
   * @returns {AORFeeAnalysis}
   */
  apply({
    hasBeenEnabled = false,
    numAORSeats = 0,
    feeBreakdownItems = [],
    systemRegistrationNumber,
  } = {}) {
    const { currency, perAORSeat, systemReg } = this.details;

    if (systemRegistrationNumber !== systemReg) {
      // When system numbers don't match it means that the scheme's generated charges
      // are not applied by the system entity's reg number used. So we return an empty analysis.

      return AORFeeAnalysis.getEmptyAnalysis();
    }
    return new AORFeeAnalysis({
      currency,
      perAORSeat,
      numAORSeats,
      hasBeenEnabled,
      feeBreakdownItems,
    });
  }

  toFormValues() {
    return {
      price: this.getPerAORSeat(),
      currency: this.getCurrency(),
      systemReg: this.getSystemRegistrationNumber(),
    };
  }
}

export default AORFeeScheme;
