/* eslint max-len: "warn", react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import PropTypes from 'prop-types';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import TextInput from 'admin/assets/js/components/inputs/TextInput.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded';
import {
  List, ReferenceField, Datagrid, TextField, Filter,
  FunctionField, SelectInput,
} from 'react-admin';
import {
  INVOICE_TYPE, TRANSACTION_STATUS,
  TRANSACTION_STATUS_LABEL,
  TRANSACTION_TYPE_LABEL,
} from 'finance/assets/js/constants';
import { formatDate } from 'admin/assets/js/lib/utils';

const Empty = () => (
  <p>No records</p>
);
const StatusIcon = ({ status }) => {
  let IconWithTooltip = null;
  switch (status) {
    case TRANSACTION_STATUS.PENDING:
      IconWithTooltip = (
        <HourglassEmptyIcon
          style={{ color: '#3f51b5' }}
        />
      );
      break;
    case TRANSACTION_STATUS.SUCCEEDED:
      IconWithTooltip = (
        <CheckCircleIcon
          style={{ color: 'green' }}
        />
      );
      break;
    case TRANSACTION_STATUS.FAILED:
      IconWithTooltip = (
        <CancelIcon
          style={{ color: 'red' }}
        />
      );
      break;
    case TRANSACTION_STATUS.CANCELLED:
      IconWithTooltip = (
        <BlockIcon
          style={{ color: 'gray' }}
        />
      );
      break;
    case TRANSACTION_STATUS.EXECUTED:
      IconWithTooltip = (
        <DoneAllRoundedIcon
          style={{ color: 'black' }}
        />
      );
      break;
    case TRANSACTION_STATUS.REFUNDED:
      IconWithTooltip = (
        <Rotate90DegreesCcwIcon />
      );
      break;
    case TRANSACTION_STATUS.REMOVED:
      IconWithTooltip = (
        <BlockIcon
          style={{ color: 'gray' }}
        />
      );
      break;
    default:
      break;
  }
  return (
    <Tooltip title={TRANSACTION_STATUS_LABEL[status]} placement="right">
      {IconWithTooltip}
    </Tooltip>
  );
};

StatusIcon.propTypes = {
  status: PropTypes.number,
};

StatusIcon.defaultProps = {
  status: null,
};

const TextFieldInForm = ({ variant, ...props }) => <TextField {...props} />;
TextFieldInForm.defaultProps = TextField.defaultProps;

const typeOptions = Object.keys(TRANSACTION_TYPE_LABEL).map(k => ({
  id: k, name: TRANSACTION_TYPE_LABEL[k],
}));
const statusOptions = Object.keys(TRANSACTION_STATUS_LABEL).map(k => ({
  id: parseInt(k, 10), name: TRANSACTION_STATUS_LABEL[k],
}));

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput alwaysOn allowEmpty source="status" choices={statusOptions} />
    <SelectInput alwaysOn allowEmpty source="type" choices={typeOptions} />
  </Filter>
);

const TransactionsTab = ({ organization }) => {
  return (
    <List
      basePath="/transactions"
      bulkActionButtons={false}
      empty={<Empty />}
      filters={<ListFilter />}
      filterDefaultValues={{ organization_id: organization.id }}
      hasCreate={false}
      hasEdit={false}
      hasList
      hasShow={false}
      resource="transactions"
      sort={{ field: 'created_at', order: 'DESC' }}
      title=" - Transactions"
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <ReferenceField label="Reference ID" source="id" reference="transactions" link="show">
          <TextField source="tw_transfer_id" />
        </ReferenceField>
        <ReferenceField label="Invoice No" source="invoice_id" reference="invoices">
          <FunctionField
            render={record => {
              const isOutbound = record.type === INVOICE_TYPE.OUTBOUND;
              const invoiceIcon = isOutbound ? <ArrowBackIcon /> : <ArrowForwardIcon />;
              return (
                (record.uniqueNumber !== record.number)
                  ? (
                    <>
                      {invoiceIcon}
                      {' '}
                      {record.number}
                      (
                      {record.uniqueNumber}
                      )
                    </>
                  ) : (
                    <>
                      {invoiceIcon}
                      {' '}
                      {record.number}
                    </>
                  )
              );
            }}
          />
        </ReferenceField>
        <TextField source="method_label" label="Method" sortable={false} />
        <FunctionField
          source="type"
          label="Type"
          render={record => TRANSACTION_TYPE_LABEL[record.type]}
          sortable={false}
        />
        <FunctionField
          source="amount"
          label="Outgoing amount"
          render={record => {
            return (
              <NumberTpl
                prefix={CURRENCY_SYMBOL[record.amounts.outgoingCurrency.toLowerCase()]}
                value={record.amounts.outgoingAmount}
              />
            );
          }}
          sortable={false}
        />
        <FunctionField
          source="amount"
          label="Target amount"
          render={record => (
            <NumberTpl
              prefix={CURRENCY_SYMBOL[record.amounts.targetCurrency.toLowerCase()]}
              value={record.amounts.targetAmount}
            />
          )}
          sortable={false}
        />
        <FunctionField
          source="date"
          label="Created at"
          render={record => formatDate(record.date)}
        />
        <FunctionField
          source="status"
          label="Status"
          render={record => <StatusIcon status={record.status} />}
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

TransactionsTab.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default TransactionsTab;

