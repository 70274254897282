import { pick } from 'lodash';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import SubscriptionPlanDetails from 'finance/assets/js/lib/SubscriptionPlanDetails';

/**
 * POJO to encapsulate the current state of a TD subscription.
 */
class SubscriptionDetails {
  /**
   * Constructor
   * @param {object} props - POJO properties.
   * @return {SubscriptionDetails} instance.
   */
  constructor(props) {
    if (props instanceof SubscriptionDetails) {
      return props;
    }

    this.init(props);
  }

  /**
   * Initialise a POJO instance
   * @param {object} param0 - POJO properties.
   */
  init({
    orgId, subId, vendor, vendorId, vendorCustomerId, created, started, status,
    currentPeriodStart, currentPeriodEnd, trialActive, trialStart, trialEnd, isCancelled,
    cancelledAt, plans,
  } = {}) {
    if (!orgId) {
      throw new Error('orgId is required');
    }
    if (!subId) {
      throw new Error('subId is required');
    }

    this.orgId = orgId;
    this.subId = subId;

    this.vendor = vendor;
    this.vendorId = vendorId;
    this.vendorCustomerId = vendorCustomerId;

    this.created = created;
    this.started = started;

    this.status = status;
    this.currentPeriodStart = currentPeriodStart;
    this.currentPeriodEnd = currentPeriodEnd;

    this.trialActive = trialActive;
    this.trialStart = trialStart;
    this.trialEnd = trialEnd;

    this.isCancelled = isCancelled;
    this.cancelledAt = cancelledAt;

    this.plans = plans.map(p => new SubscriptionPlanDetails(p));
  }

  /**
   * Serialise a POJO instance for storage ( ie in the db ).
   * @return {object} object properties ready for serialization.
   */
  serialize() {
    return {
      ...pick(this, [
        // organization
        'orgId',

        // local
        'subId',

        // vendor
        'vendor', 'vendorId', 'vendorCustomerId',

        // start dates
        'created', 'started',

        // current state
        'status',
        'currentPeriodStart', 'currentPeriodEnd',

        // trial
        'trialActive', 'trialStart', 'trialEnd',

        // cancellation
        'isCancelled', 'cancelledAt',
      ]),
      plans: this.plans.map(p => p.serialize()),
    };
  }

  /**
   * Getter for organization id.
   * @return {number} id of organization.
   */
  getOrgId() {
    return this.orgId;
  }

  /**
   * Getter for local subscription id ( Subscriptions ).
   * @return {number} id of local subscription.
   */
  getSubId() {
    return this.subId;
  }

  /**
   * Getter for trial state.
   * @return {bool} if trial is active.
   */
  isTrial() {
    return this.trialActive;
  }

  /**
   * Getter for subscription started timestamp.
   * @return {string} date and time subscription started.
   */
  getStartedAt() {
    return this.started;
  }

  /**
   * Getter for current period start timestamp of subscription.
   * @return {string} date and time current subscription period started.
   */
  getPeriodStart() {
    return this.currentPeriodStart;
  }

  /**
   * Getter for subscription expires at timestamp.
   * This will be the current period end of the plan.
   * @return {string} date and time subscription expires.
   */
  getExpiresAt() {
    return this.currentPeriodEnd;
  }

  /**
   * Getter for the subscription plan.
   * @return {SubscriptionPlanDetails} subscription plan.
   */
  getPlan() {
    return this.plans[0];
  }

  /**
   * Getter for the subscription vendor.
   * @return {SUBSCRIPTION_VENDOR} subscription vendor.
   */
  getVendor() {
    return this.vendor;
  }

  /**
   * Getter for the vendor subscription id.
   * @return {any} vendor subscrpition id.
   */
  getVendorSubscriptionId() {
    return this.vendorId;
  }

  /**
   * Getter for vendor customer id.
   * @return {any} the vendor customer id.
   */
  getVendorCustomerId() {
    return this.vendorCustomerId;
  }

  // Plan getters

  /**
   * Getter for the subscription plan renewal interval.
   * @return {String}
   */
  getPlanRenewalInterval() {
    const plan = this.getPlan();
    if (!plan) {
      throw new Error('Expecting a plan');
    }
    return plan.renewalInterval;
  }

  /**
   * Getter for the subscription plan product.
   * @return {Number}
   */
  getPlanProduct() {
    const plan = this.getPlan();
    if (!plan) {
      throw new Error('Expecting a plan');
    }
    return plan.plan; // TODO change to new label when renaming is done
  }

  /**
   * Getter for the subscription plan renewal interval.
   * @return {String}
   */
  getPlanCostPerSeat({ withCurrency = false } = {}) {
    const plan = this.getPlan();
    if (!plan) {
      throw new Error('Expecting a plan');
    }
    if (withCurrency) {
      const currency = plan.currency;
      return `${CURRENCY_SYMBOL[currency]}${plan.costPerUnit}`;
    }
    return plan.costPerUnit;
  }

  /**
   * Getter for the subscription plan total cost.
   * @return {String}
   */
  getPlanTotalCost({ withCurrency = false } = {}) {
    const plan = this.getPlan();
    if (!plan) {
      throw new Error('Expecting a plan');
    }
    if (withCurrency) {
      const currency = plan.currency;
      return `${CURRENCY_SYMBOL[currency]}${plan.totalCost}`;
    }
    return plan.totalCost;
  }
}

export default SubscriptionDetails;
