import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { TIME_OFF_REASONS } from 'accounts/assets/js/constants';
import { timeOffUrl } from 'accounts/urls';
import DateRangePickerField from 'core/assets/js/components/FinalFormFields/DateRangePickerField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import moment from 'core/assets/js/lib/tdMoment';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const TimeOffForm = ({ initialValues, onSubmit }) => {
  const activeOrg = useSelector(selectActiveOrg);
  const history = useHistory();
  return (
    <Form
      initialValues={initialValues}
      onSubmit={({ period, ...rest }) => onSubmit({
        ...rest, periodEnd: period.max, periodStart: period.min,
      })}
      render={({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="rounded shadow-sm bg-gray mb-4">
            <div className="row mx-0 justify-content-center flex-column-reverse flex-md-row">
              <div className="form-container bg-white worksheet-form col pt-3 pb-5">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <SelectField
                      label="Reason"
                      name="reason"
                      optionsMapping={
                        Object.values(TIME_OFF_REASONS).map(value => ({ text: value, value }))
                      }
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <DateRangePickerField
                      isOutsideRange={calDate => (
                        moment(calDate).startOf('day').isBefore(moment().startOf('day'))
                      )}
                      label="Date range"
                      name="period"
                      required
                      showQuickSelect={false}
                    />
                  </div>
                  <div className="col-12">
                    <p className="discreet font-smaller mt-n3">
                      Managers will be notified that during the above period you
                      wont&apos;t be able for work.
                    </p>
                  </div>
                  <div className="col-12">
                    <TextAreaField label="Description" name="description" />
                  </div>
                </div>

                {submitError && <span className="text-danger mt-3">{submitError}</span>}
              </div>
            </div>
          </div>
          <div className="mt-4 text-right button-container">
            <TDButton
              className="btn btn-lg btn-default"
              disabled={submitting}
              label="Cancel"
              onClick={() => {
                history.push(timeOffUrl(activeOrg.alias));
              }}
              type="button"
            />
            <TDButton
              disabled={submitting}
              label="Schedule days off"
              type="submit"
              variant={BS_STYLE.PRIMARY}
            />
          </div>
        </form>
      )}
    />
  );
};

TimeOffForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

TimeOffForm.defaultProps = {
  initialValues: {},
};

export default TimeOffForm;
