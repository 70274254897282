import moment from 'moment';
import { uniq } from 'lodash';
import { getDatetime } from 'core/assets/js/lib/utils';
import { INVOICING_FREQUENCY_SCHEMA_TYPES } from 'finance/assets/js/constants';
import CronFrequency from 'finance/assets/js/lib/CronFrequency';
import { assert } from 'finance/assets/js/lib/Assert';

/** A POJO keeping an array schema for frequencies
 * The expected structure is [{ type: 'cron|custom|...', value: '0 0 1 ...' }]
 * */
class CompoundFrequency {
  /**
   * Validates a frequency schema
   *
   * @param {[Object]} schemaArray - an array of schemas
   * @throws Will throw an error if the shema is not valid
   */
  static validate(schemaArray) {
    if (!schemaArray) {
      throw new Error('cannot parse an empty schema');
    }
    if (!Array.isArray(schemaArray)) {
      throw new Error('expecting an array of frequency descriptions');
    }

    if (schemaArray.length === 0) {
      throw new Error('expecting at least one frequency descriptor');
    }

    const missingTypes = schemaArray.filter(
      d => !Object.values(INVOICING_FREQUENCY_SCHEMA_TYPES).includes(d.type),
    );

    if (missingTypes.length > 0) {
      throw new Error(`Missing shema type for ${JSON.stringify(missingTypes)}`);
    }

    schemaArray.forEach(({ type }) => {
      switch (type) {
        case INVOICING_FREQUENCY_SCHEMA_TYPES.CRON: break;
        default:
          throw new Error(`schema '${type}' is not implemented yet`);
      }
    });
  }

  constructor(schema) {
    CompoundFrequency.validate(schema);
    this.details = {
      schema,
    };
  }

  serialize() {
    return this.details.schema;
  }

  /**
   * Returns the previous matching date
   *
   * @param {Date} now - an override for the current date
   * @param {boolean} inclusive - whether 'now' will be considered as a match
   * @returns {moment} the previous matching date
   */
  getPrevDate({ now = getDatetime(), inclusive = false } = {}) {
    const { schema } = this.details;
    const matchingDates = schema.map(({ type, value }) => {
      switch (type) {
        case INVOICING_FREQUENCY_SCHEMA_TYPES.CRON:
          return CronFrequency.getPrevDate(value, { now, inclusive });
        default:
          throw new Error(`schema '${type}' is not implemented yet`);
      }
    });
    return moment.max(matchingDates);
  }

  /**
   * Returns the next matching date
   *
   * @param {Date} now - an override for the current date
   * @param {boolean} inclusive - whether 'now' will be considered as a match
   * @returns {moment} the next matching date
   */
  getNextDate({ now = getDatetime(), inclusive = false } = {}) {
    const { schema } = this.details;
    const matchingDates = schema.map(({ type, value }) => {
      switch (type) {
        case INVOICING_FREQUENCY_SCHEMA_TYPES.CRON:
          return CronFrequency.getNextDate(value, { now, inclusive });
        default:
          throw new Error(`schema '${type}' is not implemented yet`);
      }
    });
    return moment.min(matchingDates);
  }

  /**
   * Whether the date matches the schema
   *
   * @param {Date} date - the date to test
   * @returns {boolean} whether the date matches the expression
   */
  isMatchingDate(date) {
    const { schema } = this.details;
    return schema.some(({ type, value: singleSchema }) => {
      switch (type) {
        case INVOICING_FREQUENCY_SCHEMA_TYPES.CRON:
          return CronFrequency.isMatchingDate(singleSchema, date);
        default:
          throw new Error(`schema '${type}' is not implemented yet`);
      }
    });
  }

  // Utility methods

  /**
   * Whether the date is a day before any date that matches the schema
   *
   * @param {Date} date - the date to test
   * @returns {boolean} whether the date is the previous day of any that match the expression
   */
  isDayBeforeMatch(date) {
    const nextDate = moment(date).add(1, 'day').startOf('day');
    return this.isMatchingDate(nextDate);
  }

  /**
   * Whether the schema describes just one day per month
   *
   * @returns {boolean}
   */
  isOncePerMonth() {
    const curr = this.getNextDate();
    const prev = this.getPrevDate({ now: curr });
    const next = this.getNextDate({ now: curr });
    return curr.month() !== prev.month() && curr.month() !== next.month();
  }

  /**
   * Returns the first day of the month that matches the schema
   *
   * @returns {Number} the first day of the month that matches the schema
   */
  getFirstMatchingDayOfMonth() {
    const now = getDatetime().endOf('month');
    const next = this.getNextDate({ now });
    return next.date();
  }

  /**
   * Returns all days of the month that match the schema
   *
   * @returns {[Number]} all days of the month that match the schema
   */
  getMatchingDaysOfMonth() {
    const now = getDatetime().endOf('month');
    let item = this.getNextDate({ now });
    const currentMonth = item.month();
    const all = [];
    do {
      all.push(item.date());
      item = this.getNextDate({ now: item });
    } while (item.month() === currentMonth);
    return all;
  }

  isFirstMatchOfMonth(date) {
    if (!this.isMatchingDate(date)) {
      return false;
    }
    const dayOfMonth = moment(date).date();
    const firstDayOfMonth = this.getFirstMatchingDayOfMonth();
    return dayOfMonth === firstDayOfMonth;
  }

  toHumanizedString() {
    const { schema } = this.details;
    const all = schema.map(({ type, value: singleSchema }) => {
      switch (type) {
        case INVOICING_FREQUENCY_SCHEMA_TYPES.CRON:
          return CronFrequency.toHumanizedString(singleSchema);
        default:
          throw new Error(`schema '${type}' is not implemented yet`);
      }
    });
    return uniq(all).join(' and ');
  }

  /**
   * Check to see if all dates in schema appear to be monthly dates.
   *
   * @throws
   *
   * @return {boolean} true if all dates appear monthly, rather than days of the week.
   */
  isOnlyMonthDates() {
    const { schema } = this.details;
    const monthDatesOnly = schema.every(c => {
      assert(c.type === INVOICING_FREQUENCY_SCHEMA_TYPES.CRON, 'expected a cron');
      return CronFrequency.isOnlyMonthDates(c.value);
    });
    return monthDatesOnly;
  }

  /**
   * Check to see if all dates in schema appear to be days of the week.
   *
   * @throws
   *
   * @return {boolean} true if all dates appear to be days of the week,
   * rather than dates in the month.
   */
  isOnlyWeekDays() {
    const { schema } = this.details;
    const weekDaysOnly = schema.every(c => {
      assert(c.type === INVOICING_FREQUENCY_SCHEMA_TYPES.CRON, 'expected a cron');
      return CronFrequency.isOnlyWeekDays(c.value);
    });
    return weekDaysOnly;
  }

  /**
   * Parse the days of the month from the cron.
   *
   * @throws
   *
   * @return {number[]} month dates.
   */
  getMonthDates() {
    assert(this.isOnlyMonthDates(), 'attempted to get month dates of a frequency that includes week days');
    const { schema } = this.details;
    return schema.flatMap(c => {
      assert(c.type === INVOICING_FREQUENCY_SCHEMA_TYPES.CRON, 'expected a cron');
      return CronFrequency.datesOfTheMonth(c.value);
    });
  }

  /**
   * Parse the days of the week from the cron.
   *
   * @throws
   *
   * @return {number[]} days of the week.
   */
  getWeekDays() {
    assert(this.isOnlyWeekDays(), 'attempted to get week days of a frequency that includes month dates');
    const { schema } = this.details;
    return schema.flatMap(c => {
      assert(c.type === INVOICING_FREQUENCY_SCHEMA_TYPES.CRON, 'expected a cron');
      return CronFrequency.daysOfTheWeek(c.value);
    });
  }
}

export default CompoundFrequency;
