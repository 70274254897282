import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { flatten, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import {
  completeStepDS, fetchSetupStepsDS, selectSetupSteps,
} from 'accounts/assets/js/ducks/account';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { BS_SIZE, BS_STYLE, BS_TOOLTIP_PLACEMENT, ICON, USER_TYPE } from 'core/assets/js/constants';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import ProfileExperienceList from 'accounts/assets/js/components/ProfileExperienceList.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchListDS } from 'core/assets/js/ducks/list';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { postUserCustomFieldsDS } from 'accounts/assets/js/data-services/account';
import { profileExperienceApiUrl } from 'accounts/urls';
import { FIELD_ENTITY_TYPE, TYPE } from 'interviews/assets/js/constants';
import { extractCustomFieldSetup, isFieldVisibleToUserType } from 'interviews/assets/js/lib/utils';
import { customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import {
  getCustomFieldTemplates, fetchCustomFieldTemplatesDS,
} from 'interviews/assets/js/ducks/customFields';
import AnswersList from 'core/assets/js/components/AnswersList.jsx';
import CustomFieldsUpdater from 'interviews/assets/js/components/CustomFieldsUpdater.jsx';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';
import { processAdditionalInformationValues } from 'people/assets/js/lib/utils';

const ProfileExperienceListApiConnected = withTDApiConnected({
  fetchData: ({
    dispatch, authedAxios, componentName, querystring, params, url,
  }) => Promise.all([
    dispatch(fetchListDS({
      authedAxios, componentName, querystring, url: profileExperienceApiUrl(params.userId),
    })),
    dispatch(fetchCustomFieldTemplatesDS({
      authedAxios,
      entityType: FIELD_ENTITY_TYPE.USER,
      fetchAll: true,
      orgAlias: params.orgAlias,
      url,
    })),
  ]),
  duck: 'list',
  storeKey: 'PeopleExperienceTab',
  skeletonComponent: SettingsPageSkeleton,
})(ProfileExperienceList);

const PeopleExperienceTab = ({
  activeUserCard,
  customFieldTemplates,
  dispatch,
  isAnyManager,
  match: { params: { orgAlias, userType } },
  modalState,
  parentComponentName,
  profile,
  steps,
  user: { answers: storedAnswers, id: userId },
}) => {
  const activeUserId = activeUserCard?.user?.id;
  const activeUserType = activeUserCard?.userRole?.ofType;
  const orgSteps = (steps.userCardSetupSteps && steps.userCardSetupSteps[orgAlias]) || [];

  const handleProfileUpdate = async values => {
    try {
      const stepName = 'ProfileAdditionalInformationStep';
      let additionalInfoStep = orgSteps.find(step => step.name === stepName);
      const stepNotCompleted = additionalInfoStep && !additionalInfoStep.completed;

      const finalValues = processAdditionalInformationValues(
        values, customFieldTemplates, activeUserType,
      );

      if (values.custom_field_ids.length > 0 && finalValues.custom_field_ids.length === 0) {
        // The user has not selected any templates, with questions they can answer
        const error = 'You have not selected any templates with questions you can answer';
        toastr.error('Oops!', error);
        return { custom_field_templates: error };
      }

      await dispatch(
        postUserCustomFieldsDS(orgAlias, userId, finalValues, parentComponentName),
      );
      // This is required to update the "Onboarding progress" profile section
      const profileIsForAProvider = userType === PEOPLE_TYPE.PROVIDERS;
      const fetchUserDS = profileIsForAProvider ? fetchProviderDS : fetchManagerDS;
      await dispatch(fetchUserDS({
        componentName: parentComponentName, params: { orgAlias, userId },
      }));

      modalState.close();

      if (stepNotCompleted) {
        const { steps: updatedSteps } = await dispatch(fetchSetupStepsDS());
        const updatedOrgSteps = updatedSteps.userCardSetupSteps[orgAlias] || [];
        additionalInfoStep = updatedOrgSteps.find(step => step.name === stepName);
        if (additionalInfoStep && additionalInfoStep.completed) {
          await completeStepDS(dispatch, orgAlias, stepName);
          return null;
        }
      }

      toastr.success('Well Done!', 'Additional information updated successfully.');

      return null;
    } catch (err) {
      const error = err._error || err.message;
      toastr.error('Oops!', error);
      return err.errors || { [FORM_ERROR]: error };
    }
  };

  const isActiveUsersProfile = activeUserId === userId;
  const hasStoredAnswers = !isEmpty(storedAnswers);
  const hasCustomFieldTemplates = !isEmpty(customFieldTemplates);
  const fields = flatten(customFieldTemplates.map(({ questions }) => questions));
  const canViewAtLeastOneQuestion = fields.some(f => isFieldVisibleToUserType(f, activeUserType));
  const canManageCustomFields = (
    isAnyManager || isActiveUsersProfile
  ) && canViewAtLeastOneQuestion && (
    hasStoredAnswers || hasCustomFieldTemplates
  );

  const { initialValues, selectedCustomFields } = extractCustomFieldSetup({
    answers: storedAnswers,
    templates: customFieldTemplates,
    useCurrentTemplates: true,
  });

  const getHasUnansweredRequiredCustomField = () => {
    if (isActiveUsersProfile) {
      return orgSteps.some(step => (
        step.name === 'ProfileAdditionalInformationStep' && !step.completed
      ));
    }
    if (!isAnyManager) {
      return false;
    }
    const customFieldTemplatesLength = customFieldTemplates.length;
    if (customFieldTemplatesLength === 0) {
      return false;
    }

    for (let i = 0; i < customFieldTemplatesLength; i += 1) {
      const {
        isMandatory: entireTemplateIsMandatory, questions,
      } = customFieldTemplates[i];
      if (entireTemplateIsMandatory) {
        const questionsLength = questions.length;
        for (let j = 0; j < questionsLength; j += 1) {
          const question = questions[j];
          const foundAnswer = Array.isArray(storedAnswers) && storedAnswers.find(a => (
            a.path === question.path
          ));
          if (
            question.required
            && question.answeredByUserType === USER_TYPE.MANAGER
            && question.type !== TYPE.TEXT_BLOB
            && (!foundAnswer || !foundAnswer.body)
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const hasUnansweredRequiredCustomField = (
    canManageCustomFields && getHasUnansweredRequiredCustomField()
  );

  return (
    <div className="tab-content w-100 p-4">
      <div className="mb-3 mt-n2">
        <h3 className="mt-3 mb-2">Resume</h3>
        <MarkdownText
          text={profile.resume || '-'}
          withBreaksPlugin
        />
      </div>

      { canManageCustomFields && (
        <div className="pt-2">
          <ModalSimple
            body={(
              <Form
                name="profile-custom-fields-form"
                onSubmit={handleProfileUpdate}
                initialValues={initialValues}
                mutators={{ ...arrayMutators }}
                // important: mandatory templates will not work properly otherwise
                keepDirtyOnReinitialize
                render={({ handleSubmit, submitting, form }) => (
                  <form onSubmit={handleSubmit}>
                    <CustomFieldsUpdater
                      allowTemplateSelection
                      entityId={userId}
                      entityType={FIELD_ENTITY_TYPE.USER}
                      form={form}
                      initialCustomFields={selectedCustomFields}
                      onTemplateAdded={template => {
                        if (!hasStoredAnswers) {
                          return;
                        }
                        // Update any fields, based on existing answers
                        const paths = template.questions.map(q => q.path);
                        storedAnswers.forEach(storedAnswer => {
                          if (paths.includes(storedAnswer.path)) {
                            form.change(storedAnswer.path, storedAnswer.body);
                          }
                        });
                      }}
                      showTemplateNames
                      templateFieldLabel="Additional information template"
                      templates={customFieldTemplates}
                      useCurrentTemplates
                    />

                    <div className="modal-footer mx-0 mt-5 border-top-0 mb-0 pb-0">
                      <TDButton
                        disabled={submitting}
                        onClick={modalState.close}
                        label="Cancel"
                      />

                      <TDButton
                        disabled={submitting}
                        type="submit"
                        variant={BS_STYLE.PRIMARY}
                        label="Save"
                      />
                    </div>
                  </form>
                )}
              />
            )}
            data-testid="people-experience-tab-custom-fields"
            heading="Additional information"
            noFooter
            onClose={modalState.close}
            open={modalState.isOpen}
          />
          <React.Fragment>
            <h3 className="mb-3 d-flex">
              Additional information
              <TDButton
                btnIcon={ICON.EDIT}
                bsSize={BS_SIZE.SMALL}
                className="ml-auto"
                data-testid="people-experience-tab-additional-information-button"
                label="Edit"
                onClick={modalState.open}
                tooltipMsg="Update additional information"
                tooltipPosition={BS_TOOLTIP_PLACEMENT.TOP}
                variant={BS_STYLE.DEFAULT}
              />
            </h3>
            {hasUnansweredRequiredCustomField && (
              <TDSystemMessage
                className="mb-4"
                data-testid="people-experience-tab-additional-information-warning"
                title="Please fill in required additional information"
                type={BS_STYLE.WARNING}
              >
                There are new additional information fields available. Click on the &quot;Edit&quot;
                button to fill in the required additional information.
              </TDSystemMessage>
            )}
            <div className="mb-2">
              {hasStoredAnswers && (
                <AnswersList
                  answers={storedAnswers}
                  customFieldTemplates={customFieldTemplates}
                  labelClass="mb-2 text-dark answer-label"
                  showTemplateNames={activeUserCard?.userRole?.isAnyManager}
                  wrapperClass="answer-value mb-3"
                />
              )}
              {!hasStoredAnswers && (
                <div className="empty-message-content py-2 px-2 mb-4">
                  <i className="discreet">
                    No additional information fields available, yet
                  </i>
                </div>
              )}
            </div>
          </React.Fragment>
        </div>
      )}

      <div>
        <h3 className="mt-3 mb-2">Experience</h3>
        <ProfileExperienceListApiConnected />
      </div>
    </div>
  );
};

PeopleExperienceTab.GetComponentName = () => 'PeopleExperienceTab';

PeopleExperienceTab.propTypes = {
  activeUserCard: userCardSpec,
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
  dispatch: PropTypes.func.isRequired,
  isAnyManager: PropTypes.bool,
  match: routerMatchContentsSpec.isRequired,
  modalState: modalStateSpec.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  profile: PropTypes.object,
  steps: PropTypes.object.isRequired,
  user: PropTypes.object,
};

PeopleExperienceTab.defaultProps = {
  activeUserCard: {},
  customFieldTemplates: [],
  isAnyManager: false,
  profile: {},
  user: {},
};

const mapStateToProps = state => ({
  activeUserCard: selectActiveUserCard(state),
  customFieldTemplates: getCustomFieldTemplates(state),
  isAnyManager: getHasOrgAccess(state)({ requireManager: true }),
  steps: selectSetupSteps(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const PeopleExperienceTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleExperienceTab);

export default withRouter(withStateModal(PeopleExperienceTabConnected));
