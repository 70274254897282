/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  useRefresh,
} from 'react-admin';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import { formatDate } from 'admin/assets/js/lib/utils';
import SubscriptionEditButton from 'admin/assets/js/resources/subscriptions/SubscriptionEditButton.jsx';
import SubscriptionEditDialog from 'admin/assets/js/resources/subscriptions/SubscriptionEditDialog.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import CreateSubscriptionButton from 'admin/assets/js/resources/subscriptions/CreateSubscriptionButton.jsx';
import { SUBSCRIPTION_PLAN_LABELS } from 'finance/assets/js/constants';
import Show from './show.jsx';
import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';

const PLAN_OPTIONS = Object.entries(SUBSCRIPTION_PLAN_LABELS).map(([id, name]) => ({ id, name }));

const ListFilter = props => (
  <Filter
    {...props}
  >
    <ReferenceInput
      alwaysOn
      allowEmpty
      label="Organization"
      source="organization_id"
      reference="organizations_lite"
      perPage={MAX_ORGANIZATIONS_FETCHED}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText={org => `${org.name} (${org.unique_alias})`} />
    </ReferenceInput>
    <SelectInput allowEmpty alwaysOn choices={PLAN_OPTIONS} label="Plan" source="plan" />
    <SelectInput
      allowEmpty
      alwaysOn
      source="is_trial"
      choices={[
        { id: 1, name: 'On trial' },
        { id: 0, name: 'Trial expired' },
      ]}
    />
    <SelectInput
      allowEmpty
      alwaysOn
      source="is_active"
      choices={[
        { id: 1, name: 'Active' },
        { id: 0, name: 'Inactive' },
      ]}
    />
  </Filter>
);

const Subscriptions = (props) => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const refresh = useRefresh();
  const { basePath } = props;
  return (
    <>
      <List
        {...props}
        filters={<ListFilter />}
        sort={{ field: 'created_at', order: 'DESC' }}
        bulkActionButtons={false}
        actions={<CreateSubscriptionButton />}
      >
        <Datagrid>
          <ReferenceField label="#" source="id" reference="subscriptions" link="show">
            <TextField source="id" label="#" />
          </ReferenceField>
          <ReferenceField
            source="organization_id"
            reference="organizations_lite"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            label="Plan"
            render={record => SUBSCRIPTION_PLAN_LABELS[record.plan]}
            source="plan"
          />
          <FunctionField
            label="Trial"
            render={record => (
              record.is_trial
                ? <CheckCircleIcon style={{ color: 'green' }} />
                : <CancelIcon style={{ color: 'red' }} />
            )}
            source="is_trial"
          />
          <FunctionField
            source="trial_expiration_date"
            label="Trial expiration"
            render={record => formatDate(record.trial_expiration_date)}
          />
          <FunctionField
            source="amount"
            label="Amount"
            render={record => (
              <NumberTpl
                prefix={CURRENCY_SYMBOL[record.currency.toLowerCase()]}
                value={record.amount}
              />
            )}
            sortable={false}
          />
          <TextField source="manager_seats" label="Manager seats" />
          <TextField source="provider_seats" label="Provider seats" />
          <FunctionField
            source="started_at"
            label="Started"
            render={record => formatDate(record.started_at)}
          />
          <FunctionField
            source="subscription_end"
            label="Active"
            render={record => (
              record.is_active
                ? (
                  <CheckCircleIcon
                    style={{ color: 'green' }}
                  />
                ) : (
                  <CancelIcon
                    style={{ color: 'red' }}
                  />
                )
            )}
          />
          <SubscriptionEditButton onClick={record => setSelectedSubscription(record)} />
        </Datagrid>
      </List>
      {selectedSubscription && (
        <SubscriptionEditDialog
          basePath={basePath}
          onClose={() => {
            setSelectedSubscription(null);
            refresh();
          }}
          subscription={selectedSubscription}
        />
      )}
    </>
  );
};

Subscriptions.propTypes = {
  basePath: PropTypes.string,
};

Subscriptions.defaultProps = {
  basePath: null,
};

export {
  Subscriptions as List,
  Show,
};
