import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { DATE_DURATION } from 'core/assets/js/constants';
import { RATE_UNIT } from 'rates/assets/js/constants';
import { paginationSpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { expenseSpec } from 'finance/assets/js/lib/objectSpecs';
import { getListState, listUpdateItemAC } from 'core/assets/js/ducks/list';
import ExpensesTable from 'finance/assets/js/components/ExpensesTable.jsx';
import { fetchFinanceExpenseListDS } from 'finance/assets/js/data-services/list';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import SearchFormWithChartsToggleHeader from 'finance/assets/js/components/SearchFormWithChartsToggleHeader.jsx';
import { PROJECT_TAB_VIEW_TYPE } from 'projects/assets/js/constants';
import ProjectChartStackedSkeleton from 'projects/assets/js/components/skeletons/ProjectChartStackedSkeleton.jsx';
import ProjectExpensesChartStacked from 'projects/assets/js/components/widgets/ProjectExpensesChartStacked.jsx';
import ProjectExpensesChartLine from 'projects/assets/js/components/widgets/ProjectExpensesChartLine.jsx';
import { refreshFinancePendingCountsDS } from 'core/assets/js/ducks/pendingCount';

class ProjectTabExpenses extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    return dispatch(fetchFinanceExpenseListDS({
      url,
      params,
      querystring,
      projectId: params.id,
      componentName,
      authedAxios,
      dispatch,
    }));
  }

  static GetComponentName() {
    return 'ProjectTabExpenses';
  }

  constructor(props) {
    super(props);

    this.state = {
      activeView: PROJECT_TAB_VIEW_TYPE.LIST,
      selectedDateDuration: DATE_DURATION.CURRENT_MONTH,
    };

    this.onChartsToggled = this.onChartsToggled.bind(this);
    this.handleDateDurationChange = this.handleDateDurationChange.bind(this);
    this.handleExpenseUpdated = this.handleExpenseUpdated.bind(this);
  }

  onChartsToggled() {
    const { activeView } = this.state;
    const nextView = activeView === PROJECT_TAB_VIEW_TYPE.LIST
      ? PROJECT_TAB_VIEW_TYPE.CHARTS
      : PROJECT_TAB_VIEW_TYPE.LIST;
    this.setState({
      activeView: nextView,
    });
  }

  handleDateDurationChange(selectedDate) {
    this.setState({
      selectedDateDuration: selectedDate,
    });
  }

  handleExpenseUpdated(expense) {
    const { dispatch, match: { params: { orgAlias } } } = this.props;

    dispatch(
      listUpdateItemAC(expense.id, expense, ProjectTabExpenses.GetComponentName()),
    );
    dispatch(refreshFinancePendingCountsDS({
      orgAlias,
    }));
  }

  render() {
    const { activeView, selectedDateDuration } = this.state;
    const {
      amountLimits, currency, expenses, filtersOpen, onFiltersToggle, pagination, rateUnit,
    } = this.props;

    const currencySymbol = CURRENCY_SYMBOL[currency];
    const emptyText = 'No expenses found';

    const showGraphs = activeView === PROJECT_TAB_VIEW_TYPE.CHARTS;
    return (
      <React.Fragment>
        <SearchFormWithChartsToggleHeader
          amountLimits={amountLimits}
          showCharts={activeView === PROJECT_TAB_VIEW_TYPE.CHARTS}
          reportType={REPORT_TYPES.EXPENSE}
          onChartsToggled={this.onChartsToggled}
          selectedDateDuration={selectedDateDuration}
          onDateDurationChanged={this.handleDateDurationChange}
          filtersOpen={filtersOpen}
          onFiltersToggle={onFiltersToggle}
        />
        { showGraphs && (
          <Card>
            <Card.Body>
              <ProjectExpensesChartStacked
                currencySymbol={currencySymbol}
                selectedDateDuration={selectedDateDuration}
              />
              <ProjectExpensesChartLine
                currencySymbol={currencySymbol}
                selectedDateDuration={selectedDateDuration}
              />
            </Card.Body>
          </Card>
        )}
        <TDApiConnected
          duck="list"
          component={this.constructor}
          skeletonComponent={showGraphs ? ProjectChartStackedSkeleton : FinanceTableSkeleton}
        >
          { !showGraphs && !filtersOpen && (
            <React.Fragment>
              <div className="finance-report finance-report__table-wrapper finance-report__table-wrapper--responsive finance-report__table-wrapper--list">
                <ExpensesTable
                  list={expenses}
                  rateUnit={rateUnit}
                  emptyText={emptyText}
                  onExpenseCancelled={this.handleExpenseUpdated}
                  embeddedMode
                />
              </div>
              <TDPagination
                {...pagination}
              />
            </React.Fragment>
          )}
        </TDApiConnected>
      </React.Fragment>
    );
  }
}

ProjectTabExpenses.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: routerMatchSpec.isRequired,
  currency: PropTypes.string.isRequired,
  expenses: PropTypes.arrayOf(expenseSpec).isRequired,
  pagination: paginationSpec.isRequired,
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)).isRequired,
  amountLimits: PropTypes.object,
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

ProjectTabExpenses.defaultProps = {
  amountLimits: {},
};

const mapStateToProps = (state) => {
  const listState = getListState(state, ProjectTabExpenses.GetComponentName());
  return {
    amountLimits: listState.extras.amountLimits,
    currency: selectActiveOrg(state).currency,
    expenses: listState.items,
    pagination: listState.pagination,
    rateUnit: selectActiveOrg(state).default_rate_unit,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const ProjectTabExpensesConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withFilters(ProjectTabExpenses),
);

export default withRouter(ProjectTabExpensesConnected);
