import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DragHandle from 'core/assets/js/components/DragHandle.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import TDSortable from 'core/assets/js/components/TDSortable.jsx';
import ProfileExperienceItem from 'accounts/assets/js/components/ProfileExperienceItem.jsx';
import { ICON } from 'core/assets/js/constants';
import { profileCreateExperienceUrl } from 'accounts/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const ProfileExperienceList = ({ canEditExperience, items, onSortEnd }) => {
  const activeOrg = useSelector(selectActiveOrg);
  const history = useHistory();

  const hasItems = items.length >= 1;
  const classNames = [];
  if (canEditExperience) {
    classNames.push('p-4');
  }
  if (hasItems && canEditExperience) {
    classNames.push('rounded shadow-sm bg-white');
  }
  const canEditEmptyMessage = (
    <div className="experience-list empty-message-content text-center">
      <span className={`${ICON.PEOPLE_CLOCK} mb-3`} />
      <div className="empty-message">You haven&apos;t added any experience yet</div>
      <div
        className="imitate-link"
        onClick={() => history.push(profileCreateExperienceUrl(activeOrg.alias))}
      >
        Add your experience
      </div>
    </div>
  );

  const previewEmptyMessage = (
    <div className="empty-message-content py-2 px-2 mb-4">
      <i className="discreet">No experience records found.</i>
    </div>
  );
  if (!hasItems && canEditExperience) {
    return canEditEmptyMessage;
  }
  if (!hasItems && !canEditExperience) {
    return previewEmptyMessage;
  }

  if (!canEditExperience) {
    return (
      <TDList
        items={items}
        canEditExperience={canEditExperience}
        cardItem={{
          component: ProfileExperienceItem,
          props: {
            canEditExperience,
          },
        }}
        emptyListMessage={canEditExperience ? canEditEmptyMessage : previewEmptyMessage}
        listClassName="experience-list"
      />
    );
  }
  return (
    <div className={classNames.join(' ')}>
      <div className="page--settings__content clearfix">
        <TDSortable containerClassName="experience-list" onSortEnd={onSortEnd}>
          {items.map(item => (
            <ProfileExperienceItem
              canEditExperience={canEditExperience}
              dragHandle={<DragHandle classes="experience-list--item__drag-handle" />}
              item={item}
              key={item.id}
            />
          ))}
        </TDSortable>
      </div>
    </div>
  );
};

ProfileExperienceList.propTypes = {
  canEditExperience: PropTypes.bool,
  items: PropTypes.array,
  onSortEnd: PropTypes.func.isRequired,
};

ProfileExperienceList.defaultProps = {
  canEditExperience: false,
  items: [],
};

export default ProfileExperienceList;
