import React from 'react';
import PropTypes from 'prop-types';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import { ICON, BS_SIZE } from 'core/assets/js/constants';
import Table from 'core/assets/js/components/Table.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';


const SalesTaxRechargeBreakdown = ({ amounts, modalState }) => {
  const { currency, salesTaxRechargeAnalysis, salesTaxRecharge } = amounts;
  const currencySymbol = CURRENCY_SYMBOL[currency];
  const tableColumns = [
    { key: 'invoiceNumber', label: 'Invoice #' },
    { key: 'name', label: 'Provider' },
    { key: 'salesTax', label: `Sales tax (${currencySymbol})`, isFixedDecimals: true, columnClassName: 'text-right', width: '170px' },
  ];
  const table = (
    <div>
      <p>
        Breakdown of the sales tax recharge fees per provider invoice.
        <br />
        <br />
        Contractors who invoice TalentDesk.io might charge their local sales tax in their invoice.
        If any of these contractors are outside of the UK, the charged sales tax cannot be claimed
        back by Talentdesk.io.
        <br />
        <br />
        The following breakdown analysis show cases like these.
      </p>

      <div className="finance-report__table-wrapper finance-report__table-wrapper--list invoice-table-wrapper mt-4 finance-report__table-wrapper--responsive">
        <Table
          containerClass="invoice-table"
          cols={tableColumns}
          items={salesTaxRechargeAnalysis}
        />
        <div className="finance-report__summary">
          <div>
            <label>Total sales tax</label>
            <NumberTpl prefix={currencySymbol} value={salesTaxRecharge} decimals={2} />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <span onClick={modalState.open} className={`${ICON.INFO} do-not-print`} />

      <ModalSimple
        open={modalState.isOpen}
        heading="Sales tax recharge breakdown"
        size={BS_SIZE.LARGE}
        body={table}
        onClose={modalState.close}
        noFooter
      />
    </React.Fragment>
  );
};

SalesTaxRechargeBreakdown.propTypes = {
  amounts: PropTypes.object.isRequired,
  modalState: modalStateSpec.isRequired,
};

export default withStateModal(SalesTaxRechargeBreakdown);
