import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';
import { Link, useGetMany } from 'react-admin';
import { groupBy, maxBy } from 'lodash';

import TransactionDetailsField
  from 'admin/assets/js/resources/transactions/TransactionDetailsField.jsx';

const CashoutRequestViewTransactions = ({ transactionIds }) => {
  const { data: transactions, loading } = useGetMany('transactions',
    (transactionIds || [])
      // descending, highest id first
      .sort((a, b) => b - a)
      // max 9 transactions
      .slice(0, 9),
  );
  // note, transactions array will contain 'undefined'
  //       placeholders until loading finished
  const ids = !loading ? (transactions || [])
    .map(t => parseInt(t.id, 10)) : [];
  if (loading || ids.length === 0) {
    return null;
  }

  const transactionGroupedByDescriptor = groupBy(transactions, 'descriptor');
  // sort the list in each key, so the first entry in the array is the most recent transaction
  // this will then allow us to sort keys by looking at only the first transaction in the list
  // for a given key
  Object.keys(transactionGroupedByDescriptor).forEach(k => {
    // sort in place
    transactionGroupedByDescriptor[k]
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  });

  // sort the keys in to order
  // this will mean that the display order of previous transaction groups that failed,
  // will be listed below the most recent / successful transaction
  const getOrderedKeys = (o) => {
    const keys = Object.keys(o);
    // sort in place
    keys.sort((a, b) => new Date(o[b][0].date).getTime() - new Date(o[a][0].date).getTime());
    return keys;
  };

  return (
    <Card variant="outlined">
      <CardHeader
        style={{ paddingBottom: 0, marginBottom: 0 }}
        title={(
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 600 }}
          >
            Transaction details
          </Typography>
        )}
      />
      <CardContent>
        {ids.length === 0 && !loading && 'No transactions found'}
        {ids.length > 0 && (
          getOrderedKeys(transactionGroupedByDescriptor)
            .map((key, index) => {
              const latestTransaction = maxBy((transactionGroupedByDescriptor[key]), 'id');
              return (
                <div key={key}>
                  <div className="mb-4">
                    <div className="mb-1 font-weight-bold">Related transactions</div>
                    {transactionGroupedByDescriptor[key]
                      .filter(tr => latestTransaction.id !== tr.id)
                      .map(tr => (
                        <Link to={`/transactions/${tr.id}/details`} className="mr-2">
                          {`#${tr.id}`}
                        </Link>
                      ))
                    }
                  </div>
                  <div className="mb-1 font-weight-bold">Latest transaction</div>
                  <TransactionDetailsField
                    record={latestTransaction}
                    isDeferred
                  />
                  {ids.length > 1 && index !== ids.length && <hr /> }
                </div>
              );
            }))
        }
      </CardContent>
    </Card>
  );
};

CashoutRequestViewTransactions.propTypes = {
  transactionIds: PropTypes.array,
};

CashoutRequestViewTransactions.defaultProps = {
  transactionIds: [],
};

export default CashoutRequestViewTransactions;
