import { Money } from 'td-finance-ts';
import TransferAllocationRequest from 'services/assets/js/lib/TransferAllocationRequest';
import { set, isEmpty, keyBy } from 'lodash';

import { assertAllKeysPresent } from 'core/assets/js/lib/utils';
import { ALLOCATION_TRANSFER_TOLERANCE, INVOICING_MODE } from 'finance/assets/js/constants';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';

const getAllocationFormInputFieldName = invoiceId => `inv-${invoiceId}`;
const getAllocationFormInputCurrencyFieldName = invoiceId => `${getAllocationFormInputFieldName(invoiceId)}-currency`;

const validateAllocationInputField = (
  formValue, invoiceTotal, invoiceCurrency,
  totalAllocatedAmount, totalAmountChosen, transferAmount, transferAmountCurrency,
) => {
  if (!formValue) {
    return false;
  }
  assertAllKeysPresent({
    formValue, invoiceTotal, invoiceCurrency,
    totalAllocatedAmount, totalAmountChosen, transferAmount, transferAmountCurrency,
  });

  const invoiceMoney = new Money(invoiceTotal, invoiceCurrency);
  if (invoiceMoney.cmp(formValue) < 0) {
    return 'Amount should not exceed invoice amount';
  }

  const transferUsageAmount = new Money(
    totalAllocatedAmount,
    transferAmountCurrency,
  ).add(totalAmountChosen);
  const maxTransferUsageAmount = new Money(transferAmount, transferAmountCurrency)
    .mul(ALLOCATION_TRANSFER_TOLERANCE);
  if (transferUsageAmount.gte(maxTransferUsageAmount)) {
    return `Total allocation of transfer can not exceed ${ALLOCATION_TRANSFER_TOLERANCE * 100}% or ${maxTransferUsageAmount.toString()}`;
  }

  return false;
};

const parseFormValues = (values, { transferId, selectedInvoiceAllocations }) => {
  const selectedInvoiceAllocationsByInvoiceId = keyBy(selectedInvoiceAllocations, 'invoiceId');
  const selectedIds = selectedInvoiceAllocations.map(
    invoiceAllocation => invoiceAllocation.invoiceId,
  );
  const perId = Object.keys(values).reduce((acc, curr) => {
    // remove prefix from key
    const key = curr.replace('inv-', '');
    if (key.endsWith('-currency')) {
      const id = parseInt(key.replace('-currency', ''), 10);
      if (selectedIds.includes(id)) {
        set(acc, `${id}.currency`, values[curr]);
      }
    } else {
      const id = parseInt(key, 10);
      if (selectedIds.includes(id)) {
        set(acc, `${id}.amount`, selectedInvoiceAllocationsByInvoiceId[key].amount);
      }
    }
    return acc;
  }, {});

  // prune any 'bad' form entries
  const sanitizedPerId = Object
    .keys(perId)
    .reduce((mapping, key) => {
      // we need an amount ( positive, non 0 ) and a currency ( non empty string )
      const parsedAmount = parseFloat(perId[key].amount || 0);
      if (parsedAmount > 0 && perId[key].currency) {
        // eslint-disable-next-line no-param-reassign
        mapping[key] = perId[key];
      }
      return mapping;
    }, {});

  // bail if nothing left to submit
  if (!Object.keys(sanitizedPerId).length) {
    return null;
  }

  const request = new TransferAllocationRequest({
    transferId,
    allocationPerInvoiceId: sanitizedPerId,
  });
  return request.serialize();
};

// Fetches the invoice amount based on the invoicing mode
const getInvoiceAmount = (inv) => {
  const invAmounts = new InvoiceAmounts(inv.frozen.amounts);
  const invoiceAmount = inv.invoicingSettings.mode === INVOICING_MODE.BILL_PAYMENTS
    ? inv.receivableAmount : invAmounts.getTotal();
  return invoiceAmount;
};

// Fetches the invoice amount that is already allocated
const getInvAllocatedAmount = (inv) => {
  const { transactions } = inv;
  const transactionsWithAllocation = transactions
    .filter(tr => tr.allocatedAmount && tr.allocatedCurrency);

  // no allocations found
  if (isEmpty(transactionsWithAllocation)) {
    return new Money(0, inv.currency);
  }

  const firstTransactionCurrency = transactionsWithAllocation[0].allocatedCurrency;
  const allocatedAmountMoney = transactionsWithAllocation.reduce(
    (totalAllocatedInvoiceAmount, tr) => {
      if (tr.allocatedCurrency === null) {
        return totalAllocatedInvoiceAmount;
      }
      if (firstTransactionCurrency !== tr.allocatedCurrency) {
        throw new Error('different currencies detected');
      }
      return totalAllocatedInvoiceAmount.add(tr.allocatedAmount);
    },
    new Money(0, firstTransactionCurrency),
  );
  return allocatedAmountMoney;
};

// Calculates the difference between the invoice amount and the allocated amount for that invoice
const getRemainingInvoiceAllocationAmount = (inv) => {
  const invoiceAmount = getInvoiceAmount(inv);
  const invoiceAmountMoney = new Money(invoiceAmount, inv.currency);
  const allocatedAmountMoney = getInvAllocatedAmount(inv);
  return invoiceAmountMoney.sub(allocatedAmountMoney);
};

const getAvailableTransferAllocationMoney = (
  transferAmount, transferAmountCurrency, totalAllocatedAmount, amountToAllocate,
) => {
  const availableAllocationMoney = new Money(transferAmount, transferAmountCurrency)
    .sub(totalAllocatedAmount)
    .sub(amountToAllocate);

  return availableAllocationMoney;
};

export {
  getAllocationFormInputFieldName,
  getAllocationFormInputCurrencyFieldName,
  parseFormValues,
  validateAllocationInputField,
  getRemainingInvoiceAllocationAmount,
  getInvoiceAmount,
  getInvAllocatedAmount,
  getAvailableTransferAllocationMoney,
};
