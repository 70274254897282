import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { toastr } from 'react-redux-toastr';

import { timeOffListApiUrl, timeOffUrl } from 'accounts/urls';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import TimeOffForm from 'accounts/assets/js/components/TimeOffForm.jsx';
import { ICON } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

class TimeOffCreateView extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(values) {
    const { activeOrg, history } = this.props;
    try {
      await axios.post(timeOffListApiUrl(), values);
      toastr.success('Well Done!', 'Time off created successfully.');
      history.push(timeOffUrl(activeOrg.alias));
      return null;
    } catch (err) {
      const periodError = err.response?.data?.periodStart || err.response?.data?.periodEnd;
      if (periodError) {
        err.response.data = { ...err.response.data, period: periodError };
      }
      return parseAxiosErrorForFinalForm(err);
    }
  }

  render() {
    const { activeOrg } = this.props;

    const breadcrumbs = [
      {
        title: 'Availability',
        url: timeOffUrl(activeOrg.alias),
      },
      {
        title: 'Set your days off',
      },
    ];


    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />
        <div className="page page--timeoff-create">
          <div className="container">
            <p className="mb-4">
              <i className={ICON.USER_CLOCK} />
              You will be shown as available for all days that you do not mark as days off
            </p>
            <TimeOffForm
              onSubmit={this.handleSubmit}
              initialValues={{
                reason: 'Unavailable',
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
TimeOffCreateView.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const TimeOffCreateViewConnected = connect(mapStateToProps, mapDispatchToProps)(TimeOffCreateView);

export default withRouter(TimeOffCreateViewConnected);
