/* eslint no-underscore-dangle: off */

import React from 'react';
import { groupBy, isEmpty, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CURRENCY_SYMBOL } from 'td-finance-ts';
import { pluralize } from 'core/assets/js/lib/utils';

import WorksheetTaskItemCard from 'projects/assets/js/components/WorksheetTaskItemCard.jsx';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import { getListState, getListStateExtras } from 'core/assets/js/ducks/list';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import { fetchTaskListForWorksheetDS, fetchTasksAssignmentAnalyticsDS } from 'projects/assets/js/data-services/tasks';
import { projectSpec, projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { BS_STYLE, CURRENCY_LABEL } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import { RATE_SUGGEST_VALUE, RATE_UNIT } from 'rates/assets/js/constants';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { PROJECT_WORK_ITEM_DESCRIPTION } from 'projects/assets/js/components/WorksheetItems.jsx';
import WorksheetItemCard from 'projects/assets/js/components/WorksheetItemCard.jsx';
import { formatTasksForWorksheet } from 'projects/assets/js/lib/utils';
import { selectActiveOrg, selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';

export const ADD_WORKSHEET_ITEM_MODAL_ID = 'add-worksheet-item-modal';

class WorksheetAddItemPanel extends React.Component {
  static FetchData({ dispatch, params, url, querystring, componentName, authedAxios }) {
    const fetchParams = {
      url,
      params,
      querystring,
      projectId: params.id,
      componentName,
      authedAxios,
      dispatch,
    };
    return Promise.all([
      dispatch(fetchTaskListForWorksheetDS(fetchParams)),
      dispatch(fetchTasksAssignmentAnalyticsDS(fetchParams)),
    ]);
  }

  static GetComponentName() {
    return 'WorksheetAddItemPanel';
  }

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      selectedItems: [],
    };

    this.getAvailableWorksheetItems = this.getAvailableWorksheetItems.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.addCurrencyWarnings = this.addCurrencyWarnings.bind(this);
  }

  getAvailableWorksheetItems() {
    const {
      activeUserCard, organization, project, tasks, rates, tasksWorksheetAnalytics,
    } = this.props;
    let availableItems = [];

    const defaultRate = rates && rates[0];

    let { worksheetCurrency: projectWorkCurrency } = this.props;
    if (defaultRate) {
      projectWorkCurrency = defaultRate.currency;
    } else if (activeUserCard.currency) {
      projectWorkCurrency = activeUserCard.currency;
    }

    // Project work item
    const projectWorkItem = {
      currency: projectWorkCurrency,
      currencySymbol: CURRENCY_SYMBOL[projectWorkCurrency],
      description: PROJECT_WORK_ITEM_DESCRIPTION,
      id: -1,
      // projectId is needed so the item renderer can tell the difference between a worksheet
      // project item and a proforma invoice item
      projectId: project.id,
      quantity: defaultRate ? null : 1,
      rate_id: defaultRate ? defaultRate.id : RATE_SUGGEST_VALUE,
      rate_unit: defaultRate ? defaultRate.unit : RATE_UNIT.FIXED,
      task_id: null,
    };

    if (!organization.allow_project_work_in_worksheets) {
      projectWorkItem.accessControl = { canCreateWorksheetFor: false };
      projectWorkItem.warning = [
        ...(projectWorkItem.warning ? projectWorkItem.warning : []),
        [
          'Raising a worksheet for project work is not enabled in this organisation. You can only',
          'select tasks assigned to you.',
        ].join(' '),
      ];
    }

    availableItems.push(projectWorkItem);

    if (!tasks) {
      return {
        availableItems: this.addCurrencyWarnings(availableItems),
        finalizedItems: [],
      };
    }

    const groupedTasks = groupBy(
      tasks,
      task => (task.accessControl.isFinalized ? 'finalized' : 'available'),
    );

    if (groupedTasks.available) {
      // transforms tasks list into valid worksheet item objects.
      availableItems = [
        ...availableItems,
        ...formatTasksForWorksheet(groupedTasks.available, tasksWorksheetAnalytics),
      ];
    }

    return {
      availableItems: this.addCurrencyWarnings(availableItems),
      finalizedItems: this.addCurrencyWarnings(groupedTasks.finalized),
    };
  }

  addCurrencyWarnings(items = []) {
    const { worksheetCurrency } = this.props;

    return items.map(item => {
      const returnItem = { ...item };

      if (item.currency !== worksheetCurrency) {
        returnItem.warning = [
          ...(returnItem.warning ? returnItem.warning : []),
          [
            "This item's currency",
            `(${CURRENCY_LABEL[item.currency]} ${CURRENCY_SYMBOL[item.currency]})`,
            'does not match the one selected for the worksheet',
            `(${CURRENCY_LABEL[worksheetCurrency]} ${CURRENCY_SYMBOL[worksheetCurrency]}),`,
            "so it will be converted into the worksheet's currency.",
          ].join(' '),
        ];
      }

      return returnItem;
    });
  }

  handleCloseModal() {
    const { dispatch, payload } = this.props;

    dispatch(modalCloseAC(ADD_WORKSHEET_ITEM_MODAL_ID));

    if (payload.onModalClose) {
      setTimeout(() => payload.onModalClose(), 2000);
    }

    this.setState({ selectedItems: [] });
  }

  handleSelectedItem(selectedItem) {
    const { selectedItems: prevSelections } = this.state;
    const alreadySelected = prevSelections.some(it => it.id === selectedItem.id);
    let newSelections;
    if (alreadySelected) {
      newSelections = prevSelections.filter(selection => selection.id !== selectedItem.id);
    } else {
      newSelections = uniqBy([...prevSelections, selectedItem], 'id');
    }

    this.setState({ selectedItems: newSelections });
  }

  render() {
    const { isOpen, project, handleAddSelectedItems, disabledItems } = this.props;
    const { selectedItems } = this.state;
    const ctaLabel = `Add ${pluralize('item', selectedItems.length || 1)}`;
    const { availableItems, finalizedItems } = this.getAvailableWorksheetItems();

    const _disabledItems = availableItems
      ? availableItems.filter(
        item => (
          (item && item.accessControl && !item.accessControl.canCreateWorksheetFor)
        ),
      ) : [];

    return (
      <ModalPanel
        data-testid="worksheet-add-item-panel"
        modalId={ADD_WORKSHEET_ITEM_MODAL_ID}
        open={isOpen}
        headingClassName="modal-header--small"
        heading={(
          <React.Fragment>
            What did you work on?
            <aside>{project.title}</aside>
          </React.Fragment>
        )}
        body={(
          <TDApiConnected
            duck="view"
            storeKey={WorksheetAddItemPanel.GetComponentName()}
            blockingLoading
            skeletonComponent={() => 'Loading items.....'}
            fetchData={({ ...args }) => WorksheetAddItemPanel.FetchData({
              ...args,
              params: { ...args.params, id: project.id },
            })}
          >
            <CheckableList
              multipleSelection
              items={availableItems}
              itemComponent={WorksheetItemCard}
              itemComponentProps={{ project }}
              disabledItems={disabledItems.concat(_disabledItems)}
              onItemChecked={this.handleSelectedItem}
            />

            {finalizedItems && finalizedItems.length > 0 && (
              <AreaCollapsible
                className="my-4"
                headingClassName="bg-light-grey mt-3 mx-0 mb-4"
                headingChildren="Finalised work"
                contentChildren={(
                  <div>
                    { finalizedItems.map((item, index) => {
                      if (typeof item === 'undefined') {
                        return null;
                      }

                      return (
                        <div
                          key={item.id || index}
                          className={[
                            'checkable-list__option',
                            'checkable-list__option--disabled',
                            'mb-3',
                            'p-4',
                            'cursor-default',
                          ].join(' ')}
                        >
                          <WorksheetTaskItemCard item={item} />
                        </div>
                      );
                    })}
                  </div>
                )}
              />
            )}
          </TDApiConnected>
        )}
        footer={(
          <div className="d-block text-right">
            <TDButton
              variant={BS_STYLE.DEFAULT}
              onClick={this.handleCloseModal}
              label="Close"
            />

            <TDButton
              className="ml-4"
              data-testid="worksheet-add-item-panel-submit"
              variant={BS_STYLE.PRIMARY}
              disabled={isEmpty(selectedItems)}
              onClick={() => {
                this.handleCloseModal();
                handleAddSelectedItems(selectedItems);
              }}
              // pluralize adds 's' on item when 0 values are selected
              label={ctaLabel}
            />
          </div>
        )}
      />
    );
  }
}
WorksheetAddItemPanel.propTypes = {
  activeUserCard: userCardSpec.isRequired,
  disabledItems: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func.isRequired,
  handleAddSelectedItems: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  organization: orgSpec.isRequired,
  payload: PropTypes.object,
  project: projectSpec.isRequired,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)).isRequired,
  tasks: PropTypes.arrayOf(projectTaskSpec).isRequired,
  tasksWorksheetAnalytics: PropTypes.arrayOf(PropTypes.object),
  worksheetCurrency: PropTypes.string,
};
WorksheetAddItemPanel.defaultProps = {
  disabledItems: [],
  payload: null,
  tasksWorksheetAnalytics: [],
  worksheetCurrency: null,
};

const mapStateToProps = (state) => {
  const tasklistState = getListState(state, WorksheetAddItemPanel.GetComponentName());
  return {
    activeUserCard: selectActiveUserCard(state),
    organization: selectActiveOrg(state),
    tasks: tasklistState.items,
    tasksWorksheetAnalytics: getListStateExtras(state, WorksheetAddItemPanel.GetComponentName(), 'tasksAssignmentAnalytics'),
    isOpen: getIsModalOpen(state, ADD_WORKSHEET_ITEM_MODAL_ID),
    payload: getModalPayload(state, ADD_WORKSHEET_ITEM_MODAL_ID),
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });
const WorksheetAddItemPanelConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorksheetAddItemPanel);

export default WorksheetAddItemPanelConnected;
