import Big from 'big.js';
import { FORM_ERROR } from 'final-form';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import {
  BS_SIZE, BS_STYLE, DATETIME_FORMAT_DEFAULT, ICON, REQ_TYPE,
} from 'core/assets/js/constants';
import { getModalPayload, modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { pushDataDS } from 'core/assets/js/lib/dataServices';
import {
  formatDate, getDatetime, getDateTimeDiff, parseDate, renderDuration,
} from 'core/assets/js/lib/utils';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import TimeTrackerUnused from 'projects/assets/js/components/timeTracker/TimeTrackerUnused.jsx';
import {
  fetchInvoiceCapUsageDS, fetchTimeTrackerUnusedRecordsDS,
} from 'projects/assets/js/data-services/view';
import { timeTrackerTaskOptionSpec } from 'projects/assets/js/lib/objectSpecs';
import { createWorkTimeBlockApiUrl, editWorkTimeBlockApiUrl } from 'projects/urls';

export const TIME_TRACKER_MODAL_ID = 'TimeTrackerModal';

const TimeTrackerForm = ({ taskOptions }) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(state => getIsModalOpen(state, TIME_TRACKER_MODAL_ID));
  const activeOrg = useSelector(state => selectActiveOrg(state));
  const activeUserCard = useSelector(selectActiveUserCard);
  const workTimeBlock = useSelector(state => getModalPayload(state, TIME_TRACKER_MODAL_ID));
  const isCreate = !workTimeBlock;

  const handleModalClose = () => {
    dispatch(modalCloseAC());
  };

  const onFormSubmit = async (values) => {
    const validationErrors = {};
    if (!values.startTime) {
      validationErrors.startTime = 'Invalid start time';
    }
    if (!values.endTime) {
      validationErrors.endTime = 'Invalid end time';
    }
    if (!values.taskId) {
      validationErrors.taskId = 'Please select a task';
    }
    if (Object.keys(validationErrors).length > 0) {
      return validationErrors;
    }

    const successMessage = isCreate
      ? 'Time entry submitted successfully.'
      : 'Time entry updated successfully.';
    try {
      await dispatch(pushDataDS({
        pushApiUrl: isCreate
          ? createWorkTimeBlockApiUrl(activeOrg.alias)
          : editWorkTimeBlockApiUrl(activeOrg.alias, workTimeBlock.id),
        reqType: isCreate ? REQ_TYPE.POST : REQ_TYPE.PUT,
        values: {
          ...values,
          startTime: values.startTime
            && moment(values.startTime).local().utc().format(DATETIME_FORMAT_DEFAULT),
          endTime: values.endTime
            && moment(values.endTime).local().utc().format(DATETIME_FORMAT_DEFAULT),
        },
      }));
      toastr.success('Well Done!', successMessage);
      dispatch(fetchTimeTrackerUnusedRecordsDS({
        componentName: TimeTrackerUnused.GetComponentName(), orgAlias: activeOrg.alias,
      }));
      if (activeOrg.invoice_caps_enabled && activeUserCard.invoiceCap) {
        dispatch(fetchInvoiceCapUsageDS({
          orgAlias: activeOrg.alias, userId: activeUserCard.user.id,
        }));
      }
      handleModalClose();
      return null;
    } catch (error) {
      const propertyErrors = omit(error.errors || {}, '_error', '_meta');
      if (Object.keys(propertyErrors).length > 0) {
        return propertyErrors;
      }
      return { [FORM_ERROR]: error._error || error.message };
    }
  };

  const calculateEarnings = ({ startTime, endTime, taskId }) => {
    const taskOption = taskOptions.find(to => to.value === taskId);

    if (!taskOption || !startTime || !endTime) {
      return '-';
    }

    const { currency, rateAmount } = taskOption;
    const timeDiff = getDateTimeDiff(startTime, endTime);
    if (Number.isNaN(timeDiff)) {
      return '-';
    }

    const total = new Big(rateAmount)
      .mul(timeDiff / 1000 / 60 / 60)
      .toFixed(2);


    return <NumberTpl prefix={CURRENCY_SYMBOL[currency]} value={total} />;
  };

  // Reset form values everytime modal is opened.
  if (!isModalOpen) {
    return null;
  }

  return (
    <Form
      initialValues={{
        ...workTimeBlock,
        startTime: workTimeBlock?.startTime
          && moment(workTimeBlock.startTime).utc(true).local().format(DATETIME_FORMAT_DEFAULT),
        endTime: workTimeBlock?.endTime
          && moment(workTimeBlock.endTime).utc(true).local().format(DATETIME_FORMAT_DEFAULT),
      }}
      onSubmit={onFormSubmit}
      render={({ form: { change }, handleSubmit, values: { endTime, startTime, taskId } }) => (
        <form className="d-inline-block" onSubmit={handleSubmit}>
          <ModalSimple
            heading={`${isCreate ? 'Add' : 'Edit'} time entry`}
            open={isModalOpen}
            size={BS_SIZE.LARGE}
            body={(
              <div className="row">
                <div className="col-12">
                  <CustomSelectField
                    label="Task"
                    name="taskId"
                    defaultOptionText="Select a task"
                    options={taskOptions}
                    isSearchable
                  />
                </div>

                <div className="col-12 col-md-6">
                  <DatePickerField
                    label="Time entry"
                    sublabel="Start date and time"
                    name="startTime"
                    placeholder="Select date and time"
                    required
                    timeSelectionEnabled
                    isValid={current => current.isSameOrBefore(getDatetime(), 'day')}
                  />
                  <FormSpy
                    onChange={({ values }) => {
                      if (!values.startTime) {
                        return;
                      }
                      const parsedStartTime = parseDate(
                        values.startTime, DATETIME_FORMAT_DEFAULT, false,
                      );
                      if (!parsedStartTime.isValid()) {
                        return;
                      }
                      const changingStartTime = values.startTime !== startTime;
                      if (!changingStartTime) {
                        // Only auto-adjust end time, when adjusting start time
                        return;
                      }
                      const previousDiffSeconds = endTime
                        ? getDateTimeDiff(startTime, endTime) / 1000
                        : 60 * 60;
                      if (previousDiffSeconds < 0) {
                        return;
                      }
                      let newEndTime = parsedStartTime.clone().add(
                        previousDiffSeconds, 'seconds',
                      );
                      const now = getDatetime();
                      if (newEndTime.isAfter(now)) {
                        newEndTime = now;
                      }
                      const newEndTimeString = formatDate(newEndTime, DATETIME_FORMAT_DEFAULT);
                      if (newEndTimeString === values.endTime) {
                        return;
                      }
                      change('endTime', newEndTimeString);
                    }}
                    subscription={{ values: true }}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <DatePickerField
                    label=" "
                    sublabel="End date and time"
                    name="endTime"
                    placeholder="Select date and time"
                    timeSelectionEnabled
                    disabled={!startTime}
                    isValid={current => (
                      current.isSameOrAfter(moment(startTime).local().utc(), 'day')
                        && current.isSameOrBefore(moment().utc(), 'day')
                    )}
                  />
                </div>

                <div className="col-12 col-md-6 mb-4">
                  <div className="label">Duration</div>
                  <div>
                    <span className="font-weight-bolder font-bigger">
                      {renderDuration(startTime, endTime)}
                    </span>
                  </div>
                </div>

                <div className="col-12 col-md-6 mb-4">
                  <div className="label">Earnings</div>
                  <div className="d-flex align-items-center">
                    <i className={`${ICON.MONEY_BILL} mr-3 text-success font-bigger`} />
                    {calculateEarnings({ startTime, endTime, taskId })}
                  </div>
                </div>

                <div className="col-12">
                  <TextAreaField
                    name="description"
                    label="Description"
                  />
                </div>
              </div>
            )}
            onClose={handleModalClose}
            footer={(
              <div>
                <TDButton
                  label="Cancel"
                  onClick={handleModalClose}
                  variant={BS_STYLE.DEFAULT}
                />

                <TDButton
                  label="Submit"
                  onClick={handleSubmit}
                  type="submit"
                  variant={BS_STYLE.SUCCESS}
                />
              </div>
            )}
          />
        </form>
      )}
    />
  );
};

TimeTrackerForm.propTypes = {
  taskOptions: PropTypes.arrayOf(timeTrackerTaskOptionSpec).isRequired,
};

export default TimeTrackerForm;
