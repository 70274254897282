import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import AnswerRenderer from 'core/assets/js/components/AnswerRenderer.jsx';
import { customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';

const AnswersList = ({
  answers, customFieldTemplates, labelClass, showTemplateNames, valueClass, wrapperClass,
}) => {
  if (isEmpty(answers)) {
    return null;
  }

  const groupedByTemplate = {};

  answers.forEach(answer => {
    const { customFieldTemplateId } = answer.question;
    if (!groupedByTemplate[customFieldTemplateId]) {
      const template = customFieldTemplates.find(t => t.id === customFieldTemplateId);
      groupedByTemplate[customFieldTemplateId] = {
        answers: [],
        name: template?.name || null,
      };
    }
    groupedByTemplate[answer.question.customFieldTemplateId].answers.push(answer);
  });

  return Object.keys(groupedByTemplate).map((templateId, index) => (
    <Fragment key={templateId}>
      {showTemplateNames && groupedByTemplate[templateId].name && (
        <div className={`custom-field-template-name mb-3${index > 0 ? ' mt-4' : ''}`}>
          {groupedByTemplate[templateId].name}
        </div>
      )}
      {groupedByTemplate[templateId].answers.map(answer => (
        <AnswerRenderer
          answer={answer}
          key={`answer-${answer.id}`}
          labelClass={labelClass}
          valueClass={valueClass}
          wrapperClass={wrapperClass}
        />
      ))}
    </Fragment>
  ));
};

AnswersList.propTypes = {
  answers: PropTypes.array,
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
  labelClass: PropTypes.string,
  showTemplateNames: PropTypes.bool,
  valueClass: PropTypes.string,
  wrapperClass: PropTypes.string,
};

AnswersList.defaultProps = {
  answers: [],
  customFieldTemplates: [],
  labelClass: '',
  showTemplateNames: false,
  valueClass: '',
  wrapperClass: '',
};

export default AnswersList;
