import axios from 'axios';
import { omit } from 'lodash';

import { NODE_ENV, ENV_PRODUCTION } from 'core/assets/js/config/settings';
import { USER_TYPE } from 'core/assets/js/constants';
import { ValidationErrorItem } from 'core/assets/js/errors';
import { INVOICING_MODE, isSEPA } from 'finance/assets/js/constants';
import {
  BANK_ACCOUNT_TYPE, DASHBOARD_WIDGET, DASHBOARD_WIDGET_FIELD_NAME, SALES_TAX_SCENARIOS,
} from 'settings/assets/js/constants';

const {
  US_TYPE, CA_TYPE, IN_TYPE, AU_TYPE,
  NZ_TYPE, GB_TYPE, EU_TYPE, BASE_TYPE,
  CH_TYPE, HK_TYPE, CN_TYPE, BR_TYPE,
  PE_TYPE, SA_TYPE, CO_TYPE, TW_TYPE,
} = BANK_ACCOUNT_TYPE;

export const paymentTermsTemplate = (gracePeriod) => { // eslint-disable-line
  if (!gracePeriod) {
    return 'Payment due with immediate effect';
  }
  return `Total due amount should be paid within ${gracePeriod} days from the date of this invoice by bank transfer.`;
};

export const getBankAccountTypeByCountryCode = (countryCode) => {
  if (isSEPA(countryCode) && countryCode !== 'GB') {
    return EU_TYPE;
  }
  switch (countryCode) {
    case 'US':
    case 'PA':
      return US_TYPE;
    case 'CA':
      return CA_TYPE;
    case 'HK':
      return HK_TYPE;
    case 'IN':
      return IN_TYPE;
    case 'AU':
      return AU_TYPE;
    case 'NZ':
      return NZ_TYPE;
    case 'GB':
      return GB_TYPE;
    case 'CH':
      return CH_TYPE;
    case 'CN':
      return CN_TYPE;
    case 'BR':
      return BR_TYPE;
    case 'PE':
      return PE_TYPE;
    case 'SA':
      return SA_TYPE;
    case 'CO':
      return CO_TYPE;
    case 'TW':
      return TW_TYPE;
    default:
      return BASE_TYPE;
  }
};

export const getBankAccountLegacyBankCode = (bankAccount) => {
  switch (bankAccount.bank_account_type
    || getBankAccountTypeByCountryCode(bankAccount.country_code)) {
    case US_TYPE:
      return bankAccount.ach_routing_no;
    case CA_TYPE:
      return bankAccount.transit_no;
    case AU_TYPE:
      return bankAccount.bsb_code;
    case GB_TYPE:
      return bankAccount.sort_code;
    case BR_TYPE:
      return bankAccount.account_number;
    case CO_TYPE:
      return bankAccount.account_number;
    case TW_TYPE:
      return bankAccount.account_number;
    case PE_TYPE:
      return bankAccount.accountNumber;
    case EU_TYPE:
    case CH_TYPE:
    case CN_TYPE:
    case BASE_TYPE:
    case NZ_TYPE:
    case HK_TYPE:
    case IN_TYPE:
    case SA_TYPE:
    default:
      return bankAccount.swift_bic_code;
  }
};

/**
 * Tokenise a card number via the TrW api.
 * Will throw an error if tokenisation fails.
 * @param {string} cardNumber - card number to tokenise.
 * @return {string} token for card number from TrW.
 */
export const fetchTransferwiseCardToken = async (cardNumber) => {
  try {
    const baseURL = NODE_ENV === ENV_PRODUCTION
      ? 'https://api.transferwise.com'
      : 'https://api.sandbox.transferwise.tech';
    const res = await axios.post(
      `${baseURL}/v3/card`,
      { cardNumber },
    );

    return res?.data?.cardToken ?? '';
  } catch (err) {
    throw new Error('Failed to get token for card number from TrW');
  }
};

/**
 * Replace a card number with a TrW card token for card payment methods.
 * If the payload uses a method that makes use of a card number, we need to call
 * the TrW API to get a token for the card number, and only send the token to our
 * backend.  The card number must be removed.
 * Non card payments are passed through without change.
 * @param {object} payload - payload we will send to our backend, includes payment details
 * from the TrW bank account form, and selected payment method.
 * @return {object} the updated payload with any card number replaced with a TrW card token.
 */
export const resolveCardTokenForPayload = async (payload) => {
  // nothing to do if we are not a card payment
  if (!payload.transferwise_type_selected
    || payload.transferwise_type_selected.toLowerCase() !== 'card') {
    return payload;
  }

  // clone payload and remove card number
  const resolvedPayload = omit(payload, ['details.cardNumber']);

  // call TrW api to get token for card number
  try {
    resolvedPayload.details.cardToken = await fetchTransferwiseCardToken(
      payload.details.cardNumber,
    );
  } catch (err) {
    throw new ValidationErrorItem('cardNumber', 'Please double check that the card number you entered is correct.');
  }

  // return payload with card number removed and token added
  return resolvedPayload;
};

/**
 * Gets the relevant sales tax scenario, for a user
 *
 * @param {Object} organization
 * @param {Object} company
 * @param {Object} profile
 * @returns {String}
 */
export const getSalesTaxScenario = (organization, company, profile) => {
  const orgBillingIsVia = organization.invoicing_mode === INVOICING_MODE.VIA;
  const orgIsInUk = organization?.address_components?.country_code === 'GB';
  const orgAllowsSettingSalesTax = !!organization.allow_providers_setting_sales_tax;
  const loggedInUserIsRegisteredForTax = !!company?.has_tax_system;
  let loggedInUserCountry = profile?.address_components?.country_code;
  if (company?.is_incorporated && company?.address_components?.country_code) {
    loggedInUserCountry = company?.address_components?.country_code;
  }
  const loggedInUserIsInUk = loggedInUserCountry === 'GB';

  if (orgBillingIsVia) {
    if (loggedInUserIsInUk) {
      if (loggedInUserIsRegisteredForTax) {
        return SALES_TAX_SCENARIOS.VIA_MODE__CONTRACTOR_UK__TAX_REGISTERED;
      }
      return SALES_TAX_SCENARIOS.VIA_MODE__CONTRACTOR_UK__NOT_TAX_REGISTERED;
    }
    return SALES_TAX_SCENARIOS.VIA_MODE__CONTRACTOR_NOT_UK;
  }

  if (orgIsInUk) {
    if (loggedInUserIsInUk) {
      if (loggedInUserIsRegisteredForTax) {
        return SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_UK__CONTRACTOR_UK__TAX_REGISTERED;
      }
      return SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_UK__CONTRACTOR_UK__NOT_TAX_REGISTERED;
    }
    if (orgAllowsSettingSalesTax) {
      return SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_UK__CONTRACTOR_NOT_UK__CAN_EDIT;
    }
    return SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_UK__CONTRACTOR_NOT_UK__CANNOT_EDIT;
  }

  if (loggedInUserIsInUk) {
    return SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_NOT_UK__CONTRACTOR_UK;
  }

  if (orgAllowsSettingSalesTax) {
    return SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_NOT_UK__CONTRACTOR_NOT_UK__CAN_EDIT;
  }

  return SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_NOT_UK__CONTRACTOR_NOT_UK__CANNOT_EDIT;
};

export const getAvailableDashboardWidgetSettings = (activeOrg, activeUserCard) => {
  const isProvider = activeUserCard.userRole?.ofType === USER_TYPE.PROVIDER;
  const widgets = [DASHBOARD_WIDGET.TASKS, DASHBOARD_WIDGET.PENDING_ACTIONS];
  if (isProvider) {
    widgets.push(
      DASHBOARD_WIDGET.WORKSHEETS, DASHBOARD_WIDGET.EXPENSES, DASHBOARD_WIDGET.OPPORTUNITIES,
    );
  } else {
    widgets.push(
      DASHBOARD_WIDGET.PROJECTS,
      DASHBOARD_WIDGET.MANAGERS,
      DASHBOARD_WIDGET.CONTRACTORS,
      DASHBOARD_WIDGET.TALENTMARKET,
    );
  }
  if (!activeUserCard.isEmployee && activeOrg.payment_status_widget_enabled) {
    widgets.push(DASHBOARD_WIDGET.PAYMENT_STATUS);
  }
  return widgets;
};

export const getDashboardWidgetDefaultSettings = (activeOrg, activeUserCard) => {
  const isProvider = activeUserCard.userRole?.ofType === USER_TYPE.PROVIDER;
  const settings = {
    [DASHBOARD_WIDGET_FIELD_NAME[DASHBOARD_WIDGET.TASKS]]: true,
    [DASHBOARD_WIDGET_FIELD_NAME[DASHBOARD_WIDGET.TALENTMARKET]]: !isProvider,
    [DASHBOARD_WIDGET_FIELD_NAME[DASHBOARD_WIDGET.PENDING_ACTIONS]]: true,
  };
  if (isProvider) {
    settings[DASHBOARD_WIDGET_FIELD_NAME[DASHBOARD_WIDGET.WORKSHEETS]] = true;
    settings[DASHBOARD_WIDGET_FIELD_NAME[DASHBOARD_WIDGET.EXPENSES]] = true;
    settings[DASHBOARD_WIDGET_FIELD_NAME[DASHBOARD_WIDGET.OPPORTUNITIES]] = true;
  } else {
    settings[DASHBOARD_WIDGET_FIELD_NAME[DASHBOARD_WIDGET.PROJECTS]] = true;
    settings[DASHBOARD_WIDGET_FIELD_NAME[DASHBOARD_WIDGET.MANAGERS]] = true;
    settings[DASHBOARD_WIDGET_FIELD_NAME[DASHBOARD_WIDGET.CONTRACTORS]] = true;
  }
  if (!activeUserCard.isEmployee && activeOrg.payment_status_widget_enabled) {
    settings[DASHBOARD_WIDGET_FIELD_NAME[DASHBOARD_WIDGET.PAYMENT_STATUS]] = true;
  }
  return settings;
};
