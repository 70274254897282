import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, omit } from 'lodash';

import { Card } from 'react-bootstrap';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import OrganizationForm from 'settings/assets/js/components/OrganizationForm.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { fetchUserCardWithVatDS, postOrganizationDS, fetchSystemFinancialEntitiesDS } from 'settings/assets/js/data-services/organizations';
import {
  getActiveUserCardPermissionChecker, selectActiveOrg,
} from 'organizations/assets/js/reducers/organizations';
import { PERMISSIONS } from 'roles/assets/js/constants';
import OrganizationSummary from 'settings/assets/js/components/OrganizationSummary.jsx';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import InvoiceTWBankDetails from 'finance/assets/js/components/invoicing/InvoiceTWBankDetails.jsx';

class OrganizationEditView extends React.Component {
  static FetchData({ dispatch, params, url = '', authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchUserCardWithVatDS(params, url, authedAxios, componentName)),
    ]).then(() => {
      dispatch(fetchSystemFinancialEntitiesDS(params, url, authedAxios, componentName));
    });
  }

  static GetComponentName() {
    return 'OrganizationEditView';
  }

  render() {
    const { hasPermission, userCard, systemFinancialEntities } = this.props;
    const systemFEPOJOs = systemFinancialEntities.map(fe => new FinancialEntity(fe));
    const breadcrumbs = [
      {
        title: 'Organisation',
        url: null,
      },
    ];
    const { organization } = userCard;
    const componentName = this.constructor.GetComponentName();
    let initialValues = {};
    if (organization) {
      initialValues = {
        ...organization.billing_details,
        ...omit(organization, ['vat', 'gst', 'registration_number']),
      };
    }
    const canManageOrgSettings = hasPermission(PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS);
    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />
        <div className="page page--org-settings">
          <div className="container">
            <TDApiConnected duck="view" component={this.constructor} blockingLoading skeletonComponent={SettingsPageSkeleton}>
              <div className="row">
                <div className={`col-12 mb-4 ${canManageOrgSettings ? 'col-md-4' : ''}`}>
                  <>
                    <Card>
                      <Card.Header className="py-3">
                        Summary
                      </Card.Header>
                      <Card.Body>
                        <OrganizationSummary org={organization} />
                      </Card.Body>
                    </Card>
                    <Card>
                      <Card.Header className="py-3">
                        Bank account details
                      </Card.Header>
                      <Card.Body>
                        {systemFEPOJOs.map(fe => {
                          if (isEmpty(fe)) {
                            return null;
                          }
                          return (
                            <div className="mb-4">
                              <div className="mb-2">
                                <strong>{fe.getName()}</strong>
                              </div>
                              <InvoiceTWBankDetails
                                owner={fe}
                              />
                            </div>
                          );
                        })}
                      </Card.Body>
                    </Card>
                  </>
                </div>
                <div className="col-12 col-md-8">
                  {canManageOrgSettings && (
                    <OrganizationForm
                      initialValues={initialValues}
                      onSubmit={async (values, dispatch) => {
                        const res = await dispatch(postOrganizationDS(
                          organization.unique_alias,
                          values,
                          componentName,
                          { forFinalForm: true },
                        ));
                        if (!isEmpty(res)) {
                          return res;
                        }
                        return null;
                      }}
                      organization={organization}
                    />
                  )}
                </div>
              </div>
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

OrganizationEditView.propTypes = {
  hasPermission: PropTypes.func.isRequired,
  userCard: PropTypes.object.isRequired,
  systemFinancialEntities: PropTypes.arrayOf(PropTypes.object),
};

OrganizationEditView.defaultProps = {
  systemFinancialEntities: [],
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, OrganizationEditView.GetComponentName());
  return {
    activeOrg: selectActiveOrg(state),
    company: viewState.extras.company,
    hasPermission: getActiveUserCardPermissionChecker(state),
    organization: viewState.item,
    sysOrg: viewState.extras.sysOrg,
    systemFinancialEntities: viewState.extras.systemFinancialEntities,
    userCard: viewState.item,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationEditView);
