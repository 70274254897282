import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Money } from 'td-finance-ts';

import { formatDate } from 'admin/assets/js/lib/utils';
import { DATETIME_FORMAT_DEFAULT } from 'core/assets/js/constants';
import IgnoreButton from './IgnoreButton.jsx';

const useStyles = makeStyles(theme => ({
  overviewContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: '1px solid lightgray',
  },
  ignoredContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: '1px solid lightgray',
    textAlign: 'left',
  },
  larger: {
    fontSize: '1rem',
    marginBottom: '1rem',
  },
  overviewTransferWrapper: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: '100',
  },
}));

const OverviewTransferHeader = ({
  remoteId,
  paymentDate,
  allocatedAmount,
  currency,
  transferAmount,
  ignored,
  ignoredComment,
  ignoredBy,
  ignoredAt,
}) => {
  const classes = useStyles();
  const transferAmountFormatted = new Money(transferAmount, currency)
    .toString({ withSymbol: true, humanizeAmount: true });
  const allocatedAmountFormatted = new Money(allocatedAmount, currency)
    .toString({ withSymbol: true, humanizeAmount: true });
  return (
    <div className={classes.overviewTransferWrapper}>
      <div className={classes.overviewContainer}>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div>
            {'Wise transfer # '}
            {remoteId}
          </div>
          <IgnoreButton remoteId={remoteId} ignored={ignored} />
        </div>
      </div>
      {ignored ? (
        <div className={classes.ignoredContainer}>
          <div className="w-100 d-flex flex-column">
            <div className={classes.larger}>
              <span className="text-muted">Reason:</span>
              {' '}
              {ignoredComment}
            </div>
            <div className="text-muted">
              {ignoredBy}
            </div>
            <div className="text-muted">
              {formatDate(ignoredAt, DATETIME_FORMAT_DEFAULT)}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.overviewContainer}>
          <div className="d-flex">
            {allocatedAmountFormatted}
            /
            <span className="text-left font-weight-bold">
              {transferAmountFormatted}
              {' '}
              allocated
            </span>
          </div>
          <div>
            {formatDate(paymentDate, 'YYYY/MM/DD')}
          </div>
        </div>
      )}
    </div>
  );
};

OverviewTransferHeader.propTypes = {
  remoteId: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]),
  paymentDate: PropTypes.oneOfType([
    PropTypes.string, PropTypes.instanceOf(Date),
  ]),
  currency: PropTypes.string,
  transferAmount: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]),
  allocatedAmount: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]),
  ignored: PropTypes.bool,
  ignoredComment: PropTypes.string,
  ignoredBy: PropTypes.string,
  ignoredAt: PropTypes.oneOfType([
    PropTypes.string, PropTypes.instanceOf(Date),
  ]),
};

OverviewTransferHeader.defaultProps = {
  remoteId: null,
  paymentDate: '-',
  currency: null,
  transferAmount: null,
  allocatedAmount: null,
  ignored: false,
  ignoredComment: null,
  ignoredBy: null,
  ignoredAt: null,
};

export default OverviewTransferHeader;
