import Big from 'big.js';
import { isEmpty } from 'lodash';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { parseDate } from 'core/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { projectViewUrl } from 'projects/urls';
import {
  API_DATE_FORMAT,
  BS_STYLE,
  CURRENCY,
  ICON,
  MAX_UPLOAD_FILES,
  MIME_TYPES,
  PROJECT_TABS,
} from 'core/assets/js/constants';
import { DEFAULT_FX_RATE_SPREAD } from 'projects/assets/js/constants';
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';
import MoneyInputField from 'core/assets/js/components/FinalFormFields/MoneyInputField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import CurrencySelectField from 'core/assets/js/components/FinalFormFields/CurrencySelectField.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { uploaderProjectExpensesPath } from 'core/urls';
import ExchangeRateInfoPopOver from 'core/assets/js/components/ExchangeRateInfoPopOver.jsx';
import { exchangeRatesServiceSpec, exchangeRatesSpec } from 'finance/assets/js/lib/objectSpecs';

Big.RM = 1;
Big.DP = 2;

const ExpenseForm = props => {
  const {
    allowedPeriodStart: allowedPeriodStartIn,
    disableSubmit,
    exchangeRates,
    exchangeRatesService,
    exchangeRateUpdatedAt,
    history,
    initialValues,
    onSubmit,
    organization: { currency: orgCurrency, id: orgId, invoicing_fx_markup: fxMarkup },
    orgAlias,
    project,
    updating,
  } = props;

  const currentDate = moment();
  const allowedPeriodStart = parseDate(allowedPeriodStartIn, API_DATE_FORMAT);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ form: { getState }, handleSubmit }) => {
        const { submitting, values: { cost, currency, periodStart, quantity } } = getState();
        const currencySymbol = CURRENCY_SYMBOL[currency];
        const total = new Money(cost || 0, currency).mul(quantity || 0).toString();

        const fxRateSpread = fxMarkup
          ? fxMarkup / 100
          : DEFAULT_FX_RATE_SPREAD;

        let expenseToOrganizationExchangeRate = null;
        if (
          !isEmpty(exchangeRates)
          && !isEmpty(exchangeRates[currency])
          && exchangeRates[currency][orgCurrency]
        ) {
          expenseToOrganizationExchangeRate = Big(exchangeRates[currency][orgCurrency])
            .times(1 + fxRateSpread)
            .toNumber();
        }
        const shouldDisplayExchangeRateInfo = (
          new Money(total, currency).toNumber() > 0
          && currency !== orgCurrency
          && expenseToOrganizationExchangeRate
        );
        return (
          <form onSubmit={handleSubmit} className="col-12">
            <div
              className={
                [
                  'row',
                  'content-box',
                  'rounded',
                  'shadow-sm',
                  'bg-white',
                  'page--project_claim-expense',
                  'mb-4',
                ].join(' ')
              }
            >
              <div className="col-12 form-container expense-form">
                <h2 className="content-box__header">
                  {updating ? 'Update the expense' : 'Claim an expense for the project'}
                </h2>

                <p>Expenditure Breakdown</p>

                <div className="row">
                  <div className="col-12 col-md-6">
                    {!isEmpty(exchangeRates) && (
                      <CurrencySelectField
                        currencyOptions={CURRENCY}
                        isClearable={false}
                        name="currency"
                      />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-6 col-sm-3 col-lg-3">
                    <InputNumberField
                      label=""
                      name="quantity"
                      placeholder="Quantity"
                    />
                  </div>

                  <span className="expense-multiplier">
                    <i className={ICON.CROSS} />
                  </span>

                  <div className="col-6 col-sm-4 col-lg-3">
                    <MoneyInputField
                      data-testid="expense-form-cost"
                      decimals={2}
                      name="cost"
                      label=""
                      placeholder="0.00"
                      step={0.01}
                      suffix={currencySymbol}
                    />
                  </div>

                  <div
                    className={
                      [
                        'col-12',
                        'col-sm-4',
                        'col-lg-5',
                        'justify-content-center',
                        'justify-content-sm-start',
                        'mt-n3',
                        'mt-sm-0',
                        'expense-calculation',
                        'd-flex',
                        'pl-0',
                      ].join(' ')
                    }
                  >
                    <span className="expense-calculation_equals pr-3">=</span>
                    <NumberTpl
                      className="expense-calculation_total-amount"
                      prefix={currencySymbol}
                      value={total}
                    />
                    {shouldDisplayExchangeRateInfo && (
                      <ExchangeRateInfoPopOver
                        className="ml-2"
                        exchangeRate={expenseToOrganizationExchangeRate}
                        exchangeRateDate={exchangeRateUpdatedAt}
                        exchangeRateService={exchangeRatesService}
                        sourceCurrency={currency}
                        targetAmount={
                          new Money(total, currency)
                            .mul(expenseToOrganizationExchangeRate)
                            .toString()
                        }
                        targetCurrency={orgCurrency}
                      />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-6">
                    <DatePickerField
                      disableAfterDate={currentDate}
                      disableBeforeDate={allowedPeriodStart}
                      label="Start Date"
                      name="periodStart"
                      placeholder="Choose date"
                      timeFormat={false}
                    />
                  </div>

                  <div className="col-12 col-sm-6">
                    <DatePickerField
                      disableAfterDate={currentDate}
                      disableBeforeDate={(
                        periodStart ? parseDate(periodStart, API_DATE_FORMAT) : allowedPeriodStart
                      )}
                      label="End Date"
                      name="periodEnd"
                      placeholder="Choose date"
                      timeFormat={false}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <TextAreaField
                      label="Summary"
                      name="summary"
                      placeholder="Add a summary of your expense"
                      type="textarea"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <FileUploaderField
                      acceptFiles={['image/*', ...MIME_TYPES.DOCUMENTS]}
                      label="Attachments"
                      maxFiles={MAX_UPLOAD_FILES}
                      name="attachments"
                      path={uploaderProjectExpensesPath(orgId, project.id)}
                      type="text"
                      uploaderWrapperClassName="fileuploader--white"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 text-right button-container">
              <TDButton
                className="btn btn-lg btn-default"
                disabled={submitting}
                label="Cancel"
                onClick={() => (
                  history.push(projectViewUrl(orgAlias, project.id, PROJECT_TABS.EXPENSES))
                )}
              />

              <TDButton
                disabled={submitting || disableSubmit}
                label="Submit"
                type="submit"
                variant={BS_STYLE.PRIMARY}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

ExpenseForm.propTypes = {
  allowedPeriodStart: PropTypes.string.isRequired,
  disableSubmit: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  exchangeRates: exchangeRatesSpec,
  exchangeRatesService: exchangeRatesServiceSpec,
  exchangeRateUpdatedAt: PropTypes.string,
  history: routerHistorySpec.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  orgAlias: PropTypes.string.isRequired,
  organization: orgSpec.isRequired,
  project: projectSpec.isRequired,
  updating: PropTypes.bool,
};

ExpenseForm.defaultProps = {
  disableSubmit: true,
  exchangeRates: null,
  exchangeRatesService: null,
  exchangeRateUpdatedAt: null,
  updating: false,
};

const mapStateToProps = state => ({ organization: selectActiveOrg(state) });

const ExpenseFormConnected = connect(mapStateToProps)(ExpenseForm);

export default withRouter(ExpenseFormConnected);
