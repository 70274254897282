import { BANK_CURRENCY } from 'td-finance-ts';

import { ICON } from 'core/assets/js/constants';

const BANK_ACCOUNT_TYPE = {
  US_TYPE: 1,
  CA_TYPE: 2,
  IN_TYPE: 3,
  AU_TYPE: 4,
  NZ_TYPE: 5,
  GB_TYPE: 6,
  EU_TYPE: 7,
  BASE_TYPE: 8,
  CH_TYPE: 9,
  HK_TYPE: 10,
  CN_TYPE: 11,
  BR_TYPE: 12,
  PE_TYPE: 13,
  SA_TYPE: 14,
  CO_TYPE: 15,
  TW_TYPE: 16,
  TRANSFERWISE_NO_VALIDATION: 49,
  TRANSFERWISE: 50,
  PAYPAL: 51,
  PAYONEER: 52,
  REVOLUT: 53,
};

// account types which are provided via a custom bank account form
const CUSTOM_BANK_ACCOUNT_TYPE = [
  BANK_ACCOUNT_TYPE.BASE_TYPE,
  BANK_ACCOUNT_TYPE.CN_TYPE,
  BANK_ACCOUNT_TYPE.BR_TYPE,
  BANK_ACCOUNT_TYPE.PE_TYPE,
  BANK_ACCOUNT_TYPE.SA_TYPE,
  BANK_ACCOUNT_TYPE.CO_TYPE,
  BANK_ACCOUNT_TYPE.TW_TYPE,
];

const CUSTOM_BANK_ACCOUNT_TYPE_LABEL = {
  [BANK_ACCOUNT_TYPE.BASE_TYPE]: 'Bank account',
  [BANK_ACCOUNT_TYPE.CN_TYPE]: 'Chinese bank account',
  [BANK_ACCOUNT_TYPE.BR_TYPE]: 'Brazilian bank account',
  [BANK_ACCOUNT_TYPE.PE_TYPE]: 'Peruvian bank account',
  [BANK_ACCOUNT_TYPE.SA_TYPE]: 'Saudi Arabian bank account',
  [BANK_ACCOUNT_TYPE.CO_TYPE]: 'Colombian bank account',
  [BANK_ACCOUNT_TYPE.TW_TYPE]: 'Taiwanese bank account',
};

const PAYMENT_METHODS = {
  BANK_TRANSFER: 1,
  PAYPAL: 2,
  PAYONEER: 3,
};

const PAYMENT_METHOD_DETAILS = {
  [PAYMENT_METHODS.BANK_TRANSFER]: {
    id: PAYMENT_METHODS.BANK_TRANSFER,
    methodId: PAYMENT_METHODS.BANK_TRANSFER,
    alias: 'bank-account',
    bankAccountTypeId: [
      BANK_ACCOUNT_TYPE.BR_TYPE,
      BANK_ACCOUNT_TYPE.BASE_TYPE,
      BANK_ACCOUNT_TYPE.CN_TYPE,
      BANK_ACCOUNT_TYPE.PE_TYPE,
      BANK_ACCOUNT_TYPE.SA_TYPE,
      BANK_ACCOUNT_TYPE.CO_TYPE,
      BANK_ACCOUNT_TYPE.TW_TYPE,
      BANK_ACCOUNT_TYPE.TRANSFERWISE_NO_VALIDATION,
      BANK_ACCOUNT_TYPE.TRANSFERWISE,
    ],
    name: 'Bank account',
    description: 'Client fees: The fees are based on the user\'s bank account currency',
    icon: ICON.BANK,
    logo: null,
    isDefault: false,
  },
  [PAYMENT_METHODS.PAYPAL]: {
    id: PAYMENT_METHODS.PAYPAL,
    methodId: PAYMENT_METHODS.PAYPAL,
    alias: 'paypal',
    bankAccountTypeId: [BANK_ACCOUNT_TYPE.PAYPAL],
    name: 'PayPal',
    description: 'Client fees: 2.5%',
    icon: null,
    logo: '/img/payment-options/paypal-logo.png',
    isDefault: false,
  },
  [PAYMENT_METHODS.PAYONEER]: {
    id: PAYMENT_METHODS.PAYONEER,
    methodId: PAYMENT_METHODS.PAYONEER,
    alias: 'payoneer',
    bankAccountTypeId: [BANK_ACCOUNT_TYPE.PAYONEER],
    name: 'Payoneer',
    description: 'Client fees: The fees are based on the user\'s bank account currency',
    icon: null,
    logo: '/img/payment-options/payoneer-logo.png',
    isDefault: false,
  },
};

const EXTENDED_PAYMENT_METHODS = {
  ...PAYMENT_METHODS,
  WISE: 0,
};

const EXTENDED_PAYMENT_METHOD_DETAILS = {
  ...PAYMENT_METHOD_DETAILS,
  // Not an actual new payment method - Used in bank account forms
  // to provide a method disguised as new - Wise.
  [EXTENDED_PAYMENT_METHODS.WISE]: {
    id: EXTENDED_PAYMENT_METHODS.WISE,
    methodId: EXTENDED_PAYMENT_METHODS.BANK_TRANSFER,
    alias: 'wise',
    bankAccountTypeId: [
      BANK_ACCOUNT_TYPE.BR_TYPE,
      BANK_ACCOUNT_TYPE.BASE_TYPE,
      BANK_ACCOUNT_TYPE.CN_TYPE,
      BANK_ACCOUNT_TYPE.PE_TYPE,
      BANK_ACCOUNT_TYPE.SA_TYPE,
      BANK_ACCOUNT_TYPE.CO_TYPE,
      BANK_ACCOUNT_TYPE.TW_TYPE,
      BANK_ACCOUNT_TYPE.TRANSFERWISE_NO_VALIDATION,
      BANK_ACCOUNT_TYPE.TRANSFERWISE,
    ],
    name: 'Wise',
    description: '',
    icon: null,
    logo: '/img/payment-options/transferwise-logo.png',
    isDefault: true,
    queryParams: { isWise: 1 },
  },
};


const TW_BANK_ACCOUNT_STEP = {
  START: 'start',
  BANK_DETAILS: 'bank-details',
};

const COMPANY_TAX_SYSTEM = {
  NO_SYSTEM: 0,
  VAT: 1,
  GST: 2,
  HST: 3,
};

const COMPANY_TAX_SYSTEM_INFO = {
  [COMPANY_TAX_SYSTEM.NO_SYSTEM]: {
    label: null,
    systemLabel: null,
    rateLabel: 'sales tax',
  },
  [COMPANY_TAX_SYSTEM.VAT]: {
    label: 'VAT',
    systemLabel: 'vat',
    rateLabel: 'VAT/Sales tax',
  },
  [COMPANY_TAX_SYSTEM.GST]: {
    label: 'GST',
    systemLabel: 'gst',
    rateLabel: 'GST/Sales tax',
  },
  [COMPANY_TAX_SYSTEM.HST]: {
    label: 'HST',
    systemLabel: 'hst',
    rateLabel: 'HST/Sales tax',
  },
};

const REVERSE_CHARGE_PLACEHOLDER = '<your client\'s VAT/GST/HST goes here>';

const SALES_TAX_TOOLTIP_MSG = 'Depending on your jurisdiction your sales tax can be the value added tax (VAT), good and services tax (GST), harmomized sales tax or a conventional sales tax, like in the case of U.S.';

const SALES_TAX_ACTIVITY_DESCRIPTION = 'Whether you are or are not incorporated, and you operate in a jurisdiction with a VAT System, e.g. Europe; whether you operate in a jurisdiction with a GST System, e.g. India; whether you operate in a jurisdiction with an HST System, e.g. Canada; or whether you operate in a jurisdiction without a VAT or GST System, e.g. the United States';

const AVAILABLE_COMPANY_TAX_SYSTEMS = Object
  .keys(COMPANY_TAX_SYSTEM_INFO)
  .map(key => COMPANY_TAX_SYSTEM_INFO[key].systemLabel)
  .filter(sys => sys);

const SALES_TAX_SYSTEM_FORM_FIELDS = [
  'has_tax_system', 'tax_system_type', ...AVAILABLE_COMPANY_TAX_SYSTEMS,
];

const SALES_TAX_SYSTEM_SERIALIZATION_FIELDS = [
  'has_tax_system', 'tax_system_type', 'tax_system', 'full_vat',
  ...AVAILABLE_COMPANY_TAX_SYSTEMS,
];

const DEFAULT_GRACE_PERIOD = 30; //eslint-disable-line

const SETTINGS_PAYMENTS_TABS = {
  INVOICE_DETAILS: 'invoice-details',
  PAYMENT_METHODS: 'payment-methods',
  PAYMENT_TERMS: 'payment-terms',
};

export const SETTINGS_PAYMENTS_SUB_PAGES = {
  ...SETTINGS_PAYMENTS_TABS,
  BANK_ACCOUNT: 'bank-account',
  CREATE_PAYMENT_METHOD: 'create-payment-method',
  PAYONEER: 'payoneer',
  PAYPAL: 'paypal',
};

export const SETTINGS_PAYMENTS_PAYMENT_METHOD_SUB_PAGES = {
  CREATE: 'create',
  EDIT: 'edit',
  SELECT_ORGANIZATION: 'select-organization',
  SUCCESS: 'success',
  STATUS: 'status',
  CANCEL: 'cancel',
};

const SETTINGS_TEMPLATE_TABS = {
  ORG_INVITATIONS: 'org-invitations',
  CUSTOM_FIELDS: 'custom-fields',
};

const ORG_SETTINGS_PAYMENTS_TABS = {
  PAYMENT_METHODS: 'payment-methods',
  SALES_TAX: 'sales-tax',
  INVOICES: 'invoices',
};

const ORG_SETTINGS_FINANCE_TABS = {
  WORKSHEETS: 'worksheets',
  INVOICES: 'invoices',
  PAYMENT_METHODS: 'payment-methods',
  TIME_TRACKER: 'time-tracker',
};

const ORG_SETTINGS_COMMON_FOOTNOTES_CONTENT = {
  NO_IDENT_SALES_TAX: 'No identification of sales tax, since small business owners according to §19 UstG.',
  NO_TVA: 'TVA non applicable, art. 293 B du CGI.',
};

const ORG_SETTINGS_COMMON_FOOTNOTES_LABEL = {
  NO_IDENT_SALES_TAX: 'Art. 19 I UStG (VAT Act)',
  NO_TVA: 'Art. 293 B du CGI',
};

const TW_BANK_ACCOUNT_INFO = {
  [BANK_CURRENCY.RUB]: 'The full account holder\'s name, that is first, last and patronymic, should be written in Cyrillic,  in order for your transfer to be accepted by the recipient bank. The patronymic name can be omitted only in case the recipient is registered at the bank with no patronymic name.',
  [BANK_CURRENCY.SEK]: 'From October 30, we\'ll be able to send transfers to SEK using only IBAN details.After that date, using Bankgiro or Local bank account numbers to receive SEK won\'t be possible.',
  [BANK_CURRENCY.JPY]: 'In order for your transfer to be accepted by the recipient bank, the account holder\'s name should be written in Katakana, with the last name followed by the first. The bank details should be on the first page of the recipient\'s bank book, not the cover. If the recipient is a business, make sure you include its legal abbreviation',
  [BANK_CURRENCY.CNY]: [
    '位于中国大陆的专家，请在货币（Currency）栏选择人民币（CNY），在国家（Country）栏选择中国（China）。',
    '请注意，UNIONPAY CARD银联渠道只支持我们将资金存入个人银联账户。 为了使收款银行接受给您的转帐，请不要添加商务银联账户。',
    '如果您有一个可以接受美元的企业帐户，请将上面的货币（Currency）更改为美元（USD），并在LOCAL BANK ACCOUNT下添加SWIFT码、CNAPS等详细信息。',
    '中国的某些银行不接受国外付款，因此我们无法向以下银行汇款：',
    '\u2022 招商银行',
    '\u2022 中国民生银行',
    '\u2022 兴业银行',
    '\u2022 上海浦东发展银行',
    '\u2022 南京银行',
    '如果您的银行包含在上面的列表中，请选择其他付款卡或付款方式。',
    'Please note that we can only send funds to personal accounts via UnionPay. In order for your transfer to be accepted by the recipient bank, please do not add business UnionPay card details.',
    'If you have a business account that accepts USD, please change the currency above to USD and add the SWIFT details.',
    'Some banks in China don’t accept payments from abroad, so we cannot send money to the following banks:',
    '- China Merchants bank',
    '- China Minsheng bank',
    '- Industrial Bank Co',
    '- Shanghai Pudong Development Bank',
    '- Bank of Nanjing',
    'If your bank is included in the list above, please enter alternative payment details.',
  ],
};

const SETTINGS_SKILL_TABS = {
  SKILLS: 'skills',
  SKILL_DIRECTORIES: 'skill-directories',
};

const PAYMENT_METHODS_LABEL = Object.entries(PAYMENT_METHOD_DETAILS).reduce((
  acc, [method, { name }],
) => {
  acc[method] = name;
  return acc;
}, {});

const BANK_ACCOUNT_TYPE_TO_PAYMENT_METHOD = Object.entries(PAYMENT_METHOD_DETAILS).reduce((
  acc, [method, { bankAccountTypeId }],
) => {
  bankAccountTypeId.forEach((bankAccountType) => {
    acc[bankAccountType] = parseInt(method, 10);
  });
  return acc;
}, {});

const BANK_ACCOUNT_STATUS = {
  DRAFT: 0, // user did not complete registration process
  PENDING: 1, // user completed registration process - waiting validation from Payoneer
  READY: 2, // Payoneer completed the validation and account is ready to use
  CANCELLED: 3, // User cancelled the registration process

  DECLINED: 4,

  // Payoneer specific payee decline notification response codes
  PAYONEER_PAYEE_DETAILS_NOT_VALID: 5,
  PAYONEER_PAYEE_DETAILS_NEED_UPDATING: 6,
  PAYONEER_PAYEE_CANCELED: 7,
  PAYONEER_PAYEE_CLOSED: 8,
  PAYONEER_CONTACT_PAYONEER: 9,
  PAYONEER_ALREADY_USED: 10,
  PAYONEER_ALREADY_APPROVED: 11,
  PAYONEER_UNSUPPORTED: 12,
  PAYONEER_MORE_INFO_REQUIRED: 13,

  // TODO add more cases here
};

const BANK_ACCOUNT_DECLINED_STATUSES = [
  BANK_ACCOUNT_STATUS.DECLINED,
  BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_DETAILS_NOT_VALID,
  BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_DETAILS_NEED_UPDATING,
  BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_CANCELED,
  BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_CLOSED,
  BANK_ACCOUNT_STATUS.PAYONEER_CONTACT_PAYONEER,
  BANK_ACCOUNT_STATUS.PAYONEER_ALREADY_USED,
  BANK_ACCOUNT_STATUS.PAYONEER_ALREADY_APPROVED,
  BANK_ACCOUNT_STATUS.PAYONEER_UNSUPPORTED,
  BANK_ACCOUNT_STATUS.PAYONEER_MORE_INFO_REQUIRED,
];

const BANK_ACCOUNT_STATUS_LABEL = {
  [BANK_ACCOUNT_STATUS.DRAFT]: 'Draft',
  [BANK_ACCOUNT_STATUS.PENDING]: 'Pending',
  [BANK_ACCOUNT_STATUS.READY]: 'Ready',
  [BANK_ACCOUNT_STATUS.CANCELLED]: 'Cancelled',
  [BANK_ACCOUNT_STATUS.DECLINED]: 'Declined',
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_DETAILS_NOT_VALID]: 'Payee details not valid',
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_DETAILS_NEED_UPDATING]: 'Payee details need updating',
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_CANCELED]: 'Payee cancelled',
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_CLOSED]: 'Payee closed',
  [BANK_ACCOUNT_STATUS.PAYONEER_CONTACT_PAYONEER]: 'Contact Payoneer',
  [BANK_ACCOUNT_STATUS.PAYONEER_ALREADY_USED]: 'Already used',
  [BANK_ACCOUNT_STATUS.PAYONEER_ALREADY_APPROVED]: 'Already approved',
  [BANK_ACCOUNT_STATUS.PAYONEER_UNSUPPORTED]: 'Unsupported',
  [BANK_ACCOUNT_STATUS.PAYONEER_MORE_INFO_REQUIRED]: 'More info required',
};

const BANK_ACCOUNT_DECLINED_STATUSES_LABELS = {
  [BANK_ACCOUNT_STATUS.DECLINED]: 'Your application to create an account has been declined by Payoneer',
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_DETAILS_NOT_VALID]: 'Your application to create an account has been declined by Payoneer, the details you have entered to do not appear to valid',
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_DETAILS_NEED_UPDATING]: 'Your application to create an account has been declined by Payoneer, the details you have entered appear to need updating',
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_CANCELED]: 'Your account has been cancelled',
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_CLOSED]: 'Your account has been closed',
  [BANK_ACCOUNT_STATUS.PAYONEER_CONTACT_PAYONEER]: 'Please contact Payoneer for more information',
  [BANK_ACCOUNT_STATUS.PAYONEER_ALREADY_USED]: 'The details you entered have already been used',
  [BANK_ACCOUNT_STATUS.PAYONEER_ALREADY_APPROVED]: 'You already have an existing account with Payoneer',
  [BANK_ACCOUNT_STATUS.PAYONEER_UNSUPPORTED]: 'Payoneer does not currently support the payment details you have entered',
  [BANK_ACCOUNT_STATUS.PAYONEER_MORE_INFO_REQUIRED]: 'Payoneer requires further details from you before this account can be activated',
};

const PAYONEER_BANK_ACCOUNT_STATUS_INFO = {
  [BANK_ACCOUNT_STATUS.PENDING]: {
    statusLabel: 'Waiting for approval',
    statusClass: 'pending',
    icon: ICON.HOURGLASS_START,
    title: alias => `Payoneer account "${alias}" pending review`,
    subtitle: () => 'Payoneer account was added but needs to be approved by Payoneer.',
    extraInfo: () => 'Once the account gets approved, we will notify you.',
  },
  [BANK_ACCOUNT_STATUS.DECLINED]: {
    statusLabel: 'Rejected',
    statusClass: 'rejected',
    icon: ICON.TIMES_CIRCLE,
    title: alias => `Payoneer account "${alias}" rejected!`,
    subtitle: alias => `Payoneer account "${alias}" was rejected by Payoneer.`,
    extraInfo: () => '',
  },
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_DETAILS_NOT_VALID]: {
    statusLabel: 'Rejected',
    statusClass: 'rejected',
    icon: ICON.TIMES_CIRCLE,
    title: alias => `Payoneer account "${alias}" rejected!`,
    subtitle: alias => `Payoneer account "${alias}" was rejected by Payoneer.`,
    extraInfo: () => '',
  },
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_DETAILS_NEED_UPDATING]: {
    statusLabel: 'Rejected',
    statusClass: 'rejected',
    icon: ICON.TIMES_CIRCLE,
    title: alias => `Payoneer account "${alias}" rejected!`,
    subtitle: alias => `Payoneer account "${alias}" was rejected by Payoneer.`,
    extraInfo: () => '',
  },
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_CANCELED]: {
    statusLabel: 'Rejected',
    statusClass: 'rejected',
    icon: ICON.TIMES_CIRCLE,
    title: alias => `Payoneer account "${alias}" rejected!`,
    subtitle: alias => `Payoneer account "${alias}" was rejected by Payoneer.`,
    extraInfo: () => '',
  },
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_CLOSED]: {
    statusLabel: 'Rejected',
    statusClass: 'rejected',
    icon: ICON.TIMES_CIRCLE,
    title: alias => `Payoneer account "${alias}" rejected!`,
    subtitle: alias => `Payoneer account "${alias}" was rejected by Payoneer.`,
    extraInfo: () => '',
  },
  [BANK_ACCOUNT_STATUS.PAYONEER_CONTACT_PAYONEER]: {
    statusLabel: 'Rejected',
    statusClass: 'rejected',
    icon: ICON.TIMES_CIRCLE,
    title: alias => `Payoneer account "${alias}" rejected!`,
    subtitle: alias => `Payoneer account "${alias}" was rejected by Payoneer.`,
    extraInfo: () => '',
  },
  [BANK_ACCOUNT_STATUS.PAYONEER_ALREADY_USED]: {
    statusLabel: 'Rejected',
    statusClass: 'rejected',
    icon: ICON.TIMES_CIRCLE,
    title: alias => `Payoneer account "${alias}" rejected!`,
    subtitle: alias => `Payoneer account "${alias}" was rejected by Payoneer.`,
    extraInfo: () => '',
  },
  [BANK_ACCOUNT_STATUS.PAYONEER_ALREADY_APPROVED]: {
    statusLabel: 'Rejected',
    statusClass: 'rejected',
    icon: ICON.TIMES_CIRCLE,
    title: alias => `Payoneer account "${alias}" rejected!`,
    subtitle: alias => `Payoneer account "${alias}" was rejected by Payoneer.`,
    extraInfo: () => '',
  },
  [BANK_ACCOUNT_STATUS.PAYONEER_UNSUPPORTED]: {
    statusLabel: 'Rejected',
    statusClass: 'rejected',
    icon: ICON.TIMES_CIRCLE,
    title: alias => `Payoneer account "${alias}" rejected!`,
    subtitle: alias => `Payoneer account "${alias}" was rejected by Payoneer.`,
    extraInfo: () => '',
  },
  [BANK_ACCOUNT_STATUS.PAYONEER_MORE_INFO_REQUIRED]: {
    statusLabel: 'Rejected',
    statusClass: 'rejected',
    icon: ICON.TIMES_CIRCLE,
    title: alias => `Payoneer account "${alias}" rejected!`,
    subtitle: alias => `Payoneer account "${alias}" was rejected by Payoneer.`,
    extraInfo: () => '',
  },
  [BANK_ACCOUNT_STATUS.CANCELLED]: {
    statusLabel: 'Rejected',
    statusClass: 'rejected',
    icon: ICON.TIMES_CIRCLE,
    title: () => 'Payoneer account adding cancelled.',
    subtitle: () => 'You have cancelled adding a Payoneer account. If you want to add a new Payoneer account, please restart the process.',
    extraInfo: () => '',
  },
};

export const DASHBOARD_WIDGET = {
  WORKSHEETS: 0,
  EXPENSES: 1,
  TASKS: 2,
  OPPORTUNITIES: 3,
  PROJECTS: 4,
  CONTRACTORS: 5,
  MANAGERS: 6,
  TALENTMARKET: 7,
  PAYMENT_STATUS: 8,
  PENDING_ACTIONS: 9,
};

export const DASHBOARD_WIDGET_VALUES = Object.values(DASHBOARD_WIDGET);

export const DASHBOARD_WIDGET_LABEL = {
  [DASHBOARD_WIDGET.WORKSHEETS]: 'Worksheets',
  [DASHBOARD_WIDGET.EXPENSES]: 'Expenses',
  [DASHBOARD_WIDGET.TASKS]: 'Tasks',
  [DASHBOARD_WIDGET.OPPORTUNITIES]: 'Opportunities',
  [DASHBOARD_WIDGET.PROJECTS]: 'Projects',
  [DASHBOARD_WIDGET.CONTRACTORS]: 'Contractors',
  [DASHBOARD_WIDGET.MANAGERS]: 'Managers',
  [DASHBOARD_WIDGET.TALENTMARKET]: 'Explore TalentMarket',
  [DASHBOARD_WIDGET.PAYMENT_STATUS]: 'Payment status',
  [DASHBOARD_WIDGET.PENDING_ACTIONS]: 'Pending actions',
};

export const DASHBOARD_WIDGET_FIELD_NAME = {
  [DASHBOARD_WIDGET.WORKSHEETS]: 'worksheets',
  [DASHBOARD_WIDGET.EXPENSES]: 'expenses',
  [DASHBOARD_WIDGET.TASKS]: 'tasks',
  [DASHBOARD_WIDGET.OPPORTUNITIES]: 'opportunities',
  [DASHBOARD_WIDGET.PROJECTS]: 'projects',
  [DASHBOARD_WIDGET.CONTRACTORS]: 'contractors',
  [DASHBOARD_WIDGET.MANAGERS]: 'managers',
  [DASHBOARD_WIDGET.TALENTMARKET]: 'talent-market',
  [DASHBOARD_WIDGET.PAYMENT_STATUS]: 'payment-status',
  [DASHBOARD_WIDGET.PENDING_ACTIONS]: 'pending-actions',
};

export const DASHBOARD_WIDGET_PAGE_SIZE = {
  [DASHBOARD_WIDGET.PROJECTS]: 3,
  [DASHBOARD_WIDGET.WORKSHEETS]: 3,
  [DASHBOARD_WIDGET.EXPENSES]: 3,
  [DASHBOARD_WIDGET.TASKS]: 3,
  [DASHBOARD_WIDGET.OPPORTUNITIES]: 3,
  [DASHBOARD_WIDGET.CONTRACTORS]: 7,
  [DASHBOARD_WIDGET.MANAGERS]: 3,
};

export const SALES_TAX_SCENARIOS = [
  'VIA_MODE__CONTRACTOR_NOT_UK',
  'VIA_MODE__CONTRACTOR_UK__NOT_TAX_REGISTERED',
  'VIA_MODE__CONTRACTOR_UK__TAX_REGISTERED',
  'NOT_VIA_MODE__ORG_UK__CONTRACTOR_UK__TAX_REGISTERED',
  'NOT_VIA_MODE__ORG_UK__CONTRACTOR_UK__NOT_TAX_REGISTERED',
  'NOT_VIA_MODE__ORG_UK__CONTRACTOR_NOT_UK__CAN_EDIT',
  'NOT_VIA_MODE__ORG_UK__CONTRACTOR_NOT_UK__CANNOT_EDIT',
  'NOT_VIA_MODE__ORG_NOT_UK__CONTRACTOR_UK',
  'NOT_VIA_MODE__ORG_NOT_UK__CONTRACTOR_NOT_UK__CAN_EDIT',
  'NOT_VIA_MODE__ORG_NOT_UK__CONTRACTOR_NOT_UK__CANNOT_EDIT',
].reduce((acc, key) => { acc[key] = key; return acc; }, {});

export const SYSTEM_BANK_BUSINESS_NUMBER = {
  TD: 10769129,
  SERVICES: 11282119,
};

export const SYSTEM_BANK_TYPE = {
  TD_BARCLAYS_GBP: 'td_barclays_gbp',
  TD_BARCLAYS_EUR: 'td_barclays_eur',
  TD_BARCLAYS_USD: 'td_barclays_usd',
  TD_WISE_GBP: 'td_wise_gbp',
  TD_WISE_EUR: 'td_wise_eur',
  TD_WISE_USD: 'td_wise_usd',
  TD_WISE_EUR_LOCAL: 'td_wise_eur_local',
  TD_WISE_USD_LOCAL: 'td_wise_usd_local',
  TD_WISE_CAD_LOCAL: 'td_wise_cad_local',
  TD_WISE_NZD_LOCAL: 'td_wise_nzd_local',
  TD_REVOLUT_JPY: 'td_revolut_jpy',
  TD_REVOLUT_HKD: 'td_revolut_hkd',
  SERVICES_BARCLAYS_GBP: 'services_barclays_gbp',
  SERVICES_BARCLAYS_EUR: 'services_barclays_eur',
  SERVICES_BARCLAYS_USD: 'services_barclays_usd',
  SERVICES_WISE_GBP: 'services_wise_gbp',
  SERVICES_WISE_EUR: 'services_wise_eur',
  SERVICES_WISE_USD: 'services_wise_usd',
  SERVICES_WISE_EUR_LOCAL: 'services_wise_eur_local',
  SERVICES_WISE_USD_LOCAL: 'services_wise_usd_local',
  SERVICES_WISE_CAD_LOCAL: 'services_wise_cad_local',
  SERVICES_WISE_NZD_LOCAL: 'services_wise_nzd_local',
  SERVICES_REVOLUT_JPY: 'services_revolut_jpy',
  SERVICES_REVOLUT_HKD: 'services_revolut_hkd',
};

export {
  AVAILABLE_COMPANY_TAX_SYSTEMS,
  BANK_ACCOUNT_STATUS,
  BANK_ACCOUNT_STATUS_LABEL,
  BANK_ACCOUNT_DECLINED_STATUSES,
  BANK_ACCOUNT_DECLINED_STATUSES_LABELS,
  BANK_ACCOUNT_TYPE,
  COMPANY_TAX_SYSTEM,
  COMPANY_TAX_SYSTEM_INFO,
  REVERSE_CHARGE_PLACEHOLDER,
  SALES_TAX_ACTIVITY_DESCRIPTION,
  SALES_TAX_TOOLTIP_MSG,
  SALES_TAX_SYSTEM_FORM_FIELDS,
  SALES_TAX_SYSTEM_SERIALIZATION_FIELDS,
  CUSTOM_BANK_ACCOUNT_TYPE,
  CUSTOM_BANK_ACCOUNT_TYPE_LABEL,
  DEFAULT_GRACE_PERIOD,
  ORG_SETTINGS_COMMON_FOOTNOTES_CONTENT,
  ORG_SETTINGS_COMMON_FOOTNOTES_LABEL,
  ORG_SETTINGS_FINANCE_TABS,
  ORG_SETTINGS_PAYMENTS_TABS,
  PAYMENT_METHOD_DETAILS,
  EXTENDED_PAYMENT_METHOD_DETAILS,
  PAYMENT_METHODS,
  EXTENDED_PAYMENT_METHODS,
  PAYMENT_METHODS_LABEL,
  BANK_ACCOUNT_TYPE_TO_PAYMENT_METHOD,
  SETTINGS_PAYMENTS_TABS,
  PAYONEER_BANK_ACCOUNT_STATUS_INFO,
  SETTINGS_SKILL_TABS,
  SETTINGS_TEMPLATE_TABS,
  TW_BANK_ACCOUNT_INFO,
  TW_BANK_ACCOUNT_STEP,
};

export const WISE_AFFILIATE_URL = 'https://wise.prf.hn/l/gANBWOq';

// TODO - remove this feature flag once this goes live
//        replace any code that uses it with the feature code
export const ENABLE_REVOLUT_FEATURE = true;

export const INVOICE_CAPS_PERIODS = {
  BILLING_CYCLE: 'billing-cycle',
  CALENDAR_WEEK: 'calendar-week',
  CALENDAR_MONTH: 'calendar-month',
};

export const INVOICE_CAPS_PERIOD_VALUES = Object.values(INVOICE_CAPS_PERIODS);

export const INVOICE_CAPS_PERIOD_LABELS = {
  [INVOICE_CAPS_PERIODS.BILLING_CYCLE]: 'By Billing Cycle will align the Cap to your current invoicing cycle (Weekly, Bi-weekly, Monthly)',
  [INVOICE_CAPS_PERIODS.CALENDAR_WEEK]: 'By Calendar Week will enforce the Cap every week',
  [INVOICE_CAPS_PERIODS.CALENDAR_MONTH]: 'By Calendar Month will enforce the Cap every calendar month',
};

export const INVOICE_CAPS_PERIOD_SHORT_LABELS = {
  [INVOICE_CAPS_PERIODS.BILLING_CYCLE]: 'Billing Cycle',
  [INVOICE_CAPS_PERIODS.CALENDAR_WEEK]: 'Calendar Week',
  [INVOICE_CAPS_PERIODS.CALENDAR_MONTH]: 'Calendar Month',
};

export const PROJECTS_SETTINGS_TABS = {
  PROJECTS: 'projects',
  TASKS: 'tasks',
};

export const PROJECTS_SETTINGS_TABS_VALUES = Object.values(PROJECTS_SETTINGS_TABS);
