import React from 'react';
import { FunctionField } from 'react-admin';
import { Money } from 'td-finance-ts';

import { useStyles } from 'admin/assets/js/layout/themes';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';

const InvoiceAmountField = ({ ...props }) => {
  const classes = useStyles();
  return (
    <FunctionField
      {...props}
      source="total"
      render={(invoice) => {
        const money = new Money(invoice.total, invoice.currency);
        const receivable = new Money(
          invoice.receivableAmount || invoice.total,
          invoice.receivableCurrency || invoice.currency,
        );
        const invoiceTotalIsReceivable = money.eq(receivable);
        return (
          <>
            <NumberTpl value={money.toString()} prefix={money.getCurrencySymbol()} />
            {!invoiceTotalIsReceivable && (
              <>
                <br />
                <small>
                  <span className={classes.discreet}>
                    Receivable:
                    <br />
                    <NumberTpl
                      value={receivable.toString()}
                      prefix={receivable.getCurrencySymbol()}
                    />
                  </span>
                </small>
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default InvoiceAmountField;
