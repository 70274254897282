import { pick } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import {
  CLASSIFICATION_SUBMISSION_RISK_LABELS, CLASSIFICATION_SUBMISSION_RISK_VALUES,
} from 'accounts/assets/js/constants';
import {
  classificationQuestionnaireIndividualUrl,
  classificationQuestionnairesApiUrl,
  classificationQuestionnairesUrl,
} from 'accounts/urls';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { ORDERING_DIRECTION } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { formatDate } from 'core/assets/js/lib/utils';
import { downloadFileApiUrl } from 'files/urls';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { orgUserProfileUrl } from 'people/urls';

const orderingOptions = [
  { text: 'Last name', value: 'lastName' },
  { text: 'Date completed', value: 'completedAt' },
  { text: 'Risk', value: 'risk' },
];
const defaultOrdering = { direction: ORDERING_DIRECTION.ASC, sortBy: 'lastName' };

const ClassificationQuestionairesView = ({ filtersOpen, onFiltersToggle }) => {
  const params = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const { hasLoaded, items, pagination, search: { isActive } } = fetchDataHook({
    componentName: ClassificationQuestionairesView.GetComponentName(params),
    duck: 'list',
    url: classificationQuestionnairesApiUrl(params.orgAlias),
  });

  const parsedQueryString = queryString.parse(search);

  return (
    <>
      <ContentHeader breadcrumbs={[{ title: 'Classification Questionnaires', url: null }]} />
      <div className="page page--classification-questionnaires">
        <div className="container">
          {hasLoaded && (
            <>
              {isActive && (
                <SearchFinalForm
                  className="px-0 mt-5"
                  initialValues={{
                    ...pick(parsedQueryString, 'kw', 'status'),
                    ordering: parsedQueryString.ordering || defaultOrdering,
                  }}
                  searchSpec={{
                    defaultOrdering,
                    orderingOptions,
                    searchTerm: {
                      component: TextInputField,
                      paramName: 'kw',
                      placeholder: 'Search contractors',
                    },
                    filters: [
                      // Note: Ordering filter is only shown on mobile devices.
                      {
                        fieldComponent: SelectableListFilterField,
                        isOrdering: true,
                        label: 'Sort By',
                        options: orderingOptions,
                        multiple: false,
                        paramName: 'ordering',
                      },
                      {
                        fieldComponent: SelectableListFilterField,
                        label: 'Risk',
                        multiple: true,
                        options: CLASSIFICATION_SUBMISSION_RISK_VALUES.map(value => ({
                          text: CLASSIFICATION_SUBMISSION_RISK_LABELS[value], value,
                        })),
                        paramName: 'risk',
                      },
                    ],
                  }}
                  onFiltersToggle={onFiltersToggle}
                  filtersOpen={filtersOpen}
                />
              )}

              <Table
                cols={[
                  {
                    dataFormat: (fullName, { avatarFileId, userId, userType }) => (
                      <>
                        <ProfilePic
                          alt={fullName}
                          className="mr-3"
                          url={avatarFileId ? downloadFileApiUrl(avatarFileId) : null}
                        />
                        <Link to={orgUserProfileUrl(params.orgAlias, userType, userId)}>
                          {fullName}
                        </Link>
                      </>
                    ),
                    key: 'fullName',
                    label: 'Name',
                    width: '300px',
                  },
                  {
                    dataFormat: completedAt => formatDate(completedAt),
                    key: 'completedAt',
                    label: 'Date completed',
                  },
                  {
                    columnClassName: risk => risk,
                    dataFormat: risk => CLASSIFICATION_SUBMISSION_RISK_LABELS[risk],
                    key: 'risk',
                    label: 'Risk',
                  },
                  {
                    dataFormat: id => (
                      <TDButton
                        label="View response"
                        onClick={() => {
                          history.push([
                            classificationQuestionnaireIndividualUrl(params.orgAlias, id),
                            `?backUrl=${classificationQuestionnairesUrl(params.orgAlias)}`,
                          ].join(''));
                        }}
                      />
                    ),
                    key: 'id',
                    label: 'Actions',
                    width: '200px',
                  },
                ]}
                items={items}
              />
              <TDPagination {...pagination} />
            </>
          )}
          {!hasLoaded && <FinanceTableSkeleton />}
        </div>
      </div>
    </>
  );
};

ClassificationQuestionairesView.GetComponentName = () => 'ClassificationQuestionairesView';

ClassificationQuestionairesView.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withFilters(ClassificationQuestionairesView);
