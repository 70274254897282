import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import DiscussionCard from 'projects/assets/js/components/DiscussionCard.jsx';
import { getProjectUnreadDiscussionsPerTaskApiUrl } from 'projects/urls';
import DiscussionsSearch from 'projects/assets/js/components/DiscussionsSearch.jsx';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';

const ProjectTabDiscussions = ({
  filtersOpen,
  onFiltersToggle,
}) => {
  const params = useParams();

  const {  hasLoaded, isLoading, items, pagination } = fetchDataHook({
    componentName: ProjectTabDiscussions.GetComponentName(),
    duck: 'list',
    url: getProjectUnreadDiscussionsPerTaskApiUrl(params.orgAlias, params.id),
  });

  const loading = !hasLoaded || isLoading;

  return (
    <>
      <div className="discussion-filter__container row">
        <DiscussionsSearch
          onFiltersToggle={onFiltersToggle}
          filtersOpen={filtersOpen}
        />
      </div>

      {(!loading && !filtersOpen) && (
        <TDList
          items={items}
          emptyListMessage="No discussion found."
          cardItem={{
            component: DiscussionCard,
            props: {
              projectId: params.id,
            },
          }}
          pagination={pagination}
        />
      )}
      {loading && <ProjectListSkeleton />}
    </>
  );
};

ProjectTabDiscussions.GetComponentName = () => 'ProjectTabDiscussions';

ProjectTabDiscussions.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};


export default withFilters(ProjectTabDiscussions);
