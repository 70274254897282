import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';

// This replicates the OnChange component of react-final-form-listeners:
// https://github.com/final-form/react-final-form-listeners/blob/master/src/OnChange.js

const TDFinalFormOnChange = ({ children: onChange, name }) => {
  const { values } = useFormState();

  // We use get, incase the property name is a nested value (e.g. "foo.bar")
  const value = get(values, name);

  const [previousValue, setPreviousValue] = useState(value);

  useEffect(() => {
    if (typeof onChange === 'function' && value !== previousValue) {
      onChange(value, previousValue);
      setPreviousValue(value);
    }
  }, [value, onChange, previousValue]);

  return null;
};

TDFinalFormOnChange.propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default TDFinalFormOnChange;
