/* eslint react/no-multi-comp: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty, isFinite, omit } from 'lodash';
import { CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import InvoicingFeeScheme from 'finance/assets/js/lib/InvoicingFeeScheme';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';
import { INVOICING_MODE, SUBSCRIPTION_PLAN_LABELS } from 'finance/assets/js/constants';
import SeatFeeAnalysis from 'finance/assets/js/lib/fees/SeatFeeAnalysis';

const prepareScalableFeeRow = (appliedFeeStep, currencySymbol, currency, month) => {
  const { billableRange, billableAmount, feePercent, fee: itemFee } = appliedFeeStep;
  let description;
  if (!isFinite(billableRange[1])) {
    if (new Money(billableRange[0], currency).isZero()) {
      description = `Provider charges in ${month}`;
    } else {
      description = `Provider charges (${currencySymbol}${billableRange[0]}+)`;
    }
  } else {
    description = `Provider charges (${currencySymbol}${billableRange[0]} - ${currencySymbol}${billableRange[1]})`;
  }
  return {
    description,
    billableAmount,
    feePercent,
    fee: itemFee,
  };
};

const getAmountColumn = (currencySymbol, key, label) => ({
  columnClassName: 'text-right',
  dataFormat: fee => <NumberTpl prefix={currencySymbol} value={fee} />,
  headerAlign: 'right',
  key,
  label,
  width: '170px',
});

const PerApprovedWorksheetFeeTable = ({ amounts }) => {
  const invoiceAmounts = new InvoiceAmounts(amounts);
  const processingFeeAnalysis = invoiceAmounts.getProcessingFeeAnalysis();
  if (!processingFeeAnalysis || processingFeeAnalysis.isEmpty()) {
    return null;
  }
  const currency = invoiceAmounts.getCurrency();
  const perApprovedWorksheet = processingFeeAnalysis.getPerApprovedWorksheetAnalysis();
  if (!perApprovedWorksheet || isEmpty(perApprovedWorksheet)) {
    return null;
  }

  const currencySymbol = CURRENCY_SYMBOL[currency];
  const tableColumns = [
    { key: 'description', label: 'Description', columnClassName: 'show-label', width: '170px' },
    getAmountColumn(currencySymbol, 'perApprovedWorksheetFee', 'Per approved worksheet fee'),
    { key: 'numApprovedWorksheets', label: 'Approved Worksheets', columnClassName: 'text-right show-label', width: '170px' },
    getAmountColumn(currencySymbol, 'fee', 'Fees'),
  ];
  const rows = perApprovedWorksheet.map(a => ({
    description: 'Processing fees',
    numApprovedWorksheets: a.numApprovedWorksheets,
    fee: a.fee,
    perApprovedWorksheetFee: a.perApprovedWorksheet,
  }));

  return <Table containerClass="invoice-table" cols={tableColumns} items={rows} />;
};

PerApprovedWorksheetFeeTable.propTypes = {
  amounts: PropTypes.object.isRequired,
};

const FixedFeeTable = ({ amounts, invoicingSettings }) => {
  const feeScheme = new InvoicingFeeScheme(invoicingSettings.feeScheme);
  const { fee, credit, currency } = amounts;
  const hasCredit = credit && !new Money(credit, currency).isZero();
  const hasAppliedFloor = fee === feeScheme.floor && !hasCredit;
  const currencySymbol = CURRENCY_SYMBOL[currency];
  const tableColumns = [
    { key: 'description', label: 'Description', columnClassName: 'show-label', width: '170px' },
    getAmountColumn(currencySymbol, 'fee', 'Fees'),
  ];

  const serviceFeesLabel = hasAppliedFloor ? 'Floored payment processing fees' : 'Payment processing fees';

  const rows = [{ description: serviceFeesLabel, fee }];

  return <Table containerClass="invoice-table" cols={tableColumns} items={rows} />;
};

FixedFeeTable.propTypes = {
  amounts: PropTypes.object.isRequired,
  invoicingSettings: PropTypes.object.isRequired,
};

const ZeroFeeTable = ({ amounts, month }) => {
  const { currency } = amounts;
  const currencySymbol = CURRENCY_SYMBOL[currency];
  const tableColumns = [
    { key: 'description', label: 'Description', columnClassName: 'show-label', width: '170px' },
    getAmountColumn(currencySymbol, 'billableAmount', 'Billable amount'),
  ];
  return (
    <Table
      containerClass="invoice-table"
      cols={tableColumns}
      items={[{
        description: `Provider charges in ${month}`,
        billableAmount: new Money('0.00', currency).toString(),
      }]}
    />
  );
};

ZeroFeeTable.propTypes = {
  amounts: PropTypes.object.isRequired,
  month: PropTypes.string.isRequired,
};

const ScalableFeeTable = ({ amounts, month }) => {
  const invoiceAmounts = new InvoiceAmounts(amounts);
  const processingFeeAnalysis = invoiceAmounts.getProcessingFeeAnalysis();
  if (!processingFeeAnalysis || processingFeeAnalysis.isEmpty()) {
    return ZeroFeeTable({ amounts, month });
  }
  const currency = invoiceAmounts.getCurrency();
  const scale = processingFeeAnalysis.getScale();
  if (!scale || isEmpty(scale)) {
    return ZeroFeeTable({ amounts, month });
  }

  const currencySymbol = CURRENCY_SYMBOL[currency];
  const tableColumns = [
    { key: 'description', label: 'Description', columnClassName: 'show-label', width: '170px' },
    getAmountColumn(currencySymbol, 'billableAmount', 'Billable amount'),
    { key: 'feePercent', label: 'Rate (%)', isFixedDecimals: true, columnClassName: 'text-right', width: '170px', headerAlign: 'right' },
    getAmountColumn(currencySymbol, 'fee', 'Fees'),
  ];
  const rows = scale.map(a => prepareScalableFeeRow(a, currencySymbol, currency, month));
  return <Table containerClass="invoice-table" cols={tableColumns} items={rows} />;
};

ScalableFeeTable.propTypes = {
  amounts: PropTypes.object.isRequired,
  month: PropTypes.string.isRequired,
};

const SubscriptionFeeTable = ({ amounts }) => {
  const invoiceAmounts = new InvoiceAmounts(amounts);
  const subscriptionFeeAnalysis = invoiceAmounts.getSubscriptionFeeAnalysis();
  if (!subscriptionFeeAnalysis || subscriptionFeeAnalysis.isEmpty()) {
    return null;
  }
  const currency = subscriptionFeeAnalysis.getCurrency();
  const currencySymbol = CURRENCY_SYMBOL[currency];
  const tableColumns = [
    { key: 'description', label: 'Description', columnClassName: 'show-label', width: '170px' },
    { key: 'qty', label: 'Qty', columnClassName: 'text-right show-label', width: '170px', headerAlign: 'right' },
    getAmountColumn(currencySymbol, 'unitPrice', 'Unit price'),
    getAmountColumn(currencySymbol, 'amount', 'Amount'),
  ];

  const rows = subscriptionFeeAnalysis.getComponents().map((a) => {
    // determine plan
    const planName = SUBSCRIPTION_PLAN_LABELS[a.getPlan()];
    // determine period length
    const periodStartDate = moment(a.getPeriodStart());
    const periodEndDate = moment(a.getPeriodEnd());
    const periodLength = moment.duration(periodEndDate.diff(periodStartDate)).as('days');
    // note - we assume renewals are either monthly or yearly,
    //        however lets give a little leeway to account for
    //        edge cases where a renewal is late etc.
    const periodLengthName = periodLength < 60 ? 'Monthly' : 'Yearly';
    // determine period start ( ie Apr 7 )
    const periodStart = periodStartDate.format('MMM D');
    // determine period end ( ie May 7, 2022 )
    const periodEnd = periodEndDate.format('MMM D, YYYY');

    return ({
      description: `${planName} (${periodLengthName})<br>${periodStart} - ${periodEnd}`,
      qty: a.getQuantity(),
      unitPrice: new Money(a.getSubscriptionFee(), a.getCurrency()).div(a.getQuantity()).toString(),
      amount: a.getSubscriptionFee(),
    });
  });

  return <Table containerClass="invoice-table" cols={tableColumns} items={rows} />;
};

SubscriptionFeeTable.propTypes = {
  amounts: PropTypes.object.isRequired,
};

const LicenceFeeTable = ({ amounts }) => {
  const invoiceAmounts = new InvoiceAmounts(amounts);
  const licenceFeeAnalysis = invoiceAmounts.getLicenceFeeAnalysis();
  if (!licenceFeeAnalysis || licenceFeeAnalysis.isEmpty()) {
    return null;
  }
  const currency = licenceFeeAnalysis.getCurrency();
  const currencySymbol = CURRENCY_SYMBOL[currency];
  const tableColumns = [
    { key: 'description', label: 'Description', columnClassName: 'show-label', width: '370px' },
    { key: 'quantity', label: 'Quantity', columnClassName: 'show-label', width: '70px' },
    getAmountColumn(currencySymbol, 'fee', 'Total'),
  ];

  const rows = [];
  const chargedServiceAnalyses = licenceFeeAnalysis.getChargedServiceAnalyses();
  chargedServiceAnalyses.forEach((service) => {
    const metricsDescriptions = service.getServiceMetricsDescriptions?.();
    if (metricsDescriptions) {
      rows.push(...metricsDescriptions);
    } else {
      rows.push({
        description: service.getServiceTitle(),
        fee: service.getTotal(),
        quantity: 1,
      });
    }
  });

  return <Table containerClass="invoice-table" cols={tableColumns} items={rows} />;
};

LicenceFeeTable.propTypes = {
  amounts: PropTypes.object.isRequired,
};

const FixedLicenceFeeTable = ({ amounts }) => {
  const invoiceAmounts = new InvoiceAmounts(amounts);
  const licenceFeeAnalysis = invoiceAmounts.getLicenceFeeAnalysis();
  if (!licenceFeeAnalysis || licenceFeeAnalysis.isEmpty()) {
    return null;
  }
  const currency = licenceFeeAnalysis.getCurrency();
  const currencySymbol = CURRENCY_SYMBOL[currency];
  const tableColumns = [
    { key: 'description', label: 'Description', columnClassName: 'show-label', width: '370px' },
    { key: 'quantity', label: 'Quantity', columnClassName: 'show-label', width: '70px' },
    getAmountColumn(currencySymbol, 'fee', 'Fees'),
  ];

  const rows = [{
    description: 'Licence fees',
    quantity: 1,
    fee: licenceFeeAnalysis.getTotal(),
  }];

  const chargedServiceAnalyses = licenceFeeAnalysis
    .getChargedServiceAnalyses().filter(service => !(service instanceof SeatFeeAnalysis));
  chargedServiceAnalyses.forEach((service) => {
    const metricsDescriptions = service.getServiceMetricsDescriptions?.();
    if (metricsDescriptions) {
      rows.push(...metricsDescriptions);
    } else {
      rows.push({
        description: service.getServiceTitle(),
        fee: service.getTotal(),
        quantity: 1,
      });
    }
  });

  return <Table containerClass="invoice-table" cols={tableColumns} items={rows} />;
};

FixedLicenceFeeTable.propTypes = {
  amounts: PropTypes.object.isRequired,
};

const FeeAnalysisTable = ({ amounts, invoicingSettings, month }) => {
  if (!amounts) {
    return null;
  }
  const invoiceAmounts = new InvoiceAmounts(omit(amounts, 'feeScheme'));
  const feeScheme = new InvoicingFeeScheme(invoicingSettings.feeScheme);
  const processingFeeScheme = feeScheme.getProcessingScheme();
  const licenceFeeScheme = feeScheme.getLicenceScheme();
  const { mode } = invoicingSettings;
  const hasZeroFees = new Money(invoiceAmounts.fee, invoiceAmounts.getCurrency()).isZero();

  if (invoiceAmounts.hasSubscriptionFee()) {
    return (<SubscriptionFeeTable amounts={amounts} />);
  }

  if (invoiceAmounts.hasLicenceFee()) {
    // Licence fees
    if (!licenceFeeScheme.isFixed()) {
      return (<LicenceFeeTable amounts={amounts} />);
    }
    return (<FixedLicenceFeeTable amounts={amounts} />);
  }

  if (mode === INVOICING_MODE.BILL_PAYMENTS && hasZeroFees) {
    return (<ZeroFeeTable amounts={amounts} month={month} />);
  }


  // Processing fees
  if (invoiceAmounts.isZero()) {
    return (<FixedFeeTable amounts={amounts} invoicingSettings={invoicingSettings} />);
  }

  if (processingFeeScheme.isScaled()) {
    return (<ScalableFeeTable amounts={amounts} month={month} />);
  }

  if (processingFeeScheme.hasPerApprovedWorksheet()) {
    return (
      <PerApprovedWorksheetFeeTable
        amounts={amounts}
      />
    );
  }

  if (feeScheme.isFixed()) {
    return (<FixedFeeTable amounts={amounts} invoicingSettings={invoicingSettings} />);
  }
  return null;
};

FeeAnalysisTable.propTypes = {
  amounts: PropTypes.object.isRequired,
  invoicingSettings: PropTypes.object,
  month: PropTypes.string.isRequired,
};

FeeAnalysisTable.defaultProps = {
  invoicingSettings: {},
};

export default FeeAnalysisTable;
