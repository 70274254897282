import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import TransactionAmounts from 'finance/assets/js/lib/TransactionAmounts';

const TransactionAmountsFieldPlain = ({ record }) => {
  if (!record || isEmpty(record)) {
    return null;
  }
  const amounts = new TransactionAmounts(record.amounts);
  const total = amounts.getOutgoingAmount();
  const currency = amounts.getOutgoingCurrency();
  const currencySymbol = CURRENCY_SYMBOL[currency];
  return (
    <span>
      <NumberTpl
        value={total}
        prefix={currencySymbol}
      />
    </span>
  );
};

TransactionAmountsFieldPlain.propTypes = {
  label: PropTypes.string.isRequired,
  record: PropTypes.object,
};

TransactionAmountsFieldPlain.defaultProps = {
  record: {},
};

export default TransactionAmountsFieldPlain;
