import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import arrayMutators from 'final-form-arrays';
import { isEmpty, groupBy } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { RATE_SUGGEST_VALUE, RATE_STATUS } from 'rates/assets/js/constants';
import { TASK_TABS } from 'core/assets/js/constants';
import { TASK_ACTION } from 'projects/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { routerMatchSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { fetchRatesForUsersDS } from 'rates/assets/js/data-services/rates';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import { getListState } from 'core/assets/js/ducks/list';
import { projectViewTaskUrl } from 'projects/urls';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import TaskAssignmentFieldSet from 'projects/assets/js/components/TaskAssignmentFieldSet.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';

export const FORM_ID = 'task-assignment-form';

class TaskAssignmentForm extends React.Component {
  static parseUserIds(search) {
    return decodeURIComponent(qs.parse(search).userIds || []).split(',').map(
      uid => parseInt(uid, 10),
    );
  }

  static FetchData({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) {
    const { orgAlias } = params;

    return Promise.all([
      dispatch(
        fetchRatesForUsersDS({
          authedAxios,
          componentName,
          orgAlias,
          url,
          status: [RATE_STATUS.ACTIVE],
          userIds: TaskAssignmentForm.parseUserIds(querystring),
        }),
      ),
    ]);
  }

  static GetComponentName() {
    return 'TaskAssignmentForm';
  }

  render() {
    const {
      addedDirectly, providers, rates,
      history: { location: { search } },
      match: { url: currentUrl, params: { orgAlias, id: projectId, taskId } },
      onAssignmentsSubmitted,
      onRemoveItem,
      activeOrg: {
        currency: defaultCurrency,
        default_rate_unit: defaultRateUnit,
      },
    } = this.props;
    const userIds = TaskAssignmentForm.parseUserIds(search);

    // Somehow landed (probably via the back button)
    // to the second step of the process, ie. this form
    // we need to redirect the user back to the selection form
    if (!isEmpty(userIds) && isEmpty(providers)) {
      const assignUrl = projectViewTaskUrl(
        orgAlias, projectId, taskId, TASK_TABS.DISCUSSION, TASK_ACTION.ASSIGN,
      );

      return (
        <RedirectRoute
          status={302}
          from={currentUrl}
          to={assignUrl}
        />
      );
    }

    const ratesPerUser = groupBy(rates, 'user_id');
    const initialValues = {
      assignments: providers.map(p => ({
        currency: defaultCurrency,
        user_id: p.user.id,
        rate_id: isEmpty(ratesPerUser[p.user.id]) ? RATE_SUGGEST_VALUE : null,
        message: null,
        rate_unit: p.rateUnit || defaultRateUnit,
        p_id: p.id,
      })),
    };
    return (
      <TDApiConnected
        duck="list"
        storeKey={TaskAssignmentForm.GetComponentName()}
        fetchData={TaskAssignmentForm.FetchData}
        skeletonComponent={PeopleListSkeleton}
      >
        <Form
          name={FORM_ID}
          onSubmit={onAssignmentsSubmitted}
          mutators={{ ...arrayMutators }}
          initialValues={initialValues}
          render={({ handleSubmit, values, form: { change } }) => (
            <form
              onSubmit={handleSubmit}
              id={FORM_ID}
            >
              <FieldArray
                name="assignments"
              >
                {({ fields, meta }) => (
                  <TaskAssignmentFieldSet
                    addedDirectly={addedDirectly}
                    providers={providers}
                    fields={fields}
                    meta={meta}
                    rates={rates}
                    defaultRateUnit={defaultRateUnit}
                    onRemoveItem={onRemoveItem}
                    values={values}
                    change={change}
                  />
                )}
              </FieldArray>
            </form>
          )}
        />
      </TDApiConnected>
    );
  }
}

TaskAssignmentForm.propTypes = {
  addedDirectly: PropTypes.bool.isRequired,
  providers: PropTypes.arrayOf(userCardSpec),
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  onAssignmentsSubmitted: PropTypes.func,
  onRemoveItem: PropTypes.func,
  activeOrg: PropTypes.object.isRequired,
};

TaskAssignmentForm.defaultProps = {
  onAssignmentsSubmitted: () => {},
  onRemoveItem: null,
  providers: [],
  rates: [],
};

const mapStateToProps = state => ({
  rates: getListState(state, TaskAssignmentForm.GetComponentName()).items,
  activeOrg: selectActiveOrg(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const TaskAssignmentFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskAssignmentForm);

export default withRouter(TaskAssignmentFormConnected);
