import { isEmpty, isEqual } from 'lodash';
import { Money } from 'td-finance-ts';

import { DEFAULT_BALANCE_CURRENCY } from 'finance/assets/js/constants';


/**
 * This class is used to calculate the ESign charges applied to an organization.
 * While the ESignFeeScheme is the record of the service settings,
 * the ESignFeeAnalysis is the actual calculated charges.
 */
class ESignFeeAnalysis {
  static getEmptyAnalysis() {
    return new ESignFeeAnalysis();
  }

  /**
   * It is used for de-serializing a ESignFeeAnalysis serialized object
   *
   * @param {Object} serialized
   *
   * @returns {ESignFeeAnalysis}
   */
  constructor(...serialized) {
    if (isEmpty(serialized) || serialized.every(s => isEmpty(s))) {
      return this.init();
    }
    return this.init(...serialized);
  }

  /**
   *
   * @param {Object} params
   * @param {String} params.currency - ESign service currency
   * @param {Number} params.basePrice - Price which will be charged for ESign usage
   * @param {Boolean} params.hasBeenEnabled - Check if ESign service was enabled
   * @returns {ESignFeeAnalysis}
   */
  init({
    currency = DEFAULT_BALANCE_CURRENCY,
    basePrice = '0.00',
    hasBeenEnabled = false,
    numESignDocs = 0,
    total,
  } = {}) {
    this.details = {
      currency,
      basePrice,
      hasBeenEnabled,
      numESignDocs,
    };

    Object.assign(this.details, {
      total: total || this.aggregate(),
    });
  }

  serialize() {
    if (this.isEmpty()) {
      return null;
    }
    return {
      ...this.details,
    };
  }

  aggregate() {
    const {
      basePrice,
      hasBeenEnabled,
      currency,
      numESignDocs,
    } = this.details;
    if (!hasBeenEnabled) {
      return '0.00';
    }
    const fee = new Money(basePrice, currency).mul(numESignDocs);
    return fee.toString();
  }

  getCurrency() {
    const { currency } = this.details;
    return currency;
  }

  getTotal() {
    return this.aggregate();
  }

  getNumDocs() {
    const { numESignDocs } = this.details;
    return numESignDocs || 0;
  }

  /**
   * Compares the analysis passed with the current one
   *
   * @param {ESignFeeAnalysis} analysisToCompareWith
   * @returns {boolean}
   */
  isEqual(analysisToCompareWith) {
    return isEqual(this, analysisToCompareWith);
  }

  /**
   * Checks if the total calculated as the analysis total is zero
   *
   * @returns {Boolean}
   */
  isZero() {
    return new Money(this.getTotal(), this.getCurrency()).isZero();
  }

  /**
   * Checks if an analysis is empty
   *
   * @returns {Boolean}
   */
  isEmpty() {
    const emptyAnalysis = ESignFeeAnalysis.getEmptyAnalysis();
    return this.isEqual(emptyAnalysis);
  }

  /**
   * Returns whether the service has been enabled
   *
   * @returns {Boolean}
   */
  isEnabled() {
    const { hasBeenEnabled } = this.details;
    return hasBeenEnabled;
  }

  /**
   * @returns {String}
   */
  getServiceTitle() {
    return `E-signature document creations (${this.getNumDocs()})`;
  }

  /**
   * @returns {String}
   */
  getServiceMetricsDescriptions() {
    return [{
      description: 'E-signature documents',
      fee: this.getTotal(),
      quantity: this.getNumDocs(),
    }];
  }
}

export default ESignFeeAnalysis;
