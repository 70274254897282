import PropTypes from 'prop-types';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import { CURRENCY, CURRENCY_LABEL } from 'core/assets/js/constants';
import { RATE_UNIT } from 'rates/assets/js/constants';

export const orgSpec = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  alias: PropTypes.string,
  address: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  country_code: PropTypes.string,
  logo: PropTypes.string,
  registration_number: PropTypes.string,
  vat: PropTypes.string,
  website: PropTypes.string,
  email: PropTypes.string,
  currency: PropTypes.string,
  invoicing_mode: PropTypes.string,
});

export const orgPublicSchema = {
  id: PropTypes.number,
  name: PropTypes.string,
  alias: PropTypes.string,
  country_code: PropTypes.string,
  logo: PropTypes.string,
  website: PropTypes.string,
  active: PropTypes.bool,
  currency: PropTypes.oneOf(Object.values(CURRENCY)),
  currency_label: PropTypes.oneOf(Object.values(CURRENCY_LABEL)),
  currency_symbol: PropTypes.oneOf(Object.values(CURRENCY_SYMBOL)),
  default_rate_unit: PropTypes.oneOf(Object.values(RATE_UNIT)),
};

export const orgPublicSpec = PropTypes.shape(orgPublicSchema);

export const billingDetailsSpec = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  address: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  country_code: PropTypes.string,
  registration_number: PropTypes.string,
  vat: PropTypes.string,
  website: PropTypes.string,
});

export const orgCompanySpec = PropTypes.shape({
  address: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  invoicable: PropTypes.bool,
  logo: PropTypes.string,
  name: PropTypes.string,
  registration_number: PropTypes.string,
  vat: PropTypes.string,
});

export const userRoleSchema = {
  isAnyManager: PropTypes.bool,
  isFinCon: PropTypes.bool,
  isHigherManager: PropTypes.bool,
  isManager: PropTypes.bool,
  isOrgCreator: PropTypes.bool,
  isProvider: PropTypes.bool,
};
export const userRoleSpec = PropTypes.shape(userRoleSchema);

export const userCardSchema = {
  completed: PropTypes.bool,
  currencySymbol: PropTypes.string,
  hasActiveTasks: PropTypes.bool,
  id: PropTypes.number,
  invitation: PropTypes.object,
  invoicing_vat_percent: PropTypes.number,
  org: orgSpec,
  pendingApproval: PropTypes.bool,
  rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rateId: PropTypes.number,
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  rateAdjustmentActions: PropTypes.object,
  role: PropTypes.object,
  status: PropTypes.number,
  statusLabel: PropTypes.string,
  user: PropTypes.object,
};

export const providerUserCardSchema = {
  ...userCardSchema,
  rating: PropTypes.number,
  numReviews: PropTypes.number,
  budget: PropTypes.number,
  submissions: PropTypes.array,
  projects: PropTypes.array,
};

export const managerUserCardSchema = { ...userCardSchema };
export const userCardSpec = PropTypes.shape(userCardSchema);
export const providerUserCardSpec = PropTypes.shape(providerUserCardSchema);
export const managerUserCardSpec = PropTypes.shape(managerUserCardSchema);

export const orgProgressSpec = PropTypes.shape({
  completed: PropTypes.bool,
  currencySymbol: PropTypes.string,
  rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.number,
  invoicing_vat_percent: PropTypes.number,
  org: orgSpec,
  pendingApproval: PropTypes.bool,
  role: PropTypes.object,
  status: PropTypes.number,
  statusLabel: PropTypes.string,
  user: PropTypes.object,
  percent: PropTypes.number,
  next: PropTypes.string,
  desc: PropTypes.string,
  curTick: PropTypes.number,
});

export const orgApiKeySpec = PropTypes.shape({
  apiKeyValue: PropTypes.string,
  friendlyName: PropTypes.string,
  id: PropTypes.number,
  issuedAt: PropTypes.string,
  issuedBy: PropTypes.number,
  locked: PropTypes.bool,
  revoked: PropTypes.bool,
  revokedAt: PropTypes.string,
  unlocksAt: PropTypes.string,
});
