import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';

import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import { ORDERING_DIRECTION } from 'core/assets/js/constants';
import {
  TASK_ASSIGNMENT_STATUS,
  TASK_ASSIGNMENT_STATUS_LABEL,
  TASK_STATUS,
  TASK_STATUS_LABEL,
} from 'projects/assets/js/constants';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';

const statusOptions = Object.values(TASK_STATUS).map(value => ({
  text: TASK_STATUS_LABEL[value], value,
}));

const assignmentStatusOptions = Object.values(TASK_ASSIGNMENT_STATUS).map(value => ({
  text: TASK_ASSIGNMENT_STATUS_LABEL[value],
  value,
}));

const DiscussionsSearch = ({ filtersOpen, location: { search }, onFiltersToggle }) => {
  const userCard = useSelector(state => (selectActiveUserCard(state)));
  const isProvider = userCard?.userRole?.isProvider;

  const taskStatusFilter = {
    label: 'Status',
    paramName: 'status',
    fieldComponent: SelectableListFilterField,
    multiple: true,
    options: statusOptions,
  };

  const assignmentStatusFilter = {
    label: 'Assignment status',
    paramName: 'assignment_status',
    fieldComponent: SelectableListFilterField,
    multiple: true,
    options: assignmentStatusOptions,
  };

  const searchSpec = {
    defaultOrdering: { direction: ORDERING_DIRECTION.DESC, sortBy: 'lastMessageAt' },
    filters: [
      isProvider ? assignmentStatusFilter : taskStatusFilter,
    ],
    orderingOptions: [
      { text: 'Message Date', value: 'lastMessageAt' },
    ],
    searchTerm: { component: TextInputField, paramName: 'kw', placeholder: 'Search by task name' },
  };

  const initialValues = pick(
    queryString.parse(search), ['kw'].concat(searchSpec.filters.map(f => f.paramName)),
  );

  return (
    <SearchFinalForm
      filtersOpen={filtersOpen}
      initialValues={initialValues}
      name="discussions"
      onFiltersToggle={onFiltersToggle}
      searchSpec={searchSpec}
    />
  );
};

DiscussionsSearch.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withRouter(DiscussionsSearch);
