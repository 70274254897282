import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import DateRangePicker from 'core/assets/js/components/DateRangePicker.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { API_DATE_FORMAT, BS_SIZE } from 'core/assets/js/constants';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import moment from 'core/assets/js/lib/tdMoment';
import { formatDate } from 'core/assets/js/lib/utils';
import WorksheetBillingPeriodSelection from 'projects/assets/js/components/WorksheetBillingPeriodSelection.jsx';

export const PERIOD_MODAL_ID = 'period-selection-modal';

const WorksheetFormBillingPeriodModal = ({
  formValues, isOutsideRange, missingPeriods, updateForm,
}) => {
  const dispatch = useDispatch();
  const isPeriodSelectModalOpen = useSelector(state => getIsModalOpen(state, PERIOD_MODAL_ID));

  const periodStart = formValues.period?.periodStart || null;
  const periodEnd = formValues.period?.periodEnd || null;

  return (
    <ModalSimple
      body={(
        <>
          <div className="d-flex w-100 align-items-center justify-content-center">
            <Field
              label=""
              name="period"
              render={({ input: { name, value } }) => {
                const startDate = value?.periodStart ? moment(value.periodStart).toDate() : null;
                const endDate = value?.periodEnd ? moment(value.periodEnd).toDate() : null;
                return (
                  <DateRangePicker
                    endDate={endDate}
                    initialVisibleDate={startDate || endDate}
                    isOutsideRange={isOutsideRange}
                    onChange={newValue => {
                      updateForm(
                        name,
                        {
                          periodStart: newValue.startDate instanceof Date
                            ? moment(newValue.startDate).format(API_DATE_FORMAT)
                            : null,
                          periodEnd: newValue.endDate instanceof Date
                            ? moment(newValue.endDate).format(API_DATE_FORMAT)
                            : null,
                        },
                      );
                    }}
                    showSecondMonth={false}
                    startDate={startDate}
                  />
                );
              }}
            />
          </div>
          {missingPeriods && (
            <WorksheetBillingPeriodSelection
              missingPeriods={missingPeriods}
              onSelect={selectedPeriod => {
                updateForm('period', pick(selectedPeriod, 'periodStart', 'periodEnd'));
              }}
              selectedPeriod={{ periodEnd, periodStart }}
            />
          )}
        </>
      )}
      data-testid="worksheet-form-billing-period-modal"
      heading={(
        <>
          Billing period
          <aside>
            <small>
              {periodStart ? formatDate(periodStart) : '...'}
              {' - '}
              {periodEnd ? formatDate(periodEnd) : '...'}
            </small>
          </aside>
        </>
      )}
      size={BS_SIZE.XSMALL}
      onClose={() => {
        if (!periodEnd) {
          // If user has selected only periodStart, then make it a single day selection.
          updateForm('period', { periodStart, periodEnd: periodStart });
        }
        dispatch(modalCloseAC(PERIOD_MODAL_ID));
      }}
      open={isPeriodSelectModalOpen}
    />
  );
};

WorksheetFormBillingPeriodModal.propTypes = {
  formValues: PropTypes.object,
  isOutsideRange: PropTypes.func,
  missingPeriods: PropTypes.arrayOf(PropTypes.object),
  updateForm: PropTypes.func.isRequired,
};

WorksheetFormBillingPeriodModal.defaultProps = {
  formValues: {},
  isOutsideRange: () => false,
  missingPeriods: null,
};

export default WorksheetFormBillingPeriodModal;
