import React from 'react';
import TagsInput from 'react-tagsinput';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import withField from 'core/assets/js/components/withField.jsx';
import AutosizeInput from 'core/assets/js/components/AutosizeInput.jsx';

// eslint-disable-next-line
export const EMAIL_VALIDATION_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class InputEmailAsText extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.parseValue = memoize(
      (value) => {
        const parsed = value ? JSON.parse(value) : [];
        return parsed;
      },
    );
  }

  handleChange(emails) {
    const { input } = this.props;
    input.onChange(JSON.stringify(emails));
  }

  render() {
    const {
      input, label, sublabel, placeholder, rest, meta: { error, submitError },
    } = this.props;
    const hasError = !!error || !!submitError;
    const emails = this.parseValue(input?.value);
    const groupClassName = ['form-group'];

    if (hasError) {
      groupClassName.push('has-error');
    }
    return (
      <div className={groupClassName.join(' ')}>
        <label htmlFor={input?.name}>
          {label}
          {sublabel && (
            <span className="sublabel">
              {' '}
              {sublabel}
            </span>
          )}
        </label>
        <TagsInput
          value={emails}
          addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
          onlyUnique
          addOnBlur
          addOnPaste
          inputProps={{ placeholder }}
          renderInput={AutosizeInput}
          validationRegex={EMAIL_VALIDATION_REGEX}
          pasteSplit={data => (
            data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
          )}
          onChange={this.handleChange}
        />
        <input
          {...input}
          placeholder={placeholder}
          type="hidden"
          className="form-control"
          {...rest}
        />
        {hasError && <span className="help-block mt-2 ml-2">{error || submitError}</span>}
      </div>
    );
  }
}

InputEmailAsText.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  sublabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  rest: PropTypes.object,
};

InputEmailAsText.defaultProps = {
  meta: {
    touched: false,
    error: false,
  },
  placeholder: '',
  rest: {},
  sublabel: '',
};

export default withField(InputEmailAsText);
