import queryString from 'query-string';
import { isEmpty } from 'lodash';

import {
  ORG_SETTINGS_FINANCE_TABS,
  ORG_SETTINGS_PAYMENTS_TABS,
  PAYMENT_METHODS,
  PROJECTS_SETTINGS_TABS,
  SETTINGS_PAYMENTS_PAYMENT_METHOD_SUB_PAGES,
  SETTINGS_PAYMENTS_SUB_PAGES,
  SETTINGS_PAYMENTS_TABS,
  SETTINGS_SKILL_TABS,
  SETTINGS_TEMPLATE_TABS,
} from 'settings/assets/js/constants';

export const settingsAccountUrl = orgAlias => `/${orgAlias}/settings/account`;
export const settingsPaymentsUrl = orgAlias => `/${orgAlias}/settings/payments`;
export const settingsPaymentsSubPageUrl = (orgAlias, subPage, { query } = {}) => {
  if (!isEmpty(query)) {
    return `${settingsPaymentsUrl(orgAlias)}/${subPage || SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS}?${queryString.stringify(query)}`;
  }
  return `${settingsPaymentsUrl(orgAlias)}/${subPage || SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS}`;
};
export const orgSettingsUrl = orgAlias => (
  `/${orgAlias}/settings/organization`
);
export const orgSettingsFinanceUrl = (orgAlias, tabName) => {
  const tab = tabName || ORG_SETTINGS_FINANCE_TABS.WORKSHEETS;
  // Using 'org/settings' to avoid conflicts with other routes (ie: provider's payment settings)
  return `/${orgAlias}/org/settings/payments/${tab}`;
};
export const notificationSettingsUrl = orgAlias => (
  `/${orgAlias}/settings/notifications`
);
export const orgSettingsFinControllerUrl = orgAlias => (
  `/${orgAlias}/settings/financial-controller`
);
export const orgSettingsPrivacyUrl = orgAlias => (
  `/${orgAlias}/settings/privacy`
);
export const orgSettingsSkillsUrl = (orgAlias, tabName) => {
  const tab = tabName || SETTINGS_SKILL_TABS.SKILLS;
  return `/${orgAlias}/settings/skills/${tab}`;
};
export const orgSettingsProjectsUrl = (orgAlias, tab = PROJECTS_SETTINGS_TABS.PROJECTS) => (
  `/${orgAlias}/settings/projects/${tab}`
);
export const orgSettingsGeneralUrl = orgAlias => `/${orgAlias}/settings/general`;

export const settingsPaymentMethodCreateSelectUrl = orgAlias => (
  settingsPaymentsSubPageUrl(orgAlias, SETTINGS_PAYMENTS_SUB_PAGES.CREATE_PAYMENT_METHOD)
);
export const settingsBankAccountCreateUrl = (orgAlias, { query } = {}) => (
  settingsPaymentsSubPageUrl(
    orgAlias,
    [
      SETTINGS_PAYMENTS_SUB_PAGES.BANK_ACCOUNT,
      SETTINGS_PAYMENTS_PAYMENT_METHOD_SUB_PAGES.CREATE,
    ].join('/'),
    { query },
  )
);
export const settingsBankAccountEditUrl = (orgAlias, bankAccountId) => (
  settingsPaymentsSubPageUrl(
    orgAlias,
    [
      SETTINGS_PAYMENTS_SUB_PAGES.BANK_ACCOUNT,
      bankAccountId,
      SETTINGS_PAYMENTS_PAYMENT_METHOD_SUB_PAGES.EDIT,
    ].join('/'),
  )
);
export const settingsPaypalCreateUrl = orgAlias => (
  settingsPaymentsSubPageUrl(
    orgAlias,
    [
      SETTINGS_PAYMENTS_SUB_PAGES.PAYPAL,
      SETTINGS_PAYMENTS_PAYMENT_METHOD_SUB_PAGES.CREATE,
    ].join('/'),
  )
);
export const settingsPaypalEditUrl = (orgAlias, accountId) => (
  settingsPaymentsSubPageUrl(
    orgAlias,
    [
      SETTINGS_PAYMENTS_SUB_PAGES.PAYPAL,
      accountId,
      SETTINGS_PAYMENTS_PAYMENT_METHOD_SUB_PAGES.EDIT,
    ].join('/'),
  )
);

export const settingsPayoneerCreateUrl = orgAlias => (
  settingsPaymentsSubPageUrl(
    orgAlias,
    [
      SETTINGS_PAYMENTS_SUB_PAGES.PAYONEER,
      SETTINGS_PAYMENTS_PAYMENT_METHOD_SUB_PAGES.CREATE,
    ].join('/'),
  )
);
export const settingsPayoneerEditUrl = (orgAlias, accountId) => (
  settingsPaymentsSubPageUrl(
    orgAlias,
    [
      SETTINGS_PAYMENTS_SUB_PAGES.PAYONEER,
      accountId,
      SETTINGS_PAYMENTS_PAYMENT_METHOD_SUB_PAGES.EDIT,
    ].join('/'),
  )
);

export const settingsPayoneerSuccessUrl = (orgAlias, bankAccountId) => (
  settingsPaymentsSubPageUrl(
    orgAlias,
    [
      SETTINGS_PAYMENTS_SUB_PAGES.PAYONEER,
      bankAccountId,
      SETTINGS_PAYMENTS_PAYMENT_METHOD_SUB_PAGES.SUCCESS,
    ].join('/'),
  )
);

export const settingsPayoneerStatusUrl = (orgAlias, bankAccountId) => (
  settingsPaymentsSubPageUrl(
    orgAlias,
    [
      SETTINGS_PAYMENTS_SUB_PAGES.PAYONEER,
      bankAccountId,
      SETTINGS_PAYMENTS_PAYMENT_METHOD_SUB_PAGES.STATUS,
    ].join('/'),
  )
);

export const settingsPayoneerCancelUrl = (orgAlias, bankAccountId) => (
  settingsPaymentsSubPageUrl(
    orgAlias,
    [
      SETTINGS_PAYMENTS_SUB_PAGES.PAYONEER,
      bankAccountId,
      SETTINGS_PAYMENTS_PAYMENT_METHOD_SUB_PAGES.CANCEL,
    ].join('/'),
  )
);

export const settingsBankAccountOrgSelectUrl = (orgAlias, paymentMethodType, bankAccountId) => (
  settingsPaymentsSubPageUrl(
    orgAlias,
    !paymentMethodType || !bankAccountId
      ? SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS
      : [
        paymentMethodType,
        bankAccountId,
        SETTINGS_PAYMENTS_PAYMENT_METHOD_SUB_PAGES.SELECT_ORGANIZATION,
      ].join('/'),
  )
);

export const paymentMethodCreateUrls = {
  [PAYMENT_METHODS.BANK_TRANSFER]: settingsBankAccountCreateUrl,
  [PAYMENT_METHODS.PAYPAL]: settingsPaypalCreateUrl,
  [PAYMENT_METHODS.PAYONEER]: settingsPayoneerCreateUrl,
};

export const orgTemplatesManageUrl = (orgAlias, tabName) => {
  const tab = tabName || SETTINGS_TEMPLATE_TABS.CUSTOM_FIELDS;
  return `/${orgAlias}/settings/templates/${tab}`;
};
export const customFieldTemplateTypeSelectUrl = orgAlias => `/${orgAlias}/settings/templates/select-template-type`;
export const customFieldTemplateCreateUrl = (orgAlias, entityType) => `/${orgAlias}/settings/templates/${entityType}/create-template`;
export const customFieldTemplateEditUrl = (orgAlias = ':orgAlias', entityType = ':entityType', templateId = ':templateId') => (
  `/${orgAlias}/settings/templates/${entityType}/${templateId}/edit`
);
export const orgInvitationTemplateCreateUrl = orgAlias => `/${orgAlias}/settings/templates/${SETTINGS_TEMPLATE_TABS.ORG_INVITATIONS}/create-template`;
export const orgInvitationTemplateEditUrl = (orgAlias = ':orgAlias', templateId = ':templateId') => (
  `/${orgAlias}/settings/templates/${SETTINGS_TEMPLATE_TABS.ORG_INVITATIONS}/${templateId}/edit`
);

export const orgSettingsPaymentsUrl = (orgAlias, tabName, url = '') => {
  const tab = tabName || ORG_SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS;
  return `${url}/${orgAlias}/org-member/settings/payments/${tab}`;
};

export const orgSettingsApiKeysManageUrl = (orgAlias) => {
  return `/${orgAlias}/settings/api-keys`;
};
export const orgSettingsTaxUrl = orgAlias => `/${orgAlias}/settings/tax`;
export const orgSettingsEmailTemplatesUrl = orgAlias => `/${orgAlias}/settings/email-templates`;
export const orgSettingsEmailTemplateUrl = (orgAlias, type) => (
  `${orgSettingsEmailTemplatesUrl(orgAlias)}/${type}`
);

// API

export const settingsPaymentMethodStatsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/payment-methods-stats`
);
export const settingsBankAccountsApiUrl = (url = '') => (
  `${url}/api/settings/bank_account`
);
export const settingsOrgBankAccountOptionsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/bank_account/options`
);
export const settingsOrgBankAccountApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/bank_account`
);
export const settingsBankAccountApiUrl = (id, url = '', { query } = {}) => {
  if (query) {
    return `${url}/api/settings/bank_account/${id}?${queryString.stringify(query)}`;
  }
  return `${url}/api/settings/bank_account/${id}`;
};

// Payoneer
export const settingsPayoneerBankAccountCancelApiUrl = (id, url = '') => (
  `${url}/api/settings/bank_account/${id}/payoneer/registration-cancel`
);

export const settingsPayoneerBankAccountSuccessApiUrl = (id, url = '') => (
  `${url}/api/settings/bank_account/${id}/payoneer/registration-success`
);


export const settingsBankAccountOrganizationsApiUrl = (id, url = '') => (
  `${url}/api/settings/bank_account/${id}/select-organization`
);

export const settingsPaymentTermsApiUrl = (url = '') => (
  `${url}/api/settings/payment_setting`
);
export const orgSettingsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/organization`
);
export const orgBillingDetailsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/billing-details`
);

export const orgBillingDetailsCreateApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/settings/billing-details`
);

export const orgBillingDetailsUpdateApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/settings/billing-details/${id}`
);

export const orgBillingDetailsSetDefaultApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/settings/billing-details/${id}/default`
);

export const orgBillingDetailsCopyApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/settings/billing-details/copy`
);

export const orgSettingsEmailApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/email`
);
export const orgSettingsFinanceEmailApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/finance-email`
);

export const orgSettingsServiceOrderNotificationsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/worksheets/notifications`
);

export const orgSettingsTaskAssigneesWorksheetNotificationsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/worksheets/task-assignees-notifications`
);

export const orgSettingsProjectsTeamMemberAdditionNotificationsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/projects/notifications/team-member-addition`
);

export const orgSettingsFinControllerApiUrl = (orgAlias, id = '', url = '') => (
  `${url}/api/${orgAlias}/settings/financial-controller${id ? `/${id}` : ''}`
);

export const userCardSettingsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/usercard`
);

export const salesTaxApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/sales-tax`
);

export const systemFinancialEntitiesApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/system-financial-entities`
);

export const orgInvitationTemplatesApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/invitation-templates`
);
export const orgInvitationTemplateFetchApiUrl = (orgAlias, templateId, url = '') => (
  `${url}/api/${orgAlias}/settings/invitation-templates/${templateId}`
);
export const orgInvitationTemplateDeleteApiUrl = (orgAlias, templateId, url = '') => (
  `${url}/api/${orgAlias}/settings/invitation-templates/${templateId}`
);

export const orgInvitationTemplateUpdateApiUrl = (orgAlias, templateId, url = '') => (
  `${url}/api/${orgAlias}/settings/invitation-templates/${templateId}`
);

export const orgSkillsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/settings/skills`
);

export const orgSkillApiUrl = (orgAlias, skillId, url = '') => (
  `${url}/api/${orgAlias}/settings/skills/${skillId}`
);

export const skillEndorseApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/profile/skills_endorse`
);

export const skillEndorseVoteApiUrl = (orgAlias, action = '', url = '') => (
  `${url}/api/${orgAlias}/profile/skills_endorse/${action}`
);
export const orgSettingsSkillDirectoriesUrl = orgAlias => (
  `/${orgAlias}/settings/skills/skill-directories`
);
export const orgSettingsSkillDirectoryManageUrl = (orgAlias, skillDirectoryId) => (
  `/${orgAlias}/settings/skill-directories/${skillDirectoryId}`
);
export const skillDirectoriesListApiUrl = (orgAlias, query = null) => (
  `/api/${orgAlias}/skill_directories${query ? `?${queryString.stringify(query)}` : ''}`
);
export const skillDirectoryOptionsApiUrl = orgAlias => (
  `/api/${orgAlias}/skill_directories/skill-directory-options`
);
export const skillDirectoriesManageApiUrl = (orgAlias, skillDirectoryId) => (
  `/api/${orgAlias}/skill_directories/${skillDirectoryId}`
);
export const skillDirectoryRemoveSkillApiUrl = (orgAlias, skillDirectoryId, skillId) => (
  `/api/${orgAlias}/skill_directories/${skillDirectoryId}/skills/${skillId}`
);
export const skillDirectorySkillsApiUrl = (orgAlias, skillDirectoryId) => (
  `/api/${orgAlias}/skill_directories/${skillDirectoryId}/skills`
);
export const getUserCardInvoiceCapUsageApiUrl = (orgAlias, userId, serviceOrderId) => [
  `/api/${orgAlias}/settings/invoice-cap-usage/${userId}`,
  serviceOrderId ? `?soid=${serviceOrderId}` : '',
].join('');
export const orgNotificationSettingsFetchApiUrl = (orgAlias, type) => (
  `/api/${orgAlias}/settings/notification-settings/${type}`
);
export const orgNotificationSettingsUpdateApiUrl = orgNotificationSettingsFetchApiUrl;
export const orgNotificationSettingsResetApiUrl = (orgAlias, type) => (
  `${orgNotificationSettingsFetchApiUrl(orgAlias, type)}/reset`
);
export const orgNotificationSettingsGetPreviewApiUrl = (orgAlias, type) => (
  `${orgNotificationSettingsFetchApiUrl(orgAlias, type)}/preview`
);
export const orgNotificationSettingsSendTestApiUrl = (orgAlias, type) => (
  `${orgNotificationSettingsFetchApiUrl(orgAlias, type)}/send-test`
);
