import { isEmpty } from 'lodash';
import { CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import { SYSTEM, CURRENCY } from 'core/assets/js/constants';
import CodatFeeAnalysis from 'finance/assets/js/lib/fees/CodatFeeAnalysis';

class CodatFeeScheme {
  static getDefaultSystemReg() {
    return SYSTEM.CODAT_FEES_REGISTRATION_NUMBER;
  }

  static getEmpty(hasSpec = true) {
    return new CodatFeeScheme({
      currency: CURRENCY.USD,
      basePrice: '0.00',
      hasSpec,
    });
  }

  static getDefault() {
    return new CodatFeeScheme({
      currency: CURRENCY.USD,
      basePrice: '99.00',
    });
  }

  static fromFormValues(values) {
    const { currency, price, systemReg } = values;
    return new CodatFeeScheme({
      currency,
      basePrice: new Money(price, currency).toString(),
      systemReg,
    });
  }
  /**
   * It is used for de-serializing a CodatFeeScheme serialized object
   *
   * @param {Object} serialized
   *
   * @returns {CodatFeeScheme}
   */

  constructor(serialized) {
    if (isEmpty(serialized)) {
      return CodatFeeScheme.getEmpty(false);
    }
    if (serialized instanceof CodatFeeScheme) {
      return new CodatFeeScheme(serialized.serialize());
    }
    this.init(serialized);
  }

  /**
   * @param {Object} params
   * @param {String} params.currency
   * @param {String} params.basePrice
   * @param {Boolean} params.hasSpec=false
   */
  init({
    currency,
    basePrice = '0.00',
    hasSpec = true,
  } = {}) {
    if (!currency) {
      throw new Error('currency is required');
    }
    if (!basePrice) {
      throw new Error('basePrice is required');
    }
    const systemReg = CodatFeeScheme.getDefaultSystemReg();
    this.hasSpec = hasSpec;
    this.details = {
      currency,
      basePrice,
      systemReg,
    };
  }

  copy() {
    return new CodatFeeScheme(this.serialize());
  }

  serialize() {
    if (this.isEmpty()) {
      return null;
    }
    return {
      ...this.details,
    };
  }

  getCurrency() {
    const { currency } = this.details;
    return currency;
  }

  getCurrencySymbol() {
    return CURRENCY_SYMBOL[this.getCurrency()];
  }

  getBasePrice() {
    const { basePrice } = this.details;
    return basePrice;
  }

  getSystemRegistrationNumber() {
    const { systemReg } = this.details;
    return systemReg;
  }

  isEmpty() {
    return !this.hasSpec;
  }

  isZero() {
    const { basePrice } = this.details;
    return new Money(basePrice, this.getCurrency()).isZero();
  }

  hasFee() {
    return !this.isZero();
  }

  /**
   * This method applies the metrics passed to the settings set by the Codat scheme
   * The result is the applied charges
   *
   * @param {Object} metrics (The metrics for the Codat service)
   * @param {Boolean} metrics.hasBeenEnabled=false - if the service has been enabled in an org level
   * @param {String} metrics.systemRegistrationNumber - The system reg number used when applying
   * Codat charges.
   *
   * @returns {CodatFeeAnalysis}
   */
  apply({
    hasBeenEnabled = false,
    systemRegistrationNumber,
  } = {}) {
    const { currency, basePrice, systemReg } = this.details;

    if (systemRegistrationNumber !== systemReg) {
      // When system numbers don't match it means that the scheme's generated charges
      // are not applied by the system entity's reg number used. So we return an empty analysis.

      return CodatFeeAnalysis.getEmptyAnalysis();
    }
    return new CodatFeeAnalysis({
      currency, basePrice, hasBeenEnabled,
    });
  }

  toFormValues() {
    return {
      price: this.getBasePrice(),
      currency: this.getCurrency(),
      systemReg: this.getSystemRegistrationNumber(),
    };
  }
}

export default CodatFeeScheme;
