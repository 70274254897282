import Big from 'big.js';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { FieldArray } from 'react-final-form-arrays';
import { isEmpty } from 'lodash';

import InvoiceCapUsageBar from 'projects/assets/js/components/InvoiceCapUsageBar.jsx';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import WorksheetItems from 'projects/assets/js/components/WorksheetItems.jsx';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, WORKSHEET_AUTO_SAVE_STATES } from 'core/assets/js/constants';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import WorksheetFormMetaFields from 'projects/assets/js/components/WorksheetFormMetaFields.jsx';
import Storage from 'core/assets/js/lib/Storage';
import { FORM_NAME } from 'projects/assets/js/components/WorksheetForm.jsx';
import WorksheetFormBillingPeriodModal from 'projects/assets/js/components/WorksheetFormBillingPeriodModal.jsx';
import WorksheetFormBillingPeriodMenu from 'projects/assets/js/components/WorksheetFormBillingPeriodMenu.jsx';
import WorksheetFormPlaceholder from 'projects/assets/js/components/WorksheetFormPlaceholder.jsx';
import WorksheetFormAutoSaver from 'projects/assets/js/components/WorksheetFormAutoSaver.jsx';
import { DEFAULT_FX_RATE_SPREAD, SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { exchangeRatesServiceSpec, exchangeRatesSpec } from 'finance/assets/js/lib/objectSpecs';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { getInvoiceCapUsage } from 'projects/assets/js/data-services/view';
import { calculateServiceOrderTotalAmount, isInDateRange } from 'projects/assets/js/lib/utils';

Big.RM = 1;
Big.DP = 2;

const WorksheetFormRenderer = (props) => {
  const [autoSaveState, setAutoSaveState] = useState(WORKSHEET_AUTO_SAVE_STATES.SAVED);
  const activeUserCard = useSelector(selectActiveUserCard);
  const saveChangesToLocalStorage = () => {
    const { profile: { userId }, worksheet, project, form: { getState } } = props;
    const { values } = getState();
    // auto save should be enabled only on worksheet creation
    if (!worksheet) {
      Storage.set(`${FORM_NAME}-${userId}-${project.id}`, values);

      // Give the illusion of saving...
      setAutoSaveState(WORKSHEET_AUTO_SAVE_STATES.SAVING);

      setTimeout(() => {
        setAutoSaveState(WORKSHEET_AUTO_SAVE_STATES.SAVED);
      }, 1000);
    }
  };

  const {
    cancelWorksheet,
    exchangeRates,
    exchangeRatesService,
    exchangeRateUpdatedAt,
    form: { change: updateForm, getState },
    handleSubmit,
    info,
    isMobileView,
    organization,
    pristine,
    profile,
    project,
    rates,
    submitErrors,
    worksheet,
  } = props;

  const { submitting, values: formValues, values: { items, period } } = getState();
  const { missingFields } = info;
  const submitBtnLabel = !worksheet ? 'Submit worksheet' : 'Update worksheet';

  const worksheetToOrganizationExchangeRates = exchangeRates && exchangeRates[formValues.currency];
  const fxRateSpread = organization.invoicing_fx_markup
    ? organization.invoicing_fx_markup / 100
    : DEFAULT_FX_RATE_SPREAD;

  let worksheetToOrganizationExchangeRate = 1;
  if (
    worksheetToOrganizationExchangeRates
    && worksheetToOrganizationExchangeRates[organization.currency]
  ) {
    worksheetToOrganizationExchangeRate = Big(
      worksheetToOrganizationExchangeRates[organization.currency],
    ).times(1 + fxRateSpread).toNumber();
  }

  const tableClass = (formValues.items && formValues.items.length > 0) ? '' : 'd-none';

  const totalAmountValue = calculateServiceOrderTotalAmount({
    defaultOrgRateUnit: organization.default_rate_unit,
    exchangeRates,
    // This is the rate agreed when the provider accepted the project invitation and is
    // associated to the provider's projectMember entry.
    defaultRateAmount: info.rate,
    defaultRateUnit: info.rateUnit,
    items,
    rates,
    serviceOrderCurrency: formValues.currency,
  });
  let totalAmountOrgValue = totalAmountValue;
  if (formValues.currency !== organization.currency) {
    totalAmountOrgValue = Big(totalAmountValue)
      .times(worksheetToOrganizationExchangeRate)
      .toFixed(2);
  }

  const getUsageData = useSelector(getInvoiceCapUsage);
  const { over100, usageAt100 } = getUsageData({
    additionalOrganizationAmount: totalAmountOrgValue, serviceOrderPeriodEnd: period?.periodEnd,
  });
  const disabledSubmit = !isEmpty(missingFields)
    || isEmpty(items)
    || submitting
    || ((usageAt100 || over100) && !organization.invoice_caps_allow_raising_beyond_cap);

  return (
    <form onSubmit={handleSubmit}>
      <InvoiceCapUsageBar
        additionalOrganizationAmount={totalAmountOrgValue}
        className="mb-5"
        serviceOrderId={worksheet?.id}
        serviceOrderPeriodEnd={period?.periodEnd}
        serviceOrderType={SERVICE_ORDER_TYPE.WORKSHEET}
        userId={activeUserCard.user?.id}
      />
      <Card className="worksheet-form">
        <Card.Body className="pt-0">
          <WorksheetFormBillingPeriodMenu
            exchangeRates={worksheetToOrganizationExchangeRates}
            formValues={formValues}
            info={info}
            isMobileView={isMobileView}
            saveChangesToLocalStorage={saveChangesToLocalStorage}
            submitting={submitting}
          />
          {['periodStart', 'periodEnd'].map(prop => (
            submitErrors?.[prop]
            && <div className="clearfix text-danger" key={prop}>{submitErrors[prop]}</div>
          ))}

          {isEmpty(items) && (
            <WorksheetFormPlaceholder
              formValues={formValues}
              info={info}
              saveChangesToLocalStorage={saveChangesToLocalStorage}
            />
          )}

          <table className={`w-100 bg-light-grey worksheet-items-table mb-3 ${tableClass}`}>
            <tbody>
              {organization && (
                <FieldArray name="items">
                  {({ fields }) => (
                    <WorksheetItems
                      defaultRateAmount={info.rate}
                      defaultRateUnit={info.rateUnit}
                      exchangeRates={exchangeRates}
                      exchangeRatesService={exchangeRatesService}
                      updateForm={updateForm}
                      fields={fields}
                      isMobileView={isMobileView}
                      items={items}
                      organization={organization}
                      project={project}
                      rates={rates}
                      worksheet={worksheet}
                      saveChangesToLocalStorage={saveChangesToLocalStorage}
                      submitErrors={submitErrors}
                      worksheetCurrency={formValues.currency}
                      worksheetToOrganizationExchangeRate={worksheetToOrganizationExchangeRate}
                      worksheetToOrganizationExchangeRateUpdatedAt={exchangeRateUpdatedAt}
                    />
                  )}
                </FieldArray>
              )}

              {!isEmpty(items) && (
                <WorksheetFormMetaFields
                  exchangeRatesService={exchangeRatesService}
                  isMobileView={isMobileView}
                  organization={organization}
                  project={project}
                  totalAmountOrgValue={totalAmountOrgValue}
                  totalAmountValue={totalAmountValue}
                  worksheetCurrency={formValues.currency}
                  worksheetToOrganizationExchangeRate={worksheetToOrganizationExchangeRate}
                  worksheetToOrganizationExchangeRateUpdatedAt={exchangeRateUpdatedAt}
                />
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>

      <div className="mt-4 button-container d-flex flex-wrap align-items-center">
        {!worksheet && (
          <WorksheetFormAutoSaver
            autoSaveState={autoSaveState}
            worksheet={worksheet}
            pristine={pristine}
            saveChangesToLocalStorage={saveChangesToLocalStorage}
            profile={profile}
            formValues={formValues}
            project={project}
          />
        )}

        <div className="ml-auto">
          <TDButton
            type="button"
            className="btn btn-lg btn-default"
            disabled={submitting}
            label="Cancel"
            onClick={cancelWorksheet}
          />

          <TDButton
            type="submit"
            variant={BS_STYLE.PRIMARY}
            disabled={disabledSubmit}
            label={submitBtnLabel}
          />
        </div>
      </div>

      <WorksheetFormBillingPeriodModal
        formValues={formValues}
        isOutsideRange={date => (
          !isInDateRange({
            date,
            startDate: info.allowedBillingPeriodStart,
            endDate: info.allowedBillingPeriodEnd,
          })
        )}
        missingPeriods={info.missingPeriods}
        updateForm={updateForm}
      />
    </form>
  );
};

WorksheetFormRenderer.propTypes = {
  exchangeRates: exchangeRatesSpec.isRequired,
  exchangeRatesService: exchangeRatesServiceSpec.isRequired,
  exchangeRateUpdatedAt: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  project: projectSpec.isRequired,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
  info: PropTypes.object,
  organization: orgSpec,
  worksheet: PropTypes.object,
  profile: PropTypes.object.isRequired,
  isMobileView: PropTypes.bool.isRequired,
  cancelWorksheet: PropTypes.func.isRequired,
  // react-final-form fields
  pristine: PropTypes.bool.isRequired,
  submitErrors: PropTypes.object,
  form: PropTypes.object,
  getState: PropTypes.func.isRequired,
};

WorksheetFormRenderer.defaultProps = {
  info: null,
  organization: null,
  rates: [],
  worksheet: null,
  submitErrors: {},
  form: {},
};

export default WorksheetFormRenderer;
