import Big from 'big.js';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import {
  billableAnalyticsSpec,
} from 'core/assets/js/lib/objectSpecs';
import { BS_SIZE } from 'core/assets/js/constants';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { WORKSHEET_STATUS, SERVICE_ORDER_STATUS_LABEL } from 'projects/assets/js/constants';

Big.RM = 1;
Big.DP = 2;

const WORKSHEET_BILLABLE_BREAKDOWN = 'worksheet-billable-breakdown';

const getBreakDownByStatus = (breakdowns, currency) => {
  const statuses = [
    WORKSHEET_STATUS.APPROVED,
    WORKSHEET_STATUS.RAISED,
    WORKSHEET_STATUS.NOT_ENOUGH_FUNDS,
  ];
  const byStatus = [];
  let totalAmount = 0;
  let totalBillableCount = 0;
  let totalProviders = 0;
  // eslint-disable-next-line array-callback-return
  statuses.map((status) => {
    const {
      amount,
      providerCount,
      billableCount,
    } = breakdowns.find(i => i.status === status)
    || { billableCount: 0, providerCount: 0, amount: 0 };

    totalAmount = new Money(totalAmount, currency).add(amount).toString();
    totalBillableCount = Big(totalBillableCount).plus(billableCount).toNumber();
    totalProviders = Big(totalProviders).plus(providerCount).toNumber();
    byStatus.push({
      providerCount,
      billableCount,
      sum: amount,
      status,
    });
  });
  return { byStatus, totalAmount, totalBillableCount, totalProviders };
};

const BillableAnalyticsBreakDown = ({ billableAnalytics }) => {
  return Object.keys(billableAnalytics.breakdownByCurrency).map(currency => {
    const currencySymbol = CURRENCY_SYMBOL[currency];
    const {
      totalAmount, totalBillableCount, totalProviders, byStatus,
    } = getBreakDownByStatus(
      billableAnalytics.breakdownByCurrency[currency], currency, WORKSHEET_STATUS.APPROVED,
    );
    return (
      <div className="react-bs-table react-bs-table-bordered mb-4" key={currency}>
        <table className="table table table-bordered">
          <thead>
            <tr>
              <th>STATUS</th>
              <th># PROVIDERS</th>
              <th># WORKSHEETS</th>
              <th>AMOUNT</th>
            </tr>
          </thead>

          <tbody>
            {byStatus.map(({ billableCount, providerCount, status, sum }) => (
              <tr key={status}>
                <td>{SERVICE_ORDER_STATUS_LABEL[status]}</td>
                <td>{providerCount || 0}</td>
                <td>{billableCount || 0}</td>
                <td className="text-right">
                  <NumberFormat
                    className="font-weight-bolder"
                    prefix={currencySymbol}
                    displayType="text"
                    value={sum || 0}
                    thousandSeparator
                  />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td><strong>Totals:</strong></td>
              <td>{totalProviders}</td>
              <td>{totalBillableCount}</td>
              <td className="text-right">
                <NumberFormat
                  className="font-weight-bolder"
                  prefix={currencySymbol}
                  displayType="text"
                  value={totalAmount}
                  thousandSeparator
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  });
};

BillableAnalyticsBreakDown.propTypes = {
  billableAnalytics: billableAnalyticsSpec,
};

BillableAnalyticsBreakDown.defaultProps = {
  billableAnalytics: null,
};

class BillableAnalyticsBreakDownModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentWillUnmount() {
    this.handleCloseModal();
  }

  handleCloseModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  render() {
    const {
      isBillableModalOpen, billableModalPayload: { billableAnalytics, currency },
    } = this.props;
    const { dateFilter } = billableAnalytics;

    return (
      <ModalSimple
        data-testid="billable-analytics-break-down-modal"
        noFooter
        onClose={this.handleCloseModal}
        body={(
          <BillableAnalyticsBreakDown
            billableAnalytics={billableAnalytics}
            currency={currency}
          />
        )}
        heading={(
          <React.Fragment>
            Amount break down
            <span className="small d-block">{dateFilter && `${dateFilter.min} - ${dateFilter.max}`}</span>
          </React.Fragment>
        )}
        open={isBillableModalOpen}
        size={BS_SIZE.LARGE}
      />
    );
  }
}

BillableAnalyticsBreakDownModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  billableModalPayload: PropTypes.object,
  isBillableModalOpen: PropTypes.bool,
};

BillableAnalyticsBreakDownModal.defaultProps = {
  isBillableModalOpen: false,
  billableModalPayload: null,
};

const mapStateToProps = state => ({
  billableModalPayload: getModalPayload(state, WORKSHEET_BILLABLE_BREAKDOWN),
  isBillableModalOpen: getIsModalOpen(state, WORKSHEET_BILLABLE_BREAKDOWN),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const BillableAnalyticsBreakDownModalConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillableAnalyticsBreakDownModal);

export default BillableAnalyticsBreakDownModalConnect;
