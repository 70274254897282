import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';

import {
  ADD_TO_PROJECT_TYPE, INVITATION_EXPIRATION_DAYS_OPTIONS, INVITATION_NEVER_EXPIRE_VALUE,
} from 'invitations/assets/js/constants';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import TDFinalFormOnChange from 'core/assets/js/components/TDFinalFormOnChange.jsx';

export const FORM_ID = 'project-add-members-form';

const expirationDaysOptions = [
  { value: INVITATION_NEVER_EXPIRE_VALUE, text: 'Never expire' },
  ...INVITATION_EXPIRATION_DAYS_OPTIONS,
];

const ProjectAddMembersForm = ({ initialValues, onAddToProjectTypeChange, onSubmit }) => {
  useEffect(() => {
    onAddToProjectTypeChange(initialValues.add_to_project_type);
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ form: { getState }, handleSubmit, submitError }) => {
        const { values } = getState();
        return (
          <form data-testid="project-add-members-form" id={FORM_ID} onSubmit={handleSubmit}>
            {submitError && (
              <div className="form-group has-error">
                <span className="help-block">{submitError}</span>
              </div>
            )}
            <RadioField
              name="add_to_project_type"
              options={[
                {
                  sublabel: (
                    'The team members will be added to the project once they accept the invitation'
                  ),
                  value: ADD_TO_PROJECT_TYPE.INVITATION,
                  text: 'Send an invitation to the team members',
                },
                {
                  sublabel: 'The team members will immediately be added to the project',
                  value: ADD_TO_PROJECT_TYPE.ADD,
                  text: 'Add the team members',
                },
              ]}
            />
            <TDFinalFormOnChange name="add_to_project_type">
              {onAddToProjectTypeChange}
            </TDFinalFormOnChange>
            {values.add_to_project_type === ADD_TO_PROJECT_TYPE.INVITATION && (
              <SelectField
                defaultOptionText="Select"
                inputClassName="col-12 col-md-6"
                label="Invitation expires in"
                name="expiration_days"
                optionsMapping={expirationDaysOptions}
                sublabel={
                  [
                    'The invitation will expire in the designated number of days. You can always',
                    'resend the invitation to renew it.',
                  ].join(' ')
                }
              />
            )}
          </form>
        );
      }}
    />
  );
};

ProjectAddMembersForm.propTypes = {
  history: routerHistorySpec.isRequired,
  initialValues: PropTypes.object.isRequired,
  onAddToProjectTypeChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  orgAlias: PropTypes.string,
  projectId: PropTypes.number,
};

ProjectAddMembersForm.defaultProps = {
  orgAlias: '',
  projectId: null,
};

export default withRouter(ProjectAddMembersForm);
