import { isArray, isEmpty, isNil, isNaN } from 'lodash';
import { CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import Logger from 'core/assets/js/lib/Logger';
import FeeScale from 'finance/assets/js/lib/FeeScale';
import ProcessingFeeAnalysis from 'finance/assets/js/lib/ProcessingFeeAnalysis';
import { PROCESSING_FEES_MODE } from 'finance/assets/js/constants';


const logger = new Logger('invoicing:fees');

const parseAmount = (value, currency, defaultValue = '0.00') => (
  (isNil(value) || isNaN(value)) ? defaultValue : new Money(value, currency).toString()
);

class ProcessingFeeScheme {
  static parseScheme(spec) {
    if (!spec) {
      throw new Error('cannot parse empty spec');
    }
    const { currency, scale, floor, ceiling, perApprovedWorksheet } = spec;
    const parsed = {
      currency,
      scale: new FeeScale(scale),
      floor: parseAmount(floor, currency, '0.00'),
      ceiling: parseAmount(ceiling, currency, null),
      perApprovedWorksheet: parseAmount(perApprovedWorksheet, currency, '0.00'),
    };

    if (parsed.floor
      && parsed.ceiling
      && new Money(parsed.floor, currency).cmp(parsed.ceiling) === 1) {
      throw new Error('floor cannot be larger than ceiling');
    }

    return parsed;
  }

  static _aggregateParts({
    currency,
    scale: scaleAnalysis,
    perApprovedWorksheet: perApprovedWorksheetAnalysis,
  }) {
    let fee = new Money(0, currency);
    if (!isEmpty(scaleAnalysis)) {
      scaleAnalysis.forEach(({ fee: partialFee }) => {
        fee = fee.add(new Money(partialFee, currency));
      });
    }
    perApprovedWorksheetAnalysis.forEach((category) => {
      fee = fee.add(new Money(category.fee, currency));
    });
    return fee.toString();
  }

  static _aggregateBillable({
    scale: scaleAnalysis,
  }, currency) {
    if (isEmpty(scaleAnalysis)) {
      return { fee: '0.00', billable: '0.00' };
    }
    let billable = new Money(0, currency);
    let fee = new Money(0, currency);
    scaleAnalysis.forEach(({ fee: partialFee, billableAmount: partialBillable }) => {
      fee = fee.add(new Money(partialFee, currency));
      billable = billable.add(new Money(partialBillable, currency));
    });
    return { fee: fee.toString(), billable: billable.toString() };
  }

  static _aggregateApprovedWorksheets({
    perApprovedWorksheet: preApprovedWorksheetAnalysis,
  }, currency) {
    if (isEmpty(preApprovedWorksheetAnalysis)) {
      return { fee: 0, numApprovedWorksheets: 0 };
    }
    let fee = new Money(0, currency);
    let numApprovedWorksheets = 0;
    preApprovedWorksheetAnalysis.forEach(({
      fee: partialFee, numApprovedWorksheets: partialNumApprovedWorksheets,
    }) => {
      fee = fee.add(new Money(partialFee, currency));
      numApprovedWorksheets += partialNumApprovedWorksheets;
    });
    return { fee: fee.toString(), numApprovedWorksheets };
  }

  static aggregatePrepaidHistory({ currency, prepaidHistory }) {
    const {
      prepaidFee, preBilledAmount, preBilledWorksheets,
    } = prepaidHistory.reduce((acc, curr) => {
      const { fee: scaleFee, billable } = ProcessingFeeScheme
        ._aggregateBillable(curr.analysis, currency);
      const {
        fee: worksheetFee, numApprovedWorksheets,
      } = ProcessingFeeScheme._aggregateApprovedWorksheets(curr.analysis, currency);

      Object.assign(acc, {
        prepaidFee: new Money(acc.prepaidFee, currency).add(scaleFee).add(worksheetFee).toString(),
        preBilledAmount: new Money(acc.preBilledAmount, currency).add(billable).toString(2),
        preBilledWorksheets: acc.preBilledWorksheets + numApprovedWorksheets,
      });
      return acc;
    }, {
      prepaidFee: '0.00',
      preBilledAmount: '0.00',
      preBilledWorksheets: 0,
    });

    return { preBilledAmount, prepaidFee, preBilledWorksheets };
  }

  constructor(spec) {
    if (!spec) {
      throw new Error('processing fee scheme is empty');
    }
    if (spec instanceof ProcessingFeeScheme) {
      throw new Error('spec is already an instance of ProcessingFeeScheme');
    }
    const {
      currency, scale, floor, ceiling, perApprovedWorksheet,
    } = ProcessingFeeScheme.parseScheme(spec);

    this.scale = scale;
    this.spec = {
      scale: this.scale.serialize(),
      floor,
      perApprovedWorksheet,
      ceiling,
      currency,
    };
  }

  serialize() {
    return this.spec;
  }

  setCurrency(currency) {
    this.spec.currency = currency;
  }

  getCurrency() {
    return this.spec.currency;
  }

  getCurrencySymbol() {
    return CURRENCY_SYMBOL[this.getCurrency()];
  }

  isEmpty() {
    const { spec } = this;
    return !spec || isEmpty(spec);
  }

  isZero() {
    return this.scale.isZero() && !this.hasFloor();
  }

  getBasePercent() {
    return this.scale.getBasePercent();
  }

  getFloor() {
    return this.spec.floor;
  }

  getCeiling() {
    return this.spec.ceiling;
  }

  getPerApprovedWorksheet() {
    return this.spec.perApprovedWorksheet;
  }

  getScale() {
    const { scale } = this;
    return scale;
  }

  getScaleSteps() {
    const { scale } = this;
    return scale.getSteps();
  }

  apply(worksheetAmount, numApprovedWorksheets, {
    preBilledAmount = 0, prepaidFee = 0, considerFloor = true,
  } = {}) {
    if (isNil(numApprovedWorksheets)) {
      throw new Error('Cannot calculate fee without number of worksheets');
    }
    const { perApprovedWorksheet, currency } = this.spec;
    const { scale } = this;
    const applied = scale.apply(worksheetAmount, { preBilledAmount });
    const parts = {
      currency,
      scale: applied,
      perApprovedWorksheet: [{
        numApprovedWorksheets,
        perApprovedWorksheet,
        fee: new Money(perApprovedWorksheet, currency).mul(numApprovedWorksheets).toString(),
      }],
    };
    const estimatedFee = ProcessingFeeScheme._aggregateParts(parts);
    const { total, floorRemainder, usedFloor } = this._applyBounds({
      estimatedFee, prepaidFee, considerFloor,
    });
    const res = {
      ...parts,
      preBilledAmount,
      prepaidFee,
      estimatedFee,
      floorRemainder,
      usedFloor,
      total,
    };
    return res;
  }

  _applyBounds({ estimatedFee, prepaidFee, considerFloor }) {
    const { ceiling, floor, currency } = this.spec;
    // how much is left over in order to reach the floor
    const floorRemainder = new Money(floor, currency).cmp(prepaidFee) === 1
      ? new Money(floor, currency).sub(prepaidFee).toString() : '0.00';
    const monthlyRequestedFee = new Money(prepaidFee, currency).add(estimatedFee);

    if (considerFloor && monthlyRequestedFee.cmp(floor) === -1) {
      // requested fee is less than floor, ask for remainder to floor instead

      if (!new Money(prepaidFee, currency).isZero()) {
        logger.info(`prepaid (${prepaidFee}) plus current (${estimatedFee}) fee is less than floor (${monthlyRequestedFee} < ${floor}), increasing current fee to cover remainder (${floorRemainder})`);
      } else {
        logger.info(`requested fee (${monthlyRequestedFee}) is less than floor (${floor}), increasing current fee to cover remainder (${floorRemainder})`);
      }
      return { usedFloor: true, floorRemainder, total: floorRemainder };
    }

    if (ceiling && new Money(estimatedFee, currency).cmp(ceiling) === 1) {
      // fee is larger than ceiling
      return { usedFloor: false, floorRemainder, total: new Money(ceiling, currency).toString() };
    }
    return {
      usedFloor: false,
      floorRemainder,
      total: new Money(estimatedFee, currency).toString(),
    };
  }

  /**
   *
   * @param {String} worksheetAmount
   * @param {Number} numApprovedWorksheets
   * @returns {string}
   */
  getFee(...args) {
    const { total } = this.apply(...args);
    return total;
  }

  hasFloor() {
    const { floor, currency } = this.spec;
    return !new Money(floor, currency).isZero();
  }

  hasScale() {
    return (this.hasFloor() || this.hasCeiling() || !this.scale.isZero());
  }

  hasCeiling() {
    const { ceiling } = this.spec;
    return !isNil(ceiling);
  }

  hasPerApprovedWorksheet() {
    const { perApprovedWorksheet, currency } = this.spec;
    return !new Money(perApprovedWorksheet, currency).isZero();
  }

  hasFee() {
    return this.hasFloor()
    || this.hasPerApprovedWorksheet()
    || !this.isZero();
  }

  isFixed() {
    return this.hasFloor() && this.scale.isZero();
  }

  isScaled() {
    return !this.isFixed() && !this.isZero();
  }

  getMode() {
    if (this.hasPerApprovedWorksheet()) {
      return PROCESSING_FEES_MODE.PER_APPROVED_WORKSHEET;
    }

    return PROCESSING_FEES_MODE.SCALE;
  }

  applyToInvoice({
    currency,
    currentBillableAmount,
    currentApprovedWorksheets,
    preBilledAmount,
    prepaidFee,
    considerFloor,
  }) {
    if (this.getCurrency() !== currency) {
      throw new Error(`trying to apply processing fee in different fee currency (${this.getCurrency()}) than the invoice (${currency})`);
    }
    if (isNil(currentBillableAmount)) {
      throw new Error('currentBillableAmount is required');
    }
    if (isNil(currency)) {
      throw new Error('currency is required');
    }
    if (isNil(currentApprovedWorksheets)) {
      throw new Error('currentApprovedWorksheets is required');
    }
    if (isNil(considerFloor)) {
      throw new Error('considerFloor is required');
    }

    const processingFeeAnalysis = this.apply(
      currentBillableAmount, currentApprovedWorksheets, {
        preBilledAmount,
        prepaidFee,
        considerFloor,
      },
    );

    return new ProcessingFeeAnalysis(processingFeeAnalysis);
  }

  applyToHistory({
    currency,
    prepaidHistory,
    monthlyBillableAmount,
    monthlyApprovedWorksheets,
    considerFloor,
  }) {
    if (isNil(prepaidHistory) || !isArray(prepaidHistory)) {
      throw new Error('prepaidHistory is required');
    }

    if (isNil(monthlyBillableAmount)) {
      throw new Error('monthlyBillableAmount is required');
    }
    if (isNil(currency)) {
      throw new Error('currency is required');
    }
    if (isNil(monthlyApprovedWorksheets)) {
      throw new Error('monthlyApprovedWorksheets is required');
    }
    if (isNil(considerFloor)) {
      throw new Error('considerFloor is required');
    }

    const {
      preBilledAmount, prepaidFee, preBilledWorksheets,
    } = ProcessingFeeScheme.aggregatePrepaidHistory({ currency, prepaidHistory });

    const currentBillableAmount = new Money(monthlyBillableAmount, currency)
      .sub(preBilledAmount)
      .toString();
    const currentApprovedWorksheets = monthlyApprovedWorksheets - preBilledWorksheets;

    return this.applyToInvoice({
      currency,
      currentBillableAmount,
      currentApprovedWorksheets,
      preBilledAmount,
      prepaidFee,
      considerFloor,
    });
  }
}

export default ProcessingFeeScheme;
