import Big from 'big.js';
import arrayMutators from 'final-form-arrays';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, withRouter } from 'react-router-dom';
import { CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import { isSSR } from 'core/assets/js/config/checks';
import {
  BOOTSTRAP_BREAKPOINTS, BS_STYLE, ICON, MIME_TYPES,
} from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import moment from 'core/assets/js/lib/tdMoment';
import { uploaderProFormaInvoicesPath } from 'core/urls';
import ProFormaInvoiceFormSkeleton from 'finance/assets/js/components/skeletons/ProFormaInvoiceFormSkeleton.jsx';
import {
  exchangeRatesServiceSpec, exchangeRatesSpec, proFormaInvoiceSpec,
} from 'finance/assets/js/lib/objectSpecs';
import {
  financeGetInvoiceByNumberApiUrl, financeNextProFormaInvoicinfoApiUrl, financeProFormaInvoicesUrl,
} from 'finance/urls';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { DEFAULT_FX_RATE_SPREAD, SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { calculateServiceOrderTotalAmount } from 'projects/assets/js/lib/utils';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import { SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import { settingsPaymentsSubPageUrl } from 'settings/urls';
import { WINDOW_ADD_EVENT_LISTENER, WINDOW_INNER_WIDTH, WINDOW_REMOVE_EVENT_LISTENER } from 'core/assets/js/config/settings';

import ButtonsContainer from 'core/assets/js/components/ButtonsContainer.jsx';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import ExchangeRateInfoPopOver from 'core/assets/js/components/ExchangeRateInfoPopOver.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import WorksheetFormBillingPeriodMenu from 'projects/assets/js/components/WorksheetFormBillingPeriodMenu.jsx';
import WorksheetFormBillingPeriodModal from 'projects/assets/js/components/WorksheetFormBillingPeriodModal.jsx';
import WorksheetItems from 'projects/assets/js/components/WorksheetItems.jsx';
import InvoiceCapUsageBar from 'projects/assets/js/components/InvoiceCapUsageBar.jsx';
import { getInvoiceCapUsage } from 'projects/assets/js/data-services/view';

Big.RM = 1;
Big.DP = 2;

const ProFormaInvoiceForm = ({
  backUrl,
  exchangeRates,
  exchangeRatesService,
  exchangeRateUpdatedAt,
  history,
  initialValues,
  isManagerCreatingForProvider,
  missingPrerequisites,
  onAddItem,
  onSubmit,
  organization,
  proFormaInvoice,
  rates,
}) => {
  const [invoiceReferenceNumberIsDuplicate, setInvoiceReferenceNumberIsDuplicate] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const activeUserCard = useSelector(selectActiveUserCard);
  const activeOrg = useSelector(selectActiveOrg);

  const checkWindowDimensions = () => {
    setIsMobileView(WINDOW_INNER_WIDTH < BOOTSTRAP_BREAKPOINTS.MEDIUM);
  };

  const { hasLoaded, item } = fetchDataHook({
    componentName: ProFormaInvoiceForm.GetComponentName(),
    url: financeNextProFormaInvoicinfoApiUrl(activeOrg.alias),
  });

  useEffect(() => {
    checkWindowDimensions();

    if (!isSSR) {
      WINDOW_ADD_EVENT_LISTENER('resize', checkWindowDimensions);

      return () => WINDOW_REMOVE_EVENT_LISTENER('resize', checkWindowDimensions);
    }

    return null;
  }, []);

  const onCancel = () => {
    history.push({
      pathname: backUrl || financeProFormaInvoicesUrl(organization.alias),
      state: { isCancel: true },
    });
  };

  const debouncedInvoiceReferenceNumberOnChange = debounce(
    async value => {
      try {
        await axios.get(financeGetInvoiceByNumberApiUrl(organization.alias, value));
        setInvoiceReferenceNumberIsDuplicate(true);
      } catch (error) {
        if (error.response?.status !== 404) {
          toastr.error('Oh Snap!', error.response?.data?._error || error.message);
        }
      }
    },
    1000);

  const getUsageData = useSelector(getInvoiceCapUsage);

  const allowedPeriodStart = item?.allowedPeriodStart ? moment(item.allowedPeriodStart) : moment();
  const endOfToday = moment().endOf('day');

  if (!hasLoaded) {
    return <ProFormaInvoiceFormSkeleton />;
  }

  return (
    <Form
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      render={({ form: { change, getState }, handleSubmit, submitErrors }) => {
        const { submitting, values } = getState();
        const invoiceToOrganizationExchangeRates = exchangeRates && exchangeRates[values.currency];

        const fxRateSpread = organization.invoicing_fx_markup
          ? organization.invoicing_fx_markup / 100
          : DEFAULT_FX_RATE_SPREAD;
        let invoiceToOrganizationExchangeRate = 1;
        if (invoiceToOrganizationExchangeRates?.[organization.currency]) {
          invoiceToOrganizationExchangeRate = Big(
            invoiceToOrganizationExchangeRates[organization.currency],
          ).times(1 + fxRateSpread).toNumber();
        }

        const total = calculateServiceOrderTotalAmount({
          defaultOrgRateUnit: organization.default_rate_unit,
          exchangeRates,
          items: values.items,
          rates,
          serviceOrderCurrency: values.currency,
        });
        let totalOrgValue = total;
        if (values.currency !== organization.currency) {
          totalOrgValue = Big(total)
            .times(invoiceToOrganizationExchangeRate)
            .toFixed(2);
        }

        const { over100, usageAt100 } = getUsageData({
          additionalOrganizationAmount: totalOrgValue,
          serviceOrderPeriodEnd: values.period?.periodEnd,
        });

        const disableSubmit = submitting
          || (missingPrerequisites && missingPrerequisites.length > 0)
          || ((usageAt100 || over100) && !organization.invoice_caps_allow_raising_beyond_cap);

        const shouldDisplayTotalExchangeRateInfo = (
          total > 0
          && values.currency !== organization.currency
          && invoiceToOrganizationExchangeRate
        );

        const invoiceReferenceNumberEnabled = (
          Array.isArray(values.invoiceReferenceNumberEnabled)
          && values.invoiceReferenceNumberEnabled.length === 1
          && values.invoiceReferenceNumberEnabled[0].value === true
        );

        const invoicingSettingsUrl = settingsPaymentsSubPageUrl(
          organization.alias, SETTINGS_PAYMENTS_TABS.PAYMENT_TERMS,
        );

        const moneyCurrency = values.currency;

        return (
          <form data-testid="pro-forma-invoice-form" onSubmit={handleSubmit}>
            <InvoiceCapUsageBar
              additionalOrganizationAmount={totalOrgValue}
              className="mb-5"
              serviceOrderId={proFormaInvoice?.id}
              serviceOrderPeriodEnd={values.period?.periodEnd}
              serviceOrderType={SERVICE_ORDER_TYPE.PROFORMA_INVOICE}
              userId={activeUserCard.user?.id}
            />
            <Card className="pro-forma-invoice-form">
              <Card.Body className="pt-0">
                <WorksheetFormBillingPeriodMenu
                  exchangeRates={invoiceToOrganizationExchangeRates}
                  formValues={values}
                  isMobileView={isMobileView}
                  showAddButton={false}
                  submitting={submitting}
                />
                {['periodStart', 'periodEnd'].map(prop => (
                  submitErrors?.[prop]
                  && <div className="clearfix text-danger" key={prop}>{submitErrors[prop]}</div>
                ))}
                <TDSystemMessage
                  title={(
                    isManagerCreatingForProvider
                      ? 'What is this invoice for?'
                      : 'Creating Proforma Invoices'
                  )}
                >
                  {isManagerCreatingForProvider && (
                    'Create an invoice for a provider to pay a bonus'
                  )}
                  {!isManagerCreatingForProvider && (
                    <>
                      Proforma Invoices are a preliminary invoice that allows you to formally
                      request a payment. It might be subject to approval.
                    </>
                  )}
                </TDSystemMessage>
                <table className="worksheet-items-table bg-light-grey mt-5 w-100">
                  <thead>
                    <tr><th className="border-bottom-0 pb-0">Invoice number</th></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="hint pb-0">
                        {'This invoice number is derived from your '}
                        <Link to={invoicingSettingsUrl}>invoicing settings</Link>
                        . Or you can set another custom number for this Proforma Invoice.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CheckboxField
                          name="invoiceReferenceNumberEnabled"
                          options={[{ text: 'Add a custom invoice number', value: true }]}
                        />
                      </td>
                    </tr>
                    {invoiceReferenceNumberEnabled && (
                      <>
                        <tr>
                          <td className="pt-0">
                            <TextInputField
                              name="invoiceReferenceNumber"
                              parse={value => {
                                setInvoiceReferenceNumberIsDuplicate(false);
                                debouncedInvoiceReferenceNumberOnChange(value);
                                return value;
                              }}
                            />
                          </td>
                        </tr>
                        {invoiceReferenceNumberIsDuplicate && (
                          <tr>
                            <td className="pt-0">
                              <TDSystemMessage
                                title="Duplicate invoice number"
                                type={BS_STYLE.WARNING}
                              >
                                <p>
                                  You have entered a custom invoice number which has already been
                                  used.
                                </p>
                              </TDSystemMessage>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
                <table className="worksheet-items-table bg-light-grey mt-5 w-100">
                  <tbody>
                    <FieldArray name="items">
                      {({ fields }) => (
                        <>
                          <WorksheetItems
                            exchangeRates={exchangeRates}
                            exchangeRatesService={exchangeRatesService}
                            fields={fields}
                            isMobileView={isMobileView}
                            items={values.items}
                            organization={organization}
                            rates={rates}
                            submitErrors={submitErrors}
                            updateForm={change}
                            worksheet={proFormaInvoice}
                            worksheetCurrency={values.currency}
                            worksheetToOrganizationExchangeRate={invoiceToOrganizationExchangeRate}
                            worksheetToOrganizationExchangeRateUpdatedAt={exchangeRateUpdatedAt}
                          />
                          <tr>
                            <td colSpan={8}>
                              <span
                                className="imitate-link"
                                data-testid="pro-forma-invoice-form-add-item"
                                onClick={() => { onAddItem(fields); }}
                              >
                                <i className={`${ICON.ADD_CIRCLE} mr-2`} />
                                Add a line item
                              </span>
                            </td>
                          </tr>
                        </>
                      )}
                    </FieldArray>
                  </tbody>
                </table>
                {typeof submitErrors?.items === 'string' && (
                  // submitErrors.items is a string if no items are supplied and an array if one is
                  // but with an issue (e.g. no quantity)
                  <div className="text-danger p-4">{submitErrors.items}</div>
                )}
                <table className="worksheet-items-table bg-light-grey mt-5 w-100">
                  <thead>
                    <tr>
                      <th className="border-bottom-0">Total</th>
                      <th className="border-bottom-0 text-right" data-testid="items-total">
                        <NumberTpl
                          prefix={CURRENCY_SYMBOL[values.currency]}
                          value={total}
                        />
                        {shouldDisplayTotalExchangeRateInfo && (
                          <ExchangeRateInfoPopOver
                            className="ml-2"
                            exchangeRate={invoiceToOrganizationExchangeRate}
                            exchangeRateService={exchangeRatesService}
                            exchangeRateDate={exchangeRateUpdatedAt}
                            sourceCurrency={values.currency}
                            targetAmount={
                              new Money(total, moneyCurrency)
                                .mul(invoiceToOrganizationExchangeRate)
                                .toString()
                            }
                            targetCurrency={organization.currency}
                          />
                        )}
                      </th>
                    </tr>
                  </thead>
                </table>
                <table className="worksheet-items-table bg-light-grey mt-5 w-100">
                  <thead>
                    <tr>
                      <th className="border-bottom-0 pb-0">Summary</th>
                      <th className="border-bottom-0 pb-0" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <TextAreaField
                          label=""
                          name="summary"
                          placeholder="Add a summary of your work"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="worksheet-items-table bg-light-grey mt-5 w-100">
                  <thead>
                    <tr><th className="border-bottom-0 pb-0">Custom invoice</th></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="hint pb-0">
                        You can optionally upload your own invoice in pdf format. Please make sure
                        that the invoice number is the same as the one used in the proforma
                        invoice.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FileUploaderField
                          acceptFiles={['image/*', ...MIME_TYPES.DOCUMENTS]}
                          maxFiles={1}
                          name="attachments"
                          path={uploaderProFormaInvoicesPath(organization.id)}
                          type="text"
                          uploaderWrapperClassName="fileuploader--white"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
            <ButtonsContainer
              className="mt-5 text-right"
              primaryButtons={[
                <TDButton
                  disabled={disableSubmit}
                  key="submit"
                  label={`${proFormaInvoice ? 'Update' : 'Create'} Proforma Invoice`}
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                />,
              ]}
              secondaryButtons={[
                <TDButton
                  disabled={submitting}
                  key="cancel"
                  label="Cancel"
                  onClick={onCancel}
                />,
              ]}
            />
            <WorksheetFormBillingPeriodModal
              formValues={values}
              isOutsideRange={date => {
                const momentDate = moment(date);
                return (
                  momentDate.isBefore(allowedPeriodStart)
                  || (
                    !organization.allow_draft_invoices_in_future && momentDate.isAfter(endOfToday)
                  )
                );
              }}
              updateForm={change}
            />
          </form>
        );
      }}
    />
  );
};

ProFormaInvoiceForm.GetComponentName = () => 'ProFormaInvoiceForm';

ProFormaInvoiceForm.propTypes = {
  backUrl: PropTypes.string,
  exchangeRates: exchangeRatesSpec,
  exchangeRatesService: exchangeRatesServiceSpec,
  exchangeRateUpdatedAt: PropTypes.string,
  history: routerHistorySpec.isRequired,
  initialValues: PropTypes.object,
  isManagerCreatingForProvider: PropTypes.bool,
  missingPrerequisites: PropTypes.arrayOf(PropTypes.string),
  onAddItem: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  organization: orgSpec.isRequired,
  proFormaInvoice: proFormaInvoiceSpec,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
};

ProFormaInvoiceForm.defaultProps = {
  backUrl: null,
  exchangeRates: null,
  exchangeRatesService: null,
  exchangeRateUpdatedAt: null,
  initialValues: {},
  isManagerCreatingForProvider: false,
  missingPrerequisites: [],
  proFormaInvoice: null,
  rates: [],
};

export default withRouter(ProFormaInvoiceForm);
