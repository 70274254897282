import React from 'react';
import PropTypes from 'prop-types';

import ProfileExperienceList from 'accounts/assets/js/components/ProfileExperienceList.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchListDS, listReorderItemsAC } from 'core/assets/js/ducks/list';
import { reorderExperienceDS } from 'accounts/assets/js/data-services/profiles';
import { profileExperienceApiUrl } from 'accounts/urls';

class ProfileExperienceTab extends React.Component {
  static GetComponentName() {
    return 'ProfileExperienceTab';
  }

  constructor(props) {
    super(props);
    this.handleSortEnd = this.handleSortEnd.bind(this);
  }

  handleSortEnd({ newIndex, oldIndex }) {
    const { dispatch } = this.props;
    // no reason to dispatch if no rearrangement was made after all
    if (newIndex !== oldIndex) {
      dispatch(listReorderItemsAC(
        { oldIndex, newIndex, componentName: ProfileExperienceTab.GetComponentName() },
      ));
      dispatch(reorderExperienceDS(
        { oldIndex, newIndex },
        ProfileExperienceTab.GetComponentName(),
      ));
    }
  }

  render() {
    const { items } = this.props;
    return (
      <ProfileExperienceList
        items={items}
        canEditExperience
        onSortEnd={this.handleSortEnd}
      />
    );
  }
}

ProfileExperienceTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.array,
};

ProfileExperienceTab.defaultProps = {
  items: [],
};

const ProfileExperienceTabConnected = withTDApiConnected({
  fetchData: ({
    dispatch, authedAxios, componentName, querystring,
  }) => dispatch(fetchListDS({
    authedAxios, componentName, querystring, url: profileExperienceApiUrl(),
  })),
  duck: 'list',
  storeKey: ProfileExperienceTab.GetComponentName(),
  skeletonComponent: SettingsPageSkeleton,
})(ProfileExperienceTab);

export default ProfileExperienceTabConnected;
