/* eslint-disable max-len */
import React from 'react';
import { Info } from '@material-ui/icons';

/**
 * Display a fraud warning.
 */
const InvoicePhishingWarning = () => {
  return (
    <div className="p-3 pr-4 rounded invoice-warning d-flex">
      <div><Info /></div>
      <div className="ml-1">
        <p className="font-weight-bold">Security Information</p>
        <p>AI is making digital fraud easier than ever.  We&apos;re proud to have a 100% fraud-free safety record.  To keep it that way, we will never notify you by email with new bank account details for your payments.</p>
        <p>If we do need to change our payment instructions for any reason, you will be notified of the change within our authenticated application, and verbally from your customer success agent.</p>
        <p className="mb-0">If you do receive a phishing email from TalentDesk with new payment instruction details, please advise your customer success agent immediately.</p>
      </div>
    </div>
  );
};

export default InvoicePhishingWarning;
