import React from 'react';
import PropTypes from 'prop-types';
import { isNaN } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, SubmissionError, reduxForm, formValueSelector } from 'redux-form';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import {
  BS_STYLE,
  CURRENCIES_WITHOUT_DECIMALS,
  CURRENCY_VALUES,
  MEMBER_SEARCH_TARGET,
  MULTISELECT_TYPE,
  PROJECT_TABS,
} from 'core/assets/js/constants';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { projectViewUrl } from 'projects/urls';
import { projectRequestBudgetDS } from 'projects/assets/js/data-services/form';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import MultiselectField from 'core/assets/js/components/ReduxFormFields/MultiselectField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const MIN_BUDGET = 10;
const FORM_ID = 'project-request-budget';

class ProjectRequestBudgetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      budgetSelected: false,
    };
    this.props = props;

    this.submit = this.submit.bind(this);
    this.checkSubmissionOnEnter = this.checkSubmissionOnEnter.bind(this);
  }

  submit(values) {
    const { project, orgAlias } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        let errMsg;
        const managerId = values.manager_id;
        const budget = parseFloat(values.budget);

        if (isNaN(budget) || budget < MIN_BUDGET) {
          errMsg = `Select a valid budget, min value is (${project.currencySymbol}${MIN_BUDGET})`;
        } else if (!parseInt(managerId, 10)) {
          errMsg = 'Select a manager to request budget from';
        }

        if (errMsg) {
          return reject(new SubmissionError({ manager_id: errMsg }));
        }

        const response = await projectRequestBudgetDS(
          orgAlias, project.id, values);

        if (response.data._error) {
          return reject(new SubmissionError(response.data));
        }
        return resolve();
      } catch (err) {
        return reject(new SubmissionError(err.response.data));
      }
    });
  }

  checkSubmissionOnEnter(e) {
    // Enter clicked, trigger a search with this budget and show the list of managers
    if (e.keyCode === 13 && e.shiftKey === false) {
      this.setState({ budgetSelected: true });
      return e.preventDefault();
    }
    return true;
  }

  render() {
    const {
      error, handleSubmit, history, submitting, submitLabel, reset,
      managerId, orgAlias, project, budgetAmount, activeUserCard,
    } = this.props;
    const { budgetSelected } = this.state;
    const hasSelectedManager = !!managerId;
    const hasEnteredBudget = !!budgetAmount;
    const hasSelectedBudget = !!budgetSelected;
    const submitBtnLabel = submitLabel || 'Request budget';
    const isSelfAssigned = +managerId === +activeUserCard.user.id;
    const noDecimals = CURRENCIES_WITHOUT_DECIMALS.includes(project.currency);

    return (
      <div>
        {isSelfAssigned && (
          <TDSystemMessage
            className="mb-4"
            title="Instant budget allocation to project"
          >
            You have selected yourself as the reviewer of the budget request.
            Once you click on the “Request budget” button,
            the budget will be allocated to the project instantly.
          </TDSystemMessage>
        )}
        <form onSubmit={handleSubmit(this.submit)}>
          <Field
            component={InputField}
            disabled={(hasSelectedManager && hasEnteredBudget && hasSelectedBudget)}
            label={`Budget (${project.currencySymbol})`}
            min={1}
            name="budget"
            placeholder={`e.g 10000${noDecimals ? '' : '.00'}`}
            prefix={project.currencySymbol}
            rest={{
              onKeyDown: this.checkSubmissionOnEnter,
            }}
            sublabel="What's the budget you need?"
            type="money"
          />
          { hasSelectedBudget && (
            <Field
              name="manager_id"
              component={MultiselectField}
              multiselectComponent={MULTISELECT_TYPE.MEMBER}
              orgAlias={orgAlias}
              target={MEMBER_SEARCH_TARGET.MANAGERS_WITH_BUDGET}
              useAsync={false}
              searchable
              placeholder="Type the name or job title of a manager..."
              payload={{
                budget: budgetAmount,
              }}
            />
          )}
          {error && (
            <div className="form-group has-error">
              <span className="help-block">{error}</span>
            </div>
          )}
          <div className="clearfix mt-4 text-right button-set">
            <TDButton
              disabled={submitting}
              label="Cancel"
              onClick={() => {
                history.push(projectViewUrl(orgAlias, project.id, PROJECT_TABS.PURCHASE_ORDERS));
              }}
            />
            <TDButton
              label="Change budget"
              onClick={(evt) => {
                // NOTICE: Even though this is not a submit button, when a budget and a manager
                //         is selected, clicking this button would submit the form, unless the
                //         default event is prevented
                evt.preventDefault();
                this.setState({ budgetSelected: false });
                reset();
              }}
              disabled={!hasSelectedManager || !hasSelectedBudget}
            />
            {
              !hasSelectedBudget && (
                <TDButton
                  disabled={!hasEnteredBudget}
                  label="Find manager"
                  onClick={() => { this.setState({ budgetSelected: true }); }}
                />
              )}
            {
              hasSelectedBudget && (
                <TDButton
                  variant={BS_STYLE.PRIMARY}
                  disabled={submitting || !hasSelectedManager}
                  label={submitBtnLabel}
                  rest={{
                    type: 'submit',
                  }}
                />
              )}
          </div>
        </form>
      </div>
    );
  }
}

ProjectRequestBudgetForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  submitLabel: PropTypes.string,
  submitting: PropTypes.bool,
  orgAlias: PropTypes.string,
  project: PropTypes.shape({
    id: PropTypes.number,
    currency: PropTypes.oneOf(CURRENCY_VALUES),
    currencySymbol: PropTypes.oneOf(Object.values(CURRENCY_SYMBOL)),
  }).isRequired,
  budgetAmount: PropTypes.number,
  managerId: PropTypes.string,
  activeUserCard: userCardSpec.isRequired,
};

ProjectRequestBudgetForm.defaultProps = {
  error: '',
  submitLabel: '',
  submitting: false,
  budgetAmount: 0,
  orgAlias: '',
  managerId: null,
};

const mapStateToProps = (state) => {
  const selector = formValueSelector('project-request-budget');
  const budget = selector(state, 'budget');

  return {
    budgetAmount: parseFloat(budget) || null,
    managerId: selector(state, 'manager_id'),
    activeUserCard: selectActiveUserCard(state),
  };
};

const ProjectRequestBudgetFormRedux = reduxForm({
  form: FORM_ID,
})(ProjectRequestBudgetForm);

const ProjectRequestBudgetFormConnect = connect(
  mapStateToProps,
)(ProjectRequestBudgetFormRedux);

export default withRouter(ProjectRequestBudgetFormConnect);
