import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Card } from 'react-bootstrap';

import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import {
  modalOpenAC, modalCloseAC, getIsModalOpen, getModalPayload,
} from 'core/assets/js/ducks/modalLauncher';
import { routerHistorySpec, paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { interviewCreateUrl, interviewEditUrl, interviewPreviewUrl, interviewListApiUrl, interviewInviteesUrl } from 'interviews/urls';
import RemoveInterviewModal from 'interviews/assets/js/components/RemoveInterviewModal.jsx';
import DuplicateInterviewModal, { DUPLICATE_INTERVIEW_MODAL } from 'interviews/assets/js/components/DuplicateInterviewModal.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { getListState, fetchListDS, listReplaceItemAC } from 'core/assets/js/ducks/list';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import InterviewCardItem from 'interviews/assets/js/components/InterviewCardItem.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import InterviewListSkeleton from 'core/assets/js/components/Skeleton/InterviewListSkeleton.jsx';
import { selectAuthenticated, getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import TDCopyToClipboard from 'core/assets/js/components/TDCopyToClipboard.jsx';
import {
  generatePublicInvite,
  revokePublicInvite,
} from 'interviews/assets/js/data-services/interviews';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { interviewSpec } from 'interviews/assets/js/lib/objectSpecs';
import { STATUS } from 'invitations/assets/js/constants';
import { fetchOrgListDS } from 'organizations/assets/js/data-services/list';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import {
  getActiveUserCardPermissionChecker, selectActiveOrg,
} from 'organizations/assets/js/reducers/organizations';
import { PERMISSIONS } from 'roles/assets/js/constants';
import { postOrganizationDS } from 'settings/assets/js/data-services/organizations';

const DELETE_MODAL_ID = 'delete-interview';
const MANAGE_MODAL_ID = 'manage-interview-url';

const InterviewList = ({
  orgAlias,
  interviews,
  openDeleteModal,
  openDuplicateModal,
  openManageModal,
}) => {
  const emptyListMsg = 'You haven\'t created any Onboarding form yet';
  if (interviews.length === 0) {
    return (
      <li key="empty">
        <Card>
          <Card.Body className="interview-builder__empty-list-message">
            {emptyListMsg}
          </Card.Body>
        </Card>
      </li>
    );
  }
  return interviews.map(interview => (
    <li
      key={`interview-${interview.id}`}
      className="interview-list-item"
    >
      <InterviewCardItem
        {...interview}
        url={interviewPreviewUrl(orgAlias, interview.id)}
        inviteesUrl={interviewInviteesUrl(orgAlias, interview.id, STATUS.PENDING)}
        editUrl={interviewEditUrl(orgAlias, interview.id)}
        onDelete={() => openDeleteModal(interview)}
        onDuplicate={() => openDuplicateModal(interview)}
        onManageUrl={() => openManageModal(interview)}
      />
    </li>
  ));
};

InterviewList.propTypes = {
  orgAlias: PropTypes.string,
  interviews: PropTypes.arrayOf(interviewSpec).isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  openDuplicateModal: PropTypes.func.isRequired,
  openManageModal: PropTypes.func.isRequired,
};

InterviewList.defaultProps = {
  orgAlias: '',
};

class InterviewListView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    return dispatch(fetchListDS({
      url: interviewListApiUrl(params.orgAlias, url),
      querystring,
      componentName,
      authedAxios,
    }));
  }

  static GetComponentName() {
    return 'InterviewListView';
  }

  constructor(props) {
    super(props);

    this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
    this.handleOpenDuplicateModal = this.handleOpenDuplicateModal.bind(this);
    this.handleOpenManageUrl = this.handleOpenManageUrl.bind(this);
    this.handleCloseManageUrl = this.handleCloseManageUrl.bind(this);
    this.handleRevokeUrl = this.handleRevokeUrl.bind(this);
    this.handleGenerateUrl = this.handleGenerateUrl.bind(this);
    this.handleClipboardCopy = this.handleClipboardCopy.bind(this);
    this.reFetchInterview = this.reFetchInterview.bind(this);
  }

  /**
   * Open the delete interview confirmation modal
   *
   * @param heading
   * @param body
   * @param confirmLabel
   */
  handleOpenDeleteModal(interview) {
    const { dispatch } = this.props;
    const payload = {
      id: interview.id,
      name: interview.name,
      pendingInvitationCount: interview.pendingInvitationCount,
    };
    dispatch(modalOpenAC(DELETE_MODAL_ID, payload));
  }

  handleOpenDuplicateModal(interview) {
    const { dispatch } = this.props;
    const payload = {
      id: interview.id,
    };
    dispatch(modalOpenAC(DUPLICATE_INTERVIEW_MODAL, payload));
  }

  // eslint-disable-next-line class-methods-use-this
  handleClipboardCopy() {
    toastr.success('Invite Link copied to clipboard!');
  }

  handleOpenManageUrl(interview) {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(MANAGE_MODAL_ID, { interviewId: interview.id }));
  }

  handleCloseManageUrl() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  async handleRevokeUrl(interviewId) {
    const { dispatch, params: { orgAlias } } = this.props;

    try {
      const response = await revokePublicInvite({ orgAlias, interviewId });
      if (!response || !response.data || !response.data.interview) {
        return toastr.error('Oh Snap!', 'Something went wrong while revoking the URL');
      }

      dispatch(listReplaceItemAC(response.data.interview, this.constructor.GetComponentName()));
      return toastr.success('Url has been successfully revoked');
    } catch (err) {
      return toastr.error('Oh Snap!', err.response.data._error);
    } finally {
      this.handleCloseManageUrl();
    }
  }

  async handleGenerateUrl(interviewId) {
    const { dispatch, params: { orgAlias } } = this.props;
    try {
      const response = await generatePublicInvite({ orgAlias, interviewId });
      if (!response || !response.data || !response.data.interview) {
        return toastr.error('Oh Snap!', 'Something went wrong while generating the URL');
      }

      dispatch(listReplaceItemAC(response.data.interview, this.constructor.GetComponentName()));
      return toastr.success('Url has been successfully generated');
    } catch (err) {
      return toastr.error('Oh Snap!', err.response.data._error);
    }
  }

  /**
   * Hndle the refetching on deleting
   */
  async reFetchInterview() {
    const { dispatch } = this.props;
    dispatch(requestRefreshAC(InterviewListView.GetComponentName()));
  }

  render() {
    const {
      activeOrg: {
        managers_can_manage_onboarding_forms: managersCanManageOnboardingForms,
        onboarding_forms_are_mandatory: onboardingFormsAreMandatory,
        should_high_manager_manage_instant_approval: shouldHighManagerManageInstantApproval,
      },
      deleteModalPayload,
      duplicatedInterviewModal,
      dispatch,
      hasOrgAccess,
      hasPermission,
      history,
      interviews,
      isManageUrlModalOpen,
      managedInterview,
      pagination,
      params: { orgAlias },
    } = this.props;
    const isAnyManager = hasOrgAccess({ requireManager: true });
    const isHigherManager = hasOrgAccess({ requireFinController: true });

    const breadcrumbs = [
      {
        title: 'Onboarding Forms',
        url: null,
      },
    ];

    let ctaButtonItems = null;

    const canCreateOnboardingForms = isHigherManager || (
      isAnyManager && managersCanManageOnboardingForms
    );

    if (canCreateOnboardingForms) {
      ctaButtonItems = [{
        label: 'Create onboarding form',
        onClick: () => history.push(interviewCreateUrl(orgAlias)),
        variant: BS_STYLE.PRIMARY,
      }];
    }

    const inviteLinkModalFooter = (
      <div className="text-right">
        { managedInterview && !managedInterview.publicUrl && (
          <TDButton
            data-testid="interview-list-generate-link-button"
            label="Generate invite link"
            onClick={
              () => this.handleGenerateUrl(managedInterview.id)
            }
            variant={BS_STYLE.PRIMARY}
          />
        )}
        { managedInterview && managedInterview.publicUrl && (
          <TDButton
            btnIcon={ICON.CROSS}
            onClick={() => this.handleRevokeUrl(managedInterview.id)}
            label="Revoke"
            variant={BS_STYLE.DANGER}
          />
        )}
      </div>
    );

    const onSettingsChanged = changes => (
      dispatch(postOrganizationDS(orgAlias, changes, InterviewListView.GetComponentName()))
    );

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} ctaButtonItems={ctaButtonItems} />

        <div className="page page--onboarding-forms">
          <div className="container">
            <TDApiConnected duck="list" component={this.constructor} skeletonComponent={InterviewListSkeleton}>
              <div className="rounded shadow-sm p-4 bg-white">
                {hasPermission(PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS) && (
                  <>
                    <h3 className="heading-block">Onboarding form settings</h3>
                    <TDToggleWithConfirmationModal
                      containerClassName="pb-3 px-3"
                      disableHeader="Allow managers to manage onboarding forms is activated"
                      DisableModalBody={() => (
                        <>
                          <p>
                            Deactivating this setting means that managers will not be able to create
                            and edit user onboarding forms. They will not be able to add, remove or
                            update questions and fields in any of the onboarding forms.
                          </p>
                          <p className="mb-4">
                            Are you sure you want to deactivate &quot;Allow managers to manage
                            onboarding forms&quot;?
                          </p>
                        </>
                      )}
                      enableHeader="Allow managers to manage onboarding forms is deactivated"
                      EnableModalBody={() => (
                        <>
                          <p>
                            Activating this setting means that managers will be able to create and
                            edit user onboarding forms. They will be able to add, remove or update
                            questions and fields in any of the onboarding forms.
                          </p>
                          <p className="mb-4">
                            Are you sure you want to activate &quot;Allow managers to manage
                            onboarding forms&quot;?
                          </p>
                        </>
                      )}
                      label="Allow managers to manage onboarding forms"
                      modalId="managers-can-manage-onboarding-forms"
                      onToggled={selected => onSettingsChanged({
                        managers_can_manage_onboarding_forms: selected,
                      })}
                      selected={!!managersCanManageOnboardingForms}
                      sublabel={(
                        <p>
                          By activating this option managers of your organization will be able to
                          create and edit onboarding forms
                        </p>
                      )}
                    />
                    {interviews.length > 0 && (
                      <TDToggleWithConfirmationModal
                        containerClassName="pb-3 px-3"
                        enableHeader="Make onboarding forms mandatory in this organization"
                        EnableModalBody={() => (
                          <>
                            <p>
                              By activating this setting, onboarding forms will be mandatory when
                              inviting contractors to join this organization.
                            </p>
                            <p className="mb-4">Are you sure you want to activate this setting?</p>
                          </>
                        )}
                        disableHeader="Make onboarding forms optional in this organization"
                        DisableModalBody={() => (
                          <>
                            <p>
                              By deactivating this setting, onboarding forms will not be mandatory
                              when inviting contractors to join this organization.
                            </p>
                            <p className="mb-4">
                              Are you sure you want to deactivate this setting?
                            </p>
                          </>
                        )}
                        label="Mandatory onboarding forms"
                        modalId="mandatory-onboarding-forms"
                        onToggled={selected => onSettingsChanged({
                          onboarding_forms_are_mandatory: selected,
                        })}
                        selected={onboardingFormsAreMandatory}
                        sublabel={(
                          `By activating this option, contractors will have to fill in an onboarding
                          form before joining the organization`
                        )}
                      />
                    )}
                    <TDToggleWithConfirmationModal
                      containerClassName="pb-3 px-3"
                      enableHeader="User onboarding automatic approval is deactivated"
                      EnableModalBody={() => (
                        <>
                          <p>
                            Speed up the user onboarding process by allowing a hiring manager
                            to instruct the platform to automatically approve an onboarding
                            form upon its submission. This is particularly useful when a
                            hiring manager onboards on the platform a user they have worked
                            with in the past.
                          </p>
                          <p className="mb-4">
                            Are you sure you want to activate
                            the &#39;Automatic user approval&#39; setting?
                          </p>
                        </>
                      )}
                      disableHeader="User onboarding automatic approval is activated"
                      DisableModalBody={() => (
                        <>
                          <p>
                            The automatic user approval during user onboarding allows the hiring
                            managers to speed up the onboarding process of selected users. This
                            is particularly useful when a hiring manager onboards on the platform
                            a user they have worked with in the past. Deactivating this setting
                            means that the user will have to submit their onboarding form and wait
                            for the hiring manager or the organisation owner to review it before
                            they get access to your organisation.
                          </p>
                          <p className="mb-4">
                            Are you sure you want to deactivate
                            the &#39;Automatic user approval&#39; setting?
                          </p>
                        </>
                      )}
                      label="Automatic user approval"
                      modalId="automatic-user-approval"
                      onToggled={selected => onSettingsChanged({
                        should_high_manager_manage_instant_approval: selected,
                      })}
                      selected={shouldHighManagerManageInstantApproval}
                      sublabel={(
                        `Speed up the user onboarding process by allowing a hiring manager to
                        instruct the platform to automatically approve an onboarding form
                        upon its submission`
                      )}
                    />
                    <h3 className="heading-block mt-4">Onboarding forms</h3>
                  </>
                )}
                <ul className="interview-list">
                  <InterviewList
                    openDeleteModal={this.handleOpenDeleteModal}
                    openDuplicateModal={this.handleOpenDuplicateModal}
                    openManageModal={this.handleOpenManageUrl}
                    interviews={interviews}
                    orgAlias={orgAlias}
                  />
                </ul>

                { duplicatedInterviewModal && (
                  <DuplicateInterviewModal
                    onDuplicating={this.reFetchInterview}
                  />
                )}

                { deleteModalPayload && (
                  <RemoveInterviewModal
                    onDelete={async () => {
                      await this.reFetchInterview();
                      // Reload the org, as onboarding_forms_are_mandatory may have been disabled
                      dispatch(fetchOrgListDS({ cached: false }));
                    }}
                    orgAlias={orgAlias}
                  />
                )}

                { managedInterview && (
                  <ModalSimple
                    heading="Invite Link"
                    open={isManageUrlModalOpen}
                    onClose={this.handleCloseManageUrl}
                    footer={inviteLinkModalFooter}
                  >
                    <p>
                      Share the invite link to allow candidates to apply directly to your
                      organization. The candidate will be prompted to submit the Onboarding Form
                      of your choice, so you can approve or reject them, or request amends.
                    </p>
                    <p>
                      You can deactivate the link at any point by clicking on Revoke.
                    </p>
                    { managedInterview.publicUrl && (
                      <TDCopyToClipboard
                        text={managedInterview.publicUrl}
                        className="mb-4 mt-5"
                      />
                    )}
                  </ModalSimple>
                )}
              </div>

              <TDPagination {...pagination} />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

InterviewListView.propTypes = {
  activeOrg: orgSpec.isRequired,
  deleteModalPayload: PropTypes.object,
  duplicatedInterviewModal: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  hasPermission: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  interviews: PropTypes.arrayOf(interviewSpec),
  invitees: PropTypes.arrayOf(PropTypes.object),
  isManageUrlModalOpen: PropTypes.bool,
  location: PropTypes.object.isRequired,
  managedInterview: PropTypes.object,
  pagination: paginationSpec.isRequired,
  params: PropTypes.object,
};

InterviewListView.defaultProps = {
  deleteModalPayload: null,
  duplicatedInterviewModal: null,
  interviews: [],
  invitees: [],
  isManageUrlModalOpen: false,
  managedInterview: null,
  params: {},
};

const mapStateToProps = (state, props) => {
  const listState = getListState(state, InterviewListView.GetComponentName());
  let managedInterview = null;
  const manageUrlModalPayload = getModalPayload(state, MANAGE_MODAL_ID);

  if (manageUrlModalPayload) {
    managedInterview = listState.items.find(i => i.id === manageUrlModalPayload.interviewId);
  }
  return {
    activeOrg: selectActiveOrg(state),
    deleteModalPayload: getModalPayload(state, DELETE_MODAL_ID),
    duplicatedInterviewModal: getModalPayload(state, DUPLICATE_INTERVIEW_MODAL),
    hasOrgAccess: getHasOrgAccess(state),
    hasPermission: getActiveUserCardPermissionChecker(state),
    history: props.history,
    interviews: listState.items,
    isAuthenticated: selectAuthenticated(state),
    isManageUrlModalOpen: getIsModalOpen(state, MANAGE_MODAL_ID),
    managedInterview,
    pagination: listState.pagination,
    params: props.match.params,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const InterviewListViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InterviewListView);

export default withRouter(InterviewListViewConnect);
