import { isEmpty, isObject, omit, map, isEqual, differenceWith } from 'lodash';
import memoize from 'memoize-one';
import { createSelector } from 'reselect';

import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { extractSearchFromHeaders, extractPaginationFromHeaders, arrayMove } from 'core/assets/js/lib/utils';

// Action types
export const LIST_FETCH = 'redux-list/FETCH';
export const LIST_FETCH_EXTRAS = 'redux-list/FETCH_EXTRAS';
export const LIST_BULK_UPDATE = 'redux-list/BULK_UPDATE';
export const LIST_PAGINATION_FETCH = 'redux-list/PAGINATION_FETCH';
export const LIST_SEARCH_FETCH = 'redux-list/SEARCH_FETCH';
export const LIST_IS_LOADING = 'redux-list/IS_LOADING';
export const LIST_RESET = 'redux-list/RESET';
export const LIST_REMOVE_ITEM = 'redux-list/REMOVE_ITEM';
export const LIST_REPLACE_ITEM = 'redux-list/REPLACE_ITEM';
export const LIST_UPDATE_ITEM = 'redux-list/UPDATE_ITEM';
export const LIST_APPEND_ITEM = 'redux-list/APPEND_ITEM';
export const LIST_APPEND_ITEMS = 'redux-list/APPEND_ITEMS';
export const LIST_ADD_EXTRAS = 'redux-list/APPEND_EXTRAS';
export const LIST_UPDATE_EXTRAS = 'redux-list/UPDATE_EXTRAS';
export const LIST_UPDATE_EXTRAS_ITEM = 'redux-list/UPDATE_EXTRAS_ITEM';
export const LIST_PREPEND_ITEMS = 'redux-list/PREPEND_ITEMS';
export const LIST_ADD_SELECTED_ITEMS = 'redux-list/ADD_SELECTED_ITEMS';
export const LIST_SELECT_ONLY_ITEM = 'redux-list/SELECT_ONLY_ITEM';
export const LIST_REMOVE_SELECTED_ITEMS = 'redux-list/REMOVE_SELECTED_ITEMS';
export const LIST_HAS_ALL_PAGE_ITEMS_SELECTED = 'redux-list/LIST_HAS_ALL_PAGE_ITEMS_SELECTED';
export const LIST_RESET_SELECTED_ITEMS = 'redux-list/RESET_SELECTED_ITEMS';
export const LIST_IS_SELECTION_MODE_ENABLED = 'redux-list/IS_SELECTION_MODE_ENABLED';
export const LIST_REORDER_ITEMS = 'redux-list/REORDER_ITEMS';

// Reducer
export const componentListInitialState = {
  extras: {},
  isLoading: false,
  isSelectionModeEnabled: false,
  pagination: {
    page: 0,
    pages: 0,
    total: 0,
    next: null,
    prev: null,
  },
  items: [],
  pageItemsSelected: true,
  selectedItems: [],
  search: {
    isActive: false,
  },
  hasLoaded: false,
};

export const listInitialState = {};

const componentReducer = (state = componentListInitialState, action) => {
  let items = [];
  let extras;

  switch (action.type) {
    case LIST_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasLoaded: !action.isLoading,
      };
    case LIST_IS_SELECTION_MODE_ENABLED:
      return {
        ...state,
        isSelectionModeEnabled: action.isSelectionModeEnabled,
      };
    case LIST_BULK_UPDATE:
      if (isEmpty(action.ids)) {
        // update all items
        items = state.items.map(it => ({ ...it, ...action.values }));
        return {
          ...state,
          items,
        };
      }
      // update specific items
      items = state.items.map((it) => {
        if (action.ids.includes(it.id)) {
          return { ...it, ...action.values };
        }
        return it;
      });

      return {
        ...state,
        items,
      };
    case LIST_REMOVE_ITEM:
      items = state.items.filter(it => (
        it.id !== action.itemId
      ));

      return {
        ...state,
        items,
      };
    case LIST_REPLACE_ITEM:
      items = state.items.map(it => (
        it.id === action.item.id ? action.item : it
      ));

      return {
        ...state,
        items,
      };
    case LIST_APPEND_ITEM:
      items = [].concat(state.items).concat(action.item);
      return {
        ...state,
        items,
      };
    case LIST_APPEND_ITEMS:
      items = [].concat(state.items).concat(action.items);
      return {
        ...state,
        items,
      };
    case LIST_PREPEND_ITEMS:
      items = [].concat(state.items);
      items.unshift(...action.items.filter(i => !state.items.some(i2 => isEqual(i2, i))));

      return {
        ...state,
        items,
      };
    case LIST_ADD_SELECTED_ITEMS: {
      const updatedSelectedItems = [].concat(state.selectedItems).concat(action.items);
      return ({
        ...state,
        pageItemsSelected: isEmpty(differenceWith(
          map(state.items, 'id'),
          map(updatedSelectedItems, 'id'),
          !isEqual,
        )),
        selectedItems: updatedSelectedItems,
      });
    }
    case LIST_SELECT_ONLY_ITEM: {
      const updatedSelectedItems = [action.item];
      return ({
        ...state,
        pageItemsSelected: isEmpty(differenceWith(
          map(state.items, 'id'),
          map(updatedSelectedItems, 'id'),
          !isEqual,
        )),
        selectedItems: updatedSelectedItems,
      });
    }
    case LIST_REMOVE_SELECTED_ITEMS: {
      const updatedSelectedItems = state.selectedItems
        .filter(it => (!action.itemIds.includes(it.id)));
      return ({
        ...state,
        pageItemsSelected: isEmpty(differenceWith(
          map(state.items, 'id'),
          map(updatedSelectedItems, 'id'),
          !isEqual,
        )),
        selectedItems: updatedSelectedItems,
      });
    }
    case LIST_RESET_SELECTED_ITEMS:
      return {
        ...state,
        pageItemsSelected: false,
        selectedItems: [],
      };
    case LIST_HAS_ALL_PAGE_ITEMS_SELECTED:
      return {
        ...state,
        pageItemsSelected: action.pageItemsSelected,
      };
    case LIST_UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map((it) => {
          if (it.id === action.id) {
            return { ...it, ...action.values };
          }
          return { ...it };
        }),
      };
    case LIST_FETCH:
      return {
        ...state,
        items: action.items,
        hasLoaded: true,
        extras: {
          ...state.extras,
          ...action.extras,
        },
      };
    case LIST_FETCH_EXTRAS:
      return {
        ...state,
        extras: action.extrasKey
          ? {
            ...state.extras,
            [action.extrasKey]: action.extras,
          }
          : action.extras,
      };
    case LIST_ADD_EXTRAS:
      return {
        ...state,
        extras: {
          ...state.extras,
          [action.extrasKey]: [
            ...(state.extras[action.extrasKey] || []),
            ...action.extras,
          ],
        },
      };
    case LIST_UPDATE_EXTRAS:
      return {
        ...state,
        extras: {
          ...state.extras,
          ...action.extras,
        },
      };
    case LIST_UPDATE_EXTRAS_ITEM:
      extras = state.extras[action.extrasKey].map(item => (
        item.id === action.item.id ? action.item : item
      ));

      return {
        ...state,
        extras: {
          ...state.extras,
          [action.extrasKey]: extras,
        },
      };
    case LIST_REORDER_ITEMS:
      if (action.oldIndex === action.newIndex) {
        return state;
      }
      return {
        ...state,
        items: arrayMove([...state.items], action.oldIndex, action.newIndex),
        touched: true,
      };
    case LIST_PAGINATION_FETCH: {
      return {
        ...state,
        pagination: action.payload,
      };
    }
    case LIST_SEARCH_FETCH: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case LIST_RESET:
      return {
        ...componentListInitialState,
      };
    default:
      return state;
  }
};

export const reducer = (state = listInitialState, action) => {
  if (action.type && action.type.startsWith('redux-list') && !action.componentName) {
    throw new Error(`cannot use list duck without specifying a component name on action ${action.type}`);
  }
  const { componentName } = action;

  const componentState = componentReducer(state[componentName], action);
  let newState = state;
  switch (action.type) {
    case LIST_IS_LOADING:
    case LIST_BULK_UPDATE:
    case LIST_REMOVE_ITEM:
    case LIST_REPLACE_ITEM:
    case LIST_UPDATE_ITEM:
    case LIST_FETCH:
    case LIST_FETCH_EXTRAS:
    case LIST_ADD_EXTRAS:
    case LIST_UPDATE_EXTRAS:
    case LIST_UPDATE_EXTRAS_ITEM:
    case LIST_PAGINATION_FETCH:
    case LIST_SEARCH_FETCH:
    case LIST_APPEND_ITEM:
    case LIST_IS_SELECTION_MODE_ENABLED:
    case LIST_HAS_ALL_PAGE_ITEMS_SELECTED:
    case LIST_ADD_SELECTED_ITEMS:
    case LIST_REMOVE_SELECTED_ITEMS:
    case LIST_RESET_SELECTED_ITEMS:
    case LIST_PREPEND_ITEMS:
    case LIST_APPEND_ITEMS:
    case LIST_REORDER_ITEMS:
    case LIST_SELECT_ONLY_ITEM:
      newState = {
        ...state,
        [componentName]: componentState,
      };
      break;
    case LIST_RESET:
      newState = omit(state, componentName);
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

const _getListStateSelector = createSelector(
  state => state.list,
  listState => memoize(
    componentName => {
      if (!componentName) {
        throw new Error('cannot get list state without specifying component name');
      }
      if (Array.isArray(componentName)) {
        return componentName.map(c => listState[c] || componentListInitialState);
      }
      return listState[componentName] || componentListInitialState;
    },
  ),
);

/**
 * Return the view state if the data currently saved in the store belong to the active component
 *
 * @param state
 * @param componentName
 * @returns {*}
 */
export const getListState = (state, componentName) => _getListStateSelector(state)(componentName);

const _getListStateExtrasSelector = createSelector(
  state => state.list,
  listState => memoize(
    (componentName, extrasKey) => {
      if (!componentName) {
        throw new Error('cannot get list state extras without specifying component name');
      }
      if (!extrasKey) {
        throw new Error('cannot get list state extras without specifying extrasKey');
      }
      return (listState[componentName] || componentListInitialState).extras[extrasKey];
    },
  ),
);

export const getListStateExtras = (state, componentName, extrasKey) => (
  _getListStateExtrasSelector(state)(componentName, extrasKey)
);

// Action creators
export const listIsLoadingAC = (bool, componentName) => ({
  type: LIST_IS_LOADING,
  componentName,
  isLoading: bool,
});

export const listIsSelectionModeEnabledAC = (bool, componentName) => ({
  type: LIST_IS_SELECTION_MODE_ENABLED,
  componentName,
  isSelectionModeEnabled: bool,
});

export const paginationFetchAC = (headers, componentName) => ({
  type: LIST_PAGINATION_FETCH,
  payload: extractPaginationFromHeaders(headers),
  componentName,
});

export const searchFetchAC = (headers, componentName) => ({
  type: LIST_SEARCH_FETCH,
  payload: extractSearchFromHeaders(headers),
  componentName,
});

export const listFetchAC = (data, componentName) => (
  isObject(data) && data.items
    ? {
      type: LIST_FETCH,
      componentName,
      items: data.items,
      extras: omit(data, 'items'),
    }
    : {
      type: LIST_FETCH,
      componentName,
      items: data,
    }
);

export const listPrependItemsAC = (items, componentName) => ({
  type: LIST_PREPEND_ITEMS,
  items,
  componentName,
});

export const listFetchExtrasAC = (extras, componentName, extrasKey = null) => ({
  type: LIST_FETCH_EXTRAS,
  componentName,
  extras,
  extrasKey,
});

export const listAddExtrasAC = (extras, componentName, extrasKey = null) => ({
  type: LIST_ADD_EXTRAS,
  componentName,
  extras,
  extrasKey,
});

export const listUpdateExtrasAC = (extras, componentName) => ({
  componentName,
  extras,
  type: LIST_UPDATE_EXTRAS,
});

export const listUpdateExtrasItem = (item, componentName, extrasKey = null) => ({
  type: LIST_UPDATE_EXTRAS_ITEM,
  componentName,
  extrasKey,
  item,
});

export const listBulkUpdateAC = (ids, values, componentName) => ({
  type: LIST_BULK_UPDATE,
  ids,
  values,
  componentName,
});

export const listRemoveItemAC = (itemId, componentName) => ({
  type: LIST_REMOVE_ITEM,
  itemId,
  componentName,
});

export const listReplaceItemAC = (item, componentName) => ({
  type: LIST_REPLACE_ITEM,
  item,
  componentName,
});

export const listReorderItemsAC = ({ oldIndex, newIndex, componentName }) => ({
  type: LIST_REORDER_ITEMS,
  oldIndex,
  newIndex,
  componentName,
});

export const listAppendItemAC = (item, componentName) => ({
  type: LIST_APPEND_ITEM,
  item,
  componentName,
});

export const listAppendItemsAC = (items, componentName) => ({
  type: LIST_APPEND_ITEMS,
  items,
  componentName,
});

export const listAddSelectedItemsAC = (items, componentName) => ({
  type: LIST_ADD_SELECTED_ITEMS,
  items: Array.isArray(items) ? items : [items],
  componentName,
});

export const listSelectOnlyItemAC = (item, componentName) => ({
  type: LIST_SELECT_ONLY_ITEM,
  item,
  componentName,
});

export const listHasAllPageItemsSelectedAC = (bool, componentName) => ({
  type: LIST_HAS_ALL_PAGE_ITEMS_SELECTED,
  componentName,
  pageItemsSelected: bool,
});

export const listRemoveSelectedItemsAC = (itemIds, componentName) => ({
  type: LIST_REMOVE_SELECTED_ITEMS,
  itemIds: Array.isArray(itemIds) ? itemIds : [itemIds],
  componentName,
});

export const listResetSelectItemsAC = componentName => ({
  type: LIST_RESET_SELECTED_ITEMS,
  componentName,
});

export const listUpdateItemAC = (id, values, componentName) => ({
  type: LIST_UPDATE_ITEM,
  id,
  values,
  componentName,
});

export const listResetAC = componentName => ({
  type: LIST_RESET,
  componentName,
});

// Data services
export const fetchListDS = ({
  url, querystring = '', componentName = '', authedAxios = null, extrasKey = null, validate,
}) => (
  fetchDataDS({
    authedAxios,
    validate,
    fetchApiUrl: () => url,
    fetchDataAC: responseData => (extrasKey
      ? listFetchExtrasAC(responseData, componentName, extrasKey)
      : listFetchAC(responseData, componentName)
    ),
    querystring,
    componentName,
    paginationAC: paginationFetchAC,
    searchAC: searchFetchAC,
  })
);

export default reducer;
