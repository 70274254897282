import { isEmpty, isNil, mapValues, pick, reduce, uniq } from 'lodash';
import { CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import { EXCHANGE_RATE_SERVICE, TAX_METHOD, SYSTEM_CURRENCY, TRANSACTION_MODE } from 'finance/assets/js/constants';
import InvoiceItemAmountsAggregate from 'finance/assets/js/lib/InvoiceItemAmountsAggregate';
import InvoiceFeeAnalysis from 'finance/assets/js/lib/InvoiceFeeAnalysis';
import RateMap, { Conversion } from 'finance/assets/js/lib/RateMap';
import InvoiceRateMap from 'finance/assets/js/lib/InvoiceRateMap';
import { assertAllKeysPresent } from 'core/assets/js/lib/utils';
import { calcExchangeRateTotal } from 'finance/assets/js/lib/utils';


/* A class to handle all amount calculations around invoices
 *
 *
 * amount: the amount stated on the respective worksheets
 * billable: the amount on which we are supposed to charge fees
 *
 *
 */
class InvoiceAmounts {
  static _aggregate(others) {
    if (isEmpty(others)) {
      throw new Error('cannot create invoice amounts instance from others without any provided');
    }
    const aggregate = reduce(others.slice(1), (sum, n) => sum.plus(n), others[0]);
    return aggregate;
  }

  static fromInvoiceItemAmountsAggregate(
    aggregate, aggregateInOrgCurrency,
    {
      invoiceRateMap,
      exchangeRateMarkup,
      exchangeRateSource,
      taxCode,
      vatPercent,
      taxMethod,
    },
  ) {
    assertAllKeysPresent({
      vatPercent, taxMethod,
      exchangeRateSource, exchangeRateMarkup,
    });

    const {
      currency, orgCurrency, balanceCurrency, targetCurrency, systemCurrency,
      invoiceToTargetRate, invoiceToOrgRate, invoiceToBalanceRate, invoiceToSystemRate,
    } = invoiceRateMap.serialize();

    if (!aggregate || !(aggregate instanceof InvoiceItemAmountsAggregate)) {
      throw new Error('aggregate is required');
    }

    if (aggregate.getCurrency() !== currency) {
      throw new Error('aggregates and invoice have different currency');
    }

    if (!aggregateInOrgCurrency || !(
      aggregateInOrgCurrency instanceof InvoiceItemAmountsAggregate
    )) {
      throw new Error('org aggregate is required');
    }

    if (aggregateInOrgCurrency.getCurrency() !== orgCurrency) {
      throw new Error('aggregates and invoice have different org currency');
    }

    return new InvoiceAmounts({
      invoiceItemAmountsAggregate: aggregate,
      invoiceItemAmountsAggregateInOrgCurrency: aggregateInOrgCurrency,
      vatPercent, taxCode, taxMethod,
      balanceCurrency,

      exchangeRateSource,
      targetCurrency: targetCurrency.toLowerCase(),
      exchangeRateMarkup,
      exchangeRate: invoiceToTargetRate,
      systemCurrency,
      systemCurrencyExchangeRate: invoiceToSystemRate,
      orgCurrencyExchangeRate: invoiceToOrgRate,
      balanceCurrencyExchangeRate: invoiceToBalanceRate,
    });
  }

  static zero(currency) {
    return InvoiceAmounts.fromInvoiceItemAmountsAggregate(
      InvoiceItemAmountsAggregate.zero(currency),
      InvoiceItemAmountsAggregate.zero(currency),
      {
        invoiceRateMap: InvoiceRateMap.fromIdentity(currency),
        vatPercent: 0,
        taxMethod: TAX_METHOD.SUBTOTAL,
        exchangeRateSource: EXCHANGE_RATE_SERVICE.TRANSFERWISE,
        exchangeRateMarkup: 0,
      },
    );
  }

  static fromSerializedTransactionInvoiceAmounts(ser) {
    const invoicedMoney = new Money(ser.total, ser.currency);
    const invoiceRateMap = new InvoiceRateMap(ser, { testAgainstMoney: invoicedMoney });
    const {
      invoiceToTargetRate, invoiceToOrgRate, invoiceToBalanceRate, invoiceToSystemRate,
      currency, balanceCurrency, orgCurrency, targetCurrency, systemCurrency,
    } = invoiceRateMap.serialize();
    const total = invoicedMoney.toString();
    const baseAmounts = {
      amount: total,
      credit: total,
      contractorCharges: total,
      billable: '0.00',
    };
    const inOrgCurrency = {
      currency: orgCurrency,
      ...mapValues(baseAmounts, v => new Money(v, currency).convert(
        orgCurrency,
        { rate: invoiceToOrgRate },
      ).toString()),
    };
    return new InvoiceAmounts({
      currency, balanceCurrency, orgCurrency, targetCurrency, systemCurrency,
      exchangeRate: invoiceToTargetRate,
      balanceCurrencyExchangeRate: invoiceToBalanceRate,
      orgCurrencyExchangeRate: invoiceToOrgRate,
      systemCurrencyExchangeRate: invoiceToSystemRate,
      ...baseAmounts,
      inOrgCurrency,
      vatPercent: 0,
    });
  }

  static fromSubscriptionFee(
    fee, charges, currency, periodStart, periodEnd,
    quantity, subscriptionId, plan, vat,
  ) {
    return new InvoiceAmounts({
      amount: '0.00',
      credit: '0.00',
      billable: '0.00',
      contractorCharges: '0.00',
      vatPercent: 0,
      fixedVat: vat,
      currency,
      orgCurrency: currency,
      targetCurrency: currency,
      balanceCurrency: 'gbp',
      subscriptionFee: new Money(fee, currency).toString(),
      feeAnalysis: {
        subscriptionFee: [{
          currency,
          subscriptionFee: new Money(fee, currency).toString(),
          periodStart,
          periodEnd,
          subscriptionId,
          quantity,
          plan,
          paymentProviderFee: new Money(charges, currency).toString(),
          vat: new Money(vat, currency).toString(),
        }],
      },
    });
  }

  static parseInOrgCurrency({ serializedAggregate, billable, orgCurrencyExchangeRate }) {
    if (!serializedAggregate) {
      throw new Error('serializedAggregate is required');
    }
    return new InvoiceItemAmountsAggregate({
      currency: serializedAggregate.currency,
      serviceOrderTotal: serializedAggregate.amount,
      credit: serializedAggregate.credit,
      billable: serializedAggregate.billable
        || new Money(billable, serializedAggregate.currency)
          .mul(orgCurrencyExchangeRate).toString(),
      charges: serializedAggregate.contractorCharges,
    });
  }

  constructor(props) {
    if (props instanceof InvoiceAmounts) {
      return props;
    }
    this.init(props);
  }

  init({
    amount, credit, billable, contractorCharges,
    currency, targetCurrency, salesTaxRecharge = 0, salesTaxRechargeAnalysis = [],
    exchangeRate = 1, exchangeRateSource = EXCHANGE_RATE_SERVICE.TRANSFERWISE,
    exchangeRateMarkup = 0, bankFee = 0, bankFeeMarkup = 0,
    childrenBankFees = 0, childrenBankFeesAnalysis = [],
    processingFee = 0, licenceFee = 0, fee,
    subscriptionFee = 0,
    feeAnalysis, systemCurrencyExchangeRate,
    orgCurrencyExchangeRate, orgCurrency,
    balanceCurrencyExchangeRate, balanceCurrency,
    taxMethod = TAX_METHOD.SUBTOTAL, vatPercent, fixedVat, internalVatPercent, taxCode,
    appliedInvoiceItemVat,
    inOrgCurrency,
    invoiceItemAmountsAggregate, invoiceItemAmountsAggregateInOrgCurrency,
  } = {}) {
    if (invoiceItemAmountsAggregate) {
      this._invoiceItemAmountsAggregate = invoiceItemAmountsAggregate;
    } else {
      if (!currency) {
        throw new Error('currency is required');
      }
      if (!amount) {
        throw new Error('amount is required');
      }
      if (!credit) {
        throw new Error('credit is required');
      }
      if (!billable) {
        throw new Error('billable is required');
      }
      if (!contractorCharges) {
        throw new Error('contractorCharges is required');
      }
      if (taxMethod === TAX_METHOD.PER_LINE_ITEM && !appliedInvoiceItemVat) {
        throw new Error('appliedInvoiceItemVat is required');
      }
      this._invoiceItemAmountsAggregate = new InvoiceItemAmountsAggregate({
        currency,
        serviceOrderTotal: amount,
        credit,
        billable,
        charges: contractorCharges,
        appliedInvoiceItemVat: appliedInvoiceItemVat || '0.00',
      });
    }

    if (invoiceItemAmountsAggregateInOrgCurrency) {
      this._invoiceItemAmountsAggregateInOrgCurrency = invoiceItemAmountsAggregateInOrgCurrency;
    } else if (currency === orgCurrency) {
      this._invoiceItemAmountsAggregateInOrgCurrency = InvoiceItemAmountsAggregate.copy(
        this._invoiceItemAmountsAggregate,
      );
    } else {
      this._invoiceItemAmountsAggregateInOrgCurrency = InvoiceAmounts.parseInOrgCurrency({
        serializedAggregate: inOrgCurrency, billable, orgCurrencyExchangeRate,
      });
    }

    if (isNil(exchangeRate)) {
      throw new Error('missing exchange rate');
    }
    if (isNil(vatPercent)) {
      throw new Error('missing vat percent');
    }
    if (isNil(childrenBankFees)) {
      throw new Error('missing children bank fees');
    }
    if (isNil(licenceFee)) {
      throw new Error('missing licence fee');
    }
    if (fee) {
      if ((!processingFee || !licenceFee) && !subscriptionFee) {
        throw new Error('Expected both processingFee and licenceFee in invoiceAmounts when fee exists');
      }
    }

    const moneyCurrency = this._invoiceItemAmountsAggregate.getCurrency();

    this.targetCurrency = targetCurrency || this._invoiceItemAmountsAggregate.getCurrency();
    this.exchangeRate = parseFloat(exchangeRate);
    this.exchangeRateSource = exchangeRateSource;
    this.salesTaxRecharge = new Money(salesTaxRecharge, moneyCurrency).toString();
    this.salesTaxRechargeAnalysis = salesTaxRechargeAnalysis
      .map(info => ({
        ...info,
        salesTax: new Money(info.salesTax, moneyCurrency).toString(),
      }));

    this.systemCurrencyExchangeRate = parseFloat(systemCurrencyExchangeRate || '1');
    this.orgCurrencyExchangeRate = parseFloat(orgCurrencyExchangeRate || '1');
    this.balanceCurrencyExchangeRate = parseFloat(
      balanceCurrencyExchangeRate || this.orgCurrencyExchangeRate,
    );
    this.taxCode = taxCode;

    // Bank fees
    this.bankFee = new Money(bankFee, moneyCurrency).toString();
    this.bankFeeMarkup = new Money(bankFeeMarkup, moneyCurrency).toString();
    this.childrenBankFees = new Money(childrenBankFees, moneyCurrency).toString();
    this.childrenBankFeesAnalysis = childrenBankFeesAnalysis;

    // Talentdesk fees
    this.licenceFee = new Money(licenceFee, moneyCurrency).toString();
    if (processingFee) {
      this.processingFee = processingFee;
    } else {
      this.processingFee = '0.00';
    }
    this.subscriptionFee = subscriptionFee ? new Money(subscriptionFee, moneyCurrency).toString() : '0.00';
    this.feeAnalysis = new InvoiceFeeAnalysis(feeAnalysis);

    if (isNil(taxMethod)) {
      throw new Error('taxMethod is required');
    }

    this.taxMethod = parseInt(taxMethod, 10);

    this.fixedVat = fixedVat;
    // keep the internal vat percent calculated previously or use the new one
    this.internalVatPercent = parseFloat(
      isNil(internalVatPercent) ? vatPercent : internalVatPercent,
    );
    // calculate the vat percent on fixedVat or use the one calculated previously
    this.vatPercent = fixedVat
      ? new Money(fixedVat, moneyCurrency).div(this.netValue).mul(100).toNumber()
      : this.internalVatPercent;
    if (this.vatPercent > 100) {
      throw new Error(`vat percent cannot be over 100% (${vatPercent})`);
    }

    // no exchange rate markup for single currency
    this.exchangeRateMarkup = parseFloat(
      this.targetCurrency !== this.getCurrency() ? exchangeRateMarkup : 0,
    );

    // deprecated
    this.currency = this.inInvoiceCurrency.currency;
    this.amount = this.inInvoiceCurrency.amount;
    this.billable = this.inInvoiceCurrency.billable;
    this.credit = this.inInvoiceCurrency.credit;
    this.orgCurrency = orgCurrency || this._invoiceItemAmountsAggregateInOrgCurrency.getCurrency();
    this.balanceCurrency = balanceCurrency || this.orgCurrency;
  }

  isZero() {
    return new Money(this.total, this._invoiceItemAmountsAggregate.getCurrency()).isZero();
  }

  reinit(params) {
    this.init({
      ...this.serialize(),
      ...params,
    });
  }

  setFixedVat(fixedVat) {
    this.fixedVat = fixedVat;
    // calculate the vat percent on fixedVat or use the one calculated previously
    this.internalVatPercent = this.vatPercent;
    const moneyCurrency = this._invoiceItemAmountsAggregate.getCurrency();
    this.vatPercent = new Money(fixedVat, moneyCurrency)
      .div(this.netValue)
      .mul(100)
      .toNumber();
    if (this.vatPercent > 100) {
      throw new Error(`vat percent cannot be over 100% (${this.vatPercent})`);
    }
  }

  resetVatPercent(vatPercent) {
    this.fixedVat = null;
    // keep the internal vat percent calculated previously or use the new one
    this.vatPercent = parseFloat(vatPercent);
    this.internalVatPercent = this.vatPercent;
    if (this.vatPercent > 100) {
      throw new Error(`vat percent cannot be over 100% (${this.vatPercent})`);
    }
  }

  serialize() {
    return {
      ...this.inInvoiceCurrency,
      ...pick(this, [
        // vat
        'taxMethod', 'vatPercent', 'fixedVat', 'internalVatPercent', 'taxCode',
        'appliedInvoiceItemVat',
        'salesTaxRechargeAnalysis', 'internalVat',

        // fees
        'licenceFee', 'processingFee', 'subscriptionFee',

        // bank fees
        'bankFee', 'bankFeeMarkup', 'bankFeeTotal',
        'childrenBankFees', 'childrenBankFeesAnalysis', 'childrenBankVat',

        // in target currency
        'targetCurrency', 'exchangeRate', 'exchangeRateSource', 'exchangeRateMarkup',
        'exchangeRateTotal', 'targetTotal',

        // in system currency
        'systemCurrencyExchangeRate',

        // in org currency
        'orgCurrency', 'orgCurrencyExchangeRate',

        // in balance currency
        'balanceCurrency', 'balanceCurrencyExchangeRate',
      ]),
      // in other currencies
      inOrgCurrency: this._getInOrgCurrency(),
      feeAnalysis: this.feeAnalysis.serialize(),
    };
  }

  get fee() {
    const { processingFee, licenceFee, subscriptionFee } = this;
    const moneyCurrency = this._invoiceItemAmountsAggregate.getCurrency();
    return new Money(processingFee, moneyCurrency)
      .add(licenceFee)
      .add(subscriptionFee)
      .toString();
  }

  get netValue() {
    return this.inInvoiceCurrency.netValue;
  }

  get vat() {
    return this.inInvoiceCurrency.vat;
  }

  _getFees() {
    const { fee, salesTaxRecharge, childrenBankFees } = this;
    const moneyCurrency = this._invoiceItemAmountsAggregate.getCurrency();
    return {
      fee, salesTaxRecharge, childrenBankFees,
      total: new Money(fee, moneyCurrency)
        .add(salesTaxRecharge)
        .add(childrenBankFees)
        .toString(),
    };
  }

  _getFeesVat() {
    const { internalVatPercent } = this;
    const taxMethod = this.getTaxMethod();

    const moneyCurrency = this._invoiceItemAmountsAggregate.getCurrency();

    const vatFactor = internalVatPercent / 100;
    const { total: totalFees, fee, salesTaxRecharge, childrenBankFees } = this._getFees();
    if (taxMethod === TAX_METHOD.PER_LINE_ITEM) {
      // calculate vat on each individual fee, then sum them up
      const feeVat = new Money(fee, moneyCurrency).mul(vatFactor);
      const salesTaxRechargeVat = new Money(salesTaxRecharge, moneyCurrency).mul(vatFactor);
      const childrenBankFeesVat = new Money(childrenBankFees, moneyCurrency).mul(vatFactor);
      return new Money(feeVat, moneyCurrency)
        .add(salesTaxRechargeVat)
        .add(childrenBankFeesVat)
        .toString();
    }
    return new Money(totalFees, moneyCurrency).mul(vatFactor).toString();
  }

  _getEstimatedVat() {
    const { _invoiceItemAmountsAggregate, internalVatPercent } = this;
    const taxMethod = this.getTaxMethod();

    const moneyCurrency = this._invoiceItemAmountsAggregate.getCurrency();

    const vatFactor = internalVatPercent / 100;
    let creditVat;
    const credit = _invoiceItemAmountsAggregate.getCredit();
    const appliedInvoiceItemVat = _invoiceItemAmountsAggregate.getAppliedVat();
    if (taxMethod === TAX_METHOD.PER_LINE_ITEM) {
      creditVat = appliedInvoiceItemVat;
    } else {
      creditVat = new Money(credit, moneyCurrency).mul(vatFactor).toString();
    }

    const { total: totalFees } = this._getFees();
    const feesVat = new Money(totalFees, moneyCurrency).mul(vatFactor).toString();

    return new Money(creditVat, moneyCurrency).add(feesVat).toString();
  }

  get inInvoiceCurrency() {
    const { _invoiceItemAmountsAggregate, fixedVat } = this;
    const invoiceAmounts = _invoiceItemAmountsAggregate.toInvoiceAmounts();
    const credit = _invoiceItemAmountsAggregate.getCredit();
    const appliedInvoiceItemVat = _invoiceItemAmountsAggregate.getAppliedVat();

    const moneyCurrency = this._invoiceItemAmountsAggregate.getCurrency();

    const { total: totalFees, fee, salesTaxRecharge, childrenBankFees } = this._getFees();

    const internalVat = this._getEstimatedVat();
    const vat = fixedVat ? new Money(fixedVat, moneyCurrency).toString() : internalVat;

    const netValue = new Money(credit, moneyCurrency).add(totalFees).toString();
    const total = new Money(netValue, moneyCurrency).add(vat).toString();

    return {
      ...invoiceAmounts,
      fee,
      salesTaxRecharge,
      childrenBankFees,
      netValue,
      total,
      vat,
      internalVat,
      appliedInvoiceItemVat,
    };
  }

  _getInAnotherCurrency(otherCurrency, rate) {
    const {
      currency, amount, contractorCharges, credit,
      fee, salesTaxRecharge, childrenBankFees, netValue,
      vat, total,
    } = this.inInvoiceCurrency;
    return {
      currency: otherCurrency,
      exchangeRate: rate,
      amount: new Money(amount, currency).convert(otherCurrency, { rate }).toString(),
      contractorCharges: new Money(contractorCharges, currency).convert(
        otherCurrency, { rate },
      ).toString(),
      credit: new Money(credit, currency).convert(otherCurrency, { rate }).toString(),
      fee: new Money(fee, currency).convert(otherCurrency, { rate }).toString(),
      salesTaxRecharge: new Money(salesTaxRecharge, currency).convert(
        otherCurrency, { rate },
      ).toString(),
      childrenBankFees: new Money(childrenBankFees, currency).convert(
        otherCurrency, { rate },
      ).toString(),
      netValue: new Money(netValue, currency).convert(otherCurrency, { rate }).toString(),
      vat: new Money(vat, currency).convert(otherCurrency, { rate }).toString(),
      total: new Money(total, currency).convert(otherCurrency, { rate }).toString(),
    };
  }

  _getInOrgCurrency() {
    const { orgCurrency, orgCurrencyExchangeRate } = this;
    const amounts = this._getInAnotherCurrency(orgCurrency, orgCurrencyExchangeRate);
    const aggregate = this._invoiceItemAmountsAggregateInOrgCurrency.toInvoiceAmounts();

    return {
      ...amounts,
      ...pick(aggregate, ['contractorCharges']),
    };
  }

  get inTargetCurrency() {
    const { targetCurrency, exchangeRateTotal } = this;
    return this._getInAnotherCurrency(targetCurrency, exchangeRateTotal);
  }

  get inBalanceCurrency() {
    const { orgCurrency, balanceCurrency, balanceCurrencyExchangeRate } = this;
    if (orgCurrency === balanceCurrency) {
      return this._getInOrgCurrency();
    }
    return this._getInAnotherCurrency(balanceCurrency, balanceCurrencyExchangeRate);
  }

  get inSystemCurrency() {
    const { systemCurrencyExchangeRate } = this;
    return this._getInAnotherCurrency(SYSTEM_CURRENCY, systemCurrencyExchangeRate);
  }

  get systemCurrencyTotal() {
    return this.inSystemCurrency.total;
  }

  get exchangeRateTotal() {
    const { exchangeRateMarkup, exchangeRate } = this;
    return calcExchangeRateTotal(exchangeRate, exchangeRateMarkup);
  }

  get total() {
    return this.inInvoiceCurrency.total;
  }

  get bankFeeTotal() {
    const { bankFee, bankFeeMarkup } = this;
    const moneyCurrency = this._invoiceItemAmountsAggregate.getCurrency();
    return new Money(bankFee, moneyCurrency).add(bankFeeMarkup).toString();
  }

  get targetTotal() {
    const { total, exchangeRateTotal, targetCurrency } = this;
    return new Money(total, targetCurrency).mul(exchangeRateTotal).toString();
  }

  get currencySymbol() {
    const currency = this._invoiceItemAmountsAggregate.getCurrency();
    return CURRENCY_SYMBOL[currency];
  }

  get targetCurrencySymbol() {
    const { targetCurrency } = this;
    return CURRENCY_SYMBOL[targetCurrency];
  }

  stringify() {
    return Object.entries(pick(this.inInvoiceCurrency, [
      'credit', 'billable', 'fee', 'vat', 'total',
    ])).map(([k, v]) => `${k}->${v}`).join(', ');
  }

  hasLicenceFee() {
    const { licenceFee } = this;
    return licenceFee
      && !new Money(licenceFee, this._invoiceItemAmountsAggregate.getCurrency()).isZero();
  }

  hasSubscriptionFee() {
    const { subscriptionFee } = this;
    return subscriptionFee
      && !new Money(subscriptionFee, this._invoiceItemAmountsAggregate.getCurrency()).isZero();
  }

  hasProcessingFee() {
    const { processingFee } = this;
    return processingFee
      && !new Money(processingFee, this._invoiceItemAmountsAggregate.getCurrency()).isZero();
  }


  hasManagersLicenceFee() {
    return !new Money(
      this.getManagersLicenceFeeInOrgCurrency(),
      this._invoiceItemAmountsAggregate.getCurrency(),
    ).isZero();
  }

  hasProvidersLicenceFee() {
    const currency = this._invoiceItemAmountsAggregate.getCurrency();
    const providersLicenceFeeInOrgCurrency = this.getProvidersLicenceFeeInOrgCurrency();
    return !new Money(providersLicenceFeeInOrgCurrency, currency).isZero();
  }

  hasFeeForServiceKey(serviceKey) {
    return !new Money(
      this.getFeeInOrgCurrencyForServiceKey(serviceKey),
      this._invoiceItemAmountsAggregate.getCurrency(),
    ).isZero();
  }

  hasLicenceBaseFee() {
    return !new Money(
      this.getLicenceBaseFeeInOrgCurrency(),
      this._invoiceItemAmountsAggregate.getCurrency(),
    ).isZero();
  }

  _getCurrencies() {
    const {
      currency: invoiceCurrency, balanceCurrency, orgCurrency, targetCurrency,
    } = this;

    return {
      invoiceCurrency, balanceCurrency, orgCurrency, targetCurrency,
      systemCurrency: this.getSystemCurrency(),
    };
  }

  /**
   * Given any new set of FX rates, pick the rates between the balance currency
   * used for a transaction and all other currencies used in our system,
   * e.g. the org currency, the invoice currency, the system currency, etc.
   *
   * @param {currency} sourceCurrency - the source currency of the transaction
   * @param {currency} targetCurrency - the target currency of the transaction
   * @param {Object<currency, number>} currentBalanceRates - an arbitrary rate map
   *                                                         between the source currency
   *                                                         and other currencies
   * @param {number} transactionRate - an actual rate between sourceCurrency and targetCurrency
   *                                   offered in any transaction
   * @return {Object} a limited map of rates picked between balance currency and other currencies
   *
   */
  pickBalanceFXRates({
    sourceCurrency, targetCurrency, currentBalanceRates, transactionRate,
  }) {
    const { invoiceCurrency, orgCurrency, systemCurrency } = this._getCurrencies();
    assertAllKeysPresent({
      sourceCurrency, targetCurrency, currentBalanceRates,
      invoiceCurrency, orgCurrency, systemCurrency,
    });
    let rateMap = new RateMap([
      new Conversion(
        sourceCurrency, currentBalanceRates[invoiceCurrency.toUpperCase()], invoiceCurrency,
      ),
      new Conversion(
        sourceCurrency, currentBalanceRates[targetCurrency.toUpperCase()], targetCurrency,
      ),
      new Conversion(
        sourceCurrency, currentBalanceRates[orgCurrency.toUpperCase()], orgCurrency,
      ),
      new Conversion(
        sourceCurrency, currentBalanceRates[systemCurrency.toUpperCase()], systemCurrency,
      ),
    ]);
    if (transactionRate) {
      // regardless of any other conversions offered by third parties,
      // we know for a fact the conversion rate offered for a particular
      // transaction that has already happened
      // we can extend our rate map to prefer that explicit conversion
      rateMap = rateMap.addConversion(new Conversion(
        sourceCurrency, transactionRate, targetCurrency,
      ));
    }
    const res = {
      balanceToTargetRate: rateMap.getRate(sourceCurrency, targetCurrency),
      balanceToInvoiceRate: rateMap.getRate(sourceCurrency, invoiceCurrency),
      balanceToOrgRate: rateMap.getRate(sourceCurrency, orgCurrency),
      balanceToSystemRate: rateMap.getRate(sourceCurrency, systemCurrency),
    };
    Object.entries(res).forEach(([key, value]) => {
      if (!value) {
        throw new Error(`${key} is required`);
      }
    });
    return res;
  }

  calcOrgRateFromOrgServiceOrderAmount({ transactionMode, orgServiceOrderAmount }) {
    if (transactionMode !== TRANSACTION_MODE.FIXED_SO_AMOUNT) {
      return null;
    }
    if (!orgServiceOrderAmount) {
      throw new Error('orgServiceOrderAmount is required');
    }
    if (this.getCurrency() === this.getOrgCurrency()) {
      return 1;
    }
    const orgAmount = new Money(orgServiceOrderAmount, this.getOrgCurrency());
    const contractorCharges = new Money(
      this.getContractorCharges(),
      this.getCurrency(),
    );
    if (contractorCharges.isZero() || orgAmount.isZero()) {
      return null;
    }

    return contractorCharges.getRate(orgAmount);
  }

  getAmountsForOrg() {
    const currency = this.getCurrency();
    const orgCurrency = this.getOrgCurrency();
    const netValue = new Money(this.netValue, currency);
    const vat = new Money(this.vat, currency);
    const total = new Money(this.getTotal(), currency);
    const netValueInOrgCurrency = new Money(this.getOrgNetValue(), orgCurrency);
    const vatInOrgCurrency = new Money(this.getOrgVat(), orgCurrency);
    const totalInOrgCurrency = new Money(this.getOrgTotal(), orgCurrency);

    return {
      currency,
      netValue: netValue.toString(),
      vat: vat.toString(),
      total: total.toString(),
      orgCurrency,
      orgNetValue: netValueInOrgCurrency.toString(),
      orgVat: vatInOrgCurrency.toString(),
      orgTotal: totalInOrgCurrency.toString(),
    };
  }

  // Single field getters
  getCurrency() {
    return this.inInvoiceCurrency.currency;
  }

  getOrgCurrency() {
    return this.orgCurrency;
  }

  getBalanceCurrency() {
    return this.balanceCurrency;
  }

  getTargetCurrency() {
    return this.inTargetCurrency.currency;
  }

  getSystemCurrency() {
    return this.inSystemCurrency.currency;
  }

  getCredit() {
    return this.inInvoiceCurrency.credit;
  }

  getNetValue() {
    return this.inInvoiceCurrency.netValue;
  }

  getBillable() {
    return this.inInvoiceCurrency.billable;
  }

  getFee() {
    return this.inInvoiceCurrency.fee;
  }

  getVat() {
    return this.inInvoiceCurrency.vat;
  }

  getSalesTaxRecharge() {
    return this.inInvoiceCurrency.salesTaxRecharge;
  }

  getSalesTaxRechargeAnalysis() {
    return this.salesTaxRechargeAnalysis;
  }

  getChildrenBankFees() {
    return this.inInvoiceCurrency.childrenBankFees;
  }

  getChildrenBankFeesAnalysis() {
    return this.childrenBankFeesAnalysis;
  }

  getTotal() {
    return this.inInvoiceCurrency.total;
  }

  getContractorCharges() {
    return this.inInvoiceCurrency.contractorCharges;
  }

  getOrgNetValue() {
    return this._getInOrgCurrency().netValue;
  }

  getExchangeRateInOrgCurrency() {
    return this._getInOrgCurrency().exchangeRate;
  }

  getOrgVat() {
    return this._getInOrgCurrency().vat;
  }

  getOrgTotal() {
    return this._getInOrgCurrency().total;
  }

  getOrgContractorCharges() {
    return this._getInOrgCurrency().contractorCharges;
  }

  getTargetVat() {
    return this.inTargetCurrency.vat;
  }

  getTargetTotal() {
    return this.inTargetCurrency.total;
  }

  getSystemTotal() {
    return this.inSystemCurrency.total;
  }

  getOrgRate() {
    return this.orgCurrencyExchangeRate;
  }

  getBalanceRate() {
    return this.balanceCurrencyExchangeRate;
  }

  getBalanceTotal() {
    return this.inBalanceCurrency.total;
  }

  getTargetRate() {
    return this.inTargetCurrency.exchangeRate;
  }

  getSystemRate() {
    return this.inSystemCurrency.exchangeRate;
  }

  getExchangeRate() {
    const { exchangeRateTotal } = this;
    return exchangeRateTotal;
  }

  getSpotExchangeRate() {
    const { exchangeRate } = this;
    return exchangeRate;
  }

  getExchangeRateMarkup() {
    const { exchangeRateMarkup } = this;

    return exchangeRateMarkup;
  }

  /**
   * Get source for invoice amounts exchange rate.
   * @return {EXCHANGE_RATE_SERVICE} source of exchange rate.
   */
  getExchangeRateSource() {
    return this.exchangeRateSource || EXCHANGE_RATE_SERVICE.TRANSFERWISE;
  }

  getTaxMethod() {
    return this.taxMethod;
  }

  getVatPercent() {
    return this.vatPercent;
  }

  getBankFee() {
    return this.bankFeeTotal;
  }

  getLicenceFee() {
    return this.licenceFee;
  }

  getSubscriptionFee() {
    return this.subscriptionFee;
  }

  getProcessingFeeAnalysis() {
    return this.feeAnalysis.getProcessingFeeAnalysis();
  }

  getLicenceFeeAnalysis() {
    return this.feeAnalysis.getLicenceFeeAnalysis();
  }

  getSubscriptionFeeAnalysis() {
    return this.feeAnalysis.getSubscriptionFeeAnalysis();
  }

  // Complex getters

  getUsedCurrencies() {
    return uniq([
      this.getCurrency(),
      this.getTargetCurrency(),
      this.getOrgCurrency(),
      this.getBalanceCurrency(),
      this.getSystemCurrency(),
    ]).sort();
  }

  getRates() {
    const currency = this.getCurrency();
    const targetCurrency = this.getTargetCurrency();
    const orgCurrency = this.getOrgCurrency();
    const balanceCurrency = this.getBalanceCurrency();
    const invoiceToTargetRate = this.getTargetRate();
    const invoiceToOrgRate = this.getOrgRate();
    const invoiceToSystemRate = this.getSystemRate();
    const invoiceToBalanceRate = this.getBalanceRate();

    return {
      invoiceToTargetRate: {
        code: `${currency.toUpperCase()}${targetCurrency.toUpperCase()}`,
        rate: invoiceToTargetRate,
      },
      invoiceToOrgRate: {
        code: `${currency.toUpperCase()}${orgCurrency.toUpperCase()}`,
        rate: invoiceToOrgRate,
      },
      invoiceToBalanceRate: {
        code: `${currency.toUpperCase()}${balanceCurrency.toUpperCase()}`,
        rate: invoiceToBalanceRate,
      },
      invoiceToSystemRate: {
        code: `${currency.toUpperCase()}${SYSTEM_CURRENCY.toUpperCase()}`,
        rate: invoiceToSystemRate,
      },
    };
  }

  getManagersLicenceFee() {
    const { licenceFee, feeAnalysis } = this;
    if (new Money(licenceFee, this._invoiceItemAmountsAggregate.getCurrency()).isZero()
      || feeAnalysis.hasEmptyLicenceFee()) {
      // return zero
      return new Money(0, this.getCurrency()).toString();
    }

    return feeAnalysis.getLicenceManagersFee();
  }

  getProvidersLicenceFee() {
    const { licenceFee, feeAnalysis } = this;
    const fee = new Money(licenceFee, this.getCurrency());
    if (fee.isZero() || feeAnalysis.hasEmptyLicenceFee()) {
      // return zero
      return new Money(0, this.getCurrency()).toString();
    }

    return feeAnalysis.getLicenceProvidersFee();
  }

  getFeeForServiceKey(serviceKey) {
    const { licenceFee, feeAnalysis } = this;
    if (new Money(licenceFee, this._invoiceItemAmountsAggregate.getCurrency()).isZero()
      || feeAnalysis.hasEmptyLicenceFee()) {
      // return zero
      return new Money(0, this.getCurrency()).toString();
    }

    return feeAnalysis
      .getLicenceFeeAnalysis()
      .getAnalysisForServiceKey(serviceKey).getTotal();
  }

  getLicenceBaseFee() {
    const { licenceFee, feeAnalysis } = this;
    if (new Money(licenceFee, this._invoiceItemAmountsAggregate.getCurrency()).isZero()
      || feeAnalysis.hasEmptyLicenceFee()) {
      // return zero
      return new Money(0, this.getCurrency()).toString();
    }

    return feeAnalysis.getLicenceBaseFee();
  }

  getManagersLicenceFeeInOrgCurrency() {
    const { orgCurrencyExchangeRate } = this;
    const moneyCurrency = this.getOrgCurrency();
    return new Money(this.getManagersLicenceFee(), moneyCurrency)
      .mul(orgCurrencyExchangeRate)
      .toString();
  }

  getProvidersLicenceFeeInOrgCurrency() {
    const { orgCurrencyExchangeRate } = this;
    const moneyCurrency = this.getOrgCurrency();
    return new Money(this.getProvidersLicenceFee(), moneyCurrency)
      .mul(orgCurrencyExchangeRate)
      .toString();
  }

  getFeeInOrgCurrencyForServiceKey(serviceKey) {
    const { orgCurrencyExchangeRate } = this;
    const moneyCurrency = this.getOrgCurrency();
    return new Money(this.getFeeForServiceKey(serviceKey), moneyCurrency)
      .mul(orgCurrencyExchangeRate)
      .toString();
  }

  getLicenceBaseFeeInOrgCurrency() {
    const { orgCurrencyExchangeRate } = this;
    const moneyCurrency = this.getOrgCurrency();
    return new Money(this.getLicenceBaseFee(), moneyCurrency)
      .mul(orgCurrencyExchangeRate)
      .toString();
  }

  /**
   * Get the total money of the invoice
   *
   * @returns {Money} the total money of the invoice
   */
  getInvoicedMoney() {
    return new Money(this.getTotal(), this.getCurrency());
  }

  /**
   * Get invoice amounts in org currency
   *
   * @returns {Money} the invoice's amounts in org currency money
   */
  getOrgMoney() {
    return this.getMoneyInAllCurrencies().orgMoney;
  }

  getMoneyInAllCurrencies() {
    const rateMap = this.getInvoiceRateMap();
    const { orgCurrency, balanceCurrency, targetCurrency, systemCurrency } = this._getCurrencies();
    const invoiceCurrency = this.getCurrency();
    const invoiceMoney = new Money(this.getTotal(), invoiceCurrency);
    return {
      invoiceMoney,
      orgMoney: invoiceMoney.convert(
        orgCurrency, { rate: rateMap.getRate(invoiceCurrency, orgCurrency) },
      ),
      balanceMoney: invoiceMoney.convert(
        balanceCurrency, { rate: rateMap.getRate(invoiceCurrency, balanceCurrency) },
      ),
      targetMoney: invoiceMoney.convert(
        targetCurrency, { rate: rateMap.getRate(invoiceCurrency, targetCurrency) },
      ),
      systemMoney: invoiceMoney.convert(
        systemCurrency, { rate: rateMap.getRate(invoiceCurrency, systemCurrency) },
      ),
      serviceOrderMoney: new Money(this.getContractorCharges(), invoiceCurrency),
    };
  }

  /**
   * Get the rate map of the invoice
   *
   * @returns {InvoiceRateMap} the rate map of the invoice
   */
  getInvoiceRateMap() {
    return new InvoiceRateMap({
      currency: this.getCurrency(),
      balanceCurrency: this.getBalanceCurrency(),
      orgCurrency: this.getOrgCurrency(),
      targetCurrency: this.getTargetCurrency(),
      systemCurrency: this.getSystemCurrency(),
      invoiceToBalanceRate: this.getBalanceRate(),
      invoiceToTargetRate: this.getTargetRate(),
      invoiceToOrgRate: this.getOrgRate(),
      invoiceToSystemRate: this.getSystemRate(),
    }, { testAgainstMoney: this.getInvoicedMoney() });
  }
}

export default InvoiceAmounts;
