import PropTypes from 'prop-types';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';

// import CurrencySelectField from
// 'core/assets/js/components/FinalFormFields/CurrencySelectField.jsx';
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';
// import MoneyInputField from 'core/assets/js/components/FinalFormFields/MoneyInputField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import YesNoField from 'core/assets/js/components/FinalFormFields/YesNoField.jsx';
import TDFinalFormOnChange from 'core/assets/js/components/TDFinalFormOnChange.jsx';
import { API_DATE_FORMAT /* , CURRENCY_SYMBOL */ } from 'core/assets/js/constants';
import { parseDate } from 'core/assets/js/lib/utils';
import {
  DOCUMENT_CONTRACT_TERM,
  DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE,
  DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE_LABEL,
  DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE_VALUES,
  DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_LABEL,
  DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_VALUES,
  DOCUMENT_CONTRACT_TERM_END_TYPE,
  DOCUMENT_CONTRACT_TERM_END_TYPE_LABEL,
  DOCUMENT_CONTRACT_TERM_END_TYPE_VALUES,
  DOCUMENT_CONTRACT_TERM_VALUES,
  DOCUMENT_CONTRACT_TERM_LABEL,
  DOCUMENT_RENEWAL_TERM_TYPE_LABEL,
  DOCUMENT_RENEWAL_TERM_TYPE_VALUES,
  DOCUMENT_RENEWAL_TYPE,
  DOCUMENT_RENEWAL_TYPE_LABEL,
  DOCUMENT_RENEWAL_TYPE_VALUES,
  // DOCUMENT_TAX_TYPE,
  // DOCUMENT_TAX_TYPE_LABEL,
  // DOCUMENT_TAX_TYPE_VALUES,
} from 'documents/assets/js/constants';
import { sanitiseIntegerValues } from 'documents/assets/js/lib/utils';

const DocumentFormDetails = ({ versionHelperText, versionSubLabel }) => {
  const { change } = useForm();
  const { values } = useFormState();
  const {
    contractTerm,
    // currency,
    effectiveDate,
    effectiveType,
    endType,
    isRenewable,
    renewalType,
    // taxType,
  } = sanitiseIntegerValues(values);

  return (
    <>
      <h2>Details</h2>
      <h3 className="heading-block">General</h3>
      <TextInputField label="Title" name="title" required />
      <TextAreaField label="Description" name="description" />
      <div className="row align-items-end">
        <div className="col-12 col-sm-6">
          <TextInputField
            label="Version"
            name="version"
            required
            sublabel={versionSubLabel}
          />
          {versionHelperText && (
            <span className="d-inline-block mb-4" data-testid="document-form-version-helper-text">
              {versionHelperText}
            </span>
          )}
        </div>
      </div>
      <h3 className="heading-block">Terms</h3>
      <RadioField
        label="Contract term"
        name="contractTerm"
        options={DOCUMENT_CONTRACT_TERM_VALUES.map(value => ({
          text: DOCUMENT_CONTRACT_TERM_LABEL[value], value,
        }))}
        required
        showInline
      />
      <div className="row">
        <div className="col-12 col-sm-6">
          {DOCUMENT_CONTRACT_TERM_VALUES.includes(contractTerm) && (
            <>
              <SelectField
                label="Contract effective date"
                name="effectiveType"
                optionsMapping={DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE_VALUES.map(value => ({
                  text: DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE_LABEL[value], value,
                }))}
                required
              />
              {effectiveType === DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE.ON_SPECIFIC_DATE && (
                <DatePickerField name="effectiveDate" required />
              )}
            </>
          )}
        </div>
        <div className="col-12 col-sm-6">
          {contractTerm === DOCUMENT_CONTRACT_TERM.DEFINITE && (
            <>
              <SelectField
                label="Contract template end date"
                name="endType"
                optionsMapping={DOCUMENT_CONTRACT_TERM_END_TYPE_VALUES.map(value => ({
                  text: DOCUMENT_CONTRACT_TERM_END_TYPE_LABEL[value], value,
                }))}
                required
              />
              {endType === DOCUMENT_CONTRACT_TERM_END_TYPE.ON_SPECIFIC_DATE && (
                <DatePickerField
                  disableBeforeDate={effectiveDate && parseDate(effectiveDate, API_DATE_FORMAT)}
                  name="endDate"
                  required
                />
              )}
              {endType === DOCUMENT_CONTRACT_TERM_END_TYPE.RELATIVE_DATE && (
                <div className="row align-items-start">
                  <div className="col-8">
                    <InputNumberField
                      name="endRelativeValue"
                      min={0}
                      required
                      step={1}
                    />
                  </div>
                  <div className="col-4">
                    <SelectField
                      name="endRelativeType"
                      optionsMapping={DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_VALUES.map(
                        value => ({
                          text: DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_LABEL[value], value,
                        }),
                      )}
                      required
                    />
                  </div>
                </div>
              )}
              {[
                DOCUMENT_CONTRACT_TERM_END_TYPE.ON_CONDITION,
                DOCUMENT_CONTRACT_TERM_END_TYPE.ON_FULFILMENT,
              ].includes(endType) && (
                <TextAreaField name="endText" />
              )}
            </>
          )}
        </div>
      </div>
      <h3 className="heading-block">Renewal details</h3>
      <YesNoField
        label="Is contract renewable?"
        name="isRenewable"
        required
        showInline
        showTextInput={false}
      />
      {isRenewable?.isYes && (
        <div className="row">
          <div className="col-12 col-sm-4">
            <SelectField
              label="Renewal type"
              name="renewalType"
              optionsMapping={DOCUMENT_RENEWAL_TYPE_VALUES.map(value => ({
                text: DOCUMENT_RENEWAL_TYPE_LABEL[value], value,
              }))}
              required
            />
          </div>
          <div className="col-12 col-sm-8">
            {renewalType === DOCUMENT_RENEWAL_TYPE.EVERGREEN && (
              <div className="row align-items-start">
                <div className="col-6">
                  <InputNumberField
                    label="Renewal term"
                    name="renewalTermValue"
                    min={0}
                    required
                    step={1}
                  />
                </div>
                <div className="col-6">
                  <SelectField
                    label="Renewal term type" // needed so the field lines up with "Renewal term"
                    name="renewalTermType"
                    optionsMapping={DOCUMENT_RENEWAL_TERM_TYPE_VALUES.map(value => ({
                      text: DOCUMENT_RENEWAL_TERM_TYPE_LABEL[value], value,
                    }))}
                    required
                    wrapperClasses="hide-label"
                  />
                </div>
              </div>
            )}
            {renewalType === DOCUMENT_RENEWAL_TYPE.MANUAL && (
              <div className="d-flex flex-column">
                <InputNumberField
                  label="Renewal notice period"
                  name="renewalNoticePeriod"
                  min={0}
                  required
                  step={1}
                  suffix="days"
                />
                <InputNumberField
                  label="Renewal expiration reminder"
                  name="renewalExpirationReminder"
                  min={0}
                  required
                  step={1}
                  suffix="days"
                />
              </div>
            )}
          </div>
        </div>
      )}
      <TDFinalFormOnChange name="isRenewable">
        {value => {
          if (!value?.isYes) {
            // isRenewable is now false, so unset any set renewable values
            [
              'renewalExpirationReminder',
              'renewalNoticePeriod',
              'renewalTermType',
              'renewalTermValue',
              'renewalType',
            ].forEach(name => {
              if (![undefined, null].includes(values[name])) {
                change(name, null);
              }
            });
          }
        }}
      </TDFinalFormOnChange>

      { /* Commented out as part of PLAT-1132 Hide Criticality and Financial Information
      from Documents (https://talentdesk.atlassian.net/browse/PLAT-1132)
      <h3 className="heading-block">Financial details</h3>
      <div className="row">
        <div className="col-12 col-sm-5">
          <CurrencySelectField isClearable={false} label="Amount to receive" name="currency" />
        </div>
        <div className="col-12 col-sm-3">
          <MoneyInputField
            className="hide-label"
            label="Amount" // needed so the field lines up with "Amount to receive"
            name="amount"
            prefix={CURRENCY_SYMBOL[currency]}
          />
        </div>
        <div className="col-12 col-sm-4">
          <SelectField
            label="Tax"
            name="taxType"
            optionsMapping={DOCUMENT_TAX_TYPE_VALUES.map(value => ({
              text: DOCUMENT_TAX_TYPE_LABEL[value], value,
            }))}
          />
          {taxType === DOCUMENT_TAX_TYPE.PAYMENT_TERMS && (
            <TextAreaField name="taxTerms" />
          )}
        </div>
      </div>
      <h3 className="heading-block">Restrictions</h3>
      <RadioField
        data-testid="document-create-is-critical"
        name="isCritical"
        options={[
          {
            sublabel: 'Non-critical Documents do not block the recipient from accessing the'
                      + ' organisation workspace',
            text: 'Non-critical document',
            value: false,
          },
          {
            sublabel: 'Critical Documents require the recipient to maintain an active acceptance of'
                      + ' the Document\'s terms throughout the contract term. If the contract'
                      + ' requires renewal, the recipient will be blocked from the organisation'
                      + ' workspace until they have agreed to the new terms',
            text: 'Critical document',
            value: true,
          },
        ]}
        required
      />
      */}
    </>
  );
};

DocumentFormDetails.GetComponentName = () => 'DocumentFormDetails';

DocumentFormDetails.propTypes = {
  versionHelperText: PropTypes.string,
  versionSubLabel: PropTypes.string,
};

DocumentFormDetails.defaultProps = {
  versionHelperText: null,
  versionSubLabel: null,
};

export default DocumentFormDetails;
