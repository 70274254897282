import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Table, TableBody, TableCell, TableContainer, Paper, TableRow,
} from '@material-ui/core';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import TransactionAmounts from 'finance/assets/js/lib/TransactionAmounts';

const TransactionFormAmountsPreviewComponent = ({ amounts: serializedAmounts }) => {
  if (isEmpty(serializedAmounts.invoiceAmounts)) {
    return null;
  }

  const transactionAmounts = new TransactionAmounts(serializedAmounts);

  const {
    invoiceAmounts: {
      total: invoiceAmount,
      targetTotal: invoiceTargetAmount,
      currency: invoiceCurrency,
      targetCurrency: invoiceTargetCurrency,
    },
  } = serializedAmounts;

  const outgoingCurrency = transactionAmounts.getOutgoingCurrency();
  const outgoingAmount = transactionAmounts.getOutgoingAmount();
  const netOutgoingAmount = transactionAmounts.getNetOutgoingAmount();
  const targetCurrency = transactionAmounts.getTargetCurrency();
  const targetAmount = transactionAmounts.getTargetAmount();
  const targetRate = transactionAmounts.getTargetRate();
  const totalFee = transactionAmounts.getTotalFee();

  const errorMsg = [];
  return (
    <>
      <div className="text-uppercase text-center">Transaction preview</div>
      <p className="hint">This is a preview of the information that will be saved based on what you have added in this form.</p>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow key="invoice-amount">
              <TableCell component="th" align="center" scope="row">
                Invoice amount
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <NumberTpl
                  value={invoiceAmount}
                  prefix={CURRENCY_SYMBOL[invoiceCurrency]}
                />
              </TableCell>
              <TableCell component="th" align="center" scope="row">
                Invoice target amount
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <NumberTpl
                  value={invoiceTargetAmount}
                  prefix={CURRENCY_SYMBOL[invoiceTargetCurrency]}
                />
              </TableCell>
            </TableRow>
            <TableRow key="invoice-outgoing">
              <TableCell component="th" align="center" scope="row">
                Outgoing amount
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <NumberTpl
                  value={outgoingAmount}
                  prefix={CURRENCY_SYMBOL[outgoingCurrency]}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" align="center" scope="row">
                Total fee
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <NumberTpl
                  value={totalFee}
                  prefix={CURRENCY_SYMBOL[outgoingCurrency]}
                />
              </TableCell>
            </TableRow>
            <TableRow key="invoice-net-outgoing">
              <TableCell component="th" align="center" scope="row">
                ΝET Outgoing amount
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <NumberTpl
                  value={netOutgoingAmount}
                  prefix={CURRENCY_SYMBOL[outgoingCurrency]}
                />
              </TableCell>
            </TableRow>
            <TableRow key="invoice-target-amount">
              <TableCell />
              <TableCell />
              <TableCell component="th" align="center" scope="row">
                Target amount
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <NumberTpl
                  value={targetAmount}
                  prefix={CURRENCY_SYMBOL[targetCurrency]}
                />
              </TableCell>
            </TableRow>
            {targetRate && targetRate !== 1 && (
              <TableRow key="invoice-rate">
                <TableCell />
                <TableCell />
                <TableCell component="th" align="center" scope="row">
                  Target rate
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <NumberTpl
                    value={targetRate}
                    decimals={6}
                    style={{ whiteSpace: 'noWrap' }}
                    prefix={`${CURRENCY_SYMBOL[targetCurrency]}/${CURRENCY_SYMBOL[outgoingCurrency]} `}
                  />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={4} align="right">
                <span className="text-danger">{errorMsg.join(' ')}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

TransactionFormAmountsPreviewComponent.propTypes = {
  amounts: PropTypes.object.isRequired,
};

export default TransactionFormAmountsPreviewComponent;
