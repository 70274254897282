import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import MembersPreview from 'core/assets/js/components/MembersPreview.jsx';
import { locationSetParam } from 'core/assets/js/lib/utils';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';

export const FORM_ID = 'task-add-type-form';

const TaskAssigneesAddDirectly = ({ providers }) => {
  const history = useHistory();

  const onSubmit = (values) => {
    const { addDirectlyToTask } = values;
    const newLocation = locationSetParam(history.location, 'addedDirectly', addDirectlyToTask);
    history.push(newLocation);
  };

  return (
    <div className="task-assignment-type">
      <p>
        You&apos;ve selected to invite
        {' '}
        <b>{providers.length}</b>
        {' '}
        new providers to your task
      </p>
      <MembersPreview
        total={providers.length}
        users={providers.slice(0, 5).map(p => p.user)}
      />
      <Form
        name={FORM_ID}
        onSubmit={onSubmit}
        render={({ form: { getState }, handleSubmit }) => {
          const { values } = getState();

          return (
            <form
              onSubmit={handleSubmit}
              id={FORM_ID}
            >
              <RadioField
                name="addDirectlyToTask"
                defaultValue={false}
                options={[
                  {
                    sublabel: (
                      'The team members will be added to the task once they accept the invitation.'
                    ),
                    value: false,
                    text: 'Send an invitation to the team members',
                  },
                  {
                    sublabel: 'The team members will immediately be added to the task.',
                    value: true,
                    text: 'Add the team members',
                  },
                ]}
              />
              {values.addDirectlyToTask && (
                <TDSystemMessage
                  type={BS_STYLE.WARNING}
                  title="No signing contract documents!"
                  className="mb-4"
                >
                  <p>
                    By directly adding the providers to the Task, you will
                    not be able to request they sign any contract documents.
                  </p>
                </TDSystemMessage>
              )}
            </form>
          );
        }}
      />
    </div>
  );
};

TaskAssigneesAddDirectly.propTypes = {
  providers: PropTypes.arrayOf(userCardSpec).isRequired,
};

export default TaskAssigneesAddDirectly;
