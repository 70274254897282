import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { BS_SIZE, BS_STYLE, CURRENCY } from 'core/assets/js/constants';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import {
  RATE_ADJUSTMENT_INITIATOR_MESSAGE_MAX_LENGTH, RATE_STATUS, RATE_UNIT, RATE_UNIT_SELECT,
} from 'rates/assets/js/constants';
import { orgSettingsRatesUrl } from 'rates/urls';
import { createRateDS, editRateDS } from 'rates/assets/js/data-services/rates';
import RateField from 'rates/assets/js/components/finalFormFields/RateField.jsx';
import CurrencySelectField from 'core/assets/js/components/FinalFormFields/CurrencySelectField.jsx';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';

export const RateCreateFormBody = ({
  currencySymbol, isInsideModal, messageSublabel, rateId, rateUnit: rateUnitProp,
}) => {
  const rateUnit = rateUnitProp || RATE_UNIT.PER_HOUR;
  const editing = !!rateId;
  const showCurrencySelector = rateUnitProp !== RATE_UNIT.COMMISSION && !editing;
  const currencySelectField = !showCurrencySelector ? null : (
    <CurrencySelectField
      currencyOptions={CURRENCY}
      isClearable={false}
      label="Currency"
      name="currency"
      required
    />
  );
  return (
    <React.Fragment>
      <TextInputField name="id" type="hidden" />
      <TextInputField
        type="text"
        name="alias"
        label="Alias"
        sublabel="Use a friendly name to easily recognise your rates"
        placeholder="e.g Logo design rate"
        required
        disabled={editing}
      />
      {showCurrencySelector && (
        <div className="row">
          <div className={`col-12${!isInsideModal ? 'col-md-6 col-lg-4' : ''}`}>
            {currencySelectField}
          </div>
        </div>
      )}
      <div className="row d-flex flex-sm-nowrap">
        <div className="col-auto">
          <RateField
            currencySymbol={currencySymbol}
            name="amount"
            unit={rateUnit}
            placeholder={
              rateUnit === RATE_UNIT.COMMISSION
                ? 'e.g 0.50'
                : 'e.g 100'
            }
          />
        </div>
        <div className="col-auto">
          <div className="w-100">
            <SelectField
              name="unit"
              optionsMapping={RATE_UNIT_SELECT}
              label="Rate unit"
              selectedOption={rateUnit}
              disabled={!!rateId}
              defaultOptionText="Select"
              required
            />
          </div>
        </div>
      </div>
      <TextAreaField
        groupClassName="mb-0"
        label="Message"
        maxLength={RATE_ADJUSTMENT_INITIATOR_MESSAGE_MAX_LENGTH}
        name="message"
        required
        rest={{ rows: 3 }}
        sublabel={(
          `${messageSublabel} (maximum ${RATE_ADJUSTMENT_INITIATOR_MESSAGE_MAX_LENGTH} characters)`
        )}
      />
    </React.Fragment>
  );
};
RateCreateFormBody.propTypes = {
  currencySymbol: PropTypes.string.isRequired,
  isInsideModal: PropTypes.bool,
  messageSublabel: PropTypes.string,
  rateId: PropTypes.string,
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)).isRequired,
};
RateCreateFormBody.defaultProps = {
  isInsideModal: false,
  messageSublabel: 'Explain why you want to set this rate',
  rateId: null,
};

export const RateCreateFormButtons = ({
  btnSize, submitting, handleCancel, handleSubmit, submitBtnLabel,
}) => {
  return (
    <React.Fragment>
      <TDButton
        type="button"
        variant={BS_STYLE.DEFAULT}
        bsSize={btnSize}
        disabled={submitting}
        label="Cancel"
        onClick={handleCancel}
      />

      <TDButton
        type="submit"
        variant={BS_STYLE.PRIMARY}
        bsSize={btnSize}
        disabled={submitting}
        label={submitBtnLabel}
        onClick={handleSubmit}
      />
    </React.Fragment>
  );
};
RateCreateFormButtons.propTypes = {
  btnSize: PropTypes.oneOf(Object.values(BS_SIZE)),
  submitting: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitBtnLabel: PropTypes.string,
};
RateCreateFormButtons.defaultProps = {
  btnSize: BS_SIZE.DEFAULT,
  handleCancel: () => {},
  handleSubmit: null,
  submitBtnLabel: 'Create',
};

const RateCreateForm = ({
  dispatch, displayInContainer, history, initialValues,
  match: { params: { orgAlias, rateId } }, onSubmitSuccess, rate, submitLabel,
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={async values => {
      try {
        const result = await dispatch(
          rateId ? editRateDS(orgAlias, rateId, values) : createRateDS(orgAlias, values),
        );
        return onSubmitSuccess(result);
      } catch ({ errors }) {
        // so react-final-form can display the errors in the UI
        if (errors.initiator_msg) {
          errors.message = errors.initiator_msg;
        }
        return errors;
      }
    }}
    render={({ handleSubmit, submitting, values }) => {
      const btnSize = displayInContainer ? BS_SIZE.DEFAULT : BS_SIZE.LARGE;
      // A rate is considered new if the user creates it now or it's still in DRAFT state
      const newRate = !rate || rate.status === RATE_STATUS.DRAFT;
      let messageSublabel = 'Explain why you want to set this rate';
      let submitBtnLabel = submitLabel;

      const targetUnit = parseInt(values.unit, 10) || initialValues.unit;

      if (!newRate) {
        messageSublabel = 'Explain why you\'d like to change the rate';
        submitBtnLabel = 'Request rate change';
      }

      const containerClasses = ['align-top', 'rounded', 'p-4'];
      if (displayInContainer) {
        containerClasses.push('bg-light');
      } else {
        containerClasses.push('shadow-sm', 'bg-white');
      }

      return (
        <form onSubmit={handleSubmit}>
          <div className={containerClasses.join(' ')}>
            <RateCreateFormBody
              currencySymbol={CURRENCY_SYMBOL[rate ? rate.currency : values.currency]}
              messageSublabel={messageSublabel}
              rateId={rateId}
              rateUnit={targetUnit}
            />
            <div className="mt-4 text-right button-container">
              <RateCreateFormButtons
                btnSize={btnSize}
                handleCancel={() => {
                  history.push(orgSettingsRatesUrl(orgAlias));
                }}
                submitting={submitting}
                submitBtnLabel={submitBtnLabel}
              />
            </div>
          </div>
        </form>
      );
    }}
  />
);

RateCreateForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  initialValues: PropTypes.shape({
    currency: PropTypes.oneOf(Object.values(CURRENCY)),
    alias: PropTypes.string,
    unit: PropTypes.oneOf(Object.values(RATE_UNIT)).isRequired,
  }),
  onSubmitSuccess: PropTypes.func,
  rate: PropTypes.shape(rateSpec),
  submitLabel: PropTypes.string,
  displayInContainer: PropTypes.bool,
};
RateCreateForm.defaultProps = {
  initialValues: {},
  onSubmitSuccess: () => null,
  rate: null,
  submitLabel: 'Create',
  displayInContainer: false,
};

export default withRouter(connect()(RateCreateForm));
