import React from 'react';
import { FunctionField } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import LinearProgressWithLabel from 'admin/assets/js/components/LinearProgressWithLabel.jsx';
import PropTypes from 'prop-types';

import Money from 'finance/assets/js/lib/Money';

import {
  getRemainingInvoiceAllocationAmount,
  getAllocationFormInputFieldName,
} from 'admin/assets/js/resources/inbound_transfers/utils';

const InvoicePendingField = ({ selectedInvoiceAllocations }) => {
  const { values: formData } = useFormState();
  if (isEmpty(formData)) {
    return null;
  }
  return (
    <FunctionField
      source="total"
      label={<Typography variant="subtitle2" className="font-weight-bold">Pending</Typography>}
      render={(inv) => {
        if (!formData[getAllocationFormInputFieldName(inv.id)]) {
          return null;
        }
        const remainingInvoiceAllocationAmount = getRemainingInvoiceAllocationAmount(inv);

        const selectedInvoiceAllocation = new Money(
          selectedInvoiceAllocations
            .find(_inv => _inv.invoiceId === inv.id)
            ?.amount || 0,
          inv.currency,
        );

        const progressPercentage = remainingInvoiceAllocationAmount.isZero()
          ? 0
          : selectedInvoiceAllocation
            .div(remainingInvoiceAllocationAmount)
            .mul(100)
            .toString();


        return (
          <div className="">
            <div>
              {remainingInvoiceAllocationAmount
                .toString({ withSymbol: true, humanizeAmount: true })
              }
            </div>
            <LinearProgressWithLabel
              variant="determinate"
              color="primary"
              valuePerCent={parseFloat(progressPercentage)}
            />
          </div>
        );
      }}
    />
  );
};

InvoicePendingField.propTypes = {
  selectedInvoiceAllocations: PropTypes.object.isRequired,
};

export default InvoicePendingField;
