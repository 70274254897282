import React from 'react';
import {
  FunctionField,
  Datagrid,
  List,
  Link,
} from 'react-admin';
import { Money } from 'td-finance-ts';

import { formatDate } from 'admin/assets/js/lib/utils';
import ListFilter from 'admin/assets/js/resources/cashout_requests/filters.jsx';
import CashoutRequestStatus from 'admin/assets/js/resources/cashout_requests/CashoutRequestStatus.jsx';

const CashoutRequestsList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<ListFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <Datagrid>
      <FunctionField
        source="id"
        label="#"
        render={record => (
          <Link to={`/cashout_requests/${record.id}/show`}>{record.id}</Link>
        )}
      />
      <FunctionField
        source="createdAt"
        label="Created at"
        render={record => formatDate(record.createdAt)}
      />
      <FunctionField
        source="createdAt"
        label="Provider"
        render={record => (
          <Link to={`/full_users/${record.providerUserCard?.user?.id}/show`}>
            {record.providerUserCard?.user?.profile?.name}
          </Link>
        )}
      />
      <FunctionField
        label="Status"
        render={({ status }) => (
          <CashoutRequestStatus
            status={status}
          />
        )}
      />
      <FunctionField
        source="createdAt"
        label="Invoices"
        render={({ invoiceIds }) => invoiceIds?.length}
      />
      <FunctionField
        source="createdAt"
        label="Amount"
        render={({ invoiceTotalAmount, invoiceTotalCurrency }) => {
          if (!invoiceTotalAmount || !invoiceTotalCurrency) {
            return null;
          }
          const invoiceTotalMoney = new Money(invoiceTotalAmount, invoiceTotalCurrency);
          return invoiceTotalMoney.toString({
            withSymbol: true,
            humanizeAmount: true,
          });
        }}
      />
    </Datagrid>
  </List>
);

export default CashoutRequestsList;
