import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { getListState, listReplaceItemAC } from 'core/assets/js/ducks/list';
import { viewFetchExtrasAC } from 'core/assets/js/ducks/view';
import { paginationSpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { worksheetSpec } from 'finance/assets/js/lib/objectSpecs';
import { DATE_DURATION } from 'core/assets/js/constants';
import { RATE_UNIT } from 'rates/assets/js/constants';
import WorksheetsTable from 'finance/assets/js/components/WorksheetsTable.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchFinanceWorksheetListDS } from 'finance/assets/js/data-services/list';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import SearchFormWithChartsToggleHeader from 'finance/assets/js/components/SearchFormWithChartsToggleHeader.jsx';
import { PROJECT_TAB_VIEW_TYPE } from 'projects/assets/js/constants';
import ProjectWorksheetsChartStacked from 'projects/assets/js/components/widgets/ProjectWorksheetsChartStacked.jsx';
import ProjectWorksheetsChartLine from 'projects/assets/js/components/widgets/ProjectWorksheetsChartLine.jsx';

class ProjectTabWorksheets extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    return dispatch(fetchFinanceWorksheetListDS({
      url,
      querystring,
      projectId: params.id,
      params,
      componentName,
      authedAxios,
      dispatch,
    }));
  }

  static GetComponentName() {
    return 'ProjectTabWorksheets';
  }

  constructor(props) {
    super(props);
    this.state = {
      activeView: PROJECT_TAB_VIEW_TYPE.LIST,
      selectedDateDuration: DATE_DURATION.CURRENT_MONTH,
    };

    this.onUpdated = this.onUpdated.bind(this);
    this.onChartsToggled = this.onChartsToggled.bind(this);
    this.handleDateDurationChange = this.handleDateDurationChange.bind(this);
  }

  onUpdated(updatedItem, projectAllowedActions) {
    const { dispatch, parentComponentName } = this.props;

    // Update list item
    dispatch(listReplaceItemAC(updatedItem, ProjectTabWorksheets.GetComponentName()));

    // Update project Allowed actions.
    dispatch(viewFetchExtrasAC(projectAllowedActions, parentComponentName, 'allowedActions'));
  }

  onChartsToggled() {
    const { activeView } = this.state;
    const nextView = activeView === PROJECT_TAB_VIEW_TYPE.LIST
      ? PROJECT_TAB_VIEW_TYPE.CHARTS
      : PROJECT_TAB_VIEW_TYPE.LIST;
    this.setState({
      activeView: nextView,
    });
  }

  handleDateDurationChange(selectedDate) {
    this.setState({
      selectedDateDuration: selectedDate,
    });
  }

  render() {
    const { activeView, selectedDateDuration } = this.state;
    const { amountLimits, currency, worksheets, filtersOpen, onFiltersToggle,
      pagination, rateUnit,
    } = this.props;

    const showGraphs = activeView === PROJECT_TAB_VIEW_TYPE.CHARTS;
    const currencySymbol = CURRENCY_SYMBOL[currency];
    const emptyText = 'No worksheets found';

    return (
      <React.Fragment>
        <SearchFormWithChartsToggleHeader
          amountLimits={amountLimits}
          showCharts={activeView === PROJECT_TAB_VIEW_TYPE.CHARTS}
          reportType={REPORT_TYPES.WORKSHEET}
          onChartsToggled={this.onChartsToggled}
          selectedDateDuration={selectedDateDuration}
          onDateDurationChanged={this.handleDateDurationChange}
          filtersOpen={filtersOpen}
          onFiltersToggle={onFiltersToggle}
        />
        { showGraphs && (
          <Card>
            <Card.Body>
              <ProjectWorksheetsChartStacked
                currencySymbol={currencySymbol}
                selectedDateDuration={selectedDateDuration}
              />
              <ProjectWorksheetsChartLine
                currencySymbol={currencySymbol}
                selectedDateDuration={selectedDateDuration}
              />
            </Card.Body>
          </Card>
        )}

        <TDApiConnected
          duck="list"
          component={this.constructor}
          blockingLoading
          skeletonComponent={FinanceTableSkeleton}
        >
          { !showGraphs && !filtersOpen && (
            <React.Fragment>
              <div className="finance-report finance-report__table-wrapper finance-report__table-wrapper--responsive finance-report__table-wrapper--list">
                <WorksheetsTable
                  list={worksheets}
                  currency={currencySymbol}
                  rateUnit={rateUnit}
                  emptyText={emptyText}
                  onItemUpdate={this.onUpdated}
                  embeddedMode
                />
              </div>
              <TDPagination {...pagination} />
            </React.Fragment>
          )}
        </TDApiConnected>
      </React.Fragment>
    );
  }
}

ProjectTabWorksheets.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: routerMatchSpec.isRequired,
  location: PropTypes.object.isRequired,
  amountLimits: PropTypes.object,
  currency: PropTypes.string.isRequired,
  pagination: paginationSpec.isRequired,
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)).isRequired,
  searchActive: PropTypes.bool.isRequired,
  worksheets: PropTypes.arrayOf(worksheetSpec).isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  parentComponentName: PropTypes.string.isRequired,
};

ProjectTabWorksheets.defaultProps = {
  amountLimits: {},
};

const mapStateToProps = (state) => {
  const listState = getListState(state, ProjectTabWorksheets.GetComponentName());
  return {
    amountLimits: listState.extras.amountLimits,
    currency: selectActiveOrg(state).currency,
    pagination: listState.pagination,
    rateUnit: selectActiveOrg(state).default_rate_unit,
    searchActive: listState.search.isActive,
    worksheets: listState.items,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectTabWorksheetsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectTabWorksheets);

export default withRouter(withFilters(ProjectTabWorksheetsConnected));
