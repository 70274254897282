import PropTypes from 'prop-types';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import { CURRENCY } from 'core/assets/js/constants';
import { RATE_UNIT } from 'rates/assets/js/constants';


export const rateAllowedOptionsSpec = {
  canCancel: PropTypes.bool,
  canInitiate: PropTypes.bool,
  canRespond: PropTypes.bool,
  hasPending: PropTypes.bool,
};

export const rateAdjustmentSpec = {
  id: PropTypes.number,
  rate_id: PropTypes.number,
  provider_id: PropTypes.number,
  initiator_id: PropTypes.number,
  initiator_msg: PropTypes.string,
  prev_amt: PropTypes.string,
  new_amt: PropTypes.string,
  organization_id: PropTypes.number,
  responded_at: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  responder_id: PropTypes.number,
  responder_msg: PropTypes.string,
  status: PropTypes.number,
  status_label: PropTypes.string,
  created_at: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  prev_rate_unit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  new_rate_unit: PropTypes.oneOf(Object.values(RATE_UNIT)),
};

export const rateSpec = {
  id: PropTypes.number,
  organization_id: PropTypes.number,
  user_id: PropTypes.number,
  alias: PropTypes.string,
  unit: PropTypes.number,
  amount: PropTypes.string,
  currency: PropTypes.oneOf(Object.values(CURRENCY)),
  currencySymbol: PropTypes.oneOf(Object.values(CURRENCY_SYMBOL)),
  status: PropTypes.number,
  allowedActions: PropTypes.shape(rateAllowedOptionsSpec),
  rateAdjustments: PropTypes.arrayOf(PropTypes.shape(rateAdjustmentSpec)),
  latestRateAdjustment: PropTypes.shape(rateAdjustmentSpec),
  isDefault: PropTypes.bool,
  isRateActive: PropTypes.bool,
  isRateDraft: PropTypes.bool,
  hasPendingRateAdjustment: PropTypes.bool,
  pendingStatusLabel: PropTypes.string,
};
