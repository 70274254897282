import Big from 'big.js';
import { isNil } from 'lodash';

import { getRate, TRateOpts } from './utils';
import { BANK_CURRENCY, CURRENCY_SYMBOL } from '../constants';
import { assert } from './Assert';

/**
 * Enum for Money.cmp results.
 */
export enum EComparison {
  // note - do not reorder
  //        this should match Big.js internal comparison result,
  //        as we currently don't apply any conversion
  GT = 1,
  EQ = 0,
  LT = -1,
}

export type TStringOpts = {
  withSymbol?: boolean;
  humanizeAmount?: boolean;
};

export type TConvertOpts = {
  rate?: number;
  reverseRate?: number;
};

export type TAssertOpts = {
  strict?: boolean;
};

/**
 * Money.
 *
 * A math library for handling currency amounts.
 */
export default class Money {
  private currency: BANK_CURRENCY;

  private amount: number;

  private originalBigRM: number = 1;

  private originalBigDP: number = 2;

  /**
   * Creates instance from cents
   *
   * Some 3rd parties keep their amounts in cent precision e.g. 120000USD to
   * denote 120.00USD. This function is an alternative to a constructor when
   * we have cents at hand
   *
   * @param {number|string} cents - the monetary amount in the given currency.
   * @param {BANK_CURRENCY} currency - the currency of the amount.
   * @return {Money} a money instance
   *
   */
  public static fromCents(cents: number, currency: BANK_CURRENCY): Money {
    const precision = Money.getPrecisionsForCurrency(currency);
    const amount = cents / (10 ** precision);

    return new Money(amount, currency);
  }

  public static zero(currency: BANK_CURRENCY): Money {
    return new Money(0, currency);
  }

  public static haveSameCurrency(moneyInstances: Money[]): boolean {
    if (!moneyInstances) {
      throw new Error('array is required');
    }
    if (!Array.isArray(moneyInstances)) {
      throw new Error('expecting an array of values');
    }
    const distinctCurrencies = new Set(moneyInstances.map((m) => m.getCurrency()));
    if (distinctCurrencies.size > 1) {
      return false;
    }
    return true;
  }

  public static aggregate(moneyInstances: Money[]): Money {
    if (!moneyInstances) {
      throw new Error('array is required');
    }
    if (!Array.isArray(moneyInstances)) {
      throw new Error('expecting an array of values');
    }
    if (moneyInstances.length === 0) {
      throw new Error('array is empty');
    }
    if (!Money.haveSameCurrency(moneyInstances)) {
      throw new Error('cannot aggregate instances of different currencies');
    }
    const currency = moneyInstances[0].getCurrency();
    return moneyInstances.reduce((acc, curr) => acc.add(curr), Money.zero(currency));
  }

  /**
   * Constructor
   *
   * Validate parameters, amount and currency.
   * @param {number|string} amount - the monetary amount in the given currency.
   * @param {BANK_CURRENCY} currency - the currency of the amount.
   */
  public constructor(amount: number | string, currency: BANK_CURRENCY) {
    const sanitisedCurrency = (currency || '').toLowerCase() as BANK_CURRENCY;
    if (!Object.values(BANK_CURRENCY).includes(sanitisedCurrency)) {
      throw new Error('currency is required, and recognised as a member of BANK_CURRENCY');
    }

    const sanitisedAmount = parseFloat(amount as string);
    if (Number.isNaN(sanitisedAmount)) {
      throw new Error('amount is required, and must be valid');
    }

    // note ensure this.currency is set before using Big
    this.currency = sanitisedCurrency;
    this.configBig();
    this.amount = Big(
      Big(sanitisedAmount).toFixed(this.getPrecisionsForCurrency()),
    ).toNumber();
    this.restoreBig();
  }

  /**
   * Get currency for this money instance.
   * @return {BANK_CURRENCY} the currency of this money amount.
   */
  public getCurrency(): BANK_CURRENCY {
    return this.currency;
  }

  /**
   * Get currency symbol for this money instance.
   * @return {String} the currency symbol of this money amount.
   */
  public getCurrencySymbol(): string {
    return CURRENCY_SYMBOL[this.currency];
  }

  /**
   * Ensure other amount is valid, and convert to a Money instance if required.
   *
   * By always returning a Money instance we can simplify the proceeding logic,
   * as it only needs the logic to handle a Money instance.
   *
   * When a number is passed, it'll assume that number is the same currency as this instance.
   *
   * When a Money instance is passed, it will ensure that the currencies match, as we can't
   * perform any operations in a reliable manner when they are in different currencies, ie how
   * do we add USD to JPY ?
   * @param {Money|number} otherAmount - other amount to sanitise.
   * @return {Money} sanitise money instance.
   */
  private sanitiseOtherAmount(otherAmount: Money | number): Money {
    if (!(otherAmount instanceof Money)) {
      return new Money(otherAmount, this.currency);
    }

    if (this.currency !== otherAmount.getCurrency()) {
      throw new Error('can not perform action, currencies do not match');
    }

    return otherAmount;
  }

  private static getPrecisionsForCurrency(currency: BANK_CURRENCY): number {
    if (isNil(currency)) {
      throw new Error('currency is required');
    }
    // currency precision overrides for non decimal currencies
    const precisionForCurrencyLookUp: Record<string, number> = {
      [BANK_CURRENCY.JPY]: 0,
      [BANK_CURRENCY.KRW]: 0,
      // from Wise
      [BANK_CURRENCY.VND]: 0,
      [BANK_CURRENCY.KES]: 0,
      [BANK_CURRENCY.IDR]: 0,
    };

    // note - this could be done better with JS nullish coalescing operator
    //        `return precisionForCurrencyLookUp[this.currency] ?? 2`, however
    //        our current webpack build doesn't like this syntax :/
    const precision = precisionForCurrencyLookUp[currency];
    return (precision === undefined) ? 2 : precision;
  }

  /**
   * Determine the precision required to represent this instances currency.
   *
   * Most currencies will have a precision of 2, ie USD, GBP, EUR etc.
   * So, rather than recording all currencies with a precision of 2, lets only
   * record the exceptions and assume a precision of 2 when no exception is present.
   * @return {number} precision of this instances currency.
   */
  private getPrecisionsForCurrency(): number {
    if (!this.currency) {
      throw new Error('this.currency must be set before using getPrecisionsForCurrency');
    }
    return Money.getPrecisionsForCurrency(this.currency);
  }

  /**
   * Configure Big.js to use the precision required by this instances currency.
   *
   * Lets also store the current Big.js state, so we can restore it before
   * returning to the calling code.
   */
  private configBig(): void {
    this.originalBigRM = Big.RM;
    this.originalBigDP = Big.DP;

    // RM - Rounding Method, 0 - down / 1 - half up / 2 - half even / 3 - up
    //      note - both 'half' will round to nearest neighbour, except when .5
    //             up - will round up when .5
    //             even - will round to nearest even neighbour when .5,
    //                    ie 7.5 rounds up because 8 is even, but 4.5 rounds down because 4 is even
    Big.RM = 1;
    // DP - number of decimal places, otherwise known as precision
    Big.DP = this.getPrecisionsForCurrency();
  }

  /**
   * Ensure Big.js is configured how we found it.
   *
   * As we've changed the Big config at the start of an operation
   * based on the currency of this instance.  We should return
   * its state as the calling code expects.
   */
  private restoreBig(): void {
    Big.RM = this.originalBigRM;
    Big.DP = this.originalBigDP;
  }

  /**
   * Divide this amount by another amount.
   * Returning the result in a new Money instance.
   *
   * @param {number|string|Money} otherAmount - another amount to divide this amount by.
   * @return {Money} a new Money instance with the amount divide by the other amount.
   */
  public div(otherAmount: Money | number): Money {
    let scalar = otherAmount;
    if (typeof scalar !== 'number' && typeof scalar !== 'string') {
      assert(scalar instanceof Money, 'otherAmount must be either a number, string or Money instance');
      assert(scalar.getCurrency() === this.getCurrency(), 'can not perform action, currencies do not match');
      scalar = scalar.toNumber();
    }

    this.configBig();

    const result = new Money(
      Big(this.amount)
        .div(scalar)
        .toNumber(),
      this.currency,
    );

    this.restoreBig();

    return result;
  }

  /**
   * Multiply this amount by another amount.
   * Returning the result in a new Money instance.
   *
   * @param {number|string|Money} otherAmount - another amount to multiply this amount by.
   * @return {Money} a new Money instance with the amounts multiplied.
   */
  public mul(otherAmount: Money | number): Money {
    let scalar = otherAmount;
    if (typeof scalar !== 'number' && typeof scalar !== 'string') {
      assert(scalar instanceof Money, 'otherAmount must be either a number, string or Money instance');
      assert(scalar.getCurrency() === this.getCurrency(), 'can not perform action, currencies do not match');
      scalar = scalar.toNumber();
    }

    this.configBig();

    const result = new Money(
      Big(this.amount)
        .mul(scalar)
        .toNumber(),
      this.currency,
    );

    this.restoreBig();

    return result;
  }

  /**
   * Add another amount to this amount.
   * Returning the result in a new Money instance.
   *
   * Note the currencies must be the same, we can't add USD to JPY etc.
   *
   * @param {Money|number} otherAmount - another amount to add to this amount.
   * @return {Money} a new Money instance with the amount added.
   */
  public add(otherAmount: Money | number): Money {
    const sanitisedOtherAmount = this.sanitiseOtherAmount(otherAmount);

    this.configBig();

    const result = new Money(
      Big(this.amount)
        .add(sanitisedOtherAmount.toNumber())
        .toNumber(),
      this.currency,
    );

    this.restoreBig();

    return result;
  }

  /**
   * Subtract another amount from this amount.
   * Returning the result in a new Money instance.
   *
   * Note the currencies must be the same, we can't subtract USD from JPY etc.
   *
   * @param {Money|number} otherAmount - another amount to subtract from this amount.
   * @return {Money} a new Money instance with the amount subtracted.
   */
  public sub(otherAmount: Money | number): Money {
    const sanitisedOtherAmount = this.sanitiseOtherAmount(otherAmount);

    this.configBig();

    const result = new Money(
      Big(this.amount)
        .sub(sanitisedOtherAmount.toNumber())
        .toNumber(),
      this.currency,
    );

    this.restoreBig();

    return result;
  }

  /**
   * Check how this amount differs from another currency value.
   * Is it equal to, greater than or less than.
   *
   * Note the currencies must be the same, we can't compare USD vs JPY.
   *
   * @param {Money|number} otherAmount - another value to compare against this value.
   * @return {EComparison} if this amount is equal, less than, or greater than the other value.
   */
  public cmp(otherAmount: Money | number): EComparison {
    const sanitisedOtherAmount = this.sanitiseOtherAmount(otherAmount);

    this.configBig();

    const result = Big(this.amount)
      .cmp(sanitisedOtherAmount.toNumber());

    this.restoreBig();

    return result;
  }

  /**
   * Check if this amount is equal to 0.
   *
   * @return {boolean} if this amount is equal to zero or not.
   */
  public isZero(): boolean {
    this.configBig();

    const result = Big(this.amount)
      .eq(0);

    this.restoreBig();

    return result;
  }

  public isNonZero(): boolean {
    return !this.isZero();
  }

  /**
   * Check if this amount is equal to another currency value.
   *
   * Note the currencies must be the same, we can't compare USD vs JPY.
   *
   * @param {Money|number} otherAmount - another value to compare against this value.
   * @return {boolean} if the two amounts are equal or not.
   */
  public eq(otherAmount: Money | number): boolean {
    const sanitisedOtherAmount = this.sanitiseOtherAmount(otherAmount);

    this.configBig();

    const result = Big(this.amount)
      .eq(sanitisedOtherAmount.toNumber());

    this.restoreBig();

    return result;
  }

  /**
   * Check if this amount is greater than another currency value.
   *
   * Note the currencies must be the same, we can't compare USD vs JPY.
   *
   * @param {Money|number} otherAmount - another value to compare against this value.
   * @return {boolean} if this amount is greater than another currency value.
   */
  public gt(otherAmount: Money | number): boolean {
    const sanitisedOtherAmount = this.sanitiseOtherAmount(otherAmount);

    this.configBig();

    const result = Big(this.amount)
      .gt(sanitisedOtherAmount.toNumber());

    this.restoreBig();

    return result;
  }

  /**
   * Check if this amount is greater than or equal to another currency value.
   *
   * Note the currencies must be the same, we can't compare USD vs JPY.
   *
   * @param {Money|number} otherAmount - another value to compare against this value.
   * @return {boolean} if this amount is greater than or equal to the other currency value.
   */
  public gte(otherAmount: Money | number): boolean {
    const sanitisedOtherAmount = this.sanitiseOtherAmount(otherAmount);

    this.configBig();

    const result = Big(this.amount)
      .gte(sanitisedOtherAmount.toNumber());

    this.restoreBig();

    return result;
  }

  /**
   * Check if this amount is less than another currency value.
   *
   * Note the currencies must be the same, we can't compare USD vs JPY.
   *
   * @param {Money|number} otherAmount - another value to compare against this value.
   * @return {boolean} if this amount is less than another currency value.
   */
  public lt(otherAmount: Money | number): boolean {
    const sanitisedOtherAmount = this.sanitiseOtherAmount(otherAmount);

    this.configBig();

    const result = Big(this.amount)
      .lt(sanitisedOtherAmount.toNumber());

    this.restoreBig();

    return result;
  }

  /**
   * Check if this amount is less than or equal to another currency value.
   *
   * Note the currencies must be the same, we can't compare USD vs JPY.
   *
   * @param {Money|number} otherAmount - another value to compare against this value.
   * @return {boolean} if this amount is less than or equal to the other currency value.
   */
  public lte(otherAmount: Money | number): boolean {
    const sanitisedOtherAmount = this.sanitiseOtherAmount(otherAmount);

    this.configBig();

    const result = Big(this.amount)
      .lte(sanitisedOtherAmount.toNumber());

    this.restoreBig();

    return result;
  }

  /**
   * Absolute the currency amount, ie make it a positive value when if it's negative.
   * Returning the result in a new Money instance.
   *
   * @return {Money} a new Money instance with it's currency amount absolute.
   */
  public abs(): Money {
    this.configBig();

    const result = new Money(
      Big(this.amount).abs().toNumber(),
      this.currency,
    );

    this.restoreBig();

    return result;
  }

  /**
   * Convert the currency value to a JS string.
   * @return {string} currency value as a string.
   */
  public toString({ withSymbol = false, humanizeAmount = false }: TStringOpts = {}): string {
    this.configBig();

    let result = Big(this.amount)
      .toFixed(this.getPrecisionsForCurrency());

    this.restoreBig();

    if (humanizeAmount) {
      result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    if (withSymbol) {
      return `${CURRENCY_SYMBOL[this.getCurrency().toLowerCase()]}${result}`;
    }
    return result;
  }

  /**
   * Convert the currency value to a JS number.
   * @return {number} currency value as a number.
   */
  public toNumber(): number {
    this.configBig();

    const result = Big(this.amount)
      .toNumber();

    this.restoreBig();

    return result;
  }

  /**
   * Convert to another currency
   *
   * Takes care of converting to arbitrary precision number, multiplying and
   * then converting to target currency precision
   *
   * @param {BANK_CURRENCY} toCurrency - the currency to convert to
   * @param {ConvertOpts} opts - the FX rate from original currency to target currency /
   *    the FX rate from target currency to original currency
   * @return {Money} a new instance of money with the converted amount/currency
   */
  public convert(toCurrency: BANK_CURRENCY, { rate, reverseRate }: TConvertOpts = {}): Money {
    if (!toCurrency) {
      throw new Error('toCurrency is required');
    }
    if (!rate && !reverseRate) {
      throw new Error('either rate or reverseRate is required');
    }
    if (rate && reverseRate) {
      throw new Error('only rate or reverseRate is permitted');
    }

    const targetPrecision = Money.getPrecisionsForCurrency(toCurrency);
    const value = rate
      ? Big(this.toNumber()).times(rate).toFixed(targetPrecision)
      // override reverseRate checking ( with ! ), as we've already asserted it above
      : Big(this.toNumber()).div(reverseRate!).toFixed(targetPrecision);

    return new Money(value, toCurrency);
  }

  public convertsTo(toMoney: Money, rate: number, {
    strict = true,
  } = {}): boolean {
    const converted = this.convert(toMoney.getCurrency(), { rate });
    const reverse = toMoney.convert(this.getCurrency(), { reverseRate: rate });

    const forwardEquivalence = converted.toString() === toMoney.toString();
    const backwardEquivalence = reverse.toString() === this.toString();

    if (!forwardEquivalence && (!backwardEquivalence || strict)) {
      return false;
    }
    return true;
  }

  /**
   * Asserts a currency conversion is correct
   *
   * @param {number|string} fromAmount - the original amount
   * @param {BANK_CURRENCY} fromCurrency - the currency of the original amount
   * @param {number} rate - the FX rate from original currency to target currency
   * @param {number|string} toAmount - the target amount
   * @param {BANK_CURRENCY} toCurrency - the currency of the target amount
   * @param {AssertOpts} opts - whether it reuires unidirectional equivalence or
   *   allows backwards equivalence
   */
  public static assertConvertsTo(
    fromAmount: number | string,
    fromCurrency: BANK_CURRENCY,
    rate: number,
    toAmount: number | string,
    toCurrency: BANK_CURRENCY,
    {
      strict = true,
    }: TAssertOpts = {},
  ): void {
    const from = new Money(fromAmount, fromCurrency);
    const to = new Money(toAmount, toCurrency);
    if (!from.convertsTo(to, rate, { strict })) {
      const converted = from.convert(toCurrency, { rate });
      throw new Error(`amounts do not convert to each other (${from.toString()} ${fromCurrency.toUpperCase()} * ${rate} == ${converted.toString()} ${toCurrency.toUpperCase()} != ${toAmount} ${toCurrency.toUpperCase()})`);
    }
  }

  public getRate(moneyInstance: Money, opts: TRateOpts): number {
    if (this.getCurrency() === moneyInstance.getCurrency()) {
      return 1;
    }
    if (this.isZero() || moneyInstance.isZero()) {
      throw new Error('cannot calculate rate when any of the amounts is zero');
    }
    const { rate } = getRate(
      this.toString(),
      this.getCurrency(),
      moneyInstance.toString(),
      moneyInstance.getCurrency(),
      opts,
    );
    Money.assertConvertsTo(
      this.toString(),
      this.getCurrency(),
      rate,
      moneyInstance.toString(),
      moneyInstance.getCurrency(),
    );
    return rate;
  }
}
