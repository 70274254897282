/* eslint react/prop-types: "warn", import/prefer-default-export: "warn", react/no-multi-comp: "off", max-len: "off" */
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Table, TableBody } from '@material-ui/core';
import { Money } from 'td-finance-ts';

import { STYLE, CostRow, CreditRow, CostBreakdownRow, EmptyRow, TotalsRow, TotalsBreakdownRow } from './BalanceSheetRow.jsx';


const OrgBillingAnalyticsBalanceSheet = ({ analytics }) => {
  if (isEmpty(analytics) || isEmpty(analytics.amounts)) {
    return null;
  }
  const {
    currency,
    amounts: {
      totalInvoiced,
      contractorInvoices,
      aorFees,
      licenceSeatFees,
      processingFees,
      tdFees,
      paymentsReconciled,
      amountPaid,
      pendingContractorInvoices,
      bankFees,
      fxCosts,
    },
  } = analytics;
  const externalFees = new Money(bankFees, currency).add(fxCosts).toString();
  const balance = new Money(paymentsReconciled, currency).sub(contractorInvoices).sub(tdFees).toString();
  const totalBalance = new Money(paymentsReconciled, currency)
    .sub(totalInvoiced)
    .sub(contractorInvoices)
    .add(amountPaid)
    .add(pendingContractorInvoices)
    .sub(externalFees)
    .toString();
  const pNl = new Money(tdFees, currency).sub(externalFees).toString();
  return (
    <Table>
      <TableBody>
        <CostRow description="Invoiced" amount={totalInvoiced} currency={currency} strongAmount />
        <CostRow description="Contractor invoices (including VAT)" amount={contractorInvoices} currency={currency} />
        <CostRow description="Talentdesk Fees" amount={tdFees} currency={currency} style={STYLE.EXTENDED} />
        <CostBreakdownRow description="Payment Processing Fees" amount={processingFees} currency={currency} />
        <CostBreakdownRow description="Licence Seats" amount={licenceSeatFees} currency={currency} />
        <CostBreakdownRow description="AOR Fees" amount={aorFees} currency={currency} />
        <EmptyRow />
        <CreditRow description="Money In" amount={paymentsReconciled} currency={currency} />
        <CreditRow description="Balance" amount={balance} currency={currency} style={STYLE.PLAIN} strongAmount strongDescription />
        <EmptyRow />
        <CostRow description="Contractor invoices" amount={contractorInvoices} currency={currency} strongAmount />
        <CreditRow description="Money Out" amount={amountPaid} currency={currency} style={STYLE.PLAIN} strongDescription />
        <EmptyRow />
        <CreditRow description="Unpaid Invoices" amount={pendingContractorInvoices} currency={currency} />
        <CostRow description="External Fees" amount={externalFees} currency={currency} style={STYLE.EXTENDED} strongDescription />
        <CostBreakdownRow description="Bank Fees" amount={bankFees} currency={currency} />
        <CostBreakdownRow description="FX Costs" amount={fxCosts} currency={currency} />
        <TotalsRow description="Balance" amount={totalBalance} currency={currency} />
        <TotalsBreakdownRow description="Talentdesk Profit/Loss" amount={pNl} currency={currency} />
      </TableBody>
    </Table>
  );
};

OrgBillingAnalyticsBalanceSheet.propTypes = {
  analytics: PropTypes.object.isRequired,
};

export default OrgBillingAnalyticsBalanceSheet;
