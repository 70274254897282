import moment from 'moment';
import { get, isEqual, isNil, set } from 'lodash';
import cronParser from 'cron-parser';
import { getDatetime } from 'core/assets/js/lib/utils';

/**
 * Parses a date (string, Date, etc.) to a moment object with zeroed time information
 *
 * @param {string|Date} date - an arbitrary date
 * @returns {moment} a moment instance of the input date with zeroed time
 */
const _parseDate = (date = getDatetime()) => {
  if (moment.isMoment(date) && !date.isValid()) {
    throw new Error('date is invalid');
  }
  const parsed = moment(date);
  if (!parsed.isValid()) {
    throw new Error('date is invalid');
  }
  return parsed.startOf('day');
};

const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const WEEKDAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const toOrdinal = (number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  return number + (s[(number - 20) % 10] || s[number] || s[0]);
};

const _joinArray = (inputs, lastSeparator = 'and') => {
  if (inputs.length === 0) {
    return '';
  }
  if (inputs.length === 1) {
    return inputs[0].toString();
  }
  const last = inputs.pop();
  return `${inputs.join(', ')} ${lastSeparator} ${last}`;
};

/* We will be keeping lookup tables for memoizing common results in memory.
 *
 * The reason is that during invoicing, we need to check frequencies multiple
 * times for each org, making the tests some orders of magnitude larger than
 * the actual values tested.
 *
 * For example, we may need to validate a simple cron like "0 0 1 * *" multiple
 * times per org or across multiple orgs. Though, the answer is always the same,
 * it is either a valid cron expression or not.
 *
 * Validating/parsing/testing crons could take a few milliseconds per invocation
 * which makes it unsuitable for scaling. However, we would not like to impose
 * limits to any part of our system that need to make multiple questions about
 * frequencies
 *
 * Given the vast difference between the number of calls and the number of different
 * input values and results, we can allow for temporarily storing results and serving
 * them directly for any subsequent call
 *
 */
const _matchingMemo = {};
const _prevMemo = {};
const _nextMemo = {};

/** A class wrapping 'cron-parser' and providing an interface for its functionality */
class CronFrequency {
  static _assertSupported(cronString) {
    const iter = cronParser.parseExpression(cronString);

    const { second, minute, hour, dayOfMonth, dayOfWeek } = iter.fields;
    if (!isEqual(second, [0]) || !isEqual(minute, [0]) || !isEqual(hour, [0])) {
      throw new Error(`unsupported cron string ${cronString}`);
    }
    const hasCustomDayOfWeek = dayOfWeek.length < 7;
    const hasCustomDayOfMonth = dayOfMonth.length < 31;
    if (hasCustomDayOfMonth && hasCustomDayOfWeek) {
      throw new Error(`having both custom days of week and custom days of month is unsupported for cron string ${cronString}`);
    }
  }

  /**
   * Parses a cron expression string into a cron-parser CronExpression
   *
   * @param {string} cronString - a cron expression string
   * @param {Date} now - an override for the current date
   * @returns {Object} a cron-parser CronExpression object
   */
  static _parse(cronString, { now = getDatetime() } = {}) {
    CronFrequency._assertSupported(cronString);
    const currentDate = _parseDate(now).toDate();
    const options = { currentDate, iterator: true };
    try {
      const iter = cronParser.parseExpression(cronString, options);
      return iter;
    } catch (e) {
      throw new Error(`cron string '${cronString}' is invalid`);
    }
  }

  /**
   * Given a cron expression and a date, it returns the previous matching date
   *
   * @param {string} cronString - a cron expression string
   * @param {Date} now - an override for the current date
   * @param {boolean} inclusive - whether 'now' will be considered as a match
   * @returns {moment} the previous matching date
   */
  static getPrevDate(cronString, { now = getDatetime(), inclusive = false } = {}) {
    if (CronFrequency.isMatchingDate(cronString, now) && inclusive) {
      return _parseDate(now);
    }

    // cron calculations are expensive, so calculate them once per case,
    // memoize and reuse
    const memoized = get(_prevMemo, [cronString, moment(now).toISOString()]);
    if (!isNil(memoized)) {
      return memoized;
    }
    const iter = CronFrequency._parse(cronString, { now });
    const result = moment(iter.prev().value.toDate());
    // memoize result to reuse later
    set(_prevMemo, [cronString, moment(now).toISOString()], result);
    return result;
  }

  /**
   * Given a cron expression and a date, it returns the next matching date
   *
   * @param {string} cronString - a cron expression string
   * @param {Date} now - an override for the current date
   * @param {boolean} inclusive - whether 'now' will be considered as a match
   * @returns {moment} the next matching date
   */
  static getNextDate(cronString, { now = getDatetime(), inclusive = false } = {}) {
    if (CronFrequency.isMatchingDate(cronString, now) && inclusive) {
      return _parseDate(now);
    }
    // cron calculations are expensive, so calculate them once per case,
    // memoize and reuse
    const memoized = get(_nextMemo, [cronString, moment(now).toISOString()]);
    if (!isNil(memoized)) {
      return memoized;
    }
    const iter = CronFrequency._parse(cronString, { now });
    const result = moment(iter.next().value.toDate());
    // memoize result to reuse later
    set(_nextMemo, [cronString, moment(now).toISOString()], result);
    return result;
  }

  /**
   * Given a cron expression and a date, it returns whether the date matches the expression
   *
   * @param {string} cronString - a cron expression string
   * @param {Date} date -the date to test
   * @returns {boolean} whether the date matches the expression
   */
  static isMatchingDate(cronString, date) {
    // cron calculations are expensive, so calculate them once per case,
    // memoize and reuse
    const memoized = get(_matchingMemo, [cronString, moment(date).toISOString()]);
    if (!isNil(memoized)) {
      return memoized;
    }
    const currentDate = _parseDate(date);
    const yesterday = moment(currentDate).subtract(1, 'day');
    const iter = CronFrequency._parse(cronString, { now: yesterday });
    const nextOfYesterday = moment(iter.next().value.toDate());
    const result = currentDate.isSame(nextOfYesterday);
    // memoize result to reuse later
    set(_matchingMemo, [cronString, moment(date).toISOString()], result);
    return result;
  }

  static toHumanizedString(cronString) {
    const iter = CronFrequency._parse(cronString, { now: '2024-01-01' });
    const { dayOfMonth, month, dayOfWeek } = iter.fields;

    const monthString = month.length === 12 ? 'each month' : _joinArray(month.map(m => MONTH_NAMES[m - 1]));
    const dayOfWeekString = dayOfWeek.length >= 7 ? '' : `each ${_joinArray(dayOfWeek.map(d => WEEKDAY_NAMES[d]))}`;
    const dayOfMonthString = dayOfMonth.length === 31 ? 'any day' : _joinArray(dayOfMonth.map(toOrdinal));

    if (!dayOfWeekString) {
      return `the ${dayOfMonthString} of ${monthString}`;
    }
    if (month.length !== 12) {
      return `${dayOfWeekString} of ${monthString}`;
    }
    return `${dayOfWeekString}`;
  }

  /**
   * Naive check to see if all dates in cron string appear to be monthly dates.
   *
   * It's difficult to determine if the cron expression was intended to be
   * month dates or week days when parsing, as the 'fields' for both
   * 'dayOfMonth' and 'dayOfWeek' will look valid.  As we use '*' when not
   * selecting these fields, the parser will populate them with every valid
   * value, essentially expanding the '*' to be every value possible.
   * For now let's just look at the raw cron expression to determine
   * intention until we can find a better way.
   *
   * @param {string} cronString - cron expression string.
   * @return {boolean} true if all dates appear monthly, rather than days of the week.
   */
  static isOnlyMonthDates(cronString) {
    const components = cronString.split(' ');
    return components[4] === '*' && components[2] !== '*';
  }

  /**
   * Naive check to see if all dates in cron string appear to be week days.
   *
   * It's difficult to determine if the cron expression was intended to be
   * month dates or week days when parsing, as the 'fields' for both
   * 'dayOfMonth' and 'dayOfWeek' will look valid.  As we use '*' when not
   * selecting these fields, the parser will populate them with every valid
   * value, essentially expanding the '*' to be every value possible.
   * For now let's just look at the raw cron expression to determine
   * intention until we can find a better way.
   *
   * @param {string} cronString - cron expression string.
   * @return {boolean} true if all dates appear as week days, rather than dates of the month.
   */
  static isOnlyWeekDays(cronString) {
    const components = cronString.split(' ');
    return components[2] === '*' && components[4] !== '*';
  }

  /**
   * Parse out days of the week.
   *
   * @param {string} cronString - cron.
   * @return {number[]} days of week list.
   */
  static daysOfTheWeek(cronString) {
    const interval = cronParser.parseExpression(cronString);
    return interval.fields.dayOfWeek.map(n => `${n}`);
  }

  /**
   * Parse out dates of the month.
   *
   * @param {string} cronString - cron.
   * @return {number[]} dates of the month list.
   */
  static datesOfTheMonth(cronString) {
    const interval = cronParser.parseExpression(cronString);
    return interval.fields.dayOfMonth.map(n => `${n}`);
  }
}

export default CronFrequency;
