import React from 'react';
import PropTypes from 'prop-types';

import { DRAG_HANDLE_CLASS, ICON } from 'core/assets/js/constants';

/**
 * Used for draggable elements as the drag handle of the element
 * @returns {*}
 * @constructor
 */
const DragHandle = ({ classes }) => {
  const classNames = [ICON.DOUBLE_DRAG_HANDLE, DRAG_HANDLE_CLASS];
  if (classes) {
    classNames.push(classes);
  }
  return <i className={classNames.join(' ')} />;
};

DragHandle.propTypes = {
  classes: PropTypes.string,
};

DragHandle.defaultProps = {
  classes: '',
};

export default DragHandle;
