import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import SkillList from 'core/assets/js/components/SkillList.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import {
  fetchProjectTaskAnalyticsDS,
  fetchProjectTaskItemsDS,
} from 'projects/assets/js/data-services/tasks';
import { projectTaskSpec, taskAssignmentSpec } from 'projects/assets/js/lib/objectSpecs';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import TaskWatchWidget from 'projects/assets/js/components/widgets/TaskWatchWidget.jsx';
import TaskDetailsWidget from 'projects/assets/js/components/widgets/TaskDetailsWidget.jsx';
import TaskChecklistWidget from 'projects/assets/js/components/widgets/TaskChecklistWidget.jsx';
import TaskAttachmentsWidget from 'projects/assets/js/components/widgets/TaskAttachmentsWidget.jsx';
import AnswersList from 'core/assets/js/components/AnswersList.jsx';
import TagList from 'core/assets/js/components/TagList.jsx';
import { TASK_STATUS, TASK_STATUS_CLASS } from 'projects/assets/js/constants';

export const COMPONENT_NAME = 'task-dashboard-tab';

const TaskDashboardTab = ({
  accessControl,
  assignments,
  match,
  onInfoUpdated,
  onItemToggled,
  onStateUpdated,
  task,
  taskItems,
}) => {
  const { answers, attachments, description, skills, status, title } = task;
  const { params: { orgAlias, id: projectId, taskId } } = match;

  return (
    <div className="row">
      <div className="col-12 col-md-8 mb-8 mb-md-4">
        <Card className="project-task__details">
          <Card.Header>
            <div className="row">
              <div className="col-10 strong font-weight-bolder" title={title}>
                {title}
              </div>
              <div className="col-2 text-right">
                {accessControl.isManager && (
                  <TaskWatchWidget
                    task={task}
                    allowedActions={accessControl}
                    onStateUpdated={onStateUpdated}
                  />
                )}
              </div>
            </div>
          </Card.Header>
          {(
            status === TASK_STATUS.COMPLETED
            && (accessControl.canAssignTask || assignments.some(a => a.allowedActions.canReOpen))
            && (
              <TDSystemMessage className="mt-4 mx-4" title="You can re-open this task">
                To re-open this task, either invite a new provider or re-open an existing task
                assignment
              </TDSystemMessage>
            )
          )}
          <Card.Body>
            {description && (
              <div className="project-task__description">
                <h3 className="mt-0">
                  Description
                </h3>

                <MarkdownText
                  withBreaksPlugin
                  text={description}
                />
                <hr />
              </div>
            )}

            <div className="project-task__tags mb-4">
              <h3 className="mb-2">
                Tags
              </h3>
              <TagList tags={task.tags} />
            </div>

            {!isEmpty(attachments) && (
              <div className="project-task__attachments">
                <h3 className="mt-0 mb-2">
                  Attachments
                </h3>

                <div className="attachments-list mb-4">
                  <TaskAttachmentsWidget
                    attachments={attachments}
                    className="d-flex"
                  />
                </div>

                <hr />
              </div>
            )}

            <AnswersList
              answers={answers}
              labelClass="h3 mb-2 text-dark"
              wrapperClass="answer-value mb-4"
            />

            <h3 className="mb-2">
              Checklist
            </h3>


            <TaskChecklistWidget
              taskId={+taskId}
              task={task}
              items={taskItems}
              orgAlias={orgAlias}
              projectId={+projectId}
              parentComponentName={COMPONENT_NAME}
              allowedActions={accessControl}
              onItemToggled={onItemToggled}
              onInfoUpdated={onInfoUpdated}
            />

            {Array.isArray(skills) && skills.length > 0 && (
              <>
                <h3 className="mt-5">Skills</h3>
                <div className="mb-4"><SkillList skills={skills} /></div>
              </>
            )}
          </Card.Body>
        </Card>
      </div>
      <div className="col-12 col-md-4 mb-8 mb-md-0">
        <Card className="project-task__details">
          <Card.Header className="d-flex align-items-center justify-content-between">
            <div>Task details</div>
            <StatusTag
              statusClass={TASK_STATUS_CLASS[task.status]}
              label={task.statusLabel}
              title={task.statusLabel}
            />
          </Card.Header>
          <Card.Body>
            <TaskDetailsWidget
              allowedActions={accessControl}
              assignments={assignments}
              task={task}
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

TaskDashboardTab.propTypes = {
  accessControl: PropTypes.object,
  assignments: PropTypes.arrayOf(taskAssignmentSpec).isRequired,
  dispatch: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  match: routerMatchContentsSpec.isRequired,
  onInfoUpdated: PropTypes.func,
  onItemToggled: PropTypes.func,
  onStateUpdated: PropTypes.func.isRequired,
  task: projectTaskSpec.isRequired,
  taskItems: PropTypes.array,
};

TaskDashboardTab.defaultProps = {
  accessControl: {},
  taskItems: [],
  onItemToggled: () => {},
  onInfoUpdated: () => {},
};

const mapStateToProps = state => ({
  taskItems: getListState(state, COMPONENT_NAME).items,
});

const mapDispatchToProps = dispatch => ({ dispatch });

const TaskDashboardTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskDashboardTab);

const TdApiConnectedTaskDashboardTab = withTDApiConnected({
  fetchData: ({ dispatch, params, url, authedAxios, componentName }) => {
    const { orgAlias, id: projectId, taskId } = params;
    return Promise.all([
      dispatch(
        fetchProjectTaskItemsDS({ orgAlias, projectId, taskId, authedAxios, url, componentName }),
      ),
      dispatch(
        fetchProjectTaskAnalyticsDS({
          orgAlias, projectId, taskId, authedAxios, url, componentName,
        }),
      ),
    ]);
  },
  duck: 'list',
  storeKey: COMPONENT_NAME,
  skeletonComponent: ProjectListSkeleton,
})(TaskDashboardTabConnected);

export default withRouter(TdApiConnectedTaskDashboardTab);
