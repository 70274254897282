import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Table from 'core/assets/js/components/Table.jsx';
import ProjectStatus from 'projects/assets/js/components/ProjectStatus.jsx';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { projectViewUrl } from 'projects/urls';
import { PROJECT_TABS } from 'core/assets/js/constants';

function projectStatusFormatter(cell) {
  return (<ProjectStatus statusLabel={cell} />);
}

const ProjectsBudgetTable = ({ projects, history, match, ...rest }) => {
  const currency = (projects && projects.length && projects[0].currency ? ` (${projects[0].currency})` : '');
  const tableColumns = [
    { key: 'createdAt', label: 'Date', isDate: true },
    { key: 'title', label: 'Project' },
    { key: 'managerName', label: 'Manager' },
    { key: 'totalBudget', label: `Total${currency}`, columnClassName: 'text-right', isFixedDecimals: true },
    { key: 'usedBudget', label: `Used${currency}`, columnClassName: 'text-right', isFixedDecimals: true },
    { key: 'availableBudget', label: `Available${currency}`, columnClassName: 'text-right', isFixedDecimals: true },
    { key: 'statusLabel', label: 'Status', dataFormat: projectStatusFormatter },
  ];
  return (
    <Table
      cols={tableColumns}
      items={projects}
      {...rest}
      onRowClick={(rowData) =>
        history.push(projectViewUrl(
          match.params.orgAlias,
          rowData.id,
          PROJECT_TABS.PURCHASE_ORDERS,
        ))
      }
    />
  );
};

ProjectsBudgetTable.propTypes = {
  projects: PropTypes.array,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
};
ProjectsBudgetTable.defaultProps = {
  projects: [],
};

export default withRouter(ProjectsBudgetTable);
