import React, { useMemo } from 'react';
import moment from 'moment';
import {
  Datagrid,
  DateField,
  FunctionField,
  Link,
  List,
  TextField,
  useGetOne,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Money } from 'td-finance-ts';

import { API_DATE_FORMAT } from 'core/assets/js/constants';
import BackButton from 'admin/assets/js/components/BackButton.jsx';
import AuditLevel from 'admin/assets/js/resources/fact_audit_log_groups/AuditLevel';
import InvoiceAuditListModal from 'admin/assets/js/resources/fact_audit_log_groups/InvoiceAuditListModal';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton';

const useStyles = makeStyles({
  divider: {
    borderRight: '1px solid #e9e9e9',
    margin: '0 16px 0 -8px',
  },
});

const getFilterValue = (location, filterName) => {
  const { filter } = qs.parse(location.search);
  if (filter) {
    const parsedFilter = JSON.parse(filter);
    if (parsedFilter[filterName]) {
      return parsedFilter[filterName];
    }
  }
  return null;
};

const useGetOrg = () => {
  // parse orgId from url query params
  const location = useLocation();
  const orgId = useMemo(() => getFilterValue(location, 'orgId'), [location]);
  // fetch org for id ( will not fetch for null )
  const { data } = useGetOne('organizations', orgId);

  // return name from org once fetched
  return {
    orgId,
    orgName: data ? data.name : '',
  };
};

const useGetInvoicingDate = () => {
  // parse billingProcessDate from url query param
  const location = useLocation();
  const billingProcessDate = useMemo(() => getFilterValue(location, 'billingProcessDate'), [location]);

  const invoicingDate = moment(billingProcessDate)
    .add(1, 'day')
    .format(API_DATE_FORMAT);

  return invoicingDate;
};

const buildInvoiceNumberDisplay = (context, invoiceId, invoiceNumber, invoiceUniqueNumber) => {
  // build message string
  let msg;
  if (invoiceUniqueNumber) {
    // eslint-disable-next-line prefer-template
    msg = `${invoiceUniqueNumber}${invoiceNumber && invoiceNumber !== invoiceUniqueNumber ? ' (' + invoiceNumber + ')' : ''}`;
  } else if (context) {
    msg = context.invoiceNumber;
  }

  // build link mark up, if invoice id available
  if (invoiceId) {
    return (<Link to={`/invoices/${invoiceId}`}>{msg}</Link>);
  }

  // build label mark up
  return (<p>{msg || '-'}</p>);
};

const FactAuditLogsList = (props) => {
  const classes = useStyles();

  const { orgId, orgName } = useGetOrg();
  const invoicingDate = useGetInvoicingDate();

  return (
    <>
      <div className="d-flex justify-content-between my-4">
        <div className="d-flex align-items-center">
          <BackButton />
          <span className={classes.divider}>&nbsp;</span>
          <h2 className="my-0">
            {orgName ? `Audit of ${orgName} invoices` : 'Audit of invoices'}
          </h2>
        </div>
        <BulkApiActionButton
          variant="contained"
          color="primary"
          successNotifMsg="Audits started"
          label="Re-Run"
          ids={[orgId]}
          resource="audits"
          action="run"
          payload={{ date: invoicingDate }}
          title={`Re-run audits for organization ${orgId} - ${orgName} on ${invoicingDate}`}
          content="Are you sure you want to re-run audits for this organization ?"
        />
      </div>
      <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        filterDefaultValues={{ billingProcessDate: '2024-09-30', orgId: '545' }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        actions={false}
      >
        <Datagrid hover={false}>
          <TextField emptyText="-" source="groupDescriptor" label="Group" />
          <FunctionField
            label="Invoice number"
            render={({
              context,
              invoiceId,
              invoiceNumber,
              invoiceUniqueNumber,
            }) => buildInvoiceNumberDisplay(
              context, invoiceId, invoiceNumber, invoiceUniqueNumber,
            )}
          />
          <FunctionField
            label="Type"
            render={({ context }) => (
              <p>{context ? context.invoiceType : '-'}</p>
            )}
          />
          <FunctionField
            label="Amount"
            render={({ context }) => (
              <p>{(context && context.invoiceCurrency) ? new Money(context.invoiceAmount, context.invoiceCurrency).toString({ withSymbol: true, humanizeAmount: true }) : '-'}</p>
            )}
          />
          <FunctionField
            label="Audit status"
            render={({ highestLevel, level }) => (
              <AuditLevel level={highestLevel || level} />
            )}
          />
          <DateField
            label="Created at"
            source="createdAt"
            showTime
          />
          <FunctionField
            label=""
            render={(record) => (
              <>
                <InvoiceAuditListModal
                  factAuditLog={record}
                  orgId={orgId}
                  orgName={orgName}
                  invoicingDate={invoicingDate}
                />
              </>
            )}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default FactAuditLogsList;
