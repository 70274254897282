import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';
import { isEmail } from 'validator';

import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import DocumentAssignmentsListItem from 'core/assets/js/components/DocumentAssignmentsListItem.jsx';
import DocumentSelectField from 'core/assets/js/components/FinalFormFields/DocumentSelectField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE, MODAL_SIZES } from 'core/assets/js/constants';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { fetchViewDS, viewUpdateKeysAC } from 'core/assets/js/ducks/view';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import {
  DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS, DOCUMENT_ASSIGNMENT_STATUS,
} from 'documents/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { documentOptionsApiUrl, documentRequestSignaturesApiUrl } from 'documents/urls';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';
import { getProfileOnboardingInfo } from 'people/urls';

const ADD_CONTRACT_MODAL_ID = 'profile-onboarding-add-contract-modal';

const PeopleOnboardingTab = ({ parentComponentName }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const activeUserCard = useSelector(selectActiveUserCard);
  const isAddContractModalOpen = useSelector(state => getIsModalOpen(state, ADD_CONTRACT_MODAL_ID));

  const [submittingAddContract, setSubmittingAddContract] = useState(false);

  const { isAnyManager } = activeUserCard.userRole;
  const isThemself = activeUserCard.user.id === parseInt(params.userId, 10);

  const fetchDocumentAssignmentsUrl = getProfileOnboardingInfo(params.orgAlias, params.userId);
  const documentAssignmentsKey = 'PeopleOnboardingTab-document-assignments';
  const {
    hasLoaded: hasLoadedDocumentAssignments, item: { documentAssignments } = {},
  } = fetchDataHook({ componentName: documentAssignmentsKey, url: fetchDocumentAssignmentsUrl });
  const { item: documentOptions = [] } = fetchDataHook({
    componentName: 'PeopleOnboardingTab-document-options',
    url: documentOptionsApiUrl(params.orgAlias, true),
  });

  const hasContractToSign = (documentAssignments || []).some(da => (
    // The assignment is pending
    da.status === DOCUMENT_ASSIGNMENT_STATUS.PENDING
    && (
      // Has no signatures
      (!Array.isArray(da.signatures) || da.signatures.length === 0)
      // The assignee has not signed
      || (
        Array.isArray(da.signatures)
        && da.signatures.length > 0
        && da.signatures.some(s => (
          !s.isCounterSigner && s.status === DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS.PENDING
        ))
      )
    )
  ));

  const reloadProfile = () => {
    const fetchDS = params.userType === PEOPLE_TYPE.PROVIDERS ? fetchProviderDS : fetchManagerDS;
    dispatch(fetchDS({ componentName: parentComponentName, params }));
  };

  const onCloseModal = () => dispatch(modalCloseAC());

  return (
    <>
      {hasLoadedDocumentAssignments && (isAnyManager || isThemself) && (
        <>
          <AreaCollapsible
            baseHeadingClassName="w-100"
            className="profile-onboarding-collapsible"
            contentChildren={(
              <>
                {hasContractToSign && (
                  <TDSystemMessage
                    className="mb-5 mx-3"
                    title="Pending contract template signing!"
                    type={BS_STYLE.WARNING}
                  >
                    Provider has not signed contract templates yet.
                  </TDSystemMessage>
                )}
                <TDList
                  cardItem={{
                    // eslint-disable-next-line react/prop-types, react/no-multi-comp
                    component: ({ item }) => (
                      <DocumentAssignmentsListItem
                        documentAssignment={item}
                        onUpdated={updatedAssignment => {
                          const newDocumentAssignments = [...documentAssignments];
                          const index = newDocumentAssignments.findIndex(a => a.id === item.id);
                          if (index === -1) {
                            return;
                          }
                          newDocumentAssignments[index] = updatedAssignment;
                          dispatch(viewUpdateKeysAC(
                            { documentAssignments: newDocumentAssignments },
                            ['documentAssignments'],
                            documentAssignmentsKey,
                          ));
                          reloadProfile();
                        }}
                      />
                    ),
                  }}
                  emptyListMessage="There aren't any document assignments."
                  items={documentAssignments}
                  listClassName="profile-documents-list m-3"
                />
              </>
            )}
            headingChildren={(
              <div
                className={(
                  'people-onboarding-collapsible-header-content d-flex align-items-center '
                  + 'justify-content-between'
                )}
              >
                <div className="d-flex align-items-center justify-content-start float-left">
                  <div>Required contracts</div>
                  {hasContractToSign && (
                    <StatusTag className="ml-5" label="Pending" statusClass="pending" />
                  )}
                </div>
                <TDButton
                  label="Add contract"
                  onClick={() => dispatch(modalOpenAC(ADD_CONTRACT_MODAL_ID))}
                  type="button"
                  variant={BS_STYLE.PRIMARY}
                />
              </div>
            )}
            headingClassName="px-5 d-flex align-items-center justify-content-between"
            toggleElementClassName={null}
          />
          <Form
            // eslint-disable-next-line consistent-return
            onSubmit={async ({ counterSignerEmails, documentId }) => {
              if (!documentId) {
                return { documentId: 'Please select a document' };
              }
              const document = documentOptions.find(o => o.value === documentId);
              if (!document) {
                return { documentId: 'Please select a document' };
              }
              if (typeof document.countersignersCount === 'number') {
                const errors = {};
                new Array(document.countersignersCount).fill('').forEach((_, index) => {
                  if (!Array.isArray(counterSignerEmails) || !isEmail(counterSignerEmails[index])) {
                    if (!errors.counterSignerEmails) {
                      errors.counterSignerEmails = {};
                    }
                    errors.counterSignerEmails[index] = 'Please enter a valid countersigner email';
                  }
                });
                if (Object.keys(errors).length > 0) {
                  return errors;
                }
              }
              setSubmittingAddContract(true);
              try {
                const { data: { count } } = await axios.post(
                  documentRequestSignaturesApiUrl(params.orgAlias, documentId),
                  { blocking: 1, counterSignerEmails, userIds: [params.userId] },
                );
                if (count === 0) {
                  toastr.warning(
                    'None requested', 'This user already has a pending signature for that contract',
                  );
                } else {
                  toastr.success(
                    'Well Done!', "Successfully added the contract to the user's onboarding",
                  );
                  dispatch(fetchViewDS({
                    componentName: documentAssignmentsKey, url: fetchDocumentAssignmentsUrl,
                  }));
                  reloadProfile();
                }
                onCloseModal();
              } catch (err) {
                return parseAxiosErrorForFinalForm(err);
              } finally {
                setSubmittingAddContract(false);
              }
            }}
            render={({ form, handleSubmit, submitError }) => {
              const { documentId } = form.getState().values;
              const document = documentId && documentOptions.find(o => o.value === documentId);
              return (
                <ModalConfirm
                  closeOnConfirm={false}
                  confirmButtonDisabled={submittingAddContract}
                  confirmLabel="Add"
                  heading="Add contract"
                  onClose={onCloseModal}
                  onConfirm={() => form.submit()}
                  open={isAddContractModalOpen}
                  size={MODAL_SIZES.LARGE}
                >
                  <form onSubmit={handleSubmit}>
                    <DocumentSelectField
                      allowCountersigners
                      isMulti={false}
                      name="documentId"
                      showSelected
                      sublabel={`Do you require invited team members to review and agree to any legal
                        documents before obtaining access to the organization?`}
                    />
                    {typeof document?.countersignersCount === 'number' && (
                      new Array(document.countersignersCount).fill('').map((_, index) => (
                        <TextInputField
                          key={index}
                          label={`Countersigner ${index + 1}`}
                          name={`counterSignerEmails[${index}]`}
                          required
                          type="email"
                        />
                      ))
                    )}
                    {submitError && <div className="clearfix mt-3 text-danger">{submitError}</div>}
                  </form>
                </ModalConfirm>
              );
            }}
          />
        </>
      )}
    </>
  );
};

PeopleOnboardingTab.propTypes = {
  parentComponentName: PropTypes.string.isRequired,
};

export default PeopleOnboardingTab;
