import React from 'react';
import { Form } from 'react-final-form';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, BANK_CURRENCY } from 'core/assets/js/constants';
import { RATE_UNIT_CUSTOM_SELECT_FILTERED } from 'rates/assets/js/constants';

// Form Fields
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import DateRangePickerField from 'core/assets/js/components/FinalFormFields/DateRangePickerField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import CurrencySelectField from 'core/assets/js/components/FinalFormFields/CurrencySelectField.jsx';
import { REVERSE_CHARGE_PLACEHOLDER } from 'settings/assets/js/constants';

const FormsSection = () => (
  <section id="forms">
    <h2>Forms</h2>
    <div className="col-12 p-5 mb-5" style={{ background: '#fff' }}>
      <form>
        <div className="form-group">
          <label htmlFor="ex1">Example label</label>
          <input
            className="form-control"
            id="ex1"
            placeholder="e.g. landing page design"
            type="text"
          />
        </div>
        <div className="form-group">
          <label htmlFor="ex2">Another label</label>
          <textarea
            className="form-control"
            id="ex2"
            placeholder="Describe the project brief..."
          />
        </div>
        <div className="form-group">
          <label htmlFor="ex2">Standard Select</label>
          <select className="form-control">
            <option value="1">Option 1</option>
            <option value="2">Option 2</option>
            <option value="3">Option 3</option>
            <option value="4">Option 4</option>
          </select>
        </div>
        <div className="form-group">
          <TDButton variant={BS_STYLE.PRIMARY} block label="Submit" />
        </div>
      </form>
    </div>

    <h2 className="my-5">FinalForm Fields</h2>

    <Form
      initialValues={{ currency: 'usd' }}
      onSubmit={() => {}}
      render={({ handleSubmit }) => (
        <>
          <form onSubmit={handleSubmit} className="px-0">
            <div className="rounded shadow-sm p-4 bg-white">
              <div className="row">
                <div className="col-12 col-md-3">
                  <CurrencySelectField label="Currency" name="currency" />
                </div>
                <div className="col-12 col-md-3">
                  <CurrencySelectField
                    currencyOptions={BANK_CURRENCY} // <-- Optional
                    label="Currency (Full list)"
                    name="currency-2"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <TextAreaField
                    extraInfo="Text content"
                    name="invoicing_footnote"
                    label="Regulatory compliance text"
                    placeholder={(
                      'e.g Reverse Charge - According to Article 194, 196 of Council Directive '
                      + '2006/112/EEC, VAT liability rests with the service recipient '
                      + `${REVERSE_CHARGE_PLACEHOLDER}.`
                    )}
                    sublabel={(
                      'Based on the payment regulations of your country or jurisdiction, you can '
                      + 'add a footnote to your invoice here'
                    )}
                    type="text"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <TextInputField
                    label="Project title"
                    name="title"
                    placeholder="e.g Landing page design"
                    required
                    type="text"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <TextInputField
                    extraInfo="Some extra details here."
                    label="Project title"
                    name="title"
                    placeholder="e.g Landing page design"
                    popOverContent="Content goes here"
                    popOverTitle="title here"
                    required
                    type="text"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <CheckboxField
                    label="Is this real?"
                    name="text-checkbox"
                    options={[
                      { text: 'Option 1', value: 1 },
                      { text: 'Option 2', value: 2 },
                      { text: 'Option 3', value: 3 },
                    ]}
                    required
                    sublabel="Speak the truth"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <CustomSelectField
                    label="Pick one"
                    name="custom-select-1"
                    options={RATE_UNIT_CUSTOM_SELECT_FILTERED}
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <DateRangePickerField label="Date range picker example" name="dateRange" />
                </div>
                <div className="col-6">
                  <DatePickerField label="Single Date picker example" name="date" />
                </div>
              </div>
            </div>

            <div className="w-100 d-flex mt-4">
              <div className="ml-auto">
                <TDButton label="Cancel" type="button" variant={BS_STYLE.DEFAULT} />
                <TDButton label="Submit" type="submit" variant={BS_STYLE.PRIMARY} />
              </div>
            </div>
          </form>
        </>
      )}
    />
  </section>
);

export default FormsSection;
