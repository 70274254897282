import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { Money } from 'td-finance-ts';

import { BS_STYLE, ICON } from 'core/assets/js/constants';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import {
  selectActiveOrg,
  selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { orgSettingsPaymentsUrl } from 'settings/urls';
import { ORG_SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import {
  financeDeferredPaymentsFeatureFlagApiUrl,
  financePendingDeferredPaymentsMetaApiUrl,
} from 'finance/urls';
import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import {
  extrasUpdateAC,
  getViewState,
  getViewStateExtras,
  viewFetchAC,
} from 'core/assets/js/ducks/view';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { settleDeferredInvoicesDS } from 'finance/assets/js/data-services/view';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';
import InvoicesView from 'finance/assets/js/InvoicesView.jsx';
import LoadingComponent from 'core/assets/js/components/LoadingComponent.jsx';

export const COMPONENT_NAME = 'RequestDeferredPaymentsPayout';
export const EXTRAS_DEFERRED_STORE_KEY = 'deferredPaymentsFeatureSetting';
const REQUEST_DEFERRED_PAYOUT_MODAL_ID = 'request-deferred-payments-payout-modal-id';

const RequestDeferredPaymentsPayout = () => {
  const { orgAlias } = useParams();
  const dispatch = useDispatch();
  const {
    userCard,
    org,
    pendingDeferredPaymentsMeta,
    financeDeferredPaymentsSetting,
    isModalOpen,
  } = useSelector(state => ({
    userCard: selectActiveUserCard(state),
    org: selectActiveOrg(state),
    pendingDeferredPaymentsMeta: getViewState(state, COMPONENT_NAME)?.item,
    financeDeferredPaymentsSetting: getViewStateExtras(
      state, COMPONENT_NAME, EXTRAS_DEFERRED_STORE_KEY,
    ),
    isModalOpen: getIsModalOpen(state, REQUEST_DEFERRED_PAYOUT_MODAL_ID),
  }));
  const [isLoading, setIsLoading] = useState(false);

  const deferredPaymentsFeatureEnabled = financeDeferredPaymentsSetting.value === '1';

  useEffect(() => {
    dispatch(fetchDataDS({
      fetchApiUrl: () => financeDeferredPaymentsFeatureFlagApiUrl(),
      fetchDataAC: responseData => ([
        extrasUpdateAC(
          { [EXTRAS_DEFERRED_STORE_KEY]: responseData.setting },
          COMPONENT_NAME,
        ),
      ]),
    }));
    dispatch(fetchDataDS({
      fetchApiUrl: () => financePendingDeferredPaymentsMetaApiUrl(orgAlias),
      fetchDataAC: responseData => ([
        viewFetchAC(responseData.pendingDeferredPaymentsMeta, COMPONENT_NAME),
      ]),
    }));
  }, [dispatch, orgAlias]);

  if (!deferredPaymentsFeatureEnabled || !org.allow_deferred_contractor_payments) {
    return null;
  }

  const hasPendingDeferredPayments = !isEmpty(pendingDeferredPaymentsMeta.perCurrency);

  const { enableDeferredPayments, userRole } = userCard;
  const isProvider = userRole?.isProvider;


  if (!isProvider || !enableDeferredPayments) {
    return null;
  }

  /**
   * Dispatch action to request a cash out to the backend.
   *
   * Shows a loading anim during the request, and refreshes the invoice
   * view once the action is complete, in order to show the new invoice
   * statuses to the user ( as a kind of proof of action ).
   *
   * Separate toasts are shown on success and failure, the error toast
   * will be accompanied by what ever errors the back end returns.  Although
   * this will result in double toasts, I think the one given here is more
   * informative to the end user.
   */
  const settleDeferredInvoices = () => {
    // show busy
    setIsLoading(true);

    // define a common 'tidy up' for both success and failure flows ( DRY )
    const tidyUp = () => {
      // hide busy
      setIsLoading(false);
      // hide modal
      dispatch(modalCloseAC());

      // reload page ( show updated status of invoices )
      dispatch(requestRefreshAC(InvoicesView.GetComponentName()));
      // update pending check outs
      dispatch(fetchDataDS({
        fetchApiUrl: () => financePendingDeferredPaymentsMetaApiUrl(orgAlias),
        fetchDataAC: responseData => ([
          viewFetchAC(responseData.pendingDeferredPaymentsMeta, COMPONENT_NAME),
        ]),
      }));
    };

    return dispatch(settleDeferredInvoicesDS({
      orgAlias, componentName: COMPONENT_NAME,
    })).then(() => {
      tidyUp();
      toastr.success('Well Done!', 'Payout requested successfully.');
    }).catch(() => {
      tidyUp();
      toastr.error('There was a problem processing your invoices, please try again later');
    });
  };

  return (
    <>
      <TDSystemMessage
        className="mb-4"
        title="Instant payouts are disabled!"
        type={BS_STYLE.INFO}
      >
        <p>
          {' You have disabled Instant payouts by bundling your payments to receive your payment on-demand. If you wish to enable Instant payouts, enable them '}
          <Link
            to={orgSettingsPaymentsUrl(orgAlias, ORG_SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS)}
          >
            here
          </Link>
          .
        </p>
      </TDSystemMessage>
      {!hasPendingDeferredPayments ? (
        <Card className="mb-4 p-4">
          <div className="d-flex align-items-center">
            <i className={`${ICON.CHECK_CIRCLE} text-success mr-2`} />
            <span>All payments are requested for payout!</span>
          </div>
        </Card>
      ) : (
        Object.keys(pendingDeferredPaymentsMeta.perCurrency).map((currency) => {
          const amountFormatted = new Money(
            pendingDeferredPaymentsMeta.perCurrency[currency].totalAmount,
            currency,
          )
            .toString({ humanizeAmount: true, withSymbol: true });

          return (
            <Card className="mb-4 p-4">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  Total payments due
                  <span className="ml-5"><strong>{amountFormatted}</strong></span>
                </div>
              </div>
            </Card>
          );
        })
      )}
      <div className="w-100 text-right">
        <TDButton
          className="mb-4"
          variant={BS_STYLE.PRIMARY}
          onClick={() => {
            dispatch(modalOpenAC(REQUEST_DEFERRED_PAYOUT_MODAL_ID));
          }}
          disabled={!hasPendingDeferredPayments}
        >
          Request payout
        </TDButton>
      </div>
      <ModalConfirm
        heading="Request a payout"
        confirmLabel="Submit"
        open={isModalOpen}
        closeOnConfirm={false}
        onClose={() => dispatch(modalCloseAC(REQUEST_DEFERRED_PAYOUT_MODAL_ID))}
        onConfirm={settleDeferredInvoices}
      >
        <>
          {isLoading && <LoadingComponent />}
          <p>Are you sure you want to request a payout ?</p>
        </>
      </ModalConfirm>
    </>
  );
};

export default RequestDeferredPaymentsPayout;

