import { isNil, omit } from 'lodash';
import { getRate, Money } from 'td-finance-ts';

import { Conversion } from 'finance/assets/js/lib/RateMap';

class TransactionAction {
  /**
   * Returns a new TransactionAction from a TransactionOrder
   *
   * @param {TransactionOrder} transactionOrder - the transaction order
   * @param {Number} expectedRate - the expected FX rate for this transaction order
   * @param {String} expectedFee - the expected fee for this transaction order
   * @returns {TransactionAction} the expected transaction action
   */
  static fromTransactionOrder({ transactionOrder, expectedRate, expectedFee = '0.00' } = {}) {
    const sourceCurrency = transactionOrder.getSourceCurrency();
    const targetCurrency = transactionOrder.getTargetCurrency();
    const sourceAmount = transactionOrder.getSourceAmount();
    const targetAmount = transactionOrder.getTargetAmount();

    const expectedTargetAmount = targetAmount
      || new Money(sourceAmount, sourceCurrency).sub(expectedFee).convert(
        targetCurrency,
        { rate: expectedRate },
      ).toString();
    const expectedSourceAmount = sourceAmount
      || new Money(targetAmount, targetCurrency).convert(
        sourceCurrency,
        { reverseRate: expectedRate },
      ).add(expectedFee).toString();
    return new TransactionAction({
      outgoingCurrency: sourceCurrency,
      targetCurrency,
      outgoingAmount: expectedSourceAmount,
      targetAmount: expectedTargetAmount,
      targetRate: expectedRate,
      totalFee: expectedFee,
    });
  }

  static zero({ outgoingCurrency, targetCurrency }) {
    return new TransactionAction({
      outgoingCurrency,
      outgoingAmount: 0,
      totalFee: 0,
      targetAmount: 0,
      targetCurrency,
      targetRate: 1,
    });
  }

  /**
   * Utility to create transaction actions having same source and target currency
   *
   * @param {number|string} outgoingAmount - the source amount of the transaction
   * @param {currency} currency - the currency of the amount
   * @param {totalFee} currency - the fee of the transaction
   * @return {TransactionAction} a transaction action instance
   *
   */
  static ofSingleCurrency({ outgoingAmount, currency, totalFee }) {
    return new TransactionAction({
      outgoingCurrency: currency,
      outgoingAmount,
      totalFee,
      targetAmount: new Money(outgoingAmount, currency).sub(totalFee).toString(),
      targetCurrency: currency,
      targetRate: 1,
    });
  }

  constructor({
    outgoingCurrency,
    outgoingAmount,
    totalFee,
    targetCurrency,
    targetRate,
    targetAmount,
  }) {
    const netOutgoingAmount = new Money(outgoingAmount, outgoingCurrency)
      .sub(totalFee).toString();
    const details = {
      outgoingCurrency,
      netOutgoingAmount,
      outgoingAmount: new Money(outgoingAmount, outgoingCurrency).toString(),
      totalFee: new Money(totalFee, outgoingCurrency).toString(),
      targetCurrency: targetCurrency.toLowerCase(),
      targetRate: parseFloat(targetRate),
      targetAmount: new Money(targetAmount, targetCurrency).toString(),
    };
    Object.entries(details).forEach(([key, value]) => {
      if (isNil(value)) {
        throw new Error(`${key} is required`);
      }
    });
    Money.assertConvertsTo(
      netOutgoingAmount,
      outgoingCurrency,
      targetRate,
      targetAmount,
      targetCurrency,
      { strict: false },
    );
    Object.assign(this, { details });
  }

  serialize() {
    return omit(this.details, ['netOutgoingAmount']);
  }

  getOutgoingAmount() {
    const { outgoingAmount } = this.details;
    return outgoingAmount;
  }

  getOutgoingCurrency() {
    const { outgoingCurrency } = this.details;
    return outgoingCurrency;
  }

  getOutgoingMoney() {
    const { outgoingAmount, outgoingCurrency } = this.details;
    return new Money(outgoingAmount, outgoingCurrency);
  }

  getNetOutgoingAmount() {
    const { netOutgoingAmount } = this.details;
    return netOutgoingAmount;
  }

  getTargetCurrency() {
    const { targetCurrency } = this.details;
    return targetCurrency;
  }

  getTargetRate() {
    const { targetRate } = this.details;
    return targetRate;
  }

  getTargetAmount() {
    const { targetAmount } = this.details;
    return targetAmount;
  }

  getTargetMoney() {
    const { targetAmount, targetCurrency } = this.details;
    return new Money(targetAmount, targetCurrency);
  }

  getTotalFee() {
    const { totalFee } = this.details;
    return totalFee;
  }

  toConversion() {
    const netOutgoingAmount = this.getNetOutgoingAmount();
    const outgoingCurrency = this.getOutgoingCurrency();
    const transactionTargetAmount = this.getTargetAmount();
    const transactionTargetCurrency = this.getTargetCurrency();
    const targetRate = this.getTargetRate();

    return new Conversion(
      outgoingCurrency,
      targetRate,
      transactionTargetCurrency,
      {
        testFromAmount: netOutgoingAmount, testToAmount: transactionTargetAmount,
      },
    );
  }

  inAnotherCurrency(currency, exchangeRate) {
    const balanceCurrency = this.getOutgoingCurrency();
    const outgoingAmount = this.getOutgoingAmount();
    const netOutgoingAmount = this.getNetOutgoingAmount();
    const netOutgoingInCurrency = new Money(netOutgoingAmount, currency)
      .mul(exchangeRate)
      .toString();
    const totalFee = new Money(this.getTotalFee(), currency).mul(exchangeRate).toString();
    const params = {
      currency,
      exchangeRate,
      exchangeRateCode: `${balanceCurrency}${currency}`.toUpperCase(),
      outgoingAmount: new Money(outgoingAmount, currency).mul(exchangeRate).toString(),
      totalFee,
      netOutgoingAmount: netOutgoingInCurrency,
    };

    return params;
  }

  getExpectedTargetRate() {
    const outgoingCurrency = this.getOutgoingCurrency();
    const targetCurrency = this.getTargetCurrency();
    const { rate } = getRate(
      new Money(this.getNetOutgoingAmount(), outgoingCurrency).toString(),
      outgoingCurrency,
      new Money(this.getTargetAmount(), targetCurrency).toString(),
      targetCurrency,
    );
    return rate;
  }
}

export default TransactionAction;
