import arrayMutators from 'final-form-arrays';
import { isEmpty, keyBy, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, withRouter } from 'react-router-dom';
import qs from 'query-string';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

// Constants and utilities
import { BS_STYLE, IMG_SIZE, MEMBER_SEARCH_TARGET } from 'core/assets/js/constants';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { locationSetParam, locationUnsetParam } from 'core/assets/js/lib/utils';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { orgPeopleListApiUrl } from 'people/urls';
import { taskAssignmentSpec } from 'projects/assets/js/lib/objectSpecs';
import { getDisplayRate } from 'rates/assets/js/lib/utils';
import { RATE_UNIT, RATE_UNIT_FORMAT } from 'rates/assets/js/constants';

// Actions and selectors
import { fetchListDS, getListState, listAddSelectedItemsAC } from 'core/assets/js/ducks/list';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import {
  createWorksheetsAndCompleteTaskDS, createWorksheetsForTaskDS,
} from 'projects/assets/js/data-services/tasks';

// Components
import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import PeopleSearch from 'people/assets/js/components/PeopleSearch.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import UserCard from 'people/assets/js/components/UserCard.jsx';
import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { DISPATCH_EVENT_ON_ELEMENT, DOCUMENT_GET_ELEMENT_BY_ID } from 'core/assets/js/config/settings';

export const MODAL_ID = 'task-raise-worksheets-modal';

const STEPS = {
  BROWSE: 'browse',
  CONFIGURE: 'configure',
};

const ACTIONS = {
  APPROVE: 'approve',
  CONFIRM: 'confirm',
};

const FORM_ID = 'task-worksheets-form';

const getUserCardWarnings = (userCard) => {
  const reasons = [];
  if (!userCard.bankAccountId && !userCard.isEmployee) {
    reasons.push('They have not created a payment method');
  }
  if (!userCard.user.profile.is_complete) {
    reasons.push('They have not completed their profile');
  }
  if (!userCard.user.hasCompanyRecord && !userCard.isEmployee) {
    reasons.push('They have not completed their company information');
  }
  if (reasons.length === 0) {
    return null;
  }
  return reasons;
};

const TaskRaiseWorksheetsPanel = (props) => {
  const {
    assignments,
    dispatch,
    filtersOpen,
    history,
    isOpen,
    match: { params: { id: projectId, orgAlias, taskId } },
    onFiltersToggle,
    organization,
    pagination,
    rootComponentName,
    selectedUserCards,
    taskIsCompleted,
    userCards,
  } = props;

  const [query, setQuery] = useState({});
  const [showSelectedItems, setShowSelectedItems] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const showSelectedItemsToggle = () => setShowSelectedItems(!showSelectedItems);

  const availabilityFilter = query.availability ? JSON.parse(query.availability) : null;

  const handleStepUpdated = step => {
    let newLocation = null;

    if (step === STEPS.CONFIGURE) {
      newLocation = locationSetParam(
        history.location, 'userIds', selectedUserCards.map(p => p.user.id).join(','),
      );
    } else {
      newLocation = locationUnsetParam(history.location, 'userIds');
    }

    history.push(newLocation);
  };

  const userIdsInUrl = qs.parse(history.location.search).userIds;
  const currentStep = isEmpty(userIdsInUrl) ? STEPS.BROWSE : STEPS.CONFIGURE;

  const actionName = ACTIONS[
    organization.second_level_of_service_order_approval ? 'CONFIRM' : 'APPROVE'
  ];

  const assignmentsByUserId = keyBy(assignments, 'userId');
  const selectedUserCardsByUserId = keyBy(selectedUserCards, 'user.id');

  const componentName = TaskRaiseWorksheetsPanel.GetComponentName();

  const selectedUserCardsLength = selectedUserCards.length;
  const hasSelectedUserCards = selectedUserCardsLength > 0;

  useEffect(() => {
    // if user has loaded the task page with ?userIds in the URL, then select those users
    if (userIdsInUrl && !hasSelectedUserCards && userCards.length > 0) {
      userIdsInUrl.split(',').forEach(userId => {
        if (/^\d+$/.test(userId)) {
          const foundProvider = userCards.find(p => p.user.id === parseInt(userId, 10));
          if (foundProvider) {
            dispatch(listAddSelectedItemsAC(foundProvider, componentName));
          }
        }
      });
    }
  }, [userCards]);

  const items = userCards.map(uc => ({ ...uc, disabled: !!getUserCardWarnings(uc) }));

  return (
    <ModalPanel
      body={(
        <>
          {/* Provider selection Step */}
          <SelectableListWrapper
            cardItem={{
              component: UserCard,
              props: {
                availabilityFilter,
                warningMessage: userCard => {
                  const reasons = getUserCardWarnings(userCard);
                  if (!reasons) {
                    return null;
                  }
                  return (
                    <div className="d-flex flex-column flex-nowrap">
                      <p className="mb-0">
                        {`You cannot submit a worksheet for this ${userCard.userRole.title} just `}
                        now:
                      </p>
                      <ul className="bullet-list">
                        {reasons.map(reason => <li key={reason}>{reason}</li>)}
                      </ul>
                      <p className="mb-0">
                        If you complete the task now, you can use &quot;Submit worksheets&quot;
                        {` again, once the ${userCard.userRole.title} is fully set-up.`}
                      </p>
                    </div>
                  );
                },
                // Open link in a new tab so that user doesn't loose selected items.
                linkTarget: '_blank',
                onUpdated: () => {},
                orgAlias,
                showAvailabilityMessages: true,
              },
            }}
            className={currentStep === STEPS.CONFIGURE ? 'd-none' : null}
            componentName={componentName}
            emptyListMessage="No users found"
            emptySelectedListMessage={(
              <p className="discreet">
                There are currently no selected users.
                <br />
                <span className="imitate-link" onClick={showSelectedItemsToggle}>
                  Find users
                </span>
                .
              </p>
            )}
            fetchData={({ querystring, ...rest }) => {
              let newQueryString = qs.parse(querystring) || {};
              if (!newQueryString.userId) {
                newQueryString.userId = assignments.map(a => a.userId);
              }
              newQueryString = qs.stringify(newQueryString, { arrayFormat: 'comma' });
              return TaskRaiseWorksheetsPanel.FetchData({
                querystring: newQueryString,
                ...rest,
              });
            }}
            filtersOpen={filtersOpen}
            items={items}
            onPageChange={newPagination => setQuery({ ...query, ...newPagination })}
            query={query}
            selectModeEnabled
            showSelectedItems={showSelectedItems}
            skeletonComponent={PeopleListSkeleton}
            searchComponent={(
              <PeopleSearch
                filtersOpen={filtersOpen}
                hideStatus
                isManager
                onFiltersChanged={newQuery => setQuery({ ...newQuery, page: pagination.page })}
                onFiltersToggle={onFiltersToggle}
                peopleType={MEMBER_SEARCH_TARGET.PROVIDERS}
                query={query}
                userGroupNames={[]}
              />
            )}
          />
          {currentStep === STEPS.CONFIGURE && (
            <Form
              initialValues={{
                worksheets: selectedUserCards.map(provider => {
                  const assignment = assignmentsByUserId[provider.user.id];
                  const worksheet = {
                    [actionName]: true,
                    userId: provider.user.id,
                  };
                  if (assignment && assignment.rate_unit !== RATE_UNIT.FIXED) {
                    worksheet.quantity = 1;
                  }
                  return worksheet;
                }),
              }}
              keepDirtyOnReinitialize
              mutators={{ ...arrayMutators }}
              name={FORM_ID}
              onSubmit={async ({ worksheets }, { reset }) => {
                setSubmitting(true);
                try {
                  const method = taskIsCompleted
                    ? createWorksheetsForTaskDS
                    : createWorksheetsAndCompleteTaskDS;
                  await dispatch(method({
                    orgAlias,
                    projectId,
                    rootComponentName,
                    taskId,
                    values: worksheets.map(w => {
                      const worksheet = { ...w };
                      if (worksheet.quantity) {
                        // TextInputField sets a string, but the API expects a number
                        worksheet.quantity = parseFloat(worksheet.quantity);
                      }
                      return worksheet;
                    }),
                  }));
                  history.push(locationUnsetParam(history.location, 'userIds'));
                  dispatch(modalCloseAC(MODAL_ID));
                  toastr.success(
                    'Well Done!',
                    `Worksheets submitted${!taskIsCompleted ? ' and task marked as complete' : ''}.`,
                  );
                  // pushDataDS will handle any thrown errors

                  // We need to use keepDirtyOnReinitialize and manually reset here, otherwise
                  // the form will be reset whilst the modal is still open
                  // The reset needs to be done in a setTimeout, to avoid a react-final-form error
                  setTimeout(reset, 500);
                } catch (e) {
                  if (
                    e.errors
                    && Object.keys(e.errors).some(key => !['_error', '_meta'].includes(key))
                  ) {
                    // there are form field level errors
                    return e.errors;
                  }
                  toastr.error('Oh Snap!', e._error || e.message);
                } finally {
                  setSubmitting(false);
                }
                return null;
              }}
              render={({ handleSubmit, form: { change } }) => (
                <form id={FORM_ID} onSubmit={handleSubmit}>
                  <FieldArray name="worksheets">
                    {({ fields }) => fields.map((fieldName, index) => {
                      const field = fields.value[index];
                      const assignment = assignmentsByUserId[field.userId];
                      if (!assignment) {
                        return null;
                      }
                      const provider = selectedUserCardsByUserId[field.userId];
                      const {
                        profileUrl,
                        user: { profile },
                      } = provider;
                      const profilePic = (
                        <ProfilePic
                          url={profile.avatar}
                          alt={profile.name}
                          size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                        />
                      );
                      const actionValue = field[actionName];
                      const getFieldName = name => `${fieldName}${name}`;
                      return (
                        <Card className="card--light-gray" key={provider.user.id}>
                          <Card.Body className="position-relative">
                            <div className="row d-flex align-items-center position-relative">
                              <div className="user-item__basic-info col-12 col-md-auto d-flex">
                                {!profileUrl ? profilePic : (
                                  <Link title={profile.name} to={profileUrl}>
                                    {profilePic}
                                  </Link>
                                )}
                                <div className="col-10 col-md-auto pl-3">
                                  {!profileUrl ? profile.name : (
                                    <Link
                                      className="user-item__title truncate"
                                      title={profile.name}
                                      to={profileUrl}
                                    >
                                      {profile.name}
                                    </Link>
                                  )}
                                  <div
                                    className={[
                                      'user-item__extra', 'discreet', 'd-flex',
                                      'align-items-center', 'col-auto px-0',
                                    ].join(' ')}
                                  >
                                    <div className="truncate">
                                      {profile.jobTitle && (
                                        <span className="truncate" title={profile.jobTitle}>
                                          {profile.jobTitle}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <TextInputField name={getFieldName('userId')} type="hidden" />
                            <TextInputField name={getFieldName(actionName)} type="hidden" />
                            <table className="task-raise-worksheet-table">
                              <thead>
                                <tr>
                                  <th>Rate</th>
                                  <th />
                                  <th>Qty</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {getDisplayRate(
                                      assignment.rate,
                                      assignment.rate_unit,
                                      {
                                        currency: CURRENCY_SYMBOL[assignment.currency],
                                        withRateUnit: false,
                                      },
                                    )}
                                  </td>
                                  <td>
                                    {assignment.rate_unit !== RATE_UNIT.FIXED && 'x'}
                                  </td>
                                  <td>
                                    {assignment.rate_unit === RATE_UNIT.FIXED ? 'Fixed' : (
                                      <InputNumberField
                                        className="mb-0"
                                        name={getFieldName('quantity')}
                                        suffix={(
                                          assignment.rate_unit !== RATE_UNIT.CUSTOM ? (
                                            RATE_UNIT_FORMAT[assignment.rate_unit].unit
                                          ) : 'units'
                                        )}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    <NumberTpl
                                      value={assignment.rate * (field.quantity || 1)}
                                      prefix={CURRENCY_SYMBOL[assignment.currency]}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="d-flex align-items-start">
                              <div
                                className="cursor-pointer d-flex flex-column"
                                onClick={() => change(getFieldName(actionName), !actionValue)}
                              >
                                <div className="d-flex align-items-center">
                                  <TDSwitch selected={actionValue} />
                                  <span className="ml-3">{upperFirst(actionName)}</span>
                                </div>
                                <span className="discreet mt-3">
                                  If enabled, the worksheet will be created and immediately
                                  {actionName === ACTIONS.APPROVE ? (
                                    ' approved, with no further reviewing required'
                                  ) : ' confirmed, then requiring approval by a payments reviewer'}
                                </span>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      );
                    })}
                  </FieldArray>
                </form>
              )}
            />
          )}
        </>
      )}
      data-testid="task-raise-worksheets-panel"
      footer={(
        <>
          {currentStep === STEPS.BROWSE && hasSelectedUserCards && (
            <span
              className="cursor-pointer d-flex align-items-center"
              onClick={showSelectedItemsToggle}
            >
              <TDSwitch selected={showSelectedItems} />
              <span className="ml-3">
                {!showSelectedItems
                  ? `Show ${selectedUserCardsLength} selected users`
                  : 'Show all users'
                }
              </span>
            </span>
          )}

          <div className="ml-auto">
            {currentStep === STEPS.BROWSE && (
              <>
                <TDButton label="Cancel" onClick={() => dispatch(modalCloseAC(MODAL_ID))} />

                <TDButton
                  className="ml-4"
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                  disabled={!hasSelectedUserCards}
                  label="Review"
                  onClick={() => handleStepUpdated(STEPS.CONFIGURE)}
                />
              </>
            )}

            {currentStep === STEPS.CONFIGURE && (
              <>
                <TDButton
                  label="Back"
                  disabled={submitting}
                  onClick={() => handleStepUpdated(STEPS.BROWSE)}
                />

                <TDButton
                  className="ml-4"
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                  disabled={!hasSelectedUserCards || submitting}
                  label={`Submit worksheets${!taskIsCompleted ? ' and complete task' : ''}`}
                  onClick={() => {
                    DISPATCH_EVENT_ON_ELEMENT(
                      DOCUMENT_GET_ELEMENT_BY_ID(FORM_ID),
                      'submit',
                      { cancelable: true },
                    );
                  }}
                />
              </>
            )}
          </div>
        </>
      )}
      heading={(
        <h4>
          {currentStep === STEPS.CONFIGURE ? 'Review ' : 'Select '}
          users to submit a worksheet for
        </h4>
      )}
      modalId={MODAL_ID}
      open={isOpen}
    />
  );
};

TaskRaiseWorksheetsPanel.FetchData = ({
  dispatch, params, url, authedAxios, componentName, querystring,
}) => {
  const { orgAlias, id: projectId, taskId } = params;
  const apiUrl = orgPeopleListApiUrl({ orgAlias }, url);
  const finalQueryString = qs.parse(querystring);
  Object.assign(finalQueryString, { includeCompanyBoolean: 1, projectId, taskId });
  const prerequisites = [
    dispatch(fetchListDS({
      url: apiUrl,
      querystring: qs.stringify(finalQueryString),
      componentName,
      authedAxios,
    })),
  ];

  return Promise.all(prerequisites);
};

TaskRaiseWorksheetsPanel.GetComponentName = () => 'TaskRaiseWorksheetsPanel';

TaskRaiseWorksheetsPanel.propTypes = {
  assignments: PropTypes.arrayOf(taskAssignmentSpec).isRequired,
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool,
  history: routerHistorySpec.isRequired,
  isOpen: PropTypes.bool.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onFiltersToggle: PropTypes.func,
  organization: orgSpec.isRequired,
  pagination: PropTypes.object.isRequired,
  rootComponentName: PropTypes.string.isRequired,
  selectedUserCards: PropTypes.arrayOf(PropTypes.object),
  taskIsCompleted: PropTypes.bool.isRequired,
  userCards: PropTypes.arrayOf(PropTypes.object),
};

TaskRaiseWorksheetsPanel.defaultProps = {
  // passed-through properties by the withFilters HOC
  filtersOpen: false,
  onFiltersToggle: () => {},
  selectedUserCards: [],
  userCards: [],
};

const mapStateToProps = state => {
  const listState = getListState(state, TaskRaiseWorksheetsPanel.GetComponentName());

  return {
    isOpen: getIsModalOpen(state, MODAL_ID),
    organization: selectActiveOrg(state),
    pagination: listState.pagination,
    selectedUserCards: listState.selectedItems,
    userCards: listState.items,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const TaskRaiseWorksheetsPanelReduxConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskRaiseWorksheetsPanel);

export default withRouter(withFilters(TaskRaiseWorksheetsPanelReduxConnect));
