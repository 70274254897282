import PropTypes from 'prop-types';
import React from 'react';
import { useFormState } from 'react-final-form';
import { Link } from 'react-router-dom';

import SelectionField from 'core/assets/js/components/FinalFormFields/SelectionField.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import {
  BS_STYLE, ICON, USER_CARD_DEACTIVATE_COUNT_ITEM_LABELS, USER_CARD_DEACTIVATE_COUNT_ITEMS,
} from 'core/assets/js/constants';
import StatusColFormat from 'finance/assets/js/components/StatusColFormat.jsx';
import {
  financeExpensesUrl, financeProFormaInvoicesUrl, financePurchaseOrderListUrl, financeWorksheetsUrl,
} from 'finance/urls';
import { PROJECT_STATUS_CLASS, TASK_STATUS_CLASS } from 'projects/assets/js/constants';
import { projectViewTaskUrl, projectViewUrl } from 'projects/urls';

const serviceOrderTypeToUrl = {
  [USER_CARD_DEACTIVATE_COUNT_ITEMS.EXPENSES]: financeExpensesUrl,
  [USER_CARD_DEACTIVATE_COUNT_ITEMS.PRO_FORMA_INVOICES]: financeProFormaInvoicesUrl,
  [USER_CARD_DEACTIVATE_COUNT_ITEMS.WORKSHEETS]: financeWorksheetsUrl,
  [USER_CARD_DEACTIVATE_COUNT_ITEMS.PURCHASE_ORDERS]: financePurchaseOrderListUrl,
};

const ManagerDeactivatePendingItems = ({
  managers: managersIn,
  nextOrgPaymentDate,
  orgAlias,
  orgName,
  outstandingCounts,
  userEmail,
  userId,
  userName,
}) => {
  const outstandingEntries = Object.entries(outstandingCounts);
  if (outstandingEntries.length === 0) {
    return null;
  }
  let hasPendingServiceOrders = false;
  const pendingNonTransferable = outstandingEntries.reduce(
    (acc, [key, entries]) => {
      const count = entries.length;
      if (
        [
          USER_CARD_DEACTIVATE_COUNT_ITEMS.EXPENSES,
          USER_CARD_DEACTIVATE_COUNT_ITEMS.PRO_FORMA_INVOICES,
          USER_CARD_DEACTIVATE_COUNT_ITEMS.PURCHASE_ORDERS,
          USER_CARD_DEACTIVATE_COUNT_ITEMS.WORKSHEETS,
          USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECT_MEMBERSHIPS,
        ].includes(key)
        && count > 0
      ) {
        if (
          [
            USER_CARD_DEACTIVATE_COUNT_ITEMS.EXPENSES,
            USER_CARD_DEACTIVATE_COUNT_ITEMS.PRO_FORMA_INVOICES,
            USER_CARD_DEACTIVATE_COUNT_ITEMS.WORKSHEETS,
          ].includes(key)
        ) {
          hasPendingServiceOrders = true;
        }
        acc.push({
          count,
          name: USER_CARD_DEACTIVATE_COUNT_ITEM_LABELS[key],
          url: key === USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECT_MEMBERSHIPS
            ? null
            : `${serviceOrderTypeToUrl[key](orgAlias)}?kw=${encodeURIComponent(userName)}`,
        });
      }
      return acc;
    },
    [],
  );
  const hasPendingNonTransferable = pendingNonTransferable.length > 0;
  const hasInProgressProjects = outstandingCounts[
    USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECTS
  ].length > 0;
  const hasInProgressTasks = outstandingCounts[USER_CARD_DEACTIVATE_COUNT_ITEMS.TASKS].length > 0;
  const { submitErrors = {} } = useFormState();
  const formatManagerSelect = isProject => (_, { id }) => {
    const fieldName = `${isProject ? 'project' : 'task'}_${id}`;
    const managers = managersIn.filter(m => m.value !== userId);
    if (managers.length === 0) {
      const error = submitErrors[fieldName];
      return (
        <>
          <span>There are no other managers in this organization</span>
          {error && (
            <div className="form-group has-error error-summary mt-2">
              <span className="help-block">{error}</span>
            </div>
          )}
        </>
      );
    }
    return (
      <SelectionField
        optionsMapping={managers}
        name={fieldName}
        required
        wrapperClasses="mb-0"
      />
    );
  };
  return (
    <>
      <TDSystemMessage title="Deactivating manager" type={BS_STYLE.WARNING}>
        {'You are about to deactivate '}
        <b>{`${userName} (${userEmail})`}</b>
        .
        <br />
        {`Once deactivated, the user will lose access to ${orgName}. Changes to their pending `}
        items are irreversible
        {hasPendingServiceOrders && (
          <>
            {' and can have payment repercussions. All approved worksheets, expenses and '}
            proforma invoices are going to be voided. If you intend to pay them, please deactivate
            {` the user after ${nextOrgPaymentDate}`}
          </>
        )}
        . You can reactivate the user again in the future.
      </TDSystemMessage>
      {hasPendingNonTransferable && (
        <Table
          containerClass="mt-5"
          cols={[
            {
              dataFormat: (name, { url }) => (
                <>
                  {name}
                  {url && (
                    <Link
                      className="text-dark"
                      to={url}
                    >
                      <span className={`${ICON.ARROW_UP_RIGHT_FROM_SQUARE} ml-3`} />
                    </Link>
                  )}
                </>
              ),
              key: 'name',
              label: 'Item',
            },
            { key: 'count', label: 'Count' },
          ]}
          items={pendingNonTransferable}
        />
      )}
      {(hasInProgressProjects || hasInProgressTasks) && (
        <>
          <h4 className="mt-5">{`Transfer ownership of ${userName}'s items`}</h4>
          <p>{`Please select new managers for all of ${userName}'s projects and tasks.`}</p>
          {hasInProgressProjects && (
            <Table
              containerClass="mt-5"
              cols={[
                {
                  dataFormat: (title, project) => (
                    <Link
                      className="text-dark"
                      rel="noopener noreferrer"
                      target="_blank"
                      to={projectViewUrl(orgAlias, project.id)}
                    >
                      {title}
                    </Link>
                  ),
                  key: 'title',
                  label: 'Project',
                },
                {
                  dataFormat: (statusLabel, project) => (
                    <StatusColFormat
                      statusLabel={statusLabel}
                      statusClass={PROJECT_STATUS_CLASS[project.status]}
                    />
                  ),
                  key: 'status_label',
                  label: 'Status',
                },
                {
                  dataFormat: (budget, project) => (
                    <NumberTpl prefix={project.currency_symbol} value={budget || 0} />
                  ),
                  key: 'budget',
                  label: 'Budget',
                },
                {
                  dataFormat: formatManagerSelect(true),
                  key: 'managers',
                  label: 'New owner',
                },
              ]}
              items={outstandingCounts[USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECTS]}
            />
          )}
          {hasInProgressTasks && (
            <Table
              containerClass="mt-5"
              cols={[
                {
                  dataFormat: (title, task) => (
                    <Link
                      className="text-dark"
                      rel="noopener noreferrer"
                      target="_blank"
                      to={projectViewTaskUrl(orgAlias, task.projectId, task.id)}
                    >
                      {title}
                    </Link>
                  ),
                  key: 'title',
                  label: 'Task',
                },
                {
                  dataFormat: (statusLabel, task) => (
                    <StatusColFormat
                      statusLabel={statusLabel}
                      statusClass={TASK_STATUS_CLASS[task.status]}
                    />
                  ),
                  key: 'statusLabel',
                  label: 'Status',
                },
                {
                  dataFormat: formatManagerSelect(false),
                  key: 'managers',
                  label: 'New owner',
                },
              ]}
              items={outstandingCounts[USER_CARD_DEACTIVATE_COUNT_ITEMS.TASKS]}
            />
          )}
        </>
      )}
    </>
  );
};

ManagerDeactivatePendingItems.propTypes = {
  nextOrgPaymentDate: PropTypes.string.isRequired,
  orgAlias: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  outstandingCounts: PropTypes.object.isRequired,
  userEmail: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  managers: PropTypes.array.isRequired,
};

export default ManagerDeactivatePendingItems;
