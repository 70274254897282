import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { BS_STYLE, CURRENCY_SYMBOL, ICON } from 'core/assets/js/constants';
import {
  getIsModalOpen, getModalPayload, modalCloseAC, modalOpenAC,
} from 'core/assets/js/ducks/modalLauncher';
import { getViewState } from 'core/assets/js/ducks/view';
import axios from 'core/assets/js/lib/tdAxios';
import { formatDate, renderDuration } from 'core/assets/js/lib/utils';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import {
  fetchInvoiceCapUsageDS, fetchTimeTrackerUnusedRecordsDS, getInvoiceCapUsage,
} from 'projects/assets/js/data-services/view';
import { workTimeBlockSpec } from 'projects/assets/js/lib/objectSpecs';
import { createTimeTrackingWorksheetsApiUrl, deleteTimeTrackingApiUrl } from 'projects/urls';
import { INVOICE_CAPS_PERIOD_SHORT_LABELS } from 'settings/assets/js/constants';

export const WorkTimeBlockTable = ({
  onDelete, openWorkTimeBlockForm, workTimeBlocks, readOnly,
}) => {
  const cols = [
    {
      dataFormat: startTime => formatDate(startTime, undefined, true),
      key: 'startTime',
      label: 'Day',
    },
    {
      dataFormat: (_, workTimeBlock) => (
        workTimeBlock.task ? workTimeBlock.task.title : (
          <a className="imitate-link" onClick={() => openWorkTimeBlockForm(workTimeBlock)}>
            Add task
          </a>
        )
      ),
      key: 'taskId',
      label: 'Task',
    },
    { key: 'description', label: 'Description' },
    {
      dataFormat: (_, workTimeBlock) => {
        let value = `${formatDate(workTimeBlock.startTime, 'HH:mm:ss', true)} - `;
        if (workTimeBlock.endTime) {
          value += formatDate(workTimeBlock.endTime, 'HH:mm:ss', true);
        }
        return value;
      },
      key: 'startTime',
      label: 'Start - end',
    },
    {
      dataFormat: (_, workTimeBlock) => {
        if (!workTimeBlock.endTime) {
          return null;
        }

        return renderDuration(workTimeBlock.startTime, workTimeBlock.endTime);
      },
      key: 'startTime',
      label: 'Duration',
    },
    {
      dataFormat: (_, workTimeBlock) => workTimeBlock.amount && (
        <NumberTpl
          prefix={CURRENCY_SYMBOL[workTimeBlock.currency]}
          value={workTimeBlock.amount}
        />
      ),
      key: 'amount',
      label: 'Earned',
    },
  ];

  if (!readOnly) {
    cols.push({
      columnClassName: 'actions-cell',
      dataAlign: 'right',
      dataFormat: (_, workTimeBlock) => (
        workTimeBlock.allowedActions.canEdit || workTimeBlock.allowedActions.canDelete
          ? (
            <TDDropButton stopPropagation>
              {workTimeBlock.allowedActions.canEdit && (
                <Dropdown.Item
                  onClick={e => {
                    e.stopPropagation();
                    openWorkTimeBlockForm(workTimeBlock);
                  }}
                >
                  Edit
                </Dropdown.Item>
              )}
              {workTimeBlock.allowedActions.canDelete && (
                <Dropdown.Item
                  onClick={e => {
                    e.stopPropagation();
                    return onDelete(workTimeBlock.id);
                  }}
                >
                  Delete
                </Dropdown.Item>
              )}
            </TDDropButton>
          ) : null
      ),
      key: 'actions',
      label: '',
      width: '80px',
    });
  }
  return <Table cols={cols} items={workTimeBlocks} />;
};

WorkTimeBlockTable.propTypes = {
  readOnly: PropTypes.bool,
  onDelete: PropTypes.func,
  openWorkTimeBlockForm: PropTypes.func,
  workTimeBlocks: PropTypes.arrayOf(workTimeBlockSpec).isRequired,
};
WorkTimeBlockTable.defaultProps = {
  readOnly: false,
  onDelete: () => {},
  openWorkTimeBlockForm: () => {},
};

const DELETE_MODAL_ID = 'time-tracker-delete-modal';
const WORKSHEETS_MODAL_ID = 'time-tracker-worksheets-modal';

const TimeTrackerUnused = ({ additionalOrganizationAmount, openWorkTimeBlockForm }) => {
  const dispatch = useDispatch();
  const [raisingWorksheets, setRaisingWorksheets] = useState(false);

  const activeOrg = useSelector(selectActiveOrg);
  const activeUserCard = useSelector(selectActiveUserCard);
  const componentName = TimeTrackerUnused.GetComponentName();
  const {
    isLoading, item: { projects = [], withoutTask = [] },
  } = useSelector(state => getViewState(state, componentName));
  const deleteModalIsOpen = useSelector(state => getIsModalOpen(state, DELETE_MODAL_ID));
  const deleteWorkTimeBlockId = useSelector(state => getModalPayload(state, DELETE_MODAL_ID));
  const worksheetsModalIsOpen = useSelector(state => getIsModalOpen(state, WORKSHEETS_MODAL_ID));
  const raiseWorksheetsProjectId = useSelector(state => getModalPayload(
    state, WORKSHEETS_MODAL_ID,
  ));
  const getUsageData = useSelector(getInvoiceCapUsage);
  const usageData = getUsageData({ additionalOrganizationAmount });

  const loadTimeBlocks = () => dispatch(fetchTimeTrackerUnusedRecordsDS({
    componentName, orgAlias: activeOrg.alias,
  }));

  useEffect(() => {
    loadTimeBlocks();
  }, []);

  if (isLoading) {
    return <FinanceTableSkeleton />;
  }

  const openModal = (modalId, payload) => dispatch(modalOpenAC(modalId, payload));

  const onDelete = id => openModal(DELETE_MODAL_ID, id);

  const closeModal = () => {
    dispatch(modalCloseAC());
  };

  const hasTaskTimeBlocks = projects.length > 0;
  const hasWithoutTask = withoutTask.length > 0;
  const hasTimeBlocks = hasTaskTimeBlocks || hasWithoutTask;

  const canDelete = hasTimeBlocks && (
    withoutTask.some(wtb => wtb.allowedActions.canDelete)
      || projects.some(p => p.workTimeBlocks.some(wtb => wtb.allowedActions.canDelete))
  );

  const hasUsedInvoiceCap = usageData.invoiceCapIsSet && usageData.usageAt100;
  const isExceedingInvoiceCap = usageData.invoiceCapIsSet && usageData.over100;
  const autoApproveWithinCap = activeOrg.invoice_caps_auto_approve_within_cap;
  const canRaiseBeyondCap = activeOrg.invoice_caps_allow_raising_beyond_cap;
  const capPeriod = INVOICE_CAPS_PERIOD_SHORT_LABELS[activeOrg.invoice_caps_period];

  const reloadInvoiceCapUsage = () => {
    if (activeOrg.invoice_caps_enabled && activeUserCard.invoiceCap) {
      dispatch(fetchInvoiceCapUsageDS({
        orgAlias: activeOrg.alias, userId: activeUserCard.user.id,
      }));
    }
  };

  return (
    <>
      {hasTimeBlocks && (
        <>
          {hasWithoutTask && (
            <>
              <h2>Time entries without selected task</h2>
              <WorkTimeBlockTable
                onDelete={onDelete}
                openWorkTimeBlockForm={openWorkTimeBlockForm}
                workTimeBlocks={withoutTask}
              />
            </>
          )}
          {projects.map(project => (
            <React.Fragment key={project.id}>
              <h2 className="mt-5 row align-items-end mb-0">
                <div className="col mb-5">
                  {project.title}
                </div>

                <div className="col-auto align-items-end mb-5">
                  <TDButton
                    className="text-nowrap"
                    floatRight
                    onClick={() => openModal(WORKSHEETS_MODAL_ID, project.id)}
                    label="Create worksheet"
                  />
                </div>
              </h2>
              <WorkTimeBlockTable
                onDelete={onDelete}
                openWorkTimeBlockForm={openWorkTimeBlockForm}
                workTimeBlocks={project.workTimeBlocks}
              />
              <div className="time-tracker-total p-4 d-flex justify-content-end">
                <div className="label">Total amount</div>
                <div className="amount">
                  {project.totalAmount && (
                    <NumberTpl
                      prefix={CURRENCY_SYMBOL[project.totalCurrency]}
                      value={project.totalAmount}
                    />
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
          {canDelete && (
            <ModalConfirm
              body="Are you sure you want to delete this time entry?"
              confirmLabel="Delete"
              confirmStyle={BS_STYLE.DANGER}
              heading="Delete time entry"
              onClose={closeModal}
              onConfirm={async () => {
                try {
                  await axios.delete(deleteTimeTrackingApiUrl(
                    activeOrg.alias, deleteWorkTimeBlockId,
                  ));
                  loadTimeBlocks();
                  reloadInvoiceCapUsage();
                  toastr.success('Well Done!', 'Time entry deleted successfully');
                } catch (err) {
                  toastr.error('Oh Snap!', err.response?.data?._error || err.message);
                }
              }}
              open={deleteModalIsOpen}
            />
          )}
          {hasTaskTimeBlocks && (
            <ModalConfirm
              body={(
                <>
                  <p>Are you sure you want to create worksheets?</p>
                  <p>
                    Time entries will be removed from the Time tracker and added to a
                    Worksheet. If the Worksheet is cancelled, voided or rejected the times
                    will return to the Time tracker.
                  </p>
                  {hasUsedInvoiceCap && (
                    <>
                      <p>{`You have used your invoice cap for this ${capPeriod}.`}</p>
                      {canRaiseBeyondCap && (
                        <p>
                          {`Any more worksheets you raise in this ${capPeriod}, will need to be `}
                          approved.
                        </p>
                      )}
                      {!canRaiseBeyondCap && (
                        <p>{`You cannot raise any more worksheets in this ${capPeriod}`}</p>
                      )}
                    </>
                  )}
                  {!hasUsedInvoiceCap && isExceedingInvoiceCap && (
                    <>
                      <p>{`You are exceeding your invoice cap for this ${capPeriod}.`}</p>
                      <p>
                        Worksheets for any time entries within your cap will be submitted
                        {autoApproveWithinCap && ' and auto-approved.'}
                        {!autoApproveWithinCap && ' and will need to be approved.'}
                      </p>
                      <p>
                        Worksheets for any time entries outside of your cap will
                        {canRaiseBeyondCap && ' be submitted and will need to be approved.'}
                        {!canRaiseBeyondCap && ' not be submitted.'}
                      </p>
                    </>
                  )}
                  <p>
                    We will create the worksheets in the background, which could take some time.
                  </p>
                  <p>We will notify you when they are created, or if there are any problems.</p>
                </>
              )}
              confirmButtonDisabled={raisingWorksheets}
              confirmLabel="Create"
              heading="Create worksheets"
              onClose={closeModal}
              onConfirm={async () => {
                try {
                  setRaisingWorksheets(true);
                  await axios.post(createTimeTrackingWorksheetsApiUrl(
                    activeOrg.alias, raiseWorksheetsProjectId,
                  ));
                  toastr.success(
                    'Well Done!',
                    'We have started raising worksheets, this may take some time, we will notify '
                      + 'you if there are any problems',
                  );
                } catch (err) {
                  toastr.error('Oh Snap!', err.response?.data?._error || err.message);
                } finally {
                  setRaisingWorksheets(false);
                }
              }}
              open={worksheetsModalIsOpen}
              showConfirmButton={!hasUsedInvoiceCap || canRaiseBeyondCap}
            />
          )}
        </>
      )}
      {!hasTimeBlocks && (
        <div className="no-results d-flex flex-column w-100 align-items-center">
          <i className={`${ICON.TIMER_DUOTONE} fa-4x mb-5`} />
          <p>Track your first task</p>
        </div>
      )}
    </>
  );
};

TimeTrackerUnused.GetComponentName = () => 'TimeTrackerUnused';

TimeTrackerUnused.propTypes = {
  additionalOrganizationAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  openWorkTimeBlockForm: PropTypes.func.isRequired,
};

TimeTrackerUnused.defaultProps = {
  additionalOrganizationAmount: null,
};

export default TimeTrackerUnused;
