/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import {
  List, Edit, Datagrid, Filter,
  SelectInput, TextInput,
  Tab, TabbedShowLayout,
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import ProcessingFeeField from './ProcessingFeeField.jsx';
import LicenceFeeField from './LicenceFeeField.jsx';
import OrgNameField from 'admin/assets/js/components/OrgNameField.jsx';
import InvoicingSettingsForm from './InvoicingSettingsForm.jsx';
import LicenceFeesForm from './LicenceFeesForm.jsx';
import ProcessingFeesForm from './ProcessingFeesForm.jsx';
import OrgPricingScaleField from './OrgPricingScaleField.jsx';
import PricingAudits from './PricingAudits.jsx';

import { ORGANIZATION_STATUS, ORGANIZATION_STATUS_LABEL } from 'organizations/assets/js/constants';
import { INVOICING_FREQUENCY, INVOICING_MODE } from 'finance/assets/js/constants';

const statusOptions = Object.keys(ORGANIZATION_STATUS_LABEL).map(k => ({
  id: k, name: ORGANIZATION_STATUS_LABEL[k],
}));


const invoiceModeOptions = Object.keys(INVOICING_MODE).map(k => ({
  id: k, name: INVOICING_MODE[k],
}));

const invFrequencyConstant = [
  INVOICING_FREQUENCY.WEEKLY, INVOICING_FREQUENCY.BIWEEKLY, INVOICING_FREQUENCY.MONTHLY,
].map(id => ({ id, name: id }));

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      label="Status"
      alwaysOn
      allowEmpty
      source="status"
      choices={statusOptions}
    />
    <SelectInput
      label="Invoice mode"
      alwaysOn
      allowEmpty
      source="invoicing_mode"
      choices={invoiceModeOptions}
    />
    <SelectInput
      label="Invoicing frequency"
      alwaysOn
      allowEmpty
      source="invoicing_frequency"
      choices={invFrequencyConstant}
    />
  </Filter>
);

const PricingEdit = (props) => {
  const { record: { id } } = props;
  return (
    <Container maxWidth="lg">
      <Paper elevation={3}>
        <Edit
          {...props}
          undoable={false}
          component="div"
        >
          <TabbedShowLayout>
            <Tab label="invoicing settings">
              <InvoicingSettingsForm />
            </Tab>
            <Tab label="processing fees">
              <ProcessingFeesForm />
            </Tab>
            <Tab label="licence fees">
              <LicenceFeesForm orgId={id} />
            </Tab>
            <Tab label="audit trail">
              <PricingAudits {...props} />
            </Tab>
          </TabbedShowLayout>
        </Edit>
      </Paper>
    </Container>
  );
};

const PricingList = (props) => {
  return (
    <List
      {...props}
      filters={<ListFilter />}
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      filterDefaultValues={{ status: ORGANIZATION_STATUS.ACTIVE }}
    >
      <Datagrid expand={<PricingEdit />}>
        <OrgNameField label="Organization" />
        <ProcessingFeeField label="Processing Fees" sortable={false} />
        <LicenceFeeField label="Licence Fees" sortable={false} />
        <OrgPricingScaleField label="Pricing scale" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default PricingList;
