import Big from 'big.js';
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import { getDisplayRate } from 'rates/assets/js/lib/utils';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { projectSpec, worksheetItemSpec } from 'projects/assets/js/lib/objectSpecs';
import { RATE_SUGGEST_VALUE, RATE_UNIT } from 'rates/assets/js/constants';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import WorksheetAddItemPanel from 'projects/assets/js/components/WorksheetAddItemPanel.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import ExchangeRateInfoPopOver from 'core/assets/js/components/ExchangeRateInfoPopOver.jsx';
import { determineSelectedRate, getSelectedRateAmountAndUnit } from 'projects/assets/js/lib/utils';
import { exchangeRatesServiceSpec, exchangeRatesSpec } from 'finance/assets/js/lib/objectSpecs';
import WorksheetWorkItemRow from 'projects/assets/js/components/WorksheetWorkItemRow.jsx';

Big.RM = 1;
Big.DP = 2;

export const PROJECT_WORK_ITEM_DESCRIPTION = 'Project work';

class WorksheetItems extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this._getDisabledItems = this._getDisabledItems.bind(this);
    this._determineSelectedRate = this._determineSelectedRate.bind(this);
    this.handleRateSelect = this.handleRateSelect.bind(this);
    this.handleAddSelectedItems = this.handleAddSelectedItems.bind(this);
  }

  /**
   * Invoked when the user selects one of their rates or suggests a new one
   *
   * Resets the previously selected quantity or rate_amount in the case of a newly
   * suggested rate
   */
  handleRateSelect({ rateId, field = 'items[0]' }) {
    const { updateForm } = this.props;
    const selectedRateResponse = this._determineSelectedRate(rateId);
    if (!selectedRateResponse) {
      return;
    }
    const { selectedRateAmount, selectedRate } = selectedRateResponse;
    const suggestingNewRate = rateId === RATE_SUGGEST_VALUE;
    const selectingNewRate = rateId !== RATE_SUGGEST_VALUE && rateId;
    const selectingFixedRate = selectedRate?.unit === RATE_UNIT.FIXED;
    const updatePayload = new Map();

    // User suggests a new rate
    if (suggestingNewRate) {
      updatePayload.set('quantity', 1);
      updatePayload.set('rate_amount', null);
      updatePayload.set('rate', null);
    }

    if (selectingNewRate) {
      if (selectingFixedRate) {
        updatePayload.set('quantity', 1);
      } else {
        updatePayload.set('quantity', null);
      }
      updatePayload.set('rate', selectedRate.amount);
      updatePayload.set('rate_amount', selectedRateAmount);
    }

    updatePayload.forEach((newValue, key) => {
      updateForm(`${field}.${key}`, newValue);
    });
  }

  /**
   * Add items to the list of worksheet items
   *
   * @param {Object[]} selectedItems The item that was just added from the WorksheetAddItemPanel
   */
  handleAddSelectedItems(selectedItems) {
    const { fields, items, project } = this.props;

    const indexPredicate = ({ id, task_id: taskId }) => id === -1 || taskId === null;
    const projectPredicate = ({ id }) => id === -1;
    const taskPredicate = ({ id }) => id !== -1;

    // // find the position in which the new project work should be placed to
    const idx = items.filter(indexPredicate).length;

    const projectWorkItems = selectedItems.filter(projectPredicate);
    const taskItems = selectedItems.filter(taskPredicate);

    if (projectWorkItems) {
      // Insert in proper position.
      const placeProjectItem = projectWorkItem => {
        const rateIsFixed = projectWorkItem.rate_unit === RATE_UNIT.FIXED;
        fields.insert(
          idx,
          {
            ...projectWorkItem,
            // If it is the first project work item, set default description.
            description: (idx === 0) ? project.title : null,
            quantity: rateIsFixed ? 1 : null,
            task_id: null,
          },
        );
      };

      projectWorkItems.forEach(placeProjectItem);
    }

    if (Array.isArray(taskItems) && !isEmpty(taskItems)) {
      const placeTaskItem = (taskItem) => {
        // setting quantity = 1 for tasks with fixed rate
        const rateIsFixed = taskItem.rate_unit === RATE_UNIT.FIXED;
        fields.push({
          ...taskItem,
          quantity: rateIsFixed ? 1 : null,
        });
      };

      taskItems.forEach(placeTaskItem);
    }
  }

  _getDisabledItems() {
    const { items } = this.props;
    return items.map(({ task_id: taskId }) => ({
      id: taskId,
      reason: 'You have already included this item',
    }));
  }

  _determineSelectedRate(selectedRateId) {
    const { defaultRateAmount, defaultRateUnit, organization, rates } = this.props;
    return determineSelectedRate({
      defaultOrgRateUnit: organization.default_rate_unit,
      defaultRateAmount,
      defaultRateUnit,
      rates,
      selectedRateId,
    });
  }

  /**
   * Removes the item from the worksheet
   *
   * @param {Number} selectedRateId
   * @returns {void}
   * @private
   */
  _removeItem(index) {
    const { fields, saveChangesToLocalStorage } = this.props;
    fields.remove(index);
    saveChangesToLocalStorage();
  }

  render() {
    const {
      exchangeRates,
      exchangeRatesService,
      fields,
      isMobileView,
      items,
      organization,
      project,
      rates,
      submitErrors,
      worksheet,
      worksheetCurrency,
      worksheetToOrganizationExchangeRate,
      worksheetToOrganizationExchangeRateUpdatedAt,
    } = this.props;
    const projectWorkItems = items.filter(it => !it.task_id);
    const hasOnlyClaimAmount = !projectWorkItems.some(
      item => item.rate_id !== RATE_SUGGEST_VALUE,
    );

    const worksheetCurrencySymbol = CURRENCY_SYMBOL[worksheetCurrency];

    return (
      <React.Fragment>
        {project && (
          <tr>
            <td>
              <WorksheetAddItemPanel
                disabledItems={this._getDisabledItems()}
                handleAddSelectedItems={this.handleAddSelectedItems}
                project={project}
                rates={rates}
                worksheetCurrency={worksheetCurrency}
              />
            </td>
          </tr>
        )}

        { fields && fields.length > 0 && (
          fields.map((field, index) => {
            const item = items[index];

            const selectedRate = this._determineSelectedRate(item.rate_id);
            const {
              selectedRateAmount, selectedRateUnit,
            } = getSelectedRateAmountAndUnit({
              exchangeRates, item, selectedRate, serviceOrderCurrency: worksheetCurrency,
            });

            let formattedRateAmount = selectedRateAmount
              ? getDisplayRate(selectedRateAmount, selectedRateUnit, {
                currency: worksheetCurrencySymbol,
                withRateUnit: false,
              })
              : null;
            let formattedTotal = !selectedRateAmount ? null : (
              <NumberTpl
                decimals={2}
                prefix={worksheetCurrencySymbol}
                value={Big(selectedRateAmount).times(item.quantity || 0).toFixed(2)}
              />
            );

            const showExchangeRateInfo = (
              worksheetCurrency !== organization.currency
              && worksheetToOrganizationExchangeRate
              && selectedRateAmount
              && Big(selectedRateAmount).toNumber() > 0
            );

            if (showExchangeRateInfo) {
              const commonProps = {
                className: 'ml-2',
                exchangeRate: worksheetToOrganizationExchangeRate,
                exchangeRateDate: worksheetToOrganizationExchangeRateUpdatedAt,
                exchangeRateService: exchangeRatesService,
                sourceCurrency: worksheetCurrency,
                targetCurrency: organization.currency,
              };
              const isCommissionRateUnit = selectedRateUnit === RATE_UNIT.COMMISSION;
              if (!isCommissionRateUnit) {
                formattedRateAmount = (
                  <>
                    {formattedRateAmount}
                    <ExchangeRateInfoPopOver
                      {...commonProps}
                      targetAmount={
                        Big(selectedRateAmount).times(worksheetToOrganizationExchangeRate)
                          .toFixed(2)
                      }
                    />
                  </>
                );
              }
              if (item.quantity > 0) {
                formattedTotal = (
                  <>
                    {formattedTotal}
                    <ExchangeRateInfoPopOver
                      {...commonProps}
                      targetAmount={
                        Big(selectedRateAmount).times(item.quantity)
                          .times(worksheetToOrganizationExchangeRate).toFixed(2)
                      }
                    />
                  </>
                );
              }
            }

            return (
              <WorksheetWorkItemRow
                field={field}
                formattedRateAmount={formattedRateAmount}
                formattedTotal={formattedTotal}
                handleRateSelect={this.handleRateSelect}
                hasOnlyClaimAmount={hasOnlyClaimAmount}
                index={index}
                isMobileView={isMobileView}
                item={item}
                key={field}
                projectWorkItemsCount={projectWorkItems.length}
                rates={rates}
                removeItem={() => this._removeItem(index)}
                selectedRateUnit={selectedRateUnit}
                submitErrors={submitErrors}
                worksheet={worksheet}
                worksheetCurrency={worksheetCurrency}
              />
            );
          })
        )}
      </React.Fragment>
    );
  }
}

WorksheetItems.propTypes = {
  defaultRateAmount: PropTypes.number,
  defaultRateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  exchangeRates: exchangeRatesSpec,
  exchangeRatesService: exchangeRatesServiceSpec,
  fields: PropTypes.object.isRequired,
  isMobileView: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(worksheetItemSpec),
  organization: orgSpec.isRequired,
  project: projectSpec,
  rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
  saveChangesToLocalStorage: PropTypes.func,
  submitErrors: PropTypes.object,
  worksheet: PropTypes.object,
  updateForm: PropTypes.func.isRequired,
  worksheetCurrency: PropTypes.string,
  worksheetToOrganizationExchangeRate: PropTypes.number,
  worksheetToOrganizationExchangeRateUpdatedAt: PropTypes.string,
};

WorksheetItems.defaultProps = {
  defaultRateAmount: null,
  defaultRateUnit: null,
  exchangeRates: {},
  exchangeRatesService: null,
  items: [],
  project: null,
  rate: null,
  rates: [],
  saveChangesToLocalStorage: () => null,
  submitErrors: {},
  worksheet: null,
  worksheetCurrency: null,
  worksheetToOrganizationExchangeRate: null,
  worksheetToOrganizationExchangeRateUpdatedAt: null,
};

export default WorksheetItems;
