import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import Sortable from 'sortablejs';

import { DRAG_HANDLE_CLASS } from 'core/assets/js/constants';

const TDSortable = ({ children, containerClassName, onSortEnd }) => {
  const listContainerRef = useRef(null);

  useEffect(() => {
    Sortable.create(
      listContainerRef.current,
      {
        animation: 150,
        ghostClass: 'opacity-0',
        handle: `.${DRAG_HANDLE_CLASS}`,
        onEnd: onSortEnd,
      },
    );
  }, []);

  return <div className={containerClassName} ref={listContainerRef}>{children}</div>;
};

TDSortable.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  containerClassName: PropTypes.string,
  onSortEnd: PropTypes.func,
};

TDSortable.defaultProps = {
  containerClassName: null,
  onSortEnd: null,
};

export default TDSortable;
