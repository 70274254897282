import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Card, Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { timeOffUrl, timeOffCreateUrl } from 'accounts/urls';
import DateRangePicker from 'core/assets/js/components/DateRangePicker.jsx';
import { API_DATE_FORMAT, DATE_FORMAT_DEFAULT, ICON } from 'core/assets/js/constants';
import moment from 'core/assets/js/lib/tdMoment';
import { userTimeOffApiUrl } from 'people/urls';
import UserTimeOffWidgetSkeleton from 'core/assets/js/components/Skeleton/UserTimeOffWidgetSkeleton.jsx';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';

const UserTimeOffWidget = ({ history, isThemself, items, orgAlias }) => (
  <Card className="mt-5">
    <Card.Header className="d-flex">
      Availability
      { isThemself && (
        <TDDropButton className="ml-auto">
          <Dropdown.Item
            eventKey="time-off"
            onClick={() => history.push(timeOffUrl(orgAlias))}
          >
            Edit availability
          </Dropdown.Item>
        </TDDropButton>
      )}
    </Card.Header>

    <Card.Body>
      <div>
        {items.map((item) => {
          const { id, periodStart, periodEnd, reason, description } = item;
          const periodStartFormatted = moment(periodStart, API_DATE_FORMAT)
            .format(DATE_FORMAT_DEFAULT);
          const periodEndFormatted = moment(periodEnd, API_DATE_FORMAT)
            .format(DATE_FORMAT_DEFAULT);
          const descriptionTooltip = (
            <Tooltip
              id={(`timeoff-tooltip-${id}`)}
            >
              {description}
            </Tooltip>
          );

          const periodNotice = (
            <div key={moment(periodStart, API_DATE_FORMAT).unix()} className="d-flex mb-3">
              {periodStartFormatted}
              {' - '}
              {periodEndFormatted}
              <span className="ml-auto">{reason}</span>
            </div>
          );

          return (
            description
              ? (
                <OverlayTrigger
                  placement="right"
                  overlay={descriptionTooltip}
                >
                  {periodNotice}
                </OverlayTrigger>
              )
              : periodNotice
          );
        })}
      </div>

      { items && items.length === 0 && (
        <div className="discreet">
          {!isThemself && 'No days off set'}

          {isThemself && (
            <div className="d-flex text-center flex-column align-items-center justify-content-center empty-list-message empty-list-message--no-min-height py-5">
              <i className={ICON.USER_CLOCK} />
              You will be shown as available for all days that you do not mark as days off

              <Link className="mt-4" to={timeOffCreateUrl(orgAlias)}>
                Update availability
              </Link>
            </div>
          )}
        </div>
      )}

      {Array.isArray(items) && items.length > 0 && (
        <div className="calendar-wrapper--unavailable d-flex">
          <div className="mx-auto">
            <DateRangePicker
              initialVisibleDate={moment(items[0].periodStart, API_DATE_FORMAT).toDate()}
              isDayHighlighted={date => {
                const itemsLength = items.length;
                for (let i = 0; i < itemsLength; i += 1) {
                  const { periodStart, periodEnd } = items[i];
                  if (
                    periodStart
                    && periodEnd
                    && moment(date).isSameOrAfter(moment(periodStart, API_DATE_FORMAT), 'day')
                    && moment(date).isSameOrBefore(moment(periodEnd, API_DATE_FORMAT), 'day')
                  ) {
                    return true;
                  }
                }
                return false;
              }}
              showSecondMonth={false}
            />
          </div>
        </div>
      )}
    </Card.Body>
  </Card>
);

UserTimeOffWidget.propTypes = {
  isThemself: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object.isRequired,
  orgAlias: PropTypes.string.isRequired,
};

UserTimeOffWidget.defaultProps = {
  items: [],
  isThemself: false,
};

const UserTimeOffWidgetConnected = withTDApiConnected({
  fetchData: ({
    dispatch, url, authedAxios, componentName, querystring, params,
  }) => {
    const { orgAlias, userId, userType } = params;
    return dispatch(fetchListDS({
      authedAxios,
      componentName,
      querystring, url: userTimeOffApiUrl({ orgAlias, userId, userType }, url),
    }));
  },
  duck: 'list',
  storeKey: 'UserTimeOffWidget',
  skeletonComponent: UserTimeOffWidgetSkeleton,
})(UserTimeOffWidget);

export default withRouter(UserTimeOffWidgetConnected);
