import React from 'react';
import PropTypes from 'prop-types';

import { INVOICE_TYPE } from 'finance/assets/js/constants';
import { generatePaymentReference } from 'finance/assets/js/lib/utils';

const InvoicePaymentReference = ({ type, customReference, uniqueNumber, number, currency }) => {
  const { paymentReference } = generatePaymentReference(
    customReference,
    number,
    uniqueNumber,
    currency,
  );

  switch (type) {
    case INVOICE_TYPE.OUTBOUND:
      return (
        <div className="mt-5" data-testid="invoice-payment-reference-outbound">
          <p className="font-weight-bold" data-testid="outbound-msg">{`When you pay this invoice, use "${number}" as a payment reference.`}</p>
        </div>
      );
    default:
      return paymentReference ? (
        <div className="mt-5" data-testid="invoice-payment-reference">
          <p data-testid="other-msg">{`${paymentReference} will appear in your bank statement when this invoice gets paid.`}</p>
        </div>
      ) : null;
  }
};

InvoicePaymentReference.propTypes = {
  type: PropTypes.number.isRequired,
  customReference: PropTypes.string,
  uniqueNumber: PropTypes.string,
  number: PropTypes.string,
  currency: PropTypes.string,
};

InvoicePaymentReference.defaultProps = {
  customReference: null,
  uniqueNumber: null,
  number: null,
  currency: null,
};

export default InvoicePaymentReference;
