import React from 'react';
import PropTypes from 'prop-types';
import { capitalize, flatten, isEmpty } from 'lodash';

import TDFinalFormOnChange from 'core/assets/js/components/TDFinalFormOnChange.jsx';
import { TW_BANK_ACCOUNT_INFO } from 'settings/assets/js/constants';
import { CURRENCY, BS_STYLE } from 'core/assets/js/constants';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import TWBankAccountSwitchFields from 'settings/assets/js/components/tw-form-components/TWBankAccountSwitchFields.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';

const TWBankAccountDynamicFields = ({
  paymentType, orgCurrencies, currency, requirements, dispatch, descriptor, onCountrySelect,
  country,
}) => {
  /**
   * Prepares the mapping (used in our dropdowns)
   * of recipient types based on selected currency
   * It gets which recipient types are available depending on the selected payment type which will
   * be selected by the user. E.g In GBP the IBAN option may provide 'Personal' and 'Business'
   * recipient type but in RUB it may provide only 'Personal' recipient type option
   * @param {Object} paymentTypeReqs - TW currency requirements of selected payment type
   * @returns {Object}  {text: *, value: *}
   */
  function getRecipientTypesInfo(paymentTypeReqs) {
    const emptyResults = {
      recipientTypeOptionsMapping: [],
      recipientTypeField: null,
    };

    const fieldLabel = 'Recipient type';
    const recipientTypeField = paymentTypeReqs.fields.find(field => field.name === fieldLabel);
    if (isEmpty(recipientTypeField)) {
      return emptyResults;
    }
    const recipientTypeFieldGroup = recipientTypeField.group
      .find(group => group.name === fieldLabel);
    const { valuesAllowed: groupAllowedValues } = recipientTypeFieldGroup;

    return {
      recipientTypeOptionsMapping: groupAllowedValues.map(val => ({
        value: val.key,
        text: capitalize(val.name),
      })),
      recipientTypeField,
    };
  }

  /**
   * It gathers all fields which will show up for the user to fill based on the
   * selected payment type. It only returns bank related field except that of the recipient type
   * @param {Object} paymentTypeReqs - TW currency requirements of selected payment type
   * @returns {Object} {{ recipientTypeA: { fieldRequiredA, fieldRequiredB }  }}
   */
  function getAccountTypeBankFields(paymentTypeReqs) {
    const fields = flatten(paymentTypeReqs.fields.map(f => f.group));
    const fieldsWithoutLegal = fields.filter(f => f.key !== 'legalType');
    const isAddressField = fieldKey => fieldKey.match(/address./);
    const filteredFields = fieldsWithoutLegal.filter(field => !isAddressField(field.key));

    return filteredFields;
  }

  /**
   * It gathers all fields which will show up for the user to fill based on the
   * selected payment type. It only returns address related field except that of the recipient type
   * @param {Object} paymentTypeReqs - TW currency requirements of selected payment type
   * @returns {Object} {{ recipientTypeA: { fieldRequiredA, fieldRequiredB }  }}
   */
  function getAccountTypeAddressFields(paymentTypeReqs) {
    const fields = flatten(paymentTypeReqs.fields.map(f => f.group));
    const filteredFields = fields.filter(field => field.key.match(/address./));

    return filteredFields;
  }

  function renderSwitchFields({ fields, recipientTypeField }) {
    return fields.map((field, index) => {
      const fieldsCount = fields.length + recipientTypeField && 1;
      const isFirstItem = index === 0;
      const isLastItem = fieldsCount === (index + 1);
      const isOdd = !!(fieldsCount % 2);
      const isEven = !isOdd;
      const splitRendering = isFirstItem || (isOdd && !isLastItem) || isEven;
      return (
        <div className={splitRendering ? 'col-12 col-sm-6' : 'col-12'} key={field.key}>
          <TWBankAccountSwitchFields
            field={field}
            currency={currency}
            onCountrySelect={onCountrySelect}
          />
          <TDFinalFormOnChange name={field.key}>
            {(value, previous) => {
              // force tab change if user has made an illegal selection for this account type
              if (field.refreshRequirementsOnChange && previous !== value) {
                dispatch(requestRefreshAC(descriptor));
              }
            }}
          </TDFinalFormOnChange>
        </div>
      );
    });
  }

  function isSelectedCountryUnavailable() {
    const reqs = requirements[paymentType];
    const addressField = reqs.fields.find(field => field.name === 'Country');
    const availableCountries = addressField?.group[0].valuesAllowed;
    const countryIsAvailable = Array.isArray(availableCountries)
      && availableCountries.some(ac => ac.key === country);
    return !countryIsAvailable;
  }

  const hasSelectedPaymentType = !!paymentType;

  if (!hasSelectedPaymentType || isEmpty(requirements[paymentType])) {
    return null;
  }

  const infoMsg = TW_BANK_ACCOUNT_INFO[currency];
  const addressMsg = orgCurrencies.includes(CURRENCY.USD)
    ? 'You\'ll need to provide your full address to receive any transfer from USD. This is so we can make sure your money gets to you on time.'
    : null;
  const selectedCountryUnavailable = isSelectedCountryUnavailable();
  const countryMsg = 'The country you have selected is not available for this currency. You need to change the selected currency or country';
  const { recipientTypeField, recipientTypeOptionsMapping } = getRecipientTypesInfo(
    requirements[paymentType],
  );
  const paymentTypeBankFields = getAccountTypeBankFields(requirements[paymentType]);
  const paymentTypeAddressFields = getAccountTypeAddressFields(requirements[paymentType]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3 className="heading-block mb-3">Recipient bank details</h3>
        </div>
      </div>
      {!!infoMsg && (
        <TDSystemMessage
          className="mb-3 tw-bank-account-information-message"
          type={BS_STYLE.INFO}
          title="Recipient details information"
        >
          {Array.isArray(infoMsg)
            ? infoMsg.map(line => (<p key={`bank-info-line_${line}`}>{line}</p>))
            : infoMsg
          }
        </TDSystemMessage>
      )}
      <div className="row">
        {recipientTypeField && (
          <div className="col-12 col-sm-6">
            <SelectField
              required
              name="legalType"
              label="Recipient type"
              wrapperClasses="px-0"
              optionsMapping={recipientTypeOptionsMapping}
            />
            <TDFinalFormOnChange name="legalType">
              {(value, previous) => {
                if (recipientTypeField.refreshRequirementsOnChange && previous !== value) {
                  dispatch(requestRefreshAC(descriptor));
                }
              }}
            </TDFinalFormOnChange>
          </div>
        )}
        { renderSwitchFields({ fields: paymentTypeBankFields, recipientTypeField }) }
      </div>
      <div className="row">
        <div className="col-12">
          <h3 className="heading-block mb-3">Residential address details</h3>
        </div>
      </div>
      {!!addressMsg && (
        <TDSystemMessage
          className="my-4 tw-bank-account-information-message"
          type={BS_STYLE.INFO}
          title="Address information"
        >
          {addressMsg}
        </TDSystemMessage>
      )}
      {selectedCountryUnavailable && (
        <TDSystemMessage
          className="my-4 tw-bank-account-information-message"
          type={BS_STYLE.INFO}
          title="Country information"
        >
          {countryMsg}
        </TDSystemMessage>
      )}
      <div className="row">
        { renderSwitchFields({ fields: paymentTypeAddressFields, recipientTypeField }) }
      </div>
    </>
  );
};

TWBankAccountDynamicFields.propTypes = {
  currency: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  onCountrySelect: PropTypes.func.isRequired,
  orgCurrencies: PropTypes.array,
  descriptor: PropTypes.string.isRequired,
  paymentType: PropTypes.string,
  requirements: PropTypes.object.isRequired,
};

TWBankAccountDynamicFields.defaultProps = {
  orgCurrencies: [],
  paymentType: null,
};


export default TWBankAccountDynamicFields;

