import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formValueSelector, Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import RateField from 'rates/assets/js/components/reduxFormFields/RateField.jsx';
import { RATE_SUGGEST_VALUE, RATE_UNIT_CUSTOM_SELECT_FILTERED } from 'rates/assets/js/constants';
import { uploaderProjectTasksPath } from 'core/urls';
import {
  BS_STYLE, CURRENCY_VALUES, ICON, MAX_UPLOAD_FILES,
} from 'core/assets/js/constants';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import FileUploaderField from 'core/assets/js/components/ReduxFormFields/FileUploaderField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import CustomSelectField from 'core/assets/js/components/ReduxFormFields/CustomSelectField.jsx';

export const FORM_ID = 'DiscussionBoardMessageForm';

const BudgetAmendmentRequestForm = ({
  buttonStyle,
  currency,
  error,
  handleSubmit,
  match: { params: { id: projectId, orgAlias } },
  onCancel,
  pristine,
  selectedRateUnit,
  submitting,
  withAttachments,
}) => {
  const [showAttachments, setShowAttachments] = useState(false);

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="message"
        type="textarea"
        placeholder="e.g. I'm happy to start working on the task using the following rate"
        component={InputField}
        label="Message"
        autoFocus
      />

      <div className="row d-flex flex-sm-nowrap">
        <div className="col-12 col-sm-6">
          <Field
            name="rate_id"
            component="input"
            type="hidden"
            value={RATE_SUGGEST_VALUE}
          />

          <Field
            name="rate_unit"
            component={CustomSelectField}
            label="Rate unit"
            selectedOption={selectedRateUnit}
            options={RATE_UNIT_CUSTOM_SELECT_FILTERED}
            required
          />
        </div>
        {selectedRateUnit && (
          <div className="col-12 col-sm-6">
            <RateField
              currencySymbol={CURRENCY_SYMBOL[currency]}
              name="rate"
              unit={selectedRateUnit}
            />
          </div>
        )}
      </div>

      { showAttachments && withAttachments && (
        <div>
          <Field
            name="attachments_metadata"
            type="text"
            component={FileUploaderField}
            maxFiles={MAX_UPLOAD_FILES}
            acceptFiles={['image/*', 'application/pdf']}
            path={uploaderProjectTasksPath(orgAlias, projectId)}
          />
        </div>
      )}

      {error && (
        <div className="col-12 form-group has-error error-summary">
          <span className="help-block">{error}</span>
        </div>
      )}

      <div className="text-right mt-4">
        { withAttachments && (
          <a
            className="float-left db-attachments-toggle imitate-link"
            onClick={() => setShowAttachments(() => !showAttachments)}
          >
            <i className={ICON.ATTACHMENT} />
            { showAttachments ? 'Hide attachments' : 'Add attachments' }
          </a>
        )}

        {onCancel && (
          <TDButton
            onClick={onCancel}
            type="button"
            variant={BS_STYLE.DEFAULT}
            disabled={submitting}
            label="Cancel"
          />
        )}

        <TDButton
          className={pristine ? 'btn--pristine' : 'btn--dirty'}
          type="submit"
          variant={buttonStyle}
          disabled={submitting}
          label="Suggest rate"
        />
      </div>
    </form>
  );
};

BudgetAmendmentRequestForm.propTypes = {
  buttonStyle: PropTypes.string,
  currency: PropTypes.oneOf(CURRENCY_VALUES).isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onCancel: PropTypes.func,
  pristine: PropTypes.bool.isRequired,
  selectedRateUnit: PropTypes.number,
  submitting: PropTypes.bool.isRequired,
  withAttachments: PropTypes.bool,
};

BudgetAmendmentRequestForm.defaultProps = {
  buttonStyle: BS_STYLE.PRIMARY,
  error: null,
  onCancel: null,
  selectedRateUnit: null,
  withAttachments: false,
};

const BudgetAmendmentRequestReduxForm = reduxForm({
  form: FORM_ID,
})(BudgetAmendmentRequestForm);

const selector = formValueSelector(FORM_ID);
const mapStateToProps = (state, { initialValues }) => ({
  selectedRateUnit: parseInt(selector(state, 'rate_unit'), 10) || initialValues.rate_unit,
});
const BudgetAmendmentRequestFormConnected = connect(
  mapStateToProps,
)(BudgetAmendmentRequestReduxForm);

export default withRouter(BudgetAmendmentRequestFormConnected);
