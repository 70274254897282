import Big from 'big.js';
import { get } from 'lodash';
import { BANK_CURRENCY } from '../constants';

type TReverseRateOpts = {
  testFromAmount?: string;
  testToAmount?: string;
};

type TRateOpts = {
  initialPrecision?: number;
  knownRates?: any;
};

const getReverseRate = (rate: number, { testFromAmount, testToAmount }: TReverseRateOpts = {}) => {
  // Change to rounding mode and precision according to monetary calculations,
  // keep the initial values locally in order to restore later
  const initialRM = Big.RM;
  const initialDP = Big.DP;

  let reverseRate = Big(1).div(rate).toNumber();

  let precision = 4;
  Big.RM = 1;
  Big.DP = precision;

  reverseRate = Big(1).div(rate).toNumber();

  if (testFromAmount && testToAmount) {
    while (precision < 10 && Big(testFromAmount).times(reverseRate).toFixed(2) !== testToAmount) {
      Big.RM = 1;
      Big.DP = precision;
      precision += 1;
      reverseRate = Big(1).div(rate).toNumber();
    }
  } else {
    while (precision < 10 && reverseRate === 0) {
      Big.RM = 1;
      Big.DP = precision;
      precision += 1;
      reverseRate = Big(1).div(rate).toNumber();
    }
  }

  if (reverseRate === 0) {
    throw new Error('cannot calculate reverse rate');
  }

  // reinstate the precision of Big.js
  Big.RM = initialRM;
  Big.DP = initialDP;
  return reverseRate;
};

const calcRate = (fromAmount: string, toAmount: string, {
  initialPrecision = 4,
}: TRateOpts = {}) => {
  let rate: number;
  let precision: number = initialPrecision;

  // Change to rounding mode and precision according to monetary calculations,
  // keep the initial values locally in order to restore later
  const initialRM = Big.RM;
  const initialDP = Big.DP;

  do {
    // temporarily change the precision of Big.js
    Big.RM = 1;
    Big.DP = precision;
    precision += 1;
    rate = Big(toAmount).div(fromAmount).toNumber();
  } while (precision < 10 && Big(fromAmount).times(rate).toFixed(2) !== toAmount);
  // reinstate the precision of Big.js
  Big.RM = initialRM;
  Big.DP = initialDP;

  const reverseRate = getReverseRate(rate, { testFromAmount: toAmount, testToAmount: fromAmount });

  return { rate, reverseRate };
};

const getRate = (
  fromAmount: string,
  fromCurrency: BANK_CURRENCY,
  toAmount: string,
  toCurrency: BANK_CURRENCY,
  {
    initialPrecision = 4, knownRates,
  }: TRateOpts = {},
) => {
  if (Big(fromAmount).eq(0)) {
    return { rate: 1, reverseRate: 1 };
  }

  const knownRate = get(knownRates, `${fromCurrency.toUpperCase()}.${toCurrency.toUpperCase()}`);
  const knownReverseRate = get(knownRates, `${toCurrency.toUpperCase()}.${fromCurrency.toUpperCase()}`);

  if (knownRate) {
    if (knownReverseRate) {
      return { rate: knownRate, reverseRate: knownReverseRate };
    }
    return {
      rate: knownRate,
      reverseRate: getReverseRate(knownRate, {
        testFromAmount: toAmount, testToAmount: fromAmount,
      }),
    };
  }

  if (knownReverseRate) {
    return {
      rate: getReverseRate(knownReverseRate, {
        testFromAmount: fromAmount, testToAmount: toAmount,
      }),
      reverseRate: knownReverseRate,
    };
  }

  if (fromCurrency.toLowerCase() === toCurrency.toLowerCase()) {
    return { rate: 1, reverseRate: 1 };
  }

  return calcRate(fromAmount, toAmount, { initialPrecision });
};

export {
  TReverseRateOpts,
  TRateOpts,

  calcRate,
  getRate,
  getReverseRate,
};
