import React from 'react';
import PropTypes from 'prop-types';

import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import { isEmpty } from 'lodash';
import { Money } from 'td-finance-ts';

import InvoiceCustomFields from 'finance/assets/js/components/invoicing/InvoiceCustomFields.jsx';
import { invoiceRenderSpec } from 'finance/assets/js/lib/objectSpecs';
import {
  EXCHANGE_RATE_SERVICE_LABEL,
  EXCHANGE_RATE_SERVICE_URL,
  INVOICE_TYPE,
  OUTBOUND_INVOICE_TEMPLATES,
  SERVICE_KEY_NAME,
} from 'finance/assets/js/constants';
import InvoiceMainTable from 'finance/assets/js/components/invoicing/InvoiceMainTable.jsx';
import SimpleInvoiceMainTable from 'finance/assets/js/components/invoicing/SimpleInvoiceMainTable.jsx';
import ReportLogo from 'finance/assets/js/components/ReportLogo.jsx';
import ReportFooter from 'finance/assets/js/components/ReportFooter.jsx';
import InvoiceDetails from 'finance/assets/js/components/invoicing/InvoiceDetails.jsx';
import ReportFinancialEntity from 'finance/assets/js/components/ReportFinancialEntity.jsx';
import InvoiceBankDetails from 'finance/assets/js/components/invoicing/InvoiceBankDetails.jsx';
import InvoiceBreakdown from 'finance/assets/js/components/invoicing/InvoiceBreakdown.jsx';
import InvoicePaymentTerms from 'finance/assets/js/components/invoicing/InvoicePaymentTerms.jsx';
import InvoiceFootnote from 'finance/assets/js/components/invoicing/InvoiceFootnote.jsx';
import InvoiceDENoIdentificationOfSalesTax from 'finance/assets/js/components/invoicing/InvoiceDENoIdentificationOfSalesTax.jsx';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import InvoicePaymentReference from 'finance/assets/js/components/invoicing/InvoicePaymentReference.jsx';
import InvoicePhishingWarning from 'finance/assets/js/components/invoicing/InvoicePhishingWarning.jsx';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import ContractorChargesTable from 'finance/assets/js/components/invoicing/ContractorChargesTable.jsx';
import LicenceFeeBreakdownItemsTable from 'finance/assets/js/components/invoicing/LicenceFeeBreakdownItemsTable.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';

class Invoice extends React.PureComponent {
  render() {
    const {
      invoice, ownerFE: owner, recipientFE, invoicingSettings, firstBillable, lastBillable,
      attachments, systemFE: system,
    } = this.props;

    const ownerFE = new FinancialEntity(owner);

    const systemFE = new FinancialEntity(system);

    const template = invoicingSettings?.invoicingOutboundTemplate
      ?? OUTBOUND_INVOICE_TEMPLATES.BASIC;

    if (!invoicingSettings) {
      throw new Error('undefined invoicing settings');
    }

    if (!invoicingSettings.feeScheme) {
      throw new Error('undefined fee scheme');
    }

    const { orgAlias } = invoicingSettings;

    const isOutbound = invoice.type === INVOICE_TYPE.OUTBOUND;

    let showContractorBreakdownAppendix = false;
    let showInvoiceBreakdown = false;
    if (isEmpty(invoice.amounts)) {
      throw new Error('Invoice amounts data missing');
    }
    const { amounts, invoiceBreakdown } = invoice;
    const {
      credit, exchangeRateTotal, exchangeRateSource,
    } = amounts;
    const invoiceAmounts = new InvoiceAmounts(amounts);
    const licenceFeeAnalysis = invoiceAmounts.getLicenceFeeAnalysis();
    const aorAnalysis = licenceFeeAnalysis.getAnalysisForServiceKey(SERVICE_KEY_NAME.AOR);
    const seatsAnalysis = licenceFeeAnalysis.getSeatAnalysis();
    const seatsFeeBreakdownItems = seatsAnalysis.getFeeBreakdownItems();
    const aorFeeBreakdownItems = aorAnalysis.getFeeBreakdownItems();
    const hasAorFeeBreakdownItems = !isEmpty(aorFeeBreakdownItems);
    const hasSeatsFeeBreakdownItems = !isEmpty(seatsFeeBreakdownItems);
    const hasZeroAorFees = aorAnalysis.isZero();
    const hasZeroSeatsFees = seatsAnalysis.isZero();
    const currency = invoiceAmounts.getCurrency();
    const targetCurrency = invoiceAmounts.getTargetCurrency();

    const showFeeBreakdownItemsTable = isOutbound && (
      // We only show the breakdown analysis if parent/org relation exists
      // in which case there will be more than on feeBreakdownItems
      (!hasZeroSeatsFees && hasSeatsFeeBreakdownItems && seatsFeeBreakdownItems.length > 1)
      || (!hasZeroAorFees && hasAorFeeBreakdownItems && aorFeeBreakdownItems.length > 1)
    );

    // credit means that we have sent money to freelancers.Only in DIRECT mode there is no credit
    const hasCredit = !new Money(credit, currency).isZero();
    showContractorBreakdownAppendix = isOutbound && !hasCredit
      && !isEmpty(invoiceBreakdown) && !isEmpty(invoice.invoiceItemGroups);
    showInvoiceBreakdown = !isEmpty(invoice.invoiceBreakdown)
      && template !== OUTBOUND_INVOICE_TEMPLATES.NO_BILLABLES_BREAKDOWN;

    const showExchangeRate = isOutbound && targetCurrency && currency
      && targetCurrency !== currency;

    const showFullVat = !isEmpty(owner) && !isEmpty(recipientFE) && (
      (new FinancialEntity(owner)).getCountryCode()
      !== (new FinancialEntity(recipientFE)).getCountryCode()
    );

    const tableClasses = [
      'finance-report__table-wrapper finance-report__table-wrapper--list',
      'finance-report__table-wrapper--responsive invoice-table-wrapper',
    ].join(' ');

    const extraTableClasses = `border-0 ${tableClasses}`;

    const isTotalInPaymentDetailsCurrency = currency === ownerFE.getBankCurrency();
    const shouldSwapTotals = showExchangeRate && isTotalInPaymentDetailsCurrency;

    const isSubInvoice = invoiceAmounts.hasSubscriptionFee();

    return (
      <div>
        <div className="invoice-page">
          <div className="finance-report__head row">
            <ReportLogo
              className="col-12 col-md-6 col-lg-8 mb-4 mb-md-0"
              user={owner ? owner.user : undefined}
              company={owner ? owner.company : undefined}
              data-testid="invoice-report-logo"
            />
            <div className="finance-report__meta col-12 col-md-6 col-lg-4">
              <InvoiceDetails
                invoice={invoice}
                firstBillable={firstBillable}
                lastBillable={lastBillable}
              />
            </div>
          </div>

          <hr />

          <div className="row mb-4">
            <div className="finance-report__provider col-12 col-md-8">
              <ReportFinancialEntity financialEntity={owner} showFreeText />
              <div className="finance-report__provider__details">
                <InvoiceBankDetails
                  data-testid="invoice-bank-details"
                  owner={owner}
                  system={system}
                  invoicingSettings={invoicingSettings}
                  classes="mt-4"
                />
              </div>
            </div>
            <div className="finance-report__provider__details col-12 col-md-4">
              <ReportFinancialEntity financialEntity={recipientFE} showFullVat={showFullVat} />
            </div>
          </div>
          {attachments?.length > 0 && (
            <div className="mb-2">
              <h5>Attachments</h5>
              <div className="row">
                <div className="col-12 col-md-6">
                  <AttachmentsList label={null} attachments={attachments} />
                </div>
              </div>
            </div>
          )}
          {template === OUTBOUND_INVOICE_TEMPLATES.SIMPLE && isOutbound
            ? (
              <SimpleInvoiceMainTable
                invoice={invoice}
                invoicingSettings={invoicingSettings}
                owner={owner}
              />
            ) : (
              <InvoiceMainTable
                invoice={invoice}
                invoicingSettings={invoicingSettings}
                owner={owner}
              />
            )}
          {showContractorBreakdownAppendix && (
            <AreaCollapsible
              className="mt-3"
              fieldSet
              contentChildren={(
                <>
                  <div className={extraTableClasses}>
                    <ContractorChargesTable
                      invoice={invoice}
                      orgAlias={orgAlias}
                      invoicingSettings={invoicingSettings}
                    />
                  </div>
                </>
              )}
              headingChildren={<span>Contractor charges breakdown</span>}
            />
          )}

          { showExchangeRate && (
            <>
              <p>
                {`* The exchange rate used for the amount conversion is ${currency.toUpperCase()} to ${targetCurrency.toUpperCase()} `}
                <span className="font-weight-bolder">
                  <NumberTpl value={exchangeRateTotal} />
                </span>
                . This exchange rate was sourced from
                {' '}
                <a
                  href={EXCHANGE_RATE_SERVICE_URL[exchangeRateSource]}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {EXCHANGE_RATE_SERVICE_LABEL[exchangeRateSource]}
                  .
                </a>
              </p>
              {(!isTotalInPaymentDetailsCurrency || shouldSwapTotals) && (
                <p>
                  {shouldSwapTotals ? '**' : '*'}
                  {' This is the exact amount and currency that needs to be paid '}
                  {'to the aforementioned electronic payment details.'}
                </p>
              )}
            </>
          )}

          { showInvoiceBreakdown && (
            <AreaCollapsible
              className="mt-3"
              fieldSet
              contentChildren={(
                <>
                  <div className={extraTableClasses}>
                    <InvoiceBreakdown
                      invoice={invoice}
                      invoicingSettings={invoicingSettings}
                      orgAlias={orgAlias}
                    />
                  </div>
                </>
              )}
              headingChildren={<span>Provider charges breakdown</span>}
            />
          )}

          { showFeeBreakdownItemsTable && (
            <AreaCollapsible
              className="mt-3"
              fieldSet
              contentChildren={(
                <LicenceFeeBreakdownItemsTable
                  invoice={invoice}
                />
              )}
              headingChildren={<span>Licence fees breakdown</span>}
            />
          )}

          {invoice.customFields && (
            <InvoiceCustomFields customFields={invoice.customFields} />
          )}

          <div className="finance-report__payment__terms mt-5">
            {isSubInvoice && <p>This invoice has been paid.</p>}
            <InvoiceFootnote owner={owner} />
            <InvoiceDENoIdentificationOfSalesTax owner={owner} recipient={recipientFE} />
            {!isSubInvoice && (
              <InvoicePaymentTerms terms={invoice.terms} comments={invoice.comments} />
            )}
          </div>

          {!isSubInvoice && (
            <InvoicePaymentReference
              type={invoice.type}
              customReference={ownerFE.getBank()?.getCustomReference()}
              uniqueNumber={invoice.unique_number}
              number={invoice.number}
              currency={targetCurrency}
            />
          )}

          {isOutbound && (
            <InvoicePhishingWarning />
          )}

          <div className="mt-5 pt-5 finance-report__organization-footer-info">
            {owner && (
              <ReportFooter data-testid="invoice-report-footer" owner={owner} />
            )}
          </div>

          { invoice.type !== INVOICE_TYPE.DIRECT && !isEmpty(systemFE)
            && (
              <div className="mt-4 discreet finance-report__talentdesk-footer-info">
                {systemFE.getFootnote()}
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

Invoice.propTypes = {
  attachments: PropTypes.array,
  invoice: invoiceRenderSpec,
  ownerFE: PropTypes.object,
  systemFE: PropTypes.object,
  recipientFE: PropTypes.object,
  invoicingSettings: PropTypes.object,
  firstBillable: PropTypes.object,
  lastBillable: PropTypes.object,
};

Invoice.defaultProps = {
  attachments: [],
  invoice: {},
  ownerFE: {},
  systemFE: {},
  invoicingSettings: {},
  firstBillable: null,
  lastBillable: null,
  recipientFE: {},
};

export default Invoice;
