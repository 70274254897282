import React, { useState } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'admin/assets/js/lib/httpClient';
import { Form, Field } from 'react-final-form';
import { Button, useNotify, useRefresh } from 'react-admin';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { adminApiUrl } from 'admin/urls';

const IgnoreButton = ({ remoteId, ignored }) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  if (!remoteId) {
    return null;
  }

  const handleToggleIgnored = async (formValues) => {
    const { ignoredComment } = formValues;
    const valuesToSend = {
      ignored: !ignored,
      ignoredComment,
    };

    const url = adminApiUrl('inbound_transfers', remoteId);
    setLoading(true);
    try {
      await httpClient(url, { method: 'PUT', body: JSON.stringify(valuesToSend) });
      await refresh();
    } catch (e) {
      notify(e.toString(), 'warning');
      setLoading(false);
    }
  };

  const buttonLabel = ignored ? 'Stop ignoring' : 'Ignore';
  const title = ignored ? `Stop ignoring transfer ${remoteId}` : `Ignore transfer ${remoteId}`;
  const submitLabel = ignored ? 'Stop ignoring' : 'Ignore transfer';

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        label={buttonLabel}
      />
      <Dialog
        fullWidth
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          <div className="d-flex justify-content-between">
            {title}
            <Button
              style={{ padding: 0, minWidth: 'initial' }}
              onClick={() => setOpen(false)}
            >
              <Icon>close</Icon>
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <Form onSubmit={handleToggleIgnored}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {!ignored && (
                  <div>
                    <Field validate={value => (value ? undefined : 'A comment is required')} name="ignoredComment">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label>Comment</label>
                          <br />
                          <input
                            style={{
                              padding: '10px',
                              width: '100%',
                            }}
                            {...input}
                            type="text"
                            placeholder="e.g. Ignored as not related to any invoice"
                          />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                )}
                <div>
                  <Button variant="contained" disabled={loading} label={submitLabel} type="submit" />
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

IgnoreButton.propTypes = {
  remoteId: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]),
  ignored: PropTypes.bool,
};

IgnoreButton.defaultProps = {
  remoteId: null,
  ignored: false,
};

export default IgnoreButton;
