/* eslint-disable react/jsx-fragments */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import PaymentProviderOfflineCard from 'settings/assets/js/components/PaymentProviderOfflineCard.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TWBankAccountForm from 'settings/assets/js/components/TWBankAccountForm.jsx';
import { SERVICE_STATUS_SERVICE } from 'services/assets/js/constants';
import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';
import { fetchServiceStatusDS } from 'services/assets/js/data-services/services';
import { fetchViewDS, getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { isServiceStatusOk } from 'services/assets/js/lib/utils';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { settingsPaymentsSubPageUrl, settingsBankAccountApiUrl } from 'settings/urls';

class ProfileManageBankAccount extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    const promises = [];
    const { bankAccountId } = params;
    if (bankAccountId) {
      // TODO - remove me !!!
      const queryParams = queryString.parse(querystring);
      promises.push(dispatch(fetchViewDS({
        url: settingsBankAccountApiUrl(params.bankAccountId, url, { query: {
          fetchRequirements: true,
          ...queryParams,
        } }),
        params,
        authedAxios,
        componentName,
      })));
    }

    promises.push(dispatch(fetchServiceStatusDS({
      authedAxios,
      componentName,
    })));

    return Promise.all(promises);
  }

  static GetComponentName() {
    return 'ProfileManageBankAccount';
  }

  render() {
    const {
      activeOrg, bankAccount, match: { params: { bankAccountId } }, history, serviceStatus,
    } = this.props;
    const breadcrumbs = [
      {
        title: 'Payments',
        url: settingsPaymentsSubPageUrl(activeOrg.alias),
      },
      {
        title: bankAccountId ? 'Edit bank account' : 'Add new bank account',
        url: null,
      },
    ];
    const isWiseOnline = isServiceStatusOk(serviceStatus, SERVICE_STATUS_SERVICE.TRANSFERWISE);
    // Check if is coming from get started page
    let fromGetStarted = false;
    if (history?.location?.state?.fromGetStarted) {
      fromGetStarted = true;
    }

    return (
      <React.Fragment>
        <ContentHeader
          breadcrumbs={breadcrumbs}
        />
        <div className="page page--settings bank-account">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
            >
              {isWiseOnline ? (
                <TWBankAccountForm
                  bankAccount={bankAccount}
                  fromGetStarted={fromGetStarted}
                />
              ) : (
                <PaymentProviderOfflineCard />
              )}
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProfileManageBankAccount.propTypes = {
  activeOrg: orgSpec.isRequired,
  bankAccount: bankAccountSpec,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  serviceStatus: PropTypes.any.isRequired,
};

ProfileManageBankAccount.defaultProps = {
  bankAccount: {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  bankAccount: getViewState(state, ProfileManageBankAccount.GetComponentName()).item,
  serviceStatus: getViewStateExtras(state, ProfileManageBankAccount.GetComponentName(), 'serviceStatus'),
});

const ProfileManageBankAccountReduxConnect = connect(
  mapStateToProps,
)(ProfileManageBankAccount);

export default withRouter(ProfileManageBankAccountReduxConnect);
