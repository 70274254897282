/* eslint react/no-multi-comp: "off" */
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

// admin
import { ReferenceManyField } from 'react-admin';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';

import { useStyles } from 'admin/assets/js/layout/themes';
import { USER_TABS } from 'admin/assets/js/constants';
import TransactionDetailsList from 'admin/assets/js/resources/transactions/TransactionDetailsList.jsx';
import InvoiceAmountsField from 'admin/assets/js/resources/invoices/InvoiceAmountsField.jsx';
import InvoiceBankField from 'admin/assets/js/resources/invoices/InvoiceBankField.jsx';
import InvoiceOwnerFields from 'admin/assets/js/resources/invoices/InvoiceOwnerFields.jsx';
import { RAISED_BY } from 'finance/assets/js/constants';
import InvoicingSettings from 'finance/assets/js/lib/InvoicingSettings';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';

export const InvoiceDetails = (props) => {
  const { record } = props;
  if (!record || !record.ownerFE) {
    return null;
  }

  const ownerFE = new FinancialEntity(record.ownerFE);
  return (
    <Card variant="outlined">
      <CardHeader
        title="Invoice Details"
        titleTypographyProps={{
          variant: 'h6',
        }}
      />
      <CardContent sx={{ m: 0, p: 0 }}>
        <InvoiceOwnerFields {...props} />
        { !ownerFE.isSystem() && (
          <React.Fragment>
            <hr />
            <InvoiceBankField {...props} />
          </React.Fragment>
        )}
        <hr />
        <InvoiceAmountsField {...props} />
      </CardContent>
    </Card>
  );
};

InvoiceDetails.propTypes = {
  record: PropTypes.object,
};

InvoiceDetails.defaultProps = {
  record: {},
};

export const UserInfo = ({ record }) => {
  const classes = useStyles();
  if (!record || !record.ownerFE) {
    return null;
  }

  const ownerFE = new FinancialEntity(record.ownerFE);

  return (
    <Card variant="outlined">
      <CardHeader
        title="User info"
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent sx={{ m: 0, p: 0 }}>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <span className={classes.discreet}>Employment type</span>
          <span className="pl-1">
            {ownerFE.isContractor() ? 'Contractor' : 'Employee'}
          </span>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <span className={classes.discreet}>AOR status</span>
          <span className="pl-1">
            {ownerFE.hasAOREnabled() ? 'Enabled' : 'Not enabled'}
          </span>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <span className={classes.discreet}>US citizen</span>
          <span className="pl-1">
            {(
              ownerFE.getCountryCode() === 'US' ? 'Yes' : 'No'
            )}
          </span>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <span className={classes.discreet}>Tax registered</span>
          <span className="pl-1">
            {ownerFE.hasTaxSystem() ? 'Yes' : 'No'}
          </span>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <span className={classes.discreet}>Notes</span>
          <span className="pl-1">
            {`${record.notesCount} (`}
            <Link to={`/full_users/${ownerFE.getUserId()}/show?tab=${USER_TABS.NOTES}`}>
              view
            </Link>
            )
            {record.notesCount > 0 && ` (last added on ${record.latestNoteCreatedAt})`}
          </span>
        </div>
      </CardContent>
    </Card>
  );
};

UserInfo.propTypes = {
  record: PropTypes.object,
};

UserInfo.defaultProps = {
  record: {},
};

export const TransactionDetails = ({ record, isDeferred }) => {
  const invoicingSettings = new InvoicingSettings(record.invoicingSettings);
  const transactionMode = invoicingSettings.getTransactionMode();

  return (
    <Card variant="outlined">
      <CardHeader
        title="Transaction Details"
        titleTypographyProps={{
          variant: 'h6',
        }}
      />
      <CardContent sx={{ m: 0, p: 0 }}>
        <ReferenceManyField
          className="trw-transfers-ref"
          label=""
          addLabel={false}
          reference="transactions"
          target="invoice_id"
          sortable={false}
        >
          <TransactionDetailsList
            transactionMode={transactionMode}
            expectedAmount={record.amounts.total}
            isDeferred={isDeferred}
            className="d-flex"
          />
        </ReferenceManyField>
      </CardContent>
    </Card>
  );
};

TransactionDetails.propTypes = {
  record: PropTypes.object,
  isDeferred: PropTypes.bool,
};

TransactionDetails.defaultProps = {
  record: {},
  isDeferred: false,
};

const InvoiceTransferDetailsPanel = (props) => {
  const { record } = props;
  return (
    <Grid container item spacing={2}>
      <Grid item lg={6} xs={12}>
        <InvoiceDetails {...props} />
      </Grid>
      {record.raisedBy === RAISED_BY.PROVIDER && (
        <Grid item lg={6} xs={12}>
          <UserInfo {...props} />
        </Grid>
      )}
      <Grid item lg={6} xs={12}>
        <TransactionDetails {...props} />
      </Grid>
    </Grid>
  );
};

InvoiceTransferDetailsPanel.propTypes = {
  record: PropTypes.object,
  isDeferred: PropTypes.bool,
};

InvoiceTransferDetailsPanel.defaultProps = {
  record: {},
  isDeferred: false,
};

export default InvoiceTransferDetailsPanel;
