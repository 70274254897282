import React from 'react';
import { Link, useGetList } from 'react-admin';
import PropTypes from 'prop-types';
import { Money } from 'td-finance-ts';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from '@material-ui/core';

import { MAX_INVOICES_FETCHED } from 'admin/assets/js/constants';
import { formatDate } from 'admin/assets/js/lib/utils';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';
import CustomTooltip from 'admin/assets/js/components/CustomToolTip.jsx';
import InvoiceStatus from 'admin/assets/js/components/InvoiceStatus.jsx';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableCell-head': {
      fontWeight: '600',
      minWidth: '200px',
    },
  },
  footer: {
    backgroundColor: '#e8e8e8',
    '& .MuiTableCell-footer': {
      fontSize: 'initial',
      color: '#000',
      fontWeight: 600,
    },
  },
  title: {
    fontWeight: 600,
  },
});

const TransactionInvoices = ({ transaction }) => {
  const classes = useStyles();
  const { loading: isLoadingInvoices, data } = useGetList(
    'invoices',
    { page: 1, perPage: MAX_INVOICES_FETCHED },
    { field: 'id', order: 'DESC' },
    { byTransactionId: transaction.id },
  );
  const invoices = Object.values(data);


  let invoicesTotalInOrgCurrency = 0;
  const hasInvoices = !isLoadingInvoices && invoices.length > 0;
  if (hasInvoices) {
    const firstInvoice = invoices[0];
    const firstInvoiceMoneyInOrgCurrency = new InvoiceAmounts(
      firstInvoice.amounts,
    ).getOrgMoney();
    invoicesTotalInOrgCurrency = invoices.reduce(
      (total, _inv) => {
        const invoiceMoneyInOrgCurrency = new InvoiceAmounts(_inv.amounts).getOrgMoney();
        return total.add(invoiceMoneyInOrgCurrency);
      },
      new Money(0, firstInvoiceMoneyInOrgCurrency.getCurrency()),
    ).toString({ humanizeAmount: true, withSymbol: true });
  }

  return (
    <Grid item lg={6} xs={12}>
      <Card variant="outlined">
        <CardHeader
          style={{ paddingBottom: 0, marginBottom: 0 }}
          title={(
            <Typography
              variant="subtitle1"
              className={classes.title}
            >
              Invoices
            </Typography>
          )}
        />
        <CardContent
          style={{ padding: 0 }}
        >

          <TableContainer component="div">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Number</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingInvoices && (
                  <TableRow>
                    <TableCell>
                      Loading invoices...
                    </TableCell>
                  </TableRow>
                )}
                {hasInvoices && invoices.map((invoice) => {
                  const invoiceAmounts = new InvoiceAmounts(invoice.amounts);

                  const invoiceMoney = invoiceAmounts.getInvoicedMoney();
                  const invoiceCurrency = invoiceMoney.getCurrency();

                  const invoiceMoneyInOrgCurrency = invoiceAmounts.getOrgMoney();
                  const orgCurrency = invoiceMoneyInOrgCurrency.getCurrency();
                  return (
                    <TableRow key={invoice.id}>
                      <TableCell component="th" scope="row">
                        <Link
                          to={`/children_invoices?filter={"parent_id"%3A${invoice.id}}`}
                        >
                          {invoice.number}
                        </Link>
                      </TableCell>
                      <TableCell>{formatDate(invoice.createdAt)}</TableCell>
                      <TableCell>
                        <InvoiceStatus
                          status={invoice.status}
                          raisedStatus={invoice.raisedStatus}
                          isOverdue={invoice.isOverdue}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {orgCurrency !== invoiceCurrency
                          ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'end',
                                flexDirection: 'column',
                              }}
                            >
                              <span>
                                {invoiceMoneyInOrgCurrency.toString({
                                  humanizeAmount: true,
                                  withSymbol: true,
                                })}
                              </span>
                              <span style={{ fontSize: '10px', color: 'gray' }}>
                                {`(${invoiceMoney.toString({ humanizeAmount: true, withSymbol: true })})`}
                              </span>
                            </div>
                          ) : invoiceMoneyInOrgCurrency.toString({
                            humanizeAmount: true,
                            withSymbol: true,
                          })
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter
                className={classes.footer}
              >
                <TableRow>
                  <TableCell
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span>Estimated total</span>
                    <CustomTooltip
                      title="Total is aggregated in organization's base currency"
                    />
                  </TableCell>
                  <TableCell align="right" colSpan={3}>
                    {invoicesTotalInOrgCurrency}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};

TransactionInvoices.propTypes = {
  transaction: PropTypes.object,
};

TransactionInvoices.defaultProps = {
  transaction: {},
};

export default TransactionInvoices;
