import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TDFinalFormOnChange from 'core/assets/js/components/TDFinalFormOnChange.jsx';
import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import {
  INVOICE_CAPS_PERIOD_LABELS, INVOICE_CAPS_PERIODS, INVOICE_CAPS_PERIOD_VALUES,
} from 'settings/assets/js/constants';
import { postOrganizationDS } from 'settings/assets/js/data-services/organizations';

const INVOICE_CAP_OPTIONS = INVOICE_CAPS_PERIOD_VALUES.map(value => ({
  text: INVOICE_CAPS_PERIOD_LABELS[value], value,
}));

const WEEKLY_DAY_OPTIONS = [
  { text: 'Monday', value: 1 },
  { text: 'Tuesday', value: 2 },
  { text: 'Wednesday', value: 3 },
  { text: 'Thursday', value: 4 },
  { text: 'Friday', value: 5 },
  { text: 'Saturday', value: 6 },
  { text: 'Sunday', value: 0 },
];

const OrgInvoiceCapsSettings = ({ parentComponentName }) => {
  const activeOrg = useSelector(selectActiveOrg);
  const dispatch = useDispatch();

  if (!activeOrg.invoice_caps_enabled) {
    return null;
  }

  const updateOrgSetting = changes => dispatch(postOrganizationDS(
    activeOrg.unique_alias, changes, parentComponentName,
  ));

  return (
    <>
      <Form
        initialValues={pick(
          activeOrg,
          'invoice_caps_period',
          'invoice_caps_weekly_start_day',
          'invoice_caps_allow_raising_beyond_cap',
          'invoice_caps_auto_approve_within_cap',
        )}
        onSubmit={() => null}
        render={() => (
          <div
            className={[
              'd-flex flex-container--standalone-small align-items-start flex-column',
              'settings-row__preview px-3 mt-3',
            ].join(' ')}
          >
            <RadioField
              className="mb-0"
              label="How should the Invoice Caps be enforced?"
              name="invoice_caps_period"
              options={INVOICE_CAP_OPTIONS}
              required
            />
            <TDFinalFormOnChange name="invoice_caps_period">
              {value => {
                if (value !== activeOrg.invoice_caps_period) {
                  updateOrgSetting({ invoice_caps_period: value });
                }
              }}
            </TDFinalFormOnChange>
            {activeOrg.invoice_caps_period === INVOICE_CAPS_PERIODS.CALENDAR_WEEK && (
              <>
                <SelectField
                  className="mb-0 mt-3"
                  label="Which day of the week should the weekly cap start on?"
                  name="invoice_caps_weekly_start_day"
                  optionsMapping={WEEKLY_DAY_OPTIONS}
                />
                <TDFinalFormOnChange name="invoice_caps_weekly_start_day">
                  {value => {
                    const integerValue = parseInt(value, 10);
                    if (integerValue !== activeOrg.invoice_caps_weekly_start_day) {
                      updateOrgSetting({ invoice_caps_weekly_start_day: integerValue });
                    }
                  }}
                </TDFinalFormOnChange>
              </>
            )}
          </div>
        )}
      />
      <TDToggleWithConfirmationModal
        containerClassName="pb-3 px-3"
        modalId="invoice-caps-auto-approve-within-cap"
        label="Auto-approve Worksheets and Proforma Invoices that are within a user's Invoice Cap"
        sublabel={[
          'By activating this option any Worksheet or Proforma Invoice that a user submitted',
          'within their Invoice Cap for the current Billing Period will be automatically',
          'approved. If needed, an approved Worksheet or Proforma Invoice may be voided by a',
          'Manager before it is invoiced.',
        ].join(' ')}
        onToggled={selected => updateOrgSetting({ invoice_caps_auto_approve_within_cap: selected })}
        selected={!!activeOrg.invoice_caps_auto_approve_within_cap}
      />
      {activeOrg.invoice_caps_auto_approve_within_cap && (
        <>
          {activeOrg.time_tracker_enabled && (
            <TDToggleWithConfirmationModal
              containerClassName="pb-3 px-3"
              modalId="invoice-caps-only-auto-approve-time-tracker-worksheets"
              label="Only auto-approve Worksheets that are created via the Time Tracker"
              sublabel={[
                'By default, all Worksheets and Proforma invoices that are created within the ',
                'current Invoice Cap will be auto-approved. By activating this option, only ',
                'Worksheets that are created via Time Tracker will be auto-approved. Any ',
                'Worksheets or Proforma invoices that are manually submitted will require manual ',
                'approval.',
              ].join(' ')}
              onToggled={selected => updateOrgSetting({
                invoice_caps_only_auto_approve_time_tracker_worksheets: selected,
              })}
              selected={!!activeOrg.invoice_caps_only_auto_approve_time_tracker_worksheets}
            />
          )}
          <TDToggleWithConfirmationModal
            containerClassName="pb-3 px-3"
            modalId="invoice-caps-allow-raising-beyond-cap"
            label="Allow users to submit Worksheets and Proforma invoices that exceed their Cap"
            sublabel={[
              'By activating this option any Worksheets or Proforma Invoices that a user submitted',
              'which are within their Invoice Cap will be automatically approved. Those that exceed',
              'the Cap will require manual approval by a Manager.',
            ].join(' ')}
            onToggled={selected => updateOrgSetting({
              invoice_caps_allow_raising_beyond_cap: selected,
            })}
            selected={!!activeOrg.invoice_caps_allow_raising_beyond_cap}
          />
        </>
      )}
      <TDToggleWithConfirmationModal
        containerClassName="pb-3 px-3"
        modalId="invoice-caps-rate-change-reminder"
        label="Invoice Cap Rate Change Reminder"
        sublabel={[
          'Managers should be reminded to review a provider’s invoice cap value if they change',
          'their default rate.',
        ].join(' ')}
        onToggled={selected => updateOrgSetting({ invoice_caps_rate_change_reminder: selected })}
        selected={!!activeOrg.invoice_caps_rate_change_reminder}
      />
    </>
  );
};

OrgInvoiceCapsSettings.propTypes = {
  parentComponentName: PropTypes.string.isRequired,
};

export default OrgInvoiceCapsSettings;
