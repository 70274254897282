import { kebabCase } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import MoneyInputField from 'core/assets/js/components/FinalFormFields/MoneyInputField.jsx';
import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import { ICON } from 'core/assets/js/constants';
import RateSelectField from 'rates/assets/js/components/finalFormFields/RateSelectField.jsx';
import {
  SERVICE_ORDER_ITEM_TYPE, SERVICE_ORDER_ITEM_TYPE_LABEL,
} from 'projects/assets/js/constants';
import { worksheetItemSpec } from 'projects/assets/js/lib/objectSpecs';
import { RATE_SUGGEST_VALUE, RATE_UNIT, RATE_UNIT_FORMAT } from 'rates/assets/js/constants';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import WorksheetProjectWorkItemHeaderRow from 'projects/assets/js/components/WorksheetProjectWorkItemHeaderRow.jsx';
import WorksheetTaskItemHeaderRow from 'projects/assets/js/components/WorksheetTaskItemHeaderRow.jsx';

const removeItemEl = (
  <TDElementWithTooltip
    delay={400}
    tooltipMsg="Remove item"
  >
    <i className={`remove-icon ${ICON.CROSS_CIRCLE}`} />
  </TDElementWithTooltip>
);

const WorksheetWorkItemRow = props => {
  const {
    field,
    formattedRateAmount,
    formattedTotal,
    handleRateSelect,
    hasOnlyClaimAmount,
    index,
    isMobileView,
    item,
    projectWorkItemsCount,
    rates,
    removeItem,
    selectedRateUnit,
    submitErrors,
    worksheet,
    worksheetCurrency,
  } = props;

  const rateId = item.rate_id;
  const ratesIsArray = Array.isArray(rates);
  const selectedRate = ratesIsArray && rates.find(r => r.id === rateId);
  const suggestNewRate = rateId === RATE_SUGGEST_VALUE;
  // Existing service order items return project from the serializer, new ones have projectId
  const isProjectItem = !!item.project || !!item.projectId;
  // Existing service order items return task and taskId from the serializer, new ones have task_id
  const taskId = item.task ? item.task.id : (item.task_id || item.taskId);
  const isTaskItem = !!taskId;
  const rateUnitFormat = RATE_UNIT_FORMAT[selectedRateUnit || item.rate_unit];
  const showRateSelectField = !isTaskItem && ratesIsArray && (!worksheet || (worksheet && rateId));
  const submitError = submitErrors && submitErrors.items && submitErrors.items[index];
  const isFixedRateTask = isTaskItem && item.rate_unit === RATE_UNIT.FIXED;

  let itemType = SERVICE_ORDER_ITEM_TYPE.BASIC;
  if (isTaskItem) {
    itemType = SERVICE_ORDER_ITEM_TYPE.TASK;
  } else if (isProjectItem) {
    itemType = SERVICE_ORDER_ITEM_TYPE.PROJECT;
  }
  const itemLabel = SERVICE_ORDER_ITEM_TYPE_LABEL[itemType];
  const itemLabelKC = kebabCase(itemLabel);

  const testIdPrefix = `worksheet-${itemLabelKC}-item`;

  let quantitySuffix = '';
  if (isTaskItem) {
    quantitySuffix = item.rate_unit === RATE_UNIT.CUSTOM
      ? 'units'
      : rateUnitFormat && rateUnitFormat.unit;
  }

  const descriptionContent = (
    <>
      {isTaskItem && item.title}
      {!isTaskItem && (
        <TextInputField
          className={isMobileView ? 'w-100' : null}
          label=""
          muteErrorMessage
          name={`${field}.description`}
          placeholder={`${itemLabel} short description...`}
        />
      )}
    </>
  );

  const taskRateContent = isTaskItem && (
    <>
      {rateId && selectedRate && selectedRate.alias}
      {!selectedRate && `Suggested ${rateUnitFormat ? rateUnitFormat.title : ''}`}
    </>
  );

  const rateSelectField = !isTaskItem && (
    <RateSelectField
      canSuggestRate
      data-testid={`${testIdPrefix}-row-rate-amounts`}
      handleItemSelect={(newRateId) => {
        handleRateSelect({
          rateId: newRateId,
          field,
          index,
        });
      }}
      label=""
      name={`${field}.rate_id`}
      rates={rates}
      selectedOption={rateId}
      suggestNewRateLabel="Claim an amount"
    />
  );

  const rateAmountField = !isTaskItem && (
    <MoneyInputField
      data-testid={`${testIdPrefix}-suggest-new-rate`}
      decimals={RATE_UNIT_FORMAT[RATE_UNIT.FIXED].decimals}
      label=""
      muteErrorMessage
      name={`${field}.rate_amount`}
      placeholder={RATE_UNIT_FORMAT[RATE_UNIT.FIXED].fieldHelpText}
      prefix={CURRENCY_SYMBOL[worksheetCurrency]}
      required
      step={0.01}
    />
  );

  const displayQuantityField = isTaskItem || !suggestNewRate;

  const quantityField = displayQuantityField && (
    <InputNumberField
      className={isMobileView ? 'w-100' : ''}
      data-testid={`${testIdPrefix}-row-quantity`}
      disabled={isFixedRateTask || (selectedRate && selectedRate.unit === RATE_UNIT.FIXED)}
      inputClassName={isMobileView ? 'text-right' : ''}
      label=""
      min={0.1}
      muteErrorMessage
      name={`${field}.quantity`}
      placeholder={rateUnitFormat && rateUnitFormat.fieldHelpText}
      step={0.01}
      style={{ width: `${isMobileView ? 7 : 9}0px` }}
      suffix={!isMobileView && quantitySuffix}
    />
  );

  let rowClassName = `worksheet-item worksheet-item--${itemLabelKC}`;
  if (isTaskItem) {
    rowClassName += ` task-${taskId}`;
  }

  if (!isMobileView) {
    return (
      <React.Fragment key={`worksheet-item-${itemLabelKC}-${index}`}>
        {!isTaskItem && index === 0 && (
          <WorksheetProjectWorkItemHeaderRow
            hasOnlyClaimAmount={hasOnlyClaimAmount}
            isProjectWork={isProjectItem}
          />
        )}
        {isTaskItem && index === projectWorkItemsCount && (
          <WorksheetTaskItemHeaderRow />
        )}

        <tr className={rowClassName} data-testid={`${testIdPrefix}-row`}>
          <td>{descriptionContent}</td>

          <td colSpan={isTaskItem ? 1 : 2}>
            {isTaskItem && taskRateContent}
            {showRateSelectField && rateSelectField}
          </td>

          {!isTaskItem && suggestNewRate && <td colSpan={3}>{rateAmountField}</td>}

          {displayQuantityField && (
            <>
              {isTaskItem && (
                <td>
                  {
                    item.rateMaxBillingQuantity
                      ? [
                        item.rateBilledQuantity || 0,
                        [
                          item.rateMaxBillingQuantity,
                          rateUnitFormat && rateUnitFormat.unitTitle,
                        ].join(' '),
                      ].join(' / ')
                      : ''
                  }
                </td>
              )}

              <td data-testid={`${testIdPrefix}-row-rate-amount`}>
                {formattedRateAmount}
              </td>

              <td className="px-0 text-center">
                {isFixedRateTask ? ' ' : 'x'}
              </td>

              <td>{quantityField}</td>
            </>
          )}

          <td data-testid={`${testIdPrefix}-row-total`}>
            {formattedTotal}
          </td>

          <td>
            <a data-testid={`${testIdPrefix}-row-remove`} onClick={() => removeItem()}>
              {removeItemEl}
            </a>
          </td>
        </tr>

        {submitError && (
          <>
            {!isTaskItem && (
              <tr className="worksheet-items-error-row">
                <td colSpan={suggestNewRate ? 3 : 5}>
                  <span className="text-danger">{submitError.description}</span>
                </td>
                <td colSpan={suggestNewRate ? 4 : 2}>
                  <span className="text-danger">
                    {submitError.quantity || submitError.rate_amount}
                  </span>
                </td>
                <td />
              </tr>
            )}
            {isTaskItem && (
              <>
                <tr className="worksheet-items-error-row">
                  <td colSpan={5} />
                  <td colSpan={2}>
                    <span className="text-danger">{submitError.quantity}</span>
                  </td>
                  <td />
                </tr>

                <tr className="worksheet-items-error-row">
                  <td colSpan={12}>
                    <span className="text-danger">{submitError.item}</span>
                  </td>
                </tr>
              </>
            )}
          </>
        )}
      </React.Fragment>
    );
  }

  // Mobile View
  return (
    <tr
      className={rowClassName}
      key={`worksheet-item-${itemLabelKC}-${index}-mobile`}
    >
      <td className="px-4" colSpan={2}>
        <div className="d-flex">
          <span className="worksheet-form__mobile-label mb-3">{itemLabel}</span>

          <a
            className="ml-auto pl-3"
            data-testid={`${testIdPrefix}-row-remove`}
            onClick={() => removeItem()}
          >
            {removeItemEl}
          </a>
        </div>

        <div className="d-flex align-items-center pb-3 mb-3 border-bottom">
          {descriptionContent}
        </div>

        {submitError?.description && (
          <div className="mt-3">
            <p className="text-danger">{submitError.description}</p>
          </div>
        )}

        {!isTaskItem && (
          <div>
            <span className="worksheet-form__mobile-label mb-2">Your rates</span>

            {showRateSelectField && rateSelectField}
          </div>
        )}

        {isTaskItem && (
          <>
            <div className="d-flex align-items-center mt-4">
              <span className="worksheet-form__mobile-label mb-2">Task rate</span>
            </div>
            <div>{taskRateContent}</div>
          </>
        )}

        <div className="d-flex align-items-center mt-4">
          <span className="worksheet-form__mobile-label mb-2">
            {RATE_UNIT_FORMAT[suggestNewRate ? RATE_UNIT.FIXED : selectedRateUnit].unitTitle}
          </span>
        </div>

        {displayQuantityField && (
          <>
            <div className="d-flex align-items-center">{quantityField}</div>
            <div className="mt-4 d-flex align-items-center justify-content-end">
              <i className={`${ICON.CROSS} mr-2`} />
            </div>
          </>
        )}

        {!isTaskItem && suggestNewRate && rateAmountField}

        {submitError && (
          <>
            {isTaskItem && (
              <>
                <p className="text-danger mt-3">{submitError.quantity}</p>
                <p className="text-danger mt-3">{submitError.item}</p>
              </>
            )}
            {!isTaskItem && (
              <div className="mt-3">
                <p className="text-danger">{submitError.quantity}</p>
                <p className="text-danger">{submitError.rate_amount}</p>
              </div>
            )}
          </>
        )}

        <div className="mt-4 d-flex align-items-center">
          <span className="worksheet-form__mobile-label">Rate</span>
          <div className="ml-auto">{formattedRateAmount}</div>
        </div>

        <div className="mt-4 d-flex align-items-center">
          <span className="worksheet-form__mobile-label">Amount</span>

          <div className="ml-auto">
            {formattedTotal}
          </div>
        </div>
      </td>
    </tr>
  );
};

WorksheetWorkItemRow.propTypes = {
  field: PropTypes.string.isRequired,
  formattedRateAmount: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  formattedTotal: PropTypes.object,
  handleRateSelect: PropTypes.func.isRequired,
  hasOnlyClaimAmount: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isMobileView: PropTypes.bool.isRequired,
  item: worksheetItemSpec.isRequired,
  projectWorkItemsCount: PropTypes.number.isRequired,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
  removeItem: PropTypes.func.isRequired,
  selectedRateUnit: PropTypes.number.isRequired,
  submitErrors: PropTypes.object.isRequired,
  worksheet: PropTypes.object,
  worksheetCurrency: PropTypes.string.isRequired,
};

WorksheetWorkItemRow.defaultProps = {
  formattedRateAmount: null,
  formattedTotal: null,
  hasOnlyClaimAmount: false,
  rates: [],
  worksheet: null,
};

export default WorksheetWorkItemRow;
