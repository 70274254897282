/* eslint react/prop-types: "warn" */
import React from 'react';
import { isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';
import {
  Table, TableBody, TableCell, TableContainer, Paper, TableRow, TableHead,
} from '@material-ui/core';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

const SalesTaxRechargeTableAnalysis = ({
  salesTaxRechargeAnalysis, classes, currency,
}) => (
  !isEmpty(salesTaxRechargeAnalysis)
    ? (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Invoice #</strong></TableCell>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell align="right"><strong>{`Sales tax (${CURRENCY_SYMBOL[currency]})`}</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesTaxRechargeAnalysis.map(row => (
              <TableRow key={row.invoiceNumber}>
                <TableCell component="th" scope="row">
                  {row.invoiceNumber}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.salesTax}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : null
);

SalesTaxRechargeTableAnalysis.propTypes = {
  salesTaxRechargeAnalysis: PropTypes.arrayOf(PropTypes.shape({
    invoiceNumber: PropTypes.string,
    name: PropTypes.string,
    salesTax: PropTypes.string,
  })).isRequired,
  classes: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
};


export default SalesTaxRechargeTableAnalysis;
