import Big from 'big.js';
import moment from 'moment';
import { fromPairs, get, isArray, isEmpty, isNil, orderBy } from 'lodash';
import { TRANSACTION_METHOD, TRANSFER_TO_TRANSACTION_STATUS } from 'finance/assets/js/constants';
import TransactionMethodTransferwise from 'finance/assets/js/lib/TransactionMethodTransferwise';
import TransferwiseTransferChange from 'services/assets/js/lib/TransferwiseTransferChange';
import TransactionAmounts from 'finance/assets/js/lib/TransactionAmounts';
import TransactionAction from 'finance/assets/js/lib/TransactionAction';
import TransactionState from 'finance/assets/js/lib/TransactionState';
import Money from 'finance/assets/js/lib/Money';

Big.RM = 1;
Big.DP = 2;

const LOCAL_FAILED_STATUS = 'local_failed';

// https://api-docs.transferwise.com/#quotes-create-response
class TransferwiseTransfer {
  static failed({ id, errorMessage }) {
    return new TransferwiseTransfer({
      id, status: LOCAL_FAILED_STATUS, details: { errorMessage },
    });
  }

  constructor({ id, status, created, details, targetAccount, targetCurrency, ...rest }) {
    if (!id) {
      throw new Error('id is required');
    }
    if (!status) {
      throw new Error('status is required');
    }
    if (status !== LOCAL_FAILED_STATUS && !created) {
      throw new Error('created date is required');
    }
    if (status !== LOCAL_FAILED_STATUS && !targetAccount) {
      throw new Error('targetAccount is required');
    }
    if (status !== LOCAL_FAILED_STATUS && !targetCurrency) {
      throw new Error('targetCurrency is required');
    }
    if (!details) {
      throw new Error('details is required');
    }
    this.id = id;
    this.status = status;
    this.created = created;
    this.details = details;
    this.targetAccount = targetAccount;
    this.targetCurrency = targetCurrency;
    Object.assign(this, rest);
  }

  serialize() {
    return fromPairs(Object.entries(this));
  }

  getTransactionStatus() {
    const { status } = this;
    return TRANSFER_TO_TRANSACTION_STATUS[status];
  }

  getSourceCurrency() {
    const { sourceCurrency } = this;
    return sourceCurrency;
  }

  getTargetCurrency() {
    const { targetCurrency } = this;
    return targetCurrency;
  }

  getTargetValue() {
    const { targetValue } = this;
    return targetValue;
  }

  getTargetMoney() {
    return new Money(this.getTargetValue(), this.getTargetCurrency());
  }

  getRate() {
    const { rate } = this;
    return rate;
  }

  getTransactionRaisedAt() {
    const { created } = this;
    return moment(created);
  }

  getStatusChangedAt(transferChanges) {
    if (!isEmpty(transferChanges)) {
      const lastChange = orderBy(transferChanges, c => c.getOccurredAt(), 'desc')[0];
      return lastChange.getOccurredAt();
    }
    return this.getTransactionRaisedAt();
  }

  getTransactionPaidAt(transferChanges) {
    if (!isEmpty(transferChanges)) {
      const paidChanges = transferChanges.filter(c => c.getCurrentState() === 'outgoing_payment_sent');
      if (paidChanges.length === 0) {
        return null;
      }
      const lastChange = orderBy(paidChanges, c => c.getOccurredAt(), 'desc')[0];
      return lastChange.getOccurredAt();
    }
    if (this.status === 'outgoing_payment_sent') {
      return this.getTransactionRaisedAt();
    }
    return null;
  }

  getTransactionRefundedAt(transferChanges) {
    const { status } = this;
    if (!isEmpty(transferChanges)) {
      const refundChanges = transferChanges.filter(c => c.getCurrentState() === 'funds_refunded');
      if (refundChanges.length === 0) {
        return null;
      }
      const lastChange = orderBy(refundChanges, c => c.getOccurredAt(), 'desc')[0];
      return lastChange.getOccurredAt();
    }
    if (status === 'funds_refunded') {
      return this.getTransactionRaisedAt();
    }
    return null;
  }

  resolveTransactionStatus({ transferChanges } = {}) {
    if (!isArray(transferChanges)) {
      throw new Error('transfer changes is required');
    }
    if (!transferChanges.every(t => t instanceof TransferwiseTransferChange)) {
      throw new Error('transfer changes are not of instance TransferwiseTransferChange');
    }
    const date = this.getTransactionRaisedAt();
    const params = {
      status: this.getTransactionStatus(),
      status_changed_at: this.getStatusChangedAt(transferChanges),
      paid_at: this.getTransactionPaidAt(transferChanges),
      refunded_at: this.getTransactionRefundedAt(transferChanges),
      raised_at: this.getTransactionRaisedAt(),
      date,
    };
    return params;
  }

  getTransactionStateFields({ transferChanges }) {
    const status = this.resolveTransactionStatus({ transferChanges });
    return {
      status: status.status,
      statusChangedAt: status.status_changed_at,
      raisedAt: status.raised_at,
      paidAt: status.paid_at,
      refundedAt: status.refunded_at,
      date: status.date,
    };
  }

  getRemoteId() {
    const { id } = this;
    return id;
  }

  getReference() {
    const { reference } = this;
    return reference;
  }

  getStaticTransactionFields({ quote } = {}) {
    const { id, details, targetAccount, targetCurrency } = this;
    const reference = get(details, 'reference');
    const currency = targetCurrency.toLowerCase();
    const date = this.getTransactionRaisedAt();
    return {
      method: TRANSACTION_METHOD.TRANSFERWISE,
      transaction_number: id,
      tw_transfer_id: id,
      tw_fx_rate_expires: quote?.getFXRateExpiry(),
      reference,
      currency,
      tw_recipient_id: targetAccount,
      vendor_details: new TransactionMethodTransferwise({
        transferId: id,
      }).serialize(),
      details_dump: JSON.stringify({
        currency,
        date,
        transferwise: new TransactionMethodTransferwise({
          transferId: id,
        }).serialize(),
      }),
    };
  }

  getTransactionFields({ quote, transferChanges, amounts } = {}) {
    if (!(amounts instanceof TransactionAmounts)) {
      throw new Error('amounts is not of instance TransactionAmounts');
    }

    const fields = this.getStaticTransactionFields({ quote });

    return {
      ...fields,
      ...this.resolveTransactionStatus({ transferChanges }),
      amounts: amounts.serialize(),
    };
  }

  matchesQuote(quote) {
    const sourceAmount = Big(this.sourceValue);
    const paymentOptions = quote.getPaymentOptions();
    if (paymentOptions && paymentOptions.some(po => (
      Big(po.sourceAmount).minus(po.fee.total).eq(sourceAmount)
      || (
        !isNil(po.fee.transferwise)
        && Big(po.sourceAmount).minus(po.fee.transferwise).eq(sourceAmount)
      )
    ))) {
      return true;
    }
    return false;
  }

  reconcileAgainst({
    invoiceAmounts, transactionMode, fee,
  }, { quote, transferChanges = [], statement, currentBalanceRates } = {}) {
    if (isNil(transactionMode)) {
      throw new Error('transactionMode is required');
    }
    if (isNil(invoiceAmounts)) {
      throw new Error('invoiceAmounts are required');
    }

    let amounts;
    const useQuote = (!statement || !statement.isDeposit()) && quote && this.matchesQuote(quote);
    if (useQuote) {
      amounts = TransactionAmounts.fromTransferwiseQuote({
        invoiceAmounts,
        transactionMode,
        transfer: this,
        quote,
        currentBalanceRates,
      });
    } else {
      let resolvedFee = fee;
      if (statement && statement.isDeposit()) {
        // for inbound deposits get the fee directly from the statement
        resolvedFee = statement.getTotalFee().toString();
      }
      amounts = TransactionAmounts.fromAction({
        transactionAction: this.toTransactionAction(resolvedFee),
        invoiceAmounts,
        transactionMode,
        currentBalanceRates,
      });
    }

    return new TransactionState({
      transactionAmounts: amounts,
      ...this.getTransactionStateFields({ transferChanges }),
    });
  }

  toTransactionAction(fee = 0) {
    const { sourceValue, sourceCurrency, targetValue, targetCurrency, rate } = this;

    const sourceMoney = new Money(sourceValue, sourceCurrency);
    const targetMoney = new Money(targetValue, targetCurrency);

    if (sourceMoney.eq(0) || targetMoney.eq(0)) {
      return TransactionAction.zero({
        outgoingCurrency: sourceCurrency,
        targetCurrency,
      });
    }

    const sourceIsNet = sourceMoney.convertsTo(targetMoney, rate);

    let totalFee = fee;
    let outgoingAmount;
    if (sourceIsNet) {
      // we don't know what the fee is, we will use any explicitly stated from outside
      totalFee = fee || '0.00';
      outgoingAmount = new Money(sourceValue, sourceCurrency).add(totalFee).toString();
    } else if (!isNil(fee)) {
      // we know the outgoing amount and the fee
      // sourceValue is the total outgoing amount
      outgoingAmount = new Money(sourceValue, sourceCurrency).toString();
      totalFee = new Money(fee, sourceCurrency).toString();
    } else {
      // we have no information on the fee, we will derive that
      const netOutgoingAmount = new Money(targetValue, targetCurrency).div(rate).toString();
      // sourceValue is the total outgoing amount
      outgoingAmount = new Money(sourceValue, sourceCurrency).toString();
      totalFee = new Money(outgoingAmount, sourceCurrency).sub(netOutgoingAmount).toString();
    }

    // calculate proper rate
    let targetRate = rate;
    const netOutgoingMoney = new Money(outgoingAmount, sourceCurrency).sub(totalFee);
    if (!netOutgoingMoney.convertsTo(targetMoney, rate)) {
      targetRate = netOutgoingMoney.getRate(targetMoney);
    }

    const params = {
      outgoingCurrency: sourceCurrency,
      outgoingAmount,
      totalFee,
      targetCurrency,
      targetRate,
      targetAmount: sourceValue ? targetValue : '0.00',
    };
    return new TransactionAction(params);
  }
}

export default TransferwiseTransfer;
