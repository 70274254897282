import React from 'react';

import ButtonsSection from 'playground/assets/js/components/styleguide/ButtonsSection.jsx';
import CardsSection from 'playground/assets/js/components/styleguide/CardsSection.jsx';
import ChartsSection from 'playground/assets/js/components/styleguide/ChartsSection.jsx';
import ColorsSection from 'playground/assets/js/components/styleguide/ColorsSection.jsx';
import DatePicker from 'playground/assets/js/components/styleguide/DatePicker.jsx';
import FormsSection from 'playground/assets/js/components/styleguide/FormsSection.jsx';
import HeadersSection from 'playground/assets/js/components/styleguide/HeadersSection.jsx';
import IconsSection from 'playground/assets/js/components/styleguide/IconsSection.jsx';
import ListGroupSection from 'playground/assets/js/components/styleguide/ListGroupSection.jsx';
import MiscSection from 'playground/assets/js/components/styleguide/MiscSection.jsx';
import ModalsSection from 'playground/assets/js/components/styleguide/ModalsSection.jsx';
import PaginationSection from 'playground/assets/js/components/styleguide/PaginationSection.jsx';
import PlaygroundView from 'playground/assets/js/components/PlaygroundView.jsx';
import PopOverSection from 'playground/assets/js/components/styleguide/PopOverSection.jsx';
import RealTimeSection from 'playground/assets/js/components/styleguide/RealTimeSection.jsx';
import SystemMessagesSection from 'playground/assets/js/components/styleguide/SystemMessagesSection.jsx';
import TablesSection from 'playground/assets/js/components/styleguide/TablesSection.jsx';
import TabsSection from 'playground/assets/js/components/styleguide/TabsSection.jsx';
import TextSection from 'playground/assets/js/components/styleguide/TextSection.jsx';
import TimeLineSection from 'playground/assets/js/components/styleguide/TimeLineSection.jsx';
import ToastrSection from 'playground/assets/js/components/styleguide/ToastrSection.jsx';
import WizardSection from 'playground/assets/js/components/styleguide/WizardSection.jsx';


const sections = {
  arts: ChartsSection,
  buttons: ButtonsSection,
  cards: CardsSection,
  colors: ColorsSection,
  forms: FormsSection,
  headers: HeadersSection,
  icons: IconsSection,
  misc: MiscSection,
  modals: ModalsSection,
  pagination: PaginationSection,
  pusher: RealTimeSection,
  tables: TablesSection,
  tabs: TabsSection,
  text: TextSection,
  timeline: TimeLineSection,
  toastr: ToastrSection,
  wizard: WizardSection,
  'date Picker': DatePicker,
  'list Group': ListGroupSection,
  'pop overs': PopOverSection,
  'system Messages': SystemMessagesSection,
};

const StyleguideView = () => {
  return (
    <PlaygroundView
      playgroundName="styleguide"
      sections={sections}
      systeMessageTitle="TalentDesk.io styleguide"
      systeMessageDescription={(
        <p>
          Browse the styleguide sections using the side navigation menu.
        </p>
      )}
    />
  );
};

export default StyleguideView;
