import React from 'react';
import PropTypes from 'prop-types';
import { filter, groupBy, isEmpty, sortBy } from 'lodash';
import moment from 'moment';

import { BS_SIZE, CURRENCY_SYMBOL, DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { INVOICING_MODE, INVOICE_TYPE } from 'finance/assets/js/constants';
import Table from 'core/assets/js/components/Table.jsx';
import WorksheetTypeIndicator from 'finance/assets/js/components/WorksheetTypeIndicator.jsx';
import InvoiceItemsModalTable from 'finance/assets/js/components/invoicing/InvoiceItemsModalTable.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import Money from 'finance/assets/js/lib/Money';

class InvoiceBreakdown extends React.PureComponent {
  /**
   * Format amounts for render, for outbound invoices we should show the
   * service order amount and currency when this is difference to the
   * invoice currency.
   * @param {boolean} isOutbound - if invoice is outbound.
   * @param {string} currencySymbol - invoice currency.
   * @param {string} amount - invoice amount
   * @param {string} soCurrencySymbol - service order currency.
   * @param {string} soAmount - service order amount.
   * @return {string} formatted value for rendering.
   */
  static formatAmountsValue(
    isOutbound,
    currencySymbol,
    amount,
    soCurrencySymbol,
    soAmount,
  ) {
    const showSOAmount = (
      isOutbound && soAmount && soCurrencySymbol && (soCurrencySymbol !== currencySymbol)
    );
    return (
      <>
        <NumberTpl prefix={currencySymbol} value={amount} />
        {showSOAmount && (
          <>
            <br />
            <NumberTpl prefix={soCurrencySymbol} value={soAmount} />
          </>
        )}
      </>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      modalData: null,
    };
    this.openInvoiceItemsModal = this.openInvoiceItemsModal.bind(this);
  }

  openInvoiceItemsModal(rowData) {
    const { modalState: { open } } = this.props;
    this.setState({
      modalData: rowData,
    }, () => open());
  }

  render() {
    const {
      orgAlias,
      invoice,
      invoice: { invoiceBreakdown },
      modalState,
      invoicingSettings,
    } = this.props;

    if (!invoice
      || !invoice.amounts
      || !invoice.invoiceBreakdown
      || isEmpty(invoice.invoiceBreakdown)
    ) {
      return null;
    }

    const {
      currency,
    } = invoice.amounts;
    const currencySymbol = CURRENCY_SYMBOL[currency];

    const hasFees = invoice.type === INVOICE_TYPE.OUTBOUND;
    let baseTableColumns = [
      {
        key: 'provider',
        label: 'Provider',
      },
      {
        key: 'project',
        label: 'Project',
      },
      {
        key: 'period',
        label: 'Period',
      },
      {
        width: '165px',
        key: 'amount',
        label: 'Amount',
        columnClassName: 'text-right',
      },
    ];

    const excludedColumns = !hasFees ? ['fee'] : [];
    // exclude project for inbound invoices containing Proforma Invoice service orders,
    // Proforma Invoice service orders are not associated with a project
    if (invoice.type === INVOICE_TYPE.INBOUND
      && invoiceBreakdown.some(bd => bd.serviceOrderType === SERVICE_ORDER_TYPE.PROFORMA_INVOICE)) {
      excludedColumns.push('project');
    }
    baseTableColumns = filter(baseTableColumns, col => !excludedColumns.includes(col.key));

    const byProviderInvoiceDate = groupBy(
      sortBy(invoiceBreakdown, 'providerInvoiceDate'), 'providerInvoiceDate',
    );

    const isOutbound = invoice.type === INVOICE_TYPE.OUTBOUND;
    const isBillingMode = invoicingSettings.mode === INVOICING_MODE.BILL_PAYMENTS;

    const { modalData } = this.state;

    let breakdownTotal = new Money(0, currency);
    Object.keys(byProviderInvoiceDate).forEach((providerInvoiceDate) => {
      byProviderInvoiceDate[providerInvoiceDate].forEach((r) => {
        breakdownTotal = breakdownTotal.add(new Money(r.amounts.amount, currency).toString());
      });
    });

    return (
      <>
        {!isEmpty(modalData) && orgAlias && (
          <ModalSimple
            open={modalState.isOpen}
            heading={modalData.description}
            bsSize={BS_SIZE.LARGE}
            size={BS_SIZE.LARGE}
            body={(
              <InvoiceItemsModalTable
                ids={modalData.children}
                orgAlias={orgAlias}
                emptyText="No Invoice Items found"
                hasFees={hasFees}
                {...modalData}
              />
            )}
            onClose={modalState.close}
          />
        )}
        <>
          {Object.keys(byProviderInvoiceDate).map(providerInvoiceDate => {
            const invoiceDate = moment(providerInvoiceDate, 'YYYY-MM-DD').format(DATE_FORMAT_DEFAULT);
            let amount = new Money(0, currency);
            const rows = byProviderInvoiceDate[providerInvoiceDate].map(r => {
              const soCurrencySymbol = CURRENCY_SYMBOL[r.amounts.serviceOrderCurrency];
              amount = amount.add(r.amounts.amount);
              return {
                ...r,
                amount: InvoiceBreakdown.formatAmountsValue(
                  isOutbound,
                  currencySymbol,
                  r.amounts.amount,
                  soCurrencySymbol,
                  r.amounts.serviceOrderAmount,
                ),
                project: (r.serviceOrderType === SERVICE_ORDER_TYPE.PROFORMA_INVOICE) ? '' : [
                  r.projectTitle,
                  `(${r.projectReference}${r.externalProjectId ? `/${r.externalProjectId}` : ''})`,
                ].join(' '),
              };
            });
            amount = amount.toString();

            /**
             * For Backwards compatibility, show type column only if at least one item includes
             * this information.
             */
            // Check if any of the items has the serviceOrderType property.
            const hasTypeInfo = rows.every((i => i.serviceOrderType));
            const tableColumns = baseTableColumns.slice(0);
            if (hasTypeInfo) {
              tableColumns.unshift({
                dataFormat: (_, row) => {
                  return (
                    <WorksheetTypeIndicator type={row.serviceOrderType} />
                  );
                },
                key: 'type',
                label: 'Type',
                width: '60px',
              });
            }

            return (
              <React.Fragment key={providerInvoiceDate}>
                {isOutbound ? (
                  <h3 className="ml-3 mr-3">
                    Provider charges invoiced on
                    {` ${invoiceDate}`}
                  </h3>
                ) : null}
                <Table
                  cols={tableColumns}
                  containerClass="invoice-table"
                  items={rows}
                  onRowClick={this.openInvoiceItemsModal}
                  tableProps={{ bordered: false }}
                />

                <div className="finance-report__summary">
                  <div>
                    {isOutbound ? (
                      <label>
                        Total provider charges invoiced on
                        {` ${invoiceDate}`}
                      </label>
                    ) : (
                      <label>Total</label>
                    )}
                    <NumberTpl
                      decimals={2}
                      prefix={CURRENCY_SYMBOL[invoice.amounts.currency]}
                      value={amount}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
          {isOutbound && isBillingMode && (
            <>
              <div className="finance-report__summary">
                <div>
                  <label>
                    Total provider charges for invoice period:
                  </label>
                  <NumberTpl
                    decimals={2}
                    prefix={CURRENCY_SYMBOL[invoice.amounts.currency]}
                    value={breakdownTotal.toString()}
                  />
                </div>
              </div>
            </>
          )}
        </>
      </>
    );
  }
}

InvoiceBreakdown.propTypes = {
  invoice: PropTypes.object.isRequired,
  invoicingSettings: PropTypes.object.isRequired,
  orgAlias: PropTypes.string,
  modalState: modalStateSpec.isRequired,
};


InvoiceBreakdown.defaultProps = {
  orgAlias: null,
};

export default withStateModal(InvoiceBreakdown);
