import React from 'react';

import DateRangePicker from 'core/assets/js/components/DateRangePicker.jsx';
import moment from 'core/assets/js/lib/tdMoment';

function DatePicker() {
  return (
    <section id="date-picker" className="clearfix">
      <h2>Date Picker (Inline / Read only view)</h2>

      <div className="d-flex justify-content-center justify-content-sm-start">
        <DateRangePicker
          className="with-border p-3"
          endDate={moment().date(22).toDate()}
          showSecondMonth={false}
          startDate={moment().date(5).toDate()}
        />
      </div>
    </section>
  );
}

export default DatePicker;
