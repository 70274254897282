import Big from 'big.js';
import { assert, BANK_CURRENCY } from 'td-finance-ts';

import { CURRENCY, USER_CARD_STATUS } from 'core/assets/js/constants';
import { PAYOUT_STATUS, REVOLUT_TRANSACTION_STATUS } from 'services/assets/js/constants';
import { BANK_ACCOUNT_TYPE } from 'settings/assets/js/constants';

Big.RM = 1;
Big.DP = 2;

export const INVOICE_TYPE = {
  INBOUND: 0,
  OUTBOUND: 1,
  DIRECT: 2,
  LICENCE: 3,
  SUBSCRIPTION: 4,
};

export const INVOICE_TYPE_LABEL = {
  [INVOICE_TYPE.INBOUND]: 'Inbound',
  [INVOICE_TYPE.OUTBOUND]: 'Outbound',
  [INVOICE_TYPE.DIRECT]: 'Direct',
  [INVOICE_TYPE.LICENCE]: 'Licence',
  [INVOICE_TYPE.SUBSCRIPTION]: 'Subscription',
};

export const INVOICE_SEGMENT = {
  ALL_OUTBOUNDS: 'all_outbounds',
  ALL_LICENCES: 'all_licences',
  ALL_CONTRACTOR: 'all_contractor',
};

export const INVOICE_SEGMENT_LABEL = {
  [INVOICE_SEGMENT.ALL_OUTBOUNDS]: 'All outbound invoices',
  [INVOICE_SEGMENT.ALL_LICENCES]: 'All licence invoices',
  [INVOICE_SEGMENT.ALL_CONTRACTOR]: 'All contractor invoices',
};

export const INVOICE_SEGMENT_TYPES = {
  [INVOICE_SEGMENT.ALL_OUTBOUNDS]: [
    INVOICE_TYPE.OUTBOUND,
    INVOICE_TYPE.LICENCE,
    INVOICE_TYPE.SUBSCRIPTION,
  ],
  [INVOICE_SEGMENT.ALL_LICENCES]: [INVOICE_TYPE.LICENCE, INVOICE_TYPE.SUBSCRIPTION],
  [INVOICE_SEGMENT.ALL_CONTRACTOR]: [INVOICE_TYPE.INBOUND, INVOICE_TYPE.DIRECT],
};

export const INVOICING_FREQUENCY_SEGMENT = {
  PROCESSING: 'processing',
  LICENCE: 'licence',
};

export const INVOICE_STATUS = {
  RAISED: 0,
  EXECUTED: 1,
  PREVIEW: 2,
  PAID: 10,
  CANCELLED: 20,
  VOID: 30,
  BAD_DEBT: 40,
};

export const INVOICE_STATUS_LABEL = {
  [INVOICE_STATUS.RAISED]: 'Raised',
  [INVOICE_STATUS.EXECUTED]: 'Executed',
  [INVOICE_STATUS.PREVIEW]: 'Preview',
  [INVOICE_STATUS.PAID]: 'Paid',
  [INVOICE_STATUS.CANCELLED]: 'Cancelled',
  [INVOICE_STATUS.VOID]: 'Void',
  [INVOICE_STATUS.BAD_DEBT]: 'Bad debt',
};

export const INVOICE_STATUS_OPTIONS = Object.keys(INVOICE_STATUS).map(status => ({
  text: INVOICE_STATUS_LABEL[INVOICE_STATUS[status]],
  value: INVOICE_STATUS[status],
}));

export const INVOICING_MODE = {
  VIA: 'via',
  DIRECT: 'direct',
  BILL_PAYMENTS: 'bill_payments',
};

export const INVOICING_MODE_LABEL = {
  [INVOICING_MODE.VIA]: 'Via',
  [INVOICING_MODE.DIRECT]: 'Direct',
  [INVOICING_MODE.BILL_PAYMENTS]: 'Bill payments',
};

export const SYSTEM_PAYMENTS_EXECUTOR_NAME = 'TalentDesk.io';

export const INVOICING_FREQUENCY_SCHEMA_TYPES = {
  LEGACY: 'legacy',
  CRON: 'cron',
  CUSTOM: 'custom',
};

export const INVOICING_FREQUENCY_DEFAULT_CRON_SCHEMA = {
  type: INVOICING_FREQUENCY_SCHEMA_TYPES.CRON,
  value: '0 0 1,8,15,23 * *',
};

export const INVOICING_FREQUENCY = {
  WEEKLY: 'weekly',
  BIWEEKLY: 'biweekly',
  MONTHLY: 'monthly',
  BIMONTHLY: 'bimonthly',
  QUARTERLY: 'quarterly',
};


export const INVOICING_DAYS = {
  DAY_1: 1,
  DAY_8: 8,
  DAY_15: 15,
  DAY_23: 23,
};

export const INVOICING_DAYS_GROUP = {
  ONE_PER_MONTH: 'one_per_month',
  TWO_PER_MONTH: 'two_per_month',
  FOUR_PER_MONTH: 'four_per_month',
};

export const INVOICING_DAYS_DEFAULT_SELECTIONS = {
  [INVOICING_DAYS_GROUP.ONE_PER_MONTH]: [INVOICING_DAYS.DAY_1],
  [INVOICING_DAYS_GROUP.TWO_PER_MONTH]: [INVOICING_DAYS.DAY_1, INVOICING_DAYS.DAY_15],
  [INVOICING_DAYS_GROUP.FOUR_PER_MONTH]: [
    INVOICING_DAYS.DAY_1, INVOICING_DAYS.DAY_8, INVOICING_DAYS.DAY_15, INVOICING_DAYS.DAY_23,
  ],
};

export const INVOICING_DAYS_CSV = {
  [INVOICING_DAYS_GROUP.ONE_PER_MONTH]: '1',
  [INVOICING_DAYS_GROUP.TWO_PER_MONTH]: '1,15',
  [INVOICING_DAYS_GROUP.FOUR_PER_MONTH]: '1,8,15,23',
};

export const INVOICING_FREQUENCY_TO_DAYS = {
  [INVOICING_FREQUENCY.WEEKLY]: [
    INVOICING_DAYS.DAY_1, INVOICING_DAYS.DAY_8, INVOICING_DAYS.DAY_15, INVOICING_DAYS.DAY_23,
  ],
  [INVOICING_FREQUENCY.BIWEEKLY]: [INVOICING_DAYS.DAY_1, INVOICING_DAYS.DAY_15],
  [INVOICING_FREQUENCY.MONTHLY]: [INVOICING_DAYS.DAY_1],
};

export const INVOICING_DAYS_AVAILABLE = Object.values(INVOICING_DAYS);

export const INVOICING_FREQUENCY_TYPES = [
  { value: INVOICING_FREQUENCY.WEEKLY, text: 'Weekly' },
  { value: INVOICING_FREQUENCY.BIWEEKLY, text: 'Bi-weekly (2 weeks)' },
  { value: INVOICING_FREQUENCY.MONTHLY, text: 'Monthly (4 weeks)' },
  { value: INVOICING_FREQUENCY.BIMONTHLY, text: 'Bi-monthly (8 weeks)' },
  { value: INVOICING_FREQUENCY.QUARTERLY, text: 'Quarterly (12 weeks)' },
];

export const BILLING_TABS = {
  OVERVIEW: 'overview',
  BILLING_HISTORY: 'billing-history',
  SUBSCRIPTION: 'subscription',
};

export const BILLING_PROCESS_INVOICE_SEGMENTS = {
  ALL: 'all',
  LICENCE: 'licence',
  PROCESSING: 'processing',
};

export const RAISED_BY = {
  PROVIDER: 'provider',
  TALENTDESK: 'talentdesk',
};

export const RAISED_BY_OPTIONS = [
  { value: [RAISED_BY.PROVIDER], text: 'Contractors' },
  { value: [RAISED_BY.TALENTDESK], text: 'TalentDesk.io' },
];

export const RAISED_FOR = {
  CLIENT: 'client',
  TALENTDESK: 'talentdesk',
};

export const RAISED_TO_INVOICE_TYPE = {
  [RAISED_BY.PROVIDER]: {
    [RAISED_FOR.TALENTDESK]: INVOICE_TYPE.INBOUND,
    [RAISED_FOR.CLIENT]: INVOICE_TYPE.DIRECT,
  },
  [RAISED_BY.TALENTDESK]: {
    [RAISED_FOR.CLIENT]: INVOICE_TYPE.OUTBOUND,
  },
};

export const INVOICE_TYPE_TO_RAISED_BY = {
  [INVOICE_TYPE.INBOUND]: RAISED_BY.PROVIDER,
  [INVOICE_TYPE.OUTBOUND]: RAISED_BY.TALENTDESK,
  [INVOICE_TYPE.DIRECT]: RAISED_BY.PROVIDER,
};

export const INVOICE_TYPE_TO_RAISED_FOR = {
  [INVOICE_TYPE.INBOUND]: RAISED_FOR.TALENTDESK,
  [INVOICE_TYPE.OUTBOUND]: RAISED_FOR.CLIENT,
  [INVOICE_TYPE.DIRECT]: RAISED_FOR.CLIENT,
};


export const TRANSACTION_TYPE = {
  INBOUND: 0,
  OUTBOUND: 1,
  DIRECT: 2,
};

export const TRANSACTION_TYPE_LABEL = {
  [TRANSACTION_TYPE.INBOUND]: 'From client',
  [TRANSACTION_TYPE.OUTBOUND]: 'To provider',
  [TRANSACTION_TYPE.DIRECT]: 'Direct',
};

export const TRANSACTION_MODE = {
  FIXED_BALANCE: 0,
  FIXED_INVOICE: 1,
  FIXED_RECIPIENT_BANK: 2,
  FIXED_ORG_PAYMENT: 3,
  FIXED_SO_AMOUNT: 4,
};

export const TRANSACTION_MODE_LABEL = {
  [TRANSACTION_MODE.FIXED_BALANCE]: 'TD pays the service order amount in the BALANCE currency, using the FX rate at the time of the service order creation',
  [TRANSACTION_MODE.FIXED_INVOICE]: 'TD pays the contractor invoice amount in the INVOICE currency, using latest spot FX rate',
  [TRANSACTION_MODE.FIXED_RECIPIENT_BANK]: 'TD pays the contractor invoice amount in the BANK currency, using latest spot FX rate',
  [TRANSACTION_MODE.FIXED_ORG_PAYMENT]: 'TD pays the service order amount in the ORG currency, using the FX rate at the time of the service order creation',
  [TRANSACTION_MODE.FIXED_SO_AMOUNT]: 'Pay exact reserved service order amount, using the FX rate at the time of the service order creation',
};

export const TRANSACTION_MODE_SHORT_LABEL = {
  [TRANSACTION_MODE.FIXED_BALANCE]: 'Pay service order amount in BALANCE currency (SO FX rate)',
  [TRANSACTION_MODE.FIXED_INVOICE]: 'Pay contractor invoice amount in INVOICE currency, (spot FX rate)',
  [TRANSACTION_MODE.FIXED_RECIPIENT_BANK]: 'Pay contractor invoice amount in BANK currency, (spot FX rate)',
  [TRANSACTION_MODE.FIXED_ORG_PAYMENT]: 'Pay service order amount in ORG currency (SO FX rate)',
  [TRANSACTION_MODE.FIXED_SO_AMOUNT]: 'Pay service order amount in ORG currency (SO FX rate)',
};

export const TRANSACTION_MODE_RC_LABEL = {
  [TRANSACTION_MODE.FIXED_BALANCE]: 'Double Reserve - optimise for the provider’s bank currency',
  [TRANSACTION_MODE.FIXED_INVOICE]: 'Pay exact invoice amount',
  [TRANSACTION_MODE.FIXED_SO_AMOUNT]: 'Provider pays fees',
};

export const AVAILABLE_RC_TRANSACTION_MODES = [
  TRANSACTION_MODE.FIXED_BALANCE,
  TRANSACTION_MODE.FIXED_INVOICE,
  TRANSACTION_MODE.FIXED_SO_AMOUNT,
];

export const TRANSACTION_TYPE_OPTIONS = Object.keys(TRANSACTION_TYPE_LABEL).map(k => ({
  value: k.toString(), text: TRANSACTION_TYPE_LABEL[k],
}));

export const TRANSACTION_STATUS = {
  PENDING: 0,
  SUCCEEDED: 1,
  FAILED: 2,
  CANCELLED: 3,
  EXECUTED: 4,
  REFUNDED: 5,
  REMOVED: 6,
};

export const FINALIZED_TRANSACTION_STATUSES = [
  TRANSACTION_STATUS.SUCCEEDED,
  TRANSACTION_STATUS.FAILED,
  TRANSACTION_STATUS.CANCELLED,
  TRANSACTION_STATUS.REFUNDED,
  TRANSACTION_STATUS.REMOVED,
];

export const NULLIFIED_TRANSACTION_STATUSES = [
  TRANSACTION_STATUS.FAILED,
  TRANSACTION_STATUS.CANCELLED,
  TRANSACTION_STATUS.REFUNDED,
  TRANSACTION_STATUS.REMOVED,
];

export const TRANSACTION_STATUS_LABEL = {
  [TRANSACTION_STATUS.PENDING]: 'Pending',
  [TRANSACTION_STATUS.SUCCEEDED]: 'Succeeded',
  [TRANSACTION_STATUS.FAILED]: 'Failed',
  [TRANSACTION_STATUS.CANCELLED]: 'Cancelled',
  [TRANSACTION_STATUS.EXECUTED]: 'Executed',
  [TRANSACTION_STATUS.REFUNDED]: 'Refunded',
  [TRANSACTION_STATUS.REMOVED]: 'Removed',
};

export const TRANSACTION_METHOD = {
  WIRE_TRANSFER: 0,
  TRANSFERWISE: 1,
  PAYPAL: 2,
  WORLDPAY: 3,
  PAYONEER: 4,
  REALNET: 5,
  STRIPE: 6,
  REVOLUT: 7,
};

export const TRANSACTION_METHOD_LABEL = {
  [TRANSACTION_METHOD.WIRE_TRANSFER]: 'Wire transfer',
  [TRANSACTION_METHOD.TRANSFERWISE]: 'Wise',
  [TRANSACTION_METHOD.PAYPAL]: 'PayPal',
  [TRANSACTION_METHOD.WORLDPAY]: 'Worldpay',
  [TRANSACTION_METHOD.PAYONEER]: 'Payoneer',
  [TRANSACTION_METHOD.REALNET]: 'RealNet',
  [TRANSACTION_METHOD.STRIPE]: 'Stripe',
  [TRANSACTION_METHOD.REVOLUT]: 'Revolut',
};

export const BANK_ACCOUNT_TYPE_TO_TRANSACTION_METHOD = Object.values(BANK_ACCOUNT_TYPE).reduce(
  (acc, curr) => {
    let method;
    switch (curr) {
      case BANK_ACCOUNT_TYPE.BASE_TYPE:
        method = TRANSACTION_METHOD.WIRE_TRANSFER;
        break;
      case BANK_ACCOUNT_TYPE.PAYPAL:
        method = TRANSACTION_METHOD.PAYPAL;
        break;
      case BANK_ACCOUNT_TYPE.PAYONEER:
        method = TRANSACTION_METHOD.PAYONEER;
        break;
      case BANK_ACCOUNT_TYPE.REVOLUT:
        method = TRANSACTION_METHOD.REVOLUT;
        break;
      default:
        method = TRANSACTION_METHOD.TRANSFERWISE;
        break;
    }
    return {
      ...acc,
      [curr]: method,
    };
  }, {},
);

export const TRANSACTION_SEGMENT = {
  INBOUND: 'inbound',
  DIRECT: 'direct',
  PREVIEW: 'preview',
  PENDING: 'pending',
  PENDING_REVIEW: 'pending_review',
  PENDING_APPROVAL: 'pending_approval',
  BEING_PROCESSED: 'being_processed',
  FINALIZED: 'finalized',
};

export const TRANSACTION_SEGMENT_LABEL = {
  [TRANSACTION_SEGMENT.INBOUND]: 'Inbound',
  [TRANSACTION_SEGMENT.DIRECT]: 'Direct',
  [TRANSACTION_SEGMENT.PREVIEW]: 'Preview',
  [TRANSACTION_SEGMENT.PENDING]: 'Pending',
  [TRANSACTION_SEGMENT.PENDING_REVIEW]: 'Pending review',
  [TRANSACTION_SEGMENT.PENDING_APPROVAL]: 'Pending approval',
  [TRANSACTION_SEGMENT.BEING_PROCESSED]: 'Being processed',
  [TRANSACTION_SEGMENT.FINALIZED]: 'Finalized',
};

export const ON_DEMAND_PAYOUT_SEGMENT = {
  PENDING_REVIEW: 'pending_review',
  PENDING_APPROVAL: 'pending_approval',
  BEING_PROCESSED: 'being_processed',
  FINALIZED: 'finalized',
};

export const ON_DEMAND_PAYOUT_SEGMENT_LABEL = {
  [ON_DEMAND_PAYOUT_SEGMENT.PENDING_REVIEW]: 'Pending review',
  [ON_DEMAND_PAYOUT_SEGMENT.PENDING_APPROVAL]: 'Pending approval',
  [ON_DEMAND_PAYOUT_SEGMENT.BEING_PROCESSED]: 'Being processed',
  [ON_DEMAND_PAYOUT_SEGMENT.FINALIZED]: 'Finalized',
};

export const REPORT_TYPES = {
  PURCHASE_ORDER: 'purchase-order',
  WORKSHEET: 'worksheet',
  EXPENSE: 'expense',
  INVOICE: 'invoice',
  MANAGER_BUDGET: 'manager-budget',
  PROJECT_BUDGET: 'project-budget',
  PRO_FORMA_INVOICE: 'proforma-invoice',
};

export const REPORT_TYPES_LABEL = {
  [REPORT_TYPES.PURCHASE_ORDER]: 'Budget request',
  [REPORT_TYPES.WORKSHEET]: 'Worksheet',
  [REPORT_TYPES.EXPENSE]: 'Expense',
  [REPORT_TYPES.INVOICE]: 'Invoice',
  [REPORT_TYPES.MANAGER_BUDGET]: 'Manager Budget',
  [REPORT_TYPES.PROJECT_BUDGET]: 'Project Budget',
  [REPORT_TYPES.PRO_FORMA_INVOICE]: 'Proforma Invoice',
};

// https://www.ecb.europa.eu/paym/integration/retail/sepa/html/index.en.html
export const SEPA_COUNTRIES = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'CH', // Switzerland
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DE', // Germany
  'DK', // Denmark
  'EE', // Estonia
  'ES', // Spain
  'FI', // Finland
  'FR', // France
  'GB', // United Kingdom
  'GF', // French Guiana
  'GI', // Gibraltar
  'GP', // Guadeloupe
  'GR', // Greece
  'HR', // Croatia
  'HU', // Hungary
  'IE', // Ireland
  'IS', // Iceland
  'IT', // Italy
  'LI', // Liechtenstein
  'LT', // Lithuania
  'LU', // Luxembourg
  'LV', // Latvia
  'MC', // Monaco
  'MQ', // Martinique
  'MT', // Malta
  'NL', // Netherlands
  'NO', // Norway
  'PL', // Poland
  'PM', // Saint Pierre and Miquelon
  'PT', // Portugal
  'RE', // Reunion
  'RO', // Romania
  'SE', // Sweden
  'SI', // Slovenia
  'SK', // Slovakia
  'YT', // Mayotte
];

export function isSEPA(countryCode) {
  return SEPA_COUNTRIES.includes(countryCode);
}

export const TAX_CODE = {
  // VAT on purchases - used on CONTRACTOR invoices
  NO_VAT: '0.0% No VAT', // No Vat supplier / outside of UK
  ECS_20: '20.0% ECS', // Vat supplier / outside of UK
  // VAT on sales - used on OUTBOUND invoices
  Z_0: '0.0% Z',
  // VAT on purchases and sales - used on all invoices - OUTBOUND & CONTRACTOR
  S_20: '20.0% S', // Vat supplier / in UK
};

export const TAX_CODE_DESCRIPTION = {
  [TAX_CODE.NO_VAT]: 'Non VAT registered UK supplier',
  [TAX_CODE.ECS_20]: 'VAT Suppliers outside of the UK',
  [TAX_CODE.Z_0]: 'Sales to businesses outside the UK',
  [TAX_CODE.S_20]: 'UK VAT registered supplier & Standard UK Tax code for all sales',
};

export const VAT_PERCENT = {
  GB: 20,
  IM: 20,
};

export const DEFAULT_VAT_PERCENT = VAT_PERCENT.GB;

export const TRANSFER_TO_TRANSACTION_STATUS = {
  incoming_payment_waiting: TRANSACTION_STATUS.EXECUTED,
  processing: TRANSACTION_STATUS.EXECUTED,
  funds_converted: TRANSACTION_STATUS.EXECUTED,
  outgoing_payment_sent: TRANSACTION_STATUS.SUCCEEDED,
  bounced_back: TRANSACTION_STATUS.FAILED,
  funds_refunded: TRANSACTION_STATUS.REFUNDED,
  cancelled: TRANSACTION_STATUS.CANCELLED,
};

// https://developer.payoneer.com/docs/MassPayout/APIDoc/apis/payouts/get-payout-details
export const PAYOUT_TO_TRANSACTION_STATUS = {
  [PAYOUT_STATUS.PENDING_PAYEE_APPROVAL]: TRANSACTION_STATUS.EXECUTED,
  [PAYOUT_STATUS.PENDING_APPROVAL]: TRANSACTION_STATUS.EXECUTED,
  [PAYOUT_STATUS.PENDING_LOAD]: TRANSACTION_STATUS.EXECUTED,
  [PAYOUT_STATUS.PAYMENT_COMPLETED]: TRANSACTION_STATUS.EXECUTED,
  [PAYOUT_STATUS.FUNDED]: TRANSACTION_STATUS.SUCCEEDED,
  [PAYOUT_STATUS.TRANSFERRED]: TRANSACTION_STATUS.SUCCEEDED,
  [PAYOUT_STATUS.CANCELLED]: TRANSACTION_STATUS.CANCELLED,
  [PAYOUT_STATUS.REFUND]: TRANSACTION_STATUS.REFUNDED,
};

export const REVOLUT_TO_TRANSACTION_STATUS = {
  [REVOLUT_TRANSACTION_STATUS.CREATED]: TRANSACTION_STATUS.EXECUTED,
  [REVOLUT_TRANSACTION_STATUS.PENDING]: TRANSACTION_STATUS.EXECUTED,
  [REVOLUT_TRANSACTION_STATUS.COMPLETED]: TRANSACTION_STATUS.SUCCEEDED,
  [REVOLUT_TRANSACTION_STATUS.FAILED]: TRANSACTION_STATUS.FAILED,
  [REVOLUT_TRANSACTION_STATUS.DECLINED]: TRANSACTION_STATUS.FAILED,
  [REVOLUT_TRANSACTION_STATUS.REVERTED]: TRANSACTION_STATUS.REFUNDED,
};

export const SYSTEM_CURRENCY = CURRENCY.GBP;

export const AOR_ALLOWED_STATUSES = [
  USER_CARD_STATUS.APPROVED,
  USER_CARD_STATUS.PENDING_APPROVAL,
];

export const BALANCE_CURRENCIES = [
  BANK_CURRENCY.GBP,
  BANK_CURRENCY.USD,
  BANK_CURRENCY.EUR,
  BANK_CURRENCY.JPY,
  BANK_CURRENCY.AUD,
  BANK_CURRENCY.SGD,
  BANK_CURRENCY.NZD,
  BANK_CURRENCY.HKD,
];

/**
 * Payoneer notification types.
 */
export const PAYONEER_NOTIFICATION = {
  // registration status notifications
  BANK_ACCOUNT_REGISTERED: 0,
  ACCOUNT_OR_CARD_REGISTERED: 1,
  PAPER_CHECK_REGISTRATION_COMPLETED: 2,
  ENTITY_MISMATCH: 3,

  // payee status nofications
  PAYEE_APPROVED: 4,
  PAYEE_DECLINED: 5,

  // payout status notification
  PAYOUT_REQUEST_RECEIVED: 6,
  PAYOUT_ACCOUNT_OR_CARD_LOADED: 7,
  PAYOUT_SENT_TO_BANK: 8,
  PAYOUT_FAILED_BANK_TRANSFER: 9,
  PAYOUT_PAPER_CHECK_SENT: 10,
  PAYOUT_PAYOUT_CANCELLED: 11,

  // other
  ACCOUNT_FUNDED: 12,
  CUSTOMER_DETAILS_CHANGED: 13,
  BANK_INFO_CHANGED: 14,
  PAYEE_ID_RELEASED: 15,
  AUTO_PAYEE_CREATED: 16,

  // talentdesk custom
  UNKNOWN: 99,
};

/**
 * List of Payoneer notifcations for payee status change.
 */
export const PAYONEER_PAYEE_NOTIFICATIONS = [
  PAYONEER_NOTIFICATION.PAYEE_APPROVED,
  PAYONEER_NOTIFICATION.PAYEE_DECLINED,
];

/**
 * Payoneer notification type labels.
 */
export const PAYONEER_NOTIFICATION_LABEL = {
  [PAYONEER_NOTIFICATION.BANK_ACCOUNT_REGISTERED]: 'Payee has completed registration for payouts via local bank account',
  [PAYONEER_NOTIFICATION.ACCOUNT_OR_CARD_REGISTERED]: 'Payee has completed registration for payouts via Payoneer account or pre-paid card',
  [PAYONEER_NOTIFICATION.PAPER_CHECK_REGISTRATION_COMPLETED]: 'Payee has completed registration for payouts via paper check',
  [PAYONEER_NOTIFICATION.ENTITY_MISMATCH]: 'Failed to match payee to existing account',
  [PAYONEER_NOTIFICATION.PAYEE_APPROVED]: 'Payee application approved by Payoneer',
  [PAYONEER_NOTIFICATION.PAYEE_DECLINED]: 'Payee application declined by Payoneer',
  [PAYONEER_NOTIFICATION.PAYOUT_REQUEST_RECEIVED]: 'Payment request has been received',
  [PAYONEER_NOTIFICATION.PAYOUT_ACCOUNT_OR_CARD_LOADED]: 'Funds loaded onto payee card',
  [PAYONEER_NOTIFICATION.PAYOUT_SENT_TO_BANK]: 'Instruction to transfer funds sent',
  [PAYONEER_NOTIFICATION.PAYOUT_FAILED_BANK_TRANSFER]: 'Bank transfer instruction failed',
  [PAYONEER_NOTIFICATION.PAYOUT_PAPER_CHECK_SENT]: 'Funds sent via paper check',
  [PAYONEER_NOTIFICATION.PAYOUT_PAYOUT_CANCELLED]: 'Payout cancelled',
  [PAYONEER_NOTIFICATION.ACCOUNT_FUNDED]: 'Client account funded',
  [PAYONEER_NOTIFICATION.CUSTOMER_DETAILS_CHANGED]: 'Payee profile changed',
  [PAYONEER_NOTIFICATION.BANK_INFO_CHANGED]: 'Payee bank account details changed',
  [PAYONEER_NOTIFICATION.PAYEE_ID_RELEASED]: 'Payee id released by Payoneer',
  [PAYONEER_NOTIFICATION.AUTO_PAYEE_CREATED]: 'Auto Payee created',
  [PAYONEER_NOTIFICATION.UNKNOWN]: 'Unknown',
};

/**
 * A list of all Payoneer notification types related to payouts.
 */
export const PAYONEER_PAYOUT_NOTIFICATIONS = [
  PAYONEER_NOTIFICATION.PAYOUT_REQUEST_RECEIVED,
  PAYONEER_NOTIFICATION.PAYOUT_ACCOUNT_OR_CARD_LOADED,
  PAYONEER_NOTIFICATION.PAYOUT_SENT_TO_BANK,
  PAYONEER_NOTIFICATION.PAYOUT_FAILED_BANK_TRANSFER,
  PAYONEER_NOTIFICATION.PAYOUT_PAPER_CHECK_SENT,
  PAYONEER_NOTIFICATION.PAYOUT_PAYOUT_CANCELLED,
];

export const EXCHANGE_RATE_SERVICE = {
  FIXER: 'fixer',
  TRANSFERWISE: 'transferwise',
};

export const EXCHANGE_RATE_SERVICE_LABEL = {
  [EXCHANGE_RATE_SERVICE.FIXER]: 'Fixer',
  [EXCHANGE_RATE_SERVICE.TRANSFERWISE]: 'Wise',
};

export const EXCHANGE_RATE_SERVICE_URL = {
  [EXCHANGE_RATE_SERVICE.FIXER]: 'https://fixer.io',
  [EXCHANGE_RATE_SERVICE.TRANSFERWISE]: 'https://wise.com',
};

export const EXCHANGE_RATE_SERVICE_VALID_VALUES = [
  ...Object.values(EXCHANGE_RATE_SERVICE),
  null,
];

export const DEFAULT_EXCHANGE_RATE_SERVICE = EXCHANGE_RATE_SERVICE.FIXER;

export const INVOICE_ITEM_PURPOSE = {
  // this invoice item is included in a contractor invoice
  // it is fully charged to the recipient (Talentdesk.io or Client depending on mode)
  CONTRACTOR_INVOICE: 0,
  // this invoice item is included in an outbound invoice containing consolidated contractor charges
  // it is fully charged to the client
  CLIENT_INVOICE_CHARGE: 1,
  // this invoice item is included in an outbound invoice for referencing earlier preprocessed work
  // this is mainly for reporting in the appendix of the invoice
  // it is *NOT* charged to the client
  CLIENT_INVOICE_BREAKDOWN: 2,
  // this invoice item is included in an outbound invoice for reporting contractor charges
  // it is used in BILL_PAYMENTS mode during mid-month invoices. it is *NOT* charged to the client
  CLIENT_INVOICE_SUMMARISE_CHARGES: 3,
};

export const INVOICE_ITEM_PURPOSES_WITH_CHARGES = [
  INVOICE_ITEM_PURPOSE.CONTRACTOR_INVOICE,
  INVOICE_ITEM_PURPOSE.CLIENT_INVOICE_CHARGE,
];

export const INVOICE_ITEM_PURPOSES_WITH_CREDIT = [
  INVOICE_ITEM_PURPOSE.CONTRACTOR_INVOICE,
  INVOICE_ITEM_PURPOSE.CLIENT_INVOICE_CHARGE,
];

export const INVOICE_ITEM_PURPOSES_PREPROCESSED = [
  INVOICE_ITEM_PURPOSE.CLIENT_INVOICE_BREAKDOWN,
];

export const INVOICE_ITEM_PURPOSES_RAISED_BY_CONTRACTORS = [
  INVOICE_ITEM_PURPOSE.CONTRACTOR_INVOICE,
];

export const SUBSCRIPTION_PLANS = {
  BASIC: 1,
  PLUS: 2,
  ENTERPRISE: 3,
  UNLIMITED: 4,
};

export const SUBSCRIPTION_PLAN_VALUES = Object.values(SUBSCRIPTION_PLANS);

export const SUBSCRIPTION_PLAN_LABELS = {
  [SUBSCRIPTION_PLANS.BASIC]: 'Basic',
  [SUBSCRIPTION_PLANS.PLUS]: 'Plus',
  [SUBSCRIPTION_PLANS.ENTERPRISE]: 'Enterprise',
  [SUBSCRIPTION_PLANS.UNLIMITED]: 'Unlimited',
};

// note - this must match the db schema for `fact_audit_logs.level`
export const AUDIT_LEVEL = {
  INFO: 0,
  SEVERE: 1,
  CRITICAL: 2,
  PASS: 3,
};

export const AUDIT_LEVEL_LABELS = {
  [AUDIT_LEVEL.INFO]: 'INFO',
  [AUDIT_LEVEL.SEVERE]: 'SEVERE',
  [AUDIT_LEVEL.CRITICAL]: 'CRITICAL',
  [AUDIT_LEVEL.PASS]: 'PASS',
};

export const AUDIT_LEVEL_COLORS = {
  [AUDIT_LEVEL.INFO]: '#5767be',
  [AUDIT_LEVEL.SEVERE]: '#dc9935',
  [AUDIT_LEVEL.CRITICAL]: '#dc3545',
  [AUDIT_LEVEL.PASS]: '#60dc35',
};

export const AUDIT_OUTCOME = {
  PENDING: 'pending',
  FAIL: 'fail',
  PASS: 'pass',
};

export const AUDIT_OUTCOME_LABELS = {
  [AUDIT_OUTCOME.PENDING]: 'PENDING',
  [AUDIT_OUTCOME.FAIL]: 'FAIL',
  [AUDIT_OUTCOME.PASS]: 'PASS',
};

// note - these ids are persisted in the db, don't re-order
//        only add new ids at the end of the list
export const AUDIT_MESSAGES = {
  OUTBOUND_NOT_FOUND: 0,
  INBOUND_NOT_FOUND: 1,
  INVOICE_SETTINGS_NOT_FOUND: 2,
  INVOICE_NOT_OF_EXPECTED_TYPE_OUTBOUND: 3,
  INVOICE_NOT_OF_EXPECTED_TYPE_INBOUND: 4,
  CONTRACTOR_CHARGES_INCONSISTENT: 5,
  CONTRACTOR_GPV_INCONSISTENT: 6,
  SALES_TAX_RECHARGE_INCONSISTENT: 7,
  SALES_TAX_FOR_GB_INCONSISTENT: 8,
  SALES_TAX_INCONSISTENT: 9,
  REGISTERED_CONTRACTOR_SALES_TAX_FOR_GB_INCONSISTENT: 10,
  UNREGISTERED_CONTRACTOR_SALES_TAX_FOR_GB_INCONSISTENT: 11,
  CONTRACTOR_SALES_TAX_FOR_IM_INCONSISTENT: 12,
  CONTRACTOR_SALES_TAX_INCONSISTENT: 13,
  VAT_AMOUNT_INCORRECT: 14,
  MISSING_SERVICE_ORDERS: 15,
  ADDITIONAL_SERVICE_ORDERS: 16,
  INVOICE_ALREADY_INCLUDES_TRANSACTION: 17,
  LICENCE_INVOICE_INCLUDES_BASE_FEE_BUT_NO_SEATS: 18,
  LICENCE_OUTSIDE_TOLERANCE: 19,
  VAT_AMOUNT_CORRECT: 20,
  CONTRACTOR_GPV_CONSISTENT: 21,
  SALES_TAX_RECHARGE_CONSISTENT: 22,
  SALES_TAX_CONSISTENT: 23,
  CONTRACTOR_SALES_TAX_CONSISTENT: 23,
  OUTBOUND_INVOICE_AUDIT: 24,
  NO_GOOD_TRANSACTION_EXISTS_FOR_INVOICE: 25,
  LICENCE_INVOICE_AUDIT: 26,
  AOR_LICENCE_OUTSIDE_TOLERANCE: 27,
  AOR_LICENCE_INVOICE_AUDIT: 28,
  AOR_LICENCE_FEES_INCONSISTENT: 29,
  AOR_LICENCE_FEES_AUDIT: 30,
  AOR_LICENCE_FEES_PRESENT_ON_CHILD: 31,
  AOR_CHILD_LICENCE_FEES_AUDIT: 32,
  CONTRACTOR_EXCHANGE_RATES_INCONSISTENT: 33,
  CONTRACTOR_EXCHANGE_RATES_CONSISTENT: 34,
  SERVICES_LICENCE_ENTITY_INCORRECT: 35,
  SERVICES_LICENCE_ENTITY_CORRECT: 36,
};

export const AUDIT_MESSAGE_LABELS = {
  [AUDIT_MESSAGES.OUTBOUND_NOT_FOUND]: 'Outbound invoice not found',
  [AUDIT_MESSAGES.INBOUND_NOT_FOUND]: 'Inbound invoice not found',
  [AUDIT_MESSAGES.INVOICE_SETTINGS_NOT_FOUND]: 'Invoice settings not found',
  [AUDIT_MESSAGES.INVOICE_NOT_OF_EXPECTED_TYPE_OUTBOUND]: 'Invoice does not appear to be an outbound invoice',
  [AUDIT_MESSAGES.INVOICE_NOT_OF_EXPECTED_TYPE_INBOUND]: 'Invoice does not appear to be an inbound invoice',
  [AUDIT_MESSAGES.CONTRACTOR_CHARGES_INCONSISTENT]: 'Contractor charges are inconsistent',
  [AUDIT_MESSAGES.CONTRACTOR_GPV_INCONSISTENT]: 'Contractor GPV is inconsistent',
  [AUDIT_MESSAGES.SALES_TAX_RECHARGE_INCONSISTENT]: 'Sales tax recharge is inconsistent',
  [AUDIT_MESSAGES.SALES_TAX_FOR_GB_INCONSISTENT]: 'Sales tax for GB is inconsistent',
  [AUDIT_MESSAGES.SALES_TAX_INCONSISTENT]: 'Sales tax is inconsistent',
  [AUDIT_MESSAGES.REGISTERED_CONTRACTOR_SALES_TAX_FOR_GB_INCONSISTENT]: 'Contractor sales tax for GB is inconsistent',
  [AUDIT_MESSAGES.UNREGISTERED_CONTRACTOR_SALES_TAX_FOR_GB_INCONSISTENT]: 'Unregistered contractor sales tax for GB is inconsistent',
  [AUDIT_MESSAGES.CONTRACTOR_SALES_TAX_FOR_IM_INCONSISTENT]: 'Contractor sales tax for IM is inconsistent',
  [AUDIT_MESSAGES.CONTRACTOR_SALES_TAX_INCONSISTENT]: 'Contractor sales tax is inconsistent',
  [AUDIT_MESSAGES.VAT_AMOUNT_INCORRECT]: 'VAT amount is incorrect',
  [AUDIT_MESSAGES.MISSING_SERVICE_ORDERS]: 'Service orders were expected to be invoiced but were not',
  [AUDIT_MESSAGES.ADDITIONAL_SERVICE_ORDERS]: 'Services orders were invoiced but not expected to be',
  [AUDIT_MESSAGES.INVOICE_ALREADY_INCLUDES_TRANSACTION]: 'Invoice already includes a transaction in a good state',
  [AUDIT_MESSAGES.LICENCE_INVOICE_INCLUDES_BASE_FEE_BUT_NO_SEATS]: 'Licence invoice appears to include a base fee but has no base seats configured, this will result in double charging of licence fees',
  [AUDIT_MESSAGES.LICENCE_OUTSIDE_TOLERANCE]: 'Licence invoice total appears to be outside tolerance, please check this invoice is correct',
  [AUDIT_MESSAGES.AOR_LICENCE_OUTSIDE_TOLERANCE]: 'AOR licence invoice total appears to be outside tolerance, please check this invoice is correct',
  [AUDIT_MESSAGES.VAT_AMOUNT_CORRECT]: 'VAT amount is correct',
  [AUDIT_MESSAGES.CONTRACTOR_GPV_CONSISTENT]: 'Contractor GPV consistent',
  [AUDIT_MESSAGES.SALES_TAX_RECHARGE_CONSISTENT]: 'Sales tax recharge consistent',
  [AUDIT_MESSAGES.SALES_TAX_CONSISTENT]: 'Sales tax consistent',
  [AUDIT_MESSAGES.CONTRACTOR_SALES_TAX_CONSISTENT]: 'Contractor sales tax consistent',
  [AUDIT_MESSAGES.OUTBOUND_INVOICE_AUDIT]: 'Outbound invoice audit',
  [AUDIT_MESSAGES.NO_GOOD_TRANSACTION_EXISTS_FOR_INVOICE]: 'No good transaction exists for invoice',
  [AUDIT_MESSAGES.LICENCE_INVOICE_AUDIT]: 'Licence invoice audit',
  [AUDIT_MESSAGES.AOR_LICENCE_INVOICE_AUDIT]: 'AOR Licence invoice audit',
  [AUDIT_MESSAGES.AOR_LICENCE_FEES_INCONSISTENT]: 'AOR billable but fees not set',
  [AUDIT_MESSAGES.AOR_LICENCE_FEES_AUDIT]: 'AOR licence fees audit',
  [AUDIT_MESSAGES.AOR_LICENCE_FEES_PRESENT_ON_CHILD]: 'AOR licence fees present on child',
  [AUDIT_MESSAGES.AOR_CHILD_LICENCE_FEES_AUDIT]: 'AOR child licence fees audit',
  [AUDIT_MESSAGES.CONTRACTOR_EXCHANGE_RATES_INCONSISTENT]: 'Contractor exchange rates are inconsistent',
  [AUDIT_MESSAGES.CONTRACTOR_EXCHANGE_RATES_CONSISTENT]: 'Contractor exchange rates are consistent',
  [AUDIT_MESSAGES.SERVICES_LICENCE_ENTITY_INCORRECT]: 'Services Licence invoice entity is incorrect',
  [AUDIT_MESSAGES.SERVICES_LICENCE_ENTITY_CORRECT]: 'Services Licence invoice entity is correct',
};

export const AUDIT_MESSAGE_TITLES = {
  [AUDIT_MESSAGES.OUTBOUND_NOT_FOUND]: 'Invoice found',
  [AUDIT_MESSAGES.INBOUND_NOT_FOUND]: 'Invoice found',
  [AUDIT_MESSAGES.INVOICE_SETTINGS_NOT_FOUND]: 'Invoice settings found',
  [AUDIT_MESSAGES.INVOICE_NOT_OF_EXPECTED_TYPE_OUTBOUND]: 'Invoice type outbound',
  [AUDIT_MESSAGES.INVOICE_NOT_OF_EXPECTED_TYPE_INBOUND]: 'Invoice type inbound',
  [AUDIT_MESSAGES.CONTRACTOR_CHARGES_INCONSISTENT]: 'Contractor charges',
  [AUDIT_MESSAGES.CONTRACTOR_GPV_INCONSISTENT]: 'Contractor GPV',
  [AUDIT_MESSAGES.SALES_TAX_RECHARGE_INCONSISTENT]: 'Sales tax recharge',
  [AUDIT_MESSAGES.SALES_TAX_FOR_GB_INCONSISTENT]: 'Sales tax GB',
  [AUDIT_MESSAGES.SALES_TAX_INCONSISTENT]: 'Sales tax',
  [AUDIT_MESSAGES.REGISTERED_CONTRACTOR_SALES_TAX_FOR_GB_INCONSISTENT]: 'Reg contractor sales tax',
  [AUDIT_MESSAGES.UNREGISTERED_CONTRACTOR_SALES_TAX_FOR_GB_INCONSISTENT]: 'Unreg contractor sales tax',
  [AUDIT_MESSAGES.CONTRACTOR_SALES_TAX_FOR_IM_INCONSISTENT]: 'Contractor sales tax for IM',
  [AUDIT_MESSAGES.CONTRACTOR_SALES_TAX_INCONSISTENT]: 'Contractor sales tax',
  [AUDIT_MESSAGES.VAT_AMOUNT_INCORRECT]: 'VAT amount',
  [AUDIT_MESSAGES.MISSING_SERVICE_ORDERS]: 'Missing service orders',
  [AUDIT_MESSAGES.ADDITIONAL_SERVICE_ORDERS]: 'Additional services orders',
  [AUDIT_MESSAGES.INVOICE_ALREADY_INCLUDES_TRANSACTION]: 'Invoice already has transction',
  [AUDIT_MESSAGES.LICENCE_INVOICE_INCLUDES_BASE_FEE_BUT_NO_SEATS]: 'Licence invoice base fee',
  [AUDIT_MESSAGES.LICENCE_OUTSIDE_TOLERANCE]: 'Licence invoice tolerance',
  [AUDIT_MESSAGES.AOR_LICENCE_OUTSIDE_TOLERANCE]: 'AOR tolerance',
  [AUDIT_MESSAGES.VAT_AMOUNT_CORRECT]: 'VAT amount',
  [AUDIT_MESSAGES.CONTRACTOR_GPV_CONSISTENT]: 'Contractor GPV',
  [AUDIT_MESSAGES.SALES_TAX_RECHARGE_CONSISTENT]: 'Sales tax recharge',
  [AUDIT_MESSAGES.SALES_TAX_CONSISTENT]: 'Sales tax',
  [AUDIT_MESSAGES.CONTRACTOR_SALES_TAX_CONSISTENT]: 'Contractor sales tax',
  [AUDIT_MESSAGES.OUTBOUND_INVOICE_AUDIT]: 'Outbound invoice',
  [AUDIT_MESSAGES.NO_GOOD_TRANSACTION_EXISTS_FOR_INVOICE]: 'No good transaction',
  [AUDIT_MESSAGES.LICENCE_INVOICE_AUDIT]: 'Licence invoice',
  [AUDIT_MESSAGES.AOR_LICENCE_INVOICE_AUDIT]: 'AOR licence invoice',
  [AUDIT_MESSAGES.AOR_LICENCE_FEES_INCONSISTENT]: 'AOR fee',
  [AUDIT_MESSAGES.AOR_LICENCE_FEES_AUDIT]: 'AOR fee',
  [AUDIT_MESSAGES.AOR_LICENCE_FEES_PRESENT_ON_CHILD]: 'AOR present on child',
  [AUDIT_MESSAGES.AOR_CHILD_LICENCE_FEES_AUDIT]: 'AOR child fees',
  [AUDIT_MESSAGES.CONTRACTOR_EXCHANGE_RATES_INCONSISTENT]: 'Contractor exchange rates',
  [AUDIT_MESSAGES.CONTRACTOR_EXCHANGE_RATES_CONSISTENT]: 'Contractor exchange rates',
  [AUDIT_MESSAGES.SERVICES_LICENCE_ENTITY_INCORRECT]: 'Services entity',
  [AUDIT_MESSAGES.SERVICES_LICENCE_ENTITY_CORRECT]: 'Services entity',
};

export const OUTBOUND_INVOICE_TEMPLATES = {
  BASIC: 'basic',
  SIMPLE: 'simple',
  NO_BILLABLES_BREAKDOWN: 'no billables breakdown',
};

export const OUTBOUND_INVOICE_TEMPLATE_LABELS = {
  [OUTBOUND_INVOICE_TEMPLATES.BASIC]: 'Standard invoice, historically used by all invoices',
  [OUTBOUND_INVOICE_TEMPLATES.SIMPLE]: 'Simple invoice, hide the breakdowns that are normally on the basic template',
  [OUTBOUND_INVOICE_TEMPLATES.NO_BILLABLES_BREAKDOWN]: 'Template hides the billables breakdown analysis that is normally on the basic template',
};

export const OUTBOUND_INVOICE_TEMPLATE_DESCRIPTIONS = {
  IT_SERVICES: 'itservices',
};

// note - these strings appear on the actual invoice
export const OUTBOUND_INVOICE_TEMPLATE_DESCRIPTION_LABELS = {
  [OUTBOUND_INVOICE_TEMPLATE_DESCRIPTIONS.IT_SERVICES]: 'Dedicated IT Services',
};

export const SUBSCRIPTION_VENDOR = {
  STRIPE: 'stripe',
};

export const SUBSCRIPTION_VENDOR_LABEL = {
  [SUBSCRIPTION_VENDOR.STRIPE]: 'Stripe',
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  UNPAID: 'unpaid',
  CANCELLED: 'cancelled',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
};

export const SUBSCRIPTION_STATUS_LABEL = {
  [SUBSCRIPTION_STATUS.ACTIVE]: 'Active',
  [SUBSCRIPTION_STATUS.PAST_DUE]: 'Past due',
  [SUBSCRIPTION_STATUS.UNPAID]: 'Unpaid',
  [SUBSCRIPTION_STATUS.CANCELLED]: 'Cancelled',
  [SUBSCRIPTION_STATUS.INCOMPLETE]: 'Incomplete',
  [SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED]: 'Incomplete expired',
  [SUBSCRIPTION_STATUS.TRIALING]: 'Trialing',
};

export const SUBSCRIPTION_RENEWAL_INTERVAL = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};

export const SUBSCRIPTION_RENEWAL_INTERVAL_LABEL = {
  [SUBSCRIPTION_RENEWAL_INTERVAL.MONTHLY]: 'Monthly',
  [SUBSCRIPTION_RENEWAL_INTERVAL.YEARLY]: 'Yearly',
};

// NOTE - do NOT use this value in code other than to initialise the org's initial fx mark up
//        read it from the org's invoice settings, as the value can be customised per org
export const INITIAL_INVOICING_SETTINGS_FX_MARKUP = 0.01;
export const INITIAL_INVOICING_SETTINGS_FX_MARKUP_AS_A_PERCENT = Big(
  INITIAL_INVOICING_SETTINGS_FX_MARKUP).times(100).toFixed(1);

export const SERVICE_ORDER_PREREQUISITES = {
  COMPANY: 'company',
  PAYMENT_METHOD: 'payment-method',
  INVOICING_VAT_PERCENT: 'invoicing-vat-percent',
  ORG_PAYMENT_METHOD: 'org-payment-method',
  ORG_PAYMENT_METHOD_PAYPAL: 'org-payment-method-paypal',
  ORG_PAYMENT_METHOD_PAYONEER: 'org-payment-method-payoneer',
  PROFILE: 'profile',
};

export const PAYONEER_BASE_FEE_USD = '3.00';


export const PRICING_SCALE_STATUS = {
  PREACTIVATED: 'preactivated',
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
  DEFAULT: 'default',
};

export const PRICING_SCALE_STATUS_LABEL = {
  [PRICING_SCALE_STATUS.PREACTIVATED]: 'Pre-activated',
  [PRICING_SCALE_STATUS.ACTIVE]: 'Active',
  [PRICING_SCALE_STATUS.DEACTIVATED]: 'Deactivated',
  [PRICING_SCALE_STATUS.DEFAULT]: 'Default',
};

export const PRICING_SCALES_ALLOWED_FOR_ASSIGNMENT = [
  PRICING_SCALE_STATUS.PREACTIVATED,
  PRICING_SCALE_STATUS.ACTIVE,
  PRICING_SCALE_STATUS.DEFAULT,
];

export const PRICING_SCALES_ALLOWED_FOR_DEFAULT = [
  PRICING_SCALE_STATUS.PREACTIVATED,
];

export const TAX_METHOD = {
  SUBTOTAL: 0,
  PER_LINE_ITEM: 1,
};
export const TAX_METHOD_LABEL = {
  [TAX_METHOD.SUBTOTAL]: 'Subtotal',
  [TAX_METHOD.PER_LINE_ITEM]: 'Per line item',
};

export const DEFAULT_BALANCE_CURRENCY = BANK_CURRENCY.USD;

export const PROCESSING_FEES_MODE = {
  SCALE: 0,
  PER_APPROVED_WORKSHEET: 1,
};

export const PROCESSING_FEES_MODE_LABEL = {
  [PROCESSING_FEES_MODE.SCALE]: 'Scale fees',
  [PROCESSING_FEES_MODE.PER_APPROVED_WORKSHEET]: 'Per approved worksheet',
};

// note - this enum matches the db enum on the column
//        Organizations.invoicing_default_payment_provider
export const DEFAULT_PAYMENT_PROVIDERS = {
  WISE: 'wise',
  REVOLUT: 'revolut',
};

export const DEFAULT_PAYMENT_PROVIDER_LABELS = {
  [DEFAULT_PAYMENT_PROVIDERS.WISE]: 'Wise',
  [DEFAULT_PAYMENT_PROVIDERS.REVOLUT]: 'Revolut',
};

export const BUDGETS_TABS = {
  MANAGER_BUDGET: 'managers-budget',
  PROJECT_BUDGET: 'projects-budget',
  BUDGET_REQUESTS: 'purchase-orders',
};

// https://docs.wise.com/api-docs/api-reference/quote#create-authenticated
export const WISE_PAYOUT_MODE = {
  BANK_TRANSFER: 'BANK_TRANSFER', // default one
  BALANCE: 'BALANCE',
  SWIFT: 'SWIFT',
  SWIFT_OUR: 'SWIFT_OUR',
  INTERAC: 'INTERAC',
};

export const SERVICE_KEY_NAME = {
  AOR: 'aor',
  API: 'api',
  CODAT: 'codat',
  ESIGN: 'esign',
  TEN99: 'ten99',
  TIN: 'tin',
};

export const SERVICE_KEY_LABEL = {
  [SERVICE_KEY_NAME.AOR]: 'AOR fees',
  [SERVICE_KEY_NAME.API]: 'API fees',
  [SERVICE_KEY_NAME.CODAT]: 'Codat fees',
  [SERVICE_KEY_NAME.ESIGN]: 'E-sign fees',
  [SERVICE_KEY_NAME.TEN99]: '1099 fees',
  [SERVICE_KEY_NAME.TIN]: 'TIN fees',
};

export const SYSTEM_BANK_ACCOUNT_STATUS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const SYSTEM_BANK_ACCOUNT_STATUS_LABEL = {
  [SYSTEM_BANK_ACCOUNT_STATUS.ACTIVE]: 'Active',
  [SYSTEM_BANK_ACCOUNT_STATUS.ARCHIVED]: 'Archived',
};

export const PAYER_MAPPINGS_STATUS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const PAYER_MAPPINGS_STATUS_LABEL = {
  [PAYER_MAPPINGS_STATUS.ACTIVE]: 'Active',
  [PAYER_MAPPINGS_STATUS.ARCHIVED]: 'Archived',
};

// note, this match the Wise API response values for the field 'transactions[n].type'
// hence why they must be uppercase, see https://docs.wise.com/api-docs/api-reference/balance-statement
export const WISE_STATEMENT_TYPE = {
  DEBIT: 'DEBIT',
  CREDIT: 'CREDIT',
};

// https://docs.wise.com/api-docs/api-reference/balance-statement#object
export const WISE_STATEMENT_TRANSACTION_TYPE = {
  CARD: 'CARD',
  CONVERSION: 'CONVERSION',
  DEPOSIT: 'DEPOSIT',
  TRANSFER: 'TRANSFER',
  MONEY_ADDED: 'MONEY_ADDED',
  UNKNOWN: 'UNKNOWN',
};

export const WISE_STATEMENT_TYPE_LABELS = {
  [WISE_STATEMENT_TYPE.DEBIT]: 'Debit',
  [WISE_STATEMENT_TYPE.CREDIT]: 'Credit',
};

export const US_TAX_FORM_TYPE = {
  'W-9': 'w-9', // American tax payers
  'W-8BEN': 'w-8ben', // Foreign citizens
  'W-8BEN-E': 'w-8ben-e', // Foreign businesses
};

export const US_TAX_FORM_TYPE_VALUES = Object.values(US_TAX_FORM_TYPE);

export const US_TAX_FORM_TYPE_LABEL = {
  [US_TAX_FORM_TYPE['W-9']]: 'W-9',
  [US_TAX_FORM_TYPE['W-8BEN']]: 'W-8BEN',
  [US_TAX_FORM_TYPE['W-8BEN-E']]: 'W-8BEN-E',
};

export const US_TAX_FORM_STATUS = {
  MISSING: 'missing',
  PENDING_REVIEW: 'pending-review',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

export const US_TAX_FORM_STATUS_VALUES = Object.values(US_TAX_FORM_STATUS);

export const BILL_PAYMENTS_TOLERANCE_PERCENTAGE = 0;

export const US_TAX_FORM_STATUS_LABEL = {
  [US_TAX_FORM_STATUS.MISSING]: 'Missing',
  [US_TAX_FORM_STATUS.PENDING_REVIEW]: 'Pending review',
  [US_TAX_FORM_STATUS.APPROVED]: 'Approved',
  [US_TAX_FORM_STATUS.REJECTED]: 'Rejected',
};

export const US_TAX_FORM_STATUS_CLASS = {
  [US_TAX_FORM_STATUS.MISSING]: 'void',
  [US_TAX_FORM_STATUS.PENDING_REVIEW]: 'pending',
  [US_TAX_FORM_STATUS.APPROVED]: 'approved',
  [US_TAX_FORM_STATUS.REJECTED]: 'rejected',
};

export const US_TAX_FILING_TABS = {
  COMPANY_DETAILS: 'company-details',
  '1099_FILINGS': '1099-filings',
  'W-9_FORMS': 'w-9-forms',
  'W-8_FORMS': 'w-8-forms',
};

export const US_TAX_FILING_TABS_VALUES = Object.values(US_TAX_FILING_TABS);

// Referred to as BusinessMemberType in Tax Bandits
export const SIGNING_AUTHORITY_POSITION = {
  CORPORATESECRETARY: 'CORPORATESECRETARY',
  SECRETARYTREASURER: 'SECRETARYTREASURER',
  PARTNER: 'PARTNER',
  GENERALPARTNER: 'GENERALPARTNER',
  LIMITEDPARTNER: 'LIMITEDPARTNER',
  LLCMEMBER: 'LLCMEMBER',
  MANAGINGMEMBER: 'MANAGINGMEMBER',
  MANAGER: 'MANAGER',
  TAXMATTERPARTNER: 'TAXMATTERPARTNER',
  PRESIDENT: 'PRESIDENT',
  VICEPRESIDENT: 'VICEPRESIDENT',
  CORPORATETREASURER: 'CORPORATETREASURER',
  TREASURER: 'TREASURER',
  ASSISTANTTREASURER: 'ASSISTANTTREASURER',
  CHIEFACCOUNTINGOFFICER: 'CHIEFACCOUNTINGOFFICER',
  CHIEFEXECUTIVEOFFICER: 'CHIEFEXECUTIVEOFFICER',
  CHIEFFINANCIALOFFICER: 'CHIEFFINANCIALOFFICER',
  TAXOFFICER: 'TAXOFFICER',
  CHIEFOPERATINGOFFICER: 'CHIEFOPERATINGOFFICER',
  CORPORATEOFFICER: 'CORPORATEOFFICER',
  EXECUTIVEDIRECTOR: 'EXECUTIVEDIRECTOR',
  DIRECTOR: 'DIRECTOR',
  CHAIRMAN: 'CHAIRMAN',
  EXECUTIVEADMINISTRATOR: 'EXECUTIVEADMINISTRATOR',
  RECEIVER: 'RECEIVER',
  PASTOR: 'PASTOR',
  ASSISTANTTORELIGIOUSLEADER: 'ASSISTANTTORELIGIOUSLEADER',
  REVEREND: 'REVEREND',
  PRIEST: 'PRIEST',
  MINISTER: 'MINISTER',
  RABBI: 'RABBI',
  LEADEROFRELIGIOUSORGANIZATION: 'LEADEROFRELIGIOUSORGANIZATION',
  SECRETARY: 'SECRETARY',
  DIRECTOROFTAXATION: 'DIRECTOROFTAXATION',
  DIRECTOROFPERSONNEL: 'DIRECTOROFPERSONNEL',
  ADMINISTRATOR: 'ADMINISTRATOR',
  EXECUTOR: 'EXECUTOR',
  TRUSTEE: 'TRUSTEE',
  FIDUCIARY: 'FIDUCIARY',
  OWNER: 'OWNER',
  SOLEPROPRIETOR: 'SOLEPROPRIETOR',
  MEMBER: 'MEMBER',
  SOLEMEMBER: 'SOLEMEMBER',
};

export const SIGNING_AUTHORITY_POSITION_VALUES = Object.values(SIGNING_AUTHORITY_POSITION);

export const SIGNING_AUTHORITY_POSITION_LABEL = {
  [SIGNING_AUTHORITY_POSITION.CORPORATESECRETARY]: 'Corporate secretary',
  [SIGNING_AUTHORITY_POSITION.SECRETARYTREASURER]: 'Secretary treasurer',
  [SIGNING_AUTHORITY_POSITION.PARTNER]: 'Partner',
  [SIGNING_AUTHORITY_POSITION.GENERALPARTNER]: 'General partner',
  [SIGNING_AUTHORITY_POSITION.LIMITEDPARTNER]: 'Limited partner',
  [SIGNING_AUTHORITY_POSITION.LLCMEMBER]: 'LLC member',
  [SIGNING_AUTHORITY_POSITION.MANAGINGMEMBER]: 'Managing member',
  [SIGNING_AUTHORITY_POSITION.MANAGER]: 'Manager',
  [SIGNING_AUTHORITY_POSITION.TAXMATTERPARTNER]: 'Tax matter partner',
  [SIGNING_AUTHORITY_POSITION.PRESIDENT]: 'President',
  [SIGNING_AUTHORITY_POSITION.VICEPRESIDENT]: 'Vice president',
  [SIGNING_AUTHORITY_POSITION.CORPORATETREASURER]: 'Coprorate treasurer',
  [SIGNING_AUTHORITY_POSITION.TREASURER]: 'Treasurer',
  [SIGNING_AUTHORITY_POSITION.ASSISTANTTREASURER]: 'Assistant treasurer',
  [SIGNING_AUTHORITY_POSITION.CHIEFACCOUNTINGOFFICER]: 'Chief accounting officer',
  [SIGNING_AUTHORITY_POSITION.CHIEFEXECUTIVEOFFICER]: 'Chief executive officer',
  [SIGNING_AUTHORITY_POSITION.CHIEFFINANCIALOFFICER]: 'Chief financial officer',
  [SIGNING_AUTHORITY_POSITION.TAXOFFICER]: 'Tax officer',
  [SIGNING_AUTHORITY_POSITION.CHIEFOPERATINGOFFICER]: 'Chief operating officer',
  [SIGNING_AUTHORITY_POSITION.CORPORATEOFFICER]: 'Corporate office',
  [SIGNING_AUTHORITY_POSITION.EXECUTIVEDIRECTOR]: 'Executive director',
  [SIGNING_AUTHORITY_POSITION.DIRECTOR]: 'Director',
  [SIGNING_AUTHORITY_POSITION.CHAIRMAN]: 'Chairman',
  [SIGNING_AUTHORITY_POSITION.EXECUTIVEADMINISTRATOR]: 'Executive administrator',
  [SIGNING_AUTHORITY_POSITION.RECEIVER]: 'Receiver',
  [SIGNING_AUTHORITY_POSITION.PASTOR]: 'Pastor',
  [SIGNING_AUTHORITY_POSITION.ASSISTANTTORELIGIOUSLEADER]: 'Assistant to religious leader',
  [SIGNING_AUTHORITY_POSITION.REVEREND]: 'Reverend',
  [SIGNING_AUTHORITY_POSITION.PRIEST]: 'Priest',
  [SIGNING_AUTHORITY_POSITION.MINISTER]: 'Minister',
  [SIGNING_AUTHORITY_POSITION.RABBI]: 'Rabbi',
  [SIGNING_AUTHORITY_POSITION.LEADEROFRELIGIOUSORGANIZATION]: 'Leader of religious organization',
  [SIGNING_AUTHORITY_POSITION.SECRETARY]: 'Secretary',
  [SIGNING_AUTHORITY_POSITION.DIRECTOROFTAXATION]: 'Director of taxation',
  [SIGNING_AUTHORITY_POSITION.DIRECTOROFPERSONNEL]: 'Director of personnel',
  [SIGNING_AUTHORITY_POSITION.ADMINISTRATOR]: 'Administrator',
  [SIGNING_AUTHORITY_POSITION.EXECUTOR]: 'Executor',
  [SIGNING_AUTHORITY_POSITION.TRUSTEE]: 'Trustee',
  [SIGNING_AUTHORITY_POSITION.FIDUCIARY]: 'Fiduciary',
  [SIGNING_AUTHORITY_POSITION.OWNER]: 'Owner',
  [SIGNING_AUTHORITY_POSITION.SOLEPROPRIETOR]: 'Sole proprietor',
  [SIGNING_AUTHORITY_POSITION.MEMBER]: 'Member',
};

export const US_TAX_1099_FILING_STATUS = {
  NOT_FILED: 'not-filed',
  IN_PROGRESS: 'in-progress',
  ERROR: 'error',
  FILED: 'filed',
};

export const US_TAX_1099_FILING_STATUS_VALUES = Object.values(US_TAX_1099_FILING_STATUS);

// Tax Bandits allowed TIN types
export const TIN_TYPE = {
  SSN: 'SSN', // Person is not incorporated
  EIN: 'EIN', // Person is incorporated
  ITIN: 'ITIN', // Person is not incorporated but not eligible for SSN, always begins with a 9
  ATIN: 'ATIN', // Person is not incorporated but is in the process of being adopted as a US citizen
};

export const TIN_TYPE_VALUES = Object.values(TIN_TYPE);

export const US_TAX_1099_FILING_STATUS_LABEL = {
  [US_TAX_1099_FILING_STATUS.NOT_FILED]: 'Not filed',
  [US_TAX_1099_FILING_STATUS.IN_PROGRESS]: 'In progress',
  [US_TAX_1099_FILING_STATUS.ERROR]: 'Error',
  [US_TAX_1099_FILING_STATUS.FILED]: 'Filed',
};

export const US_TAX_1099_FILING_STATUS_CLASS = {
  [US_TAX_1099_FILING_STATUS.NOT_FILED]: 'void',
  [US_TAX_1099_FILING_STATUS.IN_PROGRESS]: 'pending',
  [US_TAX_1099_FILING_STATUS.ERROR]: 'rejected',
  [US_TAX_1099_FILING_STATUS.FILED]: 'approved',
};

export const US_TAX_1099_CANNOT_FILE_REASON = {
  FEATURE_NOT_ENABLED: 'feature-not-enabled',
  IN_PROGRESS: 'in-progress',
  FILED: 'filed',
  UNDER_600_USD: 'under-600-usd',
  LOGGED_IN_USER_UNAUTHORIZED: 'logged-in-user-unauthorized',
  NO_COMPANY_DETAILS: 'no-company-details',
  INVALID_YEAR: 'invalid-year',
};

const lastYear = new Date().getFullYear() - 1;

export const US_TAX_1099_CANNOT_FILE_REASON_DESCRIPTION = {
  [US_TAX_1099_CANNOT_FILE_REASON.FEATURE_NOT_ENABLED]: '1099 filing is not enabled',
  [US_TAX_1099_CANNOT_FILE_REASON.IN_PROGRESS]: 'The filing is in progress',
  [US_TAX_1099_CANNOT_FILE_REASON.FILED]: 'The filing is complete',
  [US_TAX_1099_CANNOT_FILE_REASON.UNDER_600_USD]: 'The contractor earned less than $600',
  [US_TAX_1099_CANNOT_FILE_REASON.LOGGED_IN_USER_UNAUTHORIZED]: (
    'You do not have permission to file 1099s for this organization'
  ),
  [US_TAX_1099_CANNOT_FILE_REASON.NO_COMPANY_DETAILS]: (
    "This organization's 1099 company details are not complete"
  ),
  [US_TAX_1099_CANNOT_FILE_REASON.INVALID_YEAR]: (
    `You can only submit 1099 filings for the preceding two years (${lastYear} or ${lastYear - 1})`
  ),
};


export const US_TAX_1099_CANNOT_UPDATE_OFF_PLATFORM_PAYMENTS_REASON = {
  FEATURE_NOT_ENABLED: 'feature-not-enabled',
  IN_PROGRESS: 'in-progress',
  FILED: 'filed',
  LOGGED_IN_USER_UNAUTHORIZED: 'logged-in-user-unauthorized',
};

export const US_TAX_1099_CANNOT_UPDATE_OFF_PLATFORM_PAYMENTS_REASON_DESCRIPTION = {
  [US_TAX_1099_CANNOT_UPDATE_OFF_PLATFORM_PAYMENTS_REASON.FEATURE_NOT_ENABLED]: (
    '1099 filing is not enabled'
  ),
  [US_TAX_1099_CANNOT_UPDATE_OFF_PLATFORM_PAYMENTS_REASON.IN_PROGRESS]: 'The filing is in progress',
  [US_TAX_1099_CANNOT_UPDATE_OFF_PLATFORM_PAYMENTS_REASON.FILED]: 'The filing is complete',
  [US_TAX_1099_CANNOT_UPDATE_OFF_PLATFORM_PAYMENTS_REASON.LOGGED_IN_USER_UNAUTHORIZED]: (
    'You do not have permission to file 1099s for this organization'
  ),
};

export const TAX_BANDITS_PHONE_NUMBER_ERROR = (
  'Acceptable formats: 9999999999, 999.999.9999, 999-999-9999, (999) 999-9999'
);

export const US_1099_CONTRACTORS_COMPONENT_NAME = 'US1099Contractors';

// note - max percentage of transfer that can be allocated to invoices
//        without failing validation
//        must be expressed as a normalised number ( ie 1 = 100% )
export const ALLOCATION_TRANSFER_TOLERANCE = 150 / 100;

// note - this enum is used in the db schema for CASHOUT_REQUESTS
export const CASHOUT_REQUEST_STATUS = {
  REQUESTED: 'requested',
  APPROVED: 'approved',
  DENIED: 'denied',
  PROCESSED: 'processed',
};

export const CASHOUT_REQUEST_STATUS_LABEL = {
  [CASHOUT_REQUEST_STATUS.REQUESTED]: 'Requested',
  [CASHOUT_REQUEST_STATUS.APPROVED]: 'Approved',
  [CASHOUT_REQUEST_STATUS.DENIED]: 'Denied',
  [CASHOUT_REQUEST_STATUS.PROCESSED]: 'Processed',
};

// note - this enum is used in the db schema for INVOICES
export const INVOICE_RAISED_STATUS = {
  WAITING_PAYMENT: 'waiting_payment',
  INSTANT_PAYMENT: 'instant_payment',
  DEFERRED_PAYMENT: 'deferred_payment',
  REQUESTED_CASHOUT: 'requested_cashout',
};


export const INVOICE_RAISED_STATUS_LABEL = {
  [INVOICE_RAISED_STATUS.WAITING_PAYMENT]: 'Raised',
  [INVOICE_RAISED_STATUS.INSTANT_PAYMENT]: 'Raised',
  [INVOICE_RAISED_STATUS.DEFERRED_PAYMENT]: 'Deferred payment',
  [INVOICE_RAISED_STATUS.REQUESTED_CASHOUT]: 'Cashout requested',
};

export const DOCUMENT_TYPE = {
  INVOICE: 'invoice',
  CASHOUT_REQUEST: 'cashout_request',
};

export const DOCUMENT_TYPE_PREFIX = {
  [DOCUMENT_TYPE.INVOICE]: 'TD',
  [DOCUMENT_TYPE.CASHOUT_REQUEST]: 'CR',
};

// NOTE - these are stored on the db as STRING,
//        therefore the value should NOT exceed 255 chars
export const CANCELLATION_REASON = {
  RESTRICTED_DESTINATION: 'Restricted destination',
  HIGH_FEES: 'High Fees',
  BAD_PAYMENT_DETAILS: 'Bad Payment Details',
  PAYONEER_MIN_AMOUNT: 'Payoneer minimum amount not met',
  BANK_FEES_CLOSE_TO_INVOICE_AMOUNT: 'Invoice amount close to bank fees',
  BANK_FEES_HIGHER_THAN_INVOICE_AMOUNT: 'Bank fees are higher than the invoice amount',
  BENEFICIARY_NAME_MISMATCH: 'Beneficiary name not matching Talentdesk account holder name',
  BETTER_PAYMENT_PROVIDER_AVAILBLE: 'Bank details more compatible with a certain payment partner',
  BANK_FEES_CLOSE_TO_INVOICE_AMOUNT2: 'Fees almost the same as invoice amount',
  RECONCILED_INCORRECTLY: 'Inbound contractor invoice reconciled incorrectly',
  COMBINE_WITH_PREVIOUS_PAYMENT_MIN_AMOUNT: 'Payment to be combined with a previous transaction that does not meet Payoneer minimum amount',
  COMBINE_WITH_PREVIOUS_PAYMENT_HIGHER_FEE: 'Payment to be combined with a previous transaction where the fee was higher than the invoice amount',
  MYR_INVOICE_EXACT_AMOUNT: 'MYR invoice - the contractor needs to receive the exact MYR amount',
  EXACT_INVOICE_MANUAL_SWIFT: 'Client on exact invoice amount transaction mode. Payment is to be processed manually via Swift.',
};

export const ACTIVITY_RECORD_TYPE_FILTER = {
  PRICING: 'pricing',
};

// note - this is reflected in the column 'invoiced_method'
//        on the 'organization_billings' table in the db,
//        any change to this, should be reflected in that schema via a migration
export const INVOICED_METHOD = {
  DASHBOARD: 'dashboard',
  TOOLING: 'tooling',
};

export const INVOICED_METHOD_LABEL = {
  [INVOICED_METHOD.DASHBOARD]: 'Manual',
  [INVOICED_METHOD.TOOLING]: 'Tooling',
};

export const FACT_AUDIT_RUN_TYPES = {
  INVOICING: 'invoicing',
  TRANSACTION: 'transaction',
};

// ensure every document type has a prefix defined
assert(Object.values(DOCUMENT_TYPE).every(v => Object.keys(DOCUMENT_TYPE_PREFIX).includes(v)), 'must define a document prefix for each document type');
