import React from 'react';
import PropTypes from 'prop-types';

import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import Table from 'core/assets/js/components/Table.jsx';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import LicenceFeeAnalysis from 'finance/assets/js/lib/LicenceFeeAnalysis';
import { SERVICE_KEY_NAME } from 'finance/assets/js/constants';

const LicenceFeeBreakdownItemsTable = ({ invoice }) => {
  if (!invoice?.amounts) {
    return null;
  }

  const tableColumns = [
    {
      key: 'orgName',
      label: 'Organization',
    },
    {
      key: 'count',
      label: 'Seats',
    },
    {
      key: 'formattedTotal',
      label: 'Total',
    },
  ];

  const tableClasses = [
    'finance-report__table-wrapper finance-report__table-wrapper--list',
    'finance-report__table-wrapper--responsive invoice-table-wrapper',
  ].join(' ');

  const { amounts } = invoice;

  const invoiceAmounts = new InvoiceAmounts(amounts);
  const licenceFeeAnalysis = new LicenceFeeAnalysis(amounts.feeAnalysis.licenceFee);
  const aorAnalysis = licenceFeeAnalysis.getAnalysisForServiceKey(SERVICE_KEY_NAME.AOR);
  const seatsFeeAnalysis = licenceFeeAnalysis.getSeatAnalysis();
  let formattedBreakdownItems = {};

  const hasZeroAorFees = aorAnalysis.isZero();
  const hasZeroSeatsFee = seatsFeeAnalysis.isZero();

  if (hasZeroSeatsFee && hasZeroAorFees) {
    return null;
  }

  const managersCount = seatsFeeAnalysis.getNumManagers();
  const managerHasFee = seatsFeeAnalysis.hasManagersFee();

  let title = '';
  if (!hasZeroAorFees) {
    formattedBreakdownItems = invoice?.aorFormattedFeeBreakdownItemsWithMetrics
      .formattedFeeBreakdownItems || [];
    title = 'AOR Charges';
  }
  if (!hasZeroSeatsFee) {
    formattedBreakdownItems = invoice?.seatsFormattedFeeBreakdownItemsWithMetrics
      .formattedFeeBreakdownItems || [];
    if (seatsFeeAnalysis.hasManagerAndProvidersFeeApplied()) {
      title = 'Manager and Provider Seat Charges';
    } else if (managersCount && managerHasFee) {
      title = 'Manager Seat Charges';
    } else {
      title = 'Provider Seat Charges';
    }
  }
  const currency = invoiceAmounts.getCurrency();
  const currencySymbol = CURRENCY_SYMBOL[currency];

  const rows = formattedBreakdownItems
    .filter(row => row.count !== 0)
    .map(item => ({
      ...item,
      formattedTotal: (
        <NumberTpl
          prefix={currencySymbol}
          value={item.total}
        />
      ),
    }));

  return (
    <div className={tableClasses}>
      <h3 className="p-3">
        {title}
      </h3>
      <Table containerClass="invoice-table" cols={tableColumns} items={rows} />
    </div>
  );
};

LicenceFeeBreakdownItemsTable.propTypes = {
  invoice: PropTypes.object.isRequired,
};


export default LicenceFeeBreakdownItemsTable;
