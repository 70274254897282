import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';

import { REPORTS_TABS, REPORTS_TABS_LABEL } from 'admin/assets/js/constants';
import DownloadList from 'admin/assets/js/resources/reports/DownloadsList.jsx';
import SignNowUsage from 'admin/assets/js/resources/reports/SignNowUsage.jsx';

const ReportsDownloadsView = (props) => {
  const { selectedTab } = props;
  const history = useHistory();

  const handleChange = useCallback((event, newValue) => {
    if (!newValue.includes('reports')) {
      history.push(`/reports/${newValue}`);
      return;
    }
    history.push(newValue);
  }, [history]);

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
      >
        { Object.keys(REPORTS_TABS).map(key => (
          <Tab
            key={REPORTS_TABS[key]}
            value={REPORTS_TABS[key]}
            label={REPORTS_TABS_LABEL[REPORTS_TABS[key]]}
          />
        ))}
      </Tabs>
      { selectedTab === REPORTS_TABS.DOWNLOADS && (
        <DownloadList />
      )}
      { selectedTab === REPORTS_TABS.SIGNNOW && (
        <SignNowUsage basePath="/signnow_usage" resource="signnow_usage" />
      )}
    </>
  );
};

ReportsDownloadsView.propTypes = {
  selectedTab: PropTypes.string,
};

ReportsDownloadsView.defaultProps = {
  selectedTab: REPORTS_TABS.DOWNLOADS,
};

export default ReportsDownloadsView;
