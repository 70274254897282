
export enum BANK_CURRENCY {
  AED = 'aed',
  ARS = 'ars',
  AUD = 'aud',
  BGN = 'bgn',
  BRL = 'brl',
  CAD = 'cad',
  CHF = 'chf',
  CLP = 'clp',
  CNY = 'cny',
  COP = 'cop',
  CZK = 'czk',
  DKK = 'dkk',
  EGP = 'egp',
  EUR = 'eur',
  FJD = 'fjd',
  GBP = 'gbp',
  GEL = 'gel',
  GHS = 'ghs',
  HKD = 'hkd',
  HRK = 'hrk',
  HUF = 'huf',
  IDR = 'idr',
  ILS = 'ils',
  INR = 'inr',
  JPY = 'jpy',
  KES = 'kes',
  KRW = 'krw',
  LKR = 'lkr',
  MAD = 'mad',
  MXN = 'mxn',
  MYR = 'myr',
  NGN = 'ngn',
  NOK = 'nok',
  NPR = 'npr',
  NZD = 'nzd',
  PEN = 'pen',
  PHP = 'php',
  PLN = 'pln',
  RON = 'ron',
  RUB = 'rub',
  SAR = 'sar',
  SEK = 'sek',
  SGD = 'sgd',
  THB = 'thb',
  TRY = 'try',
  TWD = 'twd',
  UAH = 'uah',
  USD = 'usd',
  VND = 'vnd',
  ZAR = 'zar',
  ZMW = 'zmw',
};

export const CURRENCY_SYMBOL: Record<string, string> = {
  [BANK_CURRENCY.AED]: 'Dhs',
  [BANK_CURRENCY.ARS]: 'ARS$',
  [BANK_CURRENCY.AUD]: 'AU$',
  [BANK_CURRENCY.BGN]: 'лв.',
  [BANK_CURRENCY.BRL]: 'R$',
  [BANK_CURRENCY.CAD]: 'c$',
  [BANK_CURRENCY.CHF]: 'fr.',
  [BANK_CURRENCY.CLP]: 'CLP$',
  [BANK_CURRENCY.CNY]: '¥',
  [BANK_CURRENCY.COP]: 'COL$',
  [BANK_CURRENCY.CZK]: 'Kč',
  [BANK_CURRENCY.DKK]: 'kr.',
  [BANK_CURRENCY.EGP]: 'E£',
  [BANK_CURRENCY.EUR]: '€',
  [BANK_CURRENCY.FJD]: 'FJ$',
  [BANK_CURRENCY.GBP]: '£',
  [BANK_CURRENCY.GEL]: '₾',
  [BANK_CURRENCY.GHS]: 'GH₵',
  [BANK_CURRENCY.HKD]: 'HK$',
  [BANK_CURRENCY.HRK]: 'kn',
  [BANK_CURRENCY.HUF]: 'Ft',
  [BANK_CURRENCY.IDR]: 'Rp',
  [BANK_CURRENCY.ILS]: '₪',
  [BANK_CURRENCY.INR]: '₹',
  [BANK_CURRENCY.JPY]: '¥',
  [BANK_CURRENCY.KES]: 'KSh',
  [BANK_CURRENCY.KRW]: '₩',
  [BANK_CURRENCY.LKR]: 'Rs',
  [BANK_CURRENCY.MAD]: 'DH',
  [BANK_CURRENCY.MXN]: 'MX$',
  [BANK_CURRENCY.MYR]: 'RM',
  [BANK_CURRENCY.NGN]: '₦',
  [BANK_CURRENCY.NOK]: 'kr',
  [BANK_CURRENCY.NPR]: 'रू',
  [BANK_CURRENCY.NZD]: 'NZ$',
  [BANK_CURRENCY.PEN]: 'S/',
  [BANK_CURRENCY.PHP]: '₱',
  [BANK_CURRENCY.PLN]: 'zł',
  [BANK_CURRENCY.RON]: 'L',
  [BANK_CURRENCY.RUB]: '₽',
  [BANK_CURRENCY.SAR]: 'SR',
  [BANK_CURRENCY.SEK]: 'kr',
  [BANK_CURRENCY.SGD]: 'S$',
  [BANK_CURRENCY.THB]: '฿',
  [BANK_CURRENCY.TRY]: '₺',
  [BANK_CURRENCY.TWD]: 'NT$',
  [BANK_CURRENCY.UAH]: '₴',
  [BANK_CURRENCY.USD]: '$',
  [BANK_CURRENCY.VND]: '₫',
  [BANK_CURRENCY.ZAR]: 'R',
  [BANK_CURRENCY.ZMW]: 'ZMW',
};


