/* eslint max-len: "off", react/no-multi-comp: "off" */
import Big from 'big.js';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import { useStyles } from 'admin/assets/js/layout/themes';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';
import CustomTooltip from 'admin/assets/js/components/CustomToolTip.jsx';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import TransactionOrder from 'finance/assets/js/lib/TransactionOrder';
import { INVOICE_STATUS, TRANSACTION_MODE, INVOICE_TYPE } from 'finance/assets/js/constants';
import { adminCommandApiUrl } from 'admin/urls';
import InvoicingSettings from 'finance/assets/js/lib/InvoicingSettings';

Big.RM = 1;
Big.DP = 2;

const AmountsDetails = ({ showTitle, amounts, invoice }) => {
  const classes = useStyles();
  const [transactionRetries, setTransactionRetries] = useState({});
  const [loadingRetries, setLoadingRetries] = useState(false);

  const isInvoiceRaised = invoice.status === INVOICE_STATUS.RAISED;
  const isOutbound = invoice.type === INVOICE_TYPE.OUTBOUND;
  const invoicingSettingsPOJO = new InvoicingSettings(invoice.invoicingSettings);
  const transactionMode = invoicingSettingsPOJO.getTransactionMode();

  const shouldFetchTransactionRetries = isInvoiceRaised
    && !isOutbound
    && transactionMode === TRANSACTION_MODE.FIXED_INVOICE;

  useEffect(() => {
    const url = adminCommandApiUrl('transaction-retries', { invoiceId: invoice.id });
    if (invoice.id && shouldFetchTransactionRetries) {
      setLoadingRetries(true);
      fetch(url)
        .then(res => res.json())
        .then((res) => {
          setTransactionRetries(res);
          setLoadingRetries(false);
        })
        .catch(() => {
          setLoadingRetries(false);
        });
    }
  }, [invoice.id, shouldFetchTransactionRetries, setTransactionRetries, setLoadingRetries]);
  const invoiceAmounts = new InvoiceAmounts(amounts);
  const {
    licenceFee, subscriptionFee, processingFee,
    exchangeRate,
    targetCurrency, orgCurrency,
    currency, targetTotal, vat,
  } = invoiceAmounts;


  const { serviceOrderToOrgFXRatesAverages } = invoice;

  const total = invoiceAmounts.getTotal();
  const exchangeRateMarkup = invoiceAmounts.getExchangeRateMarkup();
  const targetExchangeRate = invoiceAmounts.getTargetRate();
  const exchangeRateInOrgCurrency = invoiceAmounts.getExchangeRateInOrgCurrency();

  const contractorCharges = invoiceAmounts.getContractorCharges();
  const contractorChargesOrgCurrency = invoiceAmounts.getOrgContractorCharges();
  const orgNetValue = invoiceAmounts.getOrgNetValue();

  const currencySymbol = CURRENCY_SYMBOL[currency];
  const targetCurrencySymbol = CURRENCY_SYMBOL[targetCurrency];
  const orgCurrencySymbol = CURRENCY_SYMBOL[orgCurrency];

  const showTransactionRetries = shouldFetchTransactionRetries && !isEmpty(transactionRetries);

  const toBePaidToContractor = transactionRetries?.owed?.amount;
  const toBePaidToContractorCurrency = transactionRetries?.owed?.currency;
  const transactionRetriesInfo = transactionRetries?.retries;

  let firstAttemptQuoteParams;
  let secondAttemptQuoteParams;

  if (transactionRetriesInfo?.[0]) {
    const firstTransactionOrder = new TransactionOrder(transactionRetriesInfo[0]);
    firstAttemptQuoteParams = firstTransactionOrder.getWiseRequestParams();
  }

  if (transactionRetriesInfo?.[1]) {
    const secondTransactionOrder = new TransactionOrder(transactionRetriesInfo[1]);
    secondAttemptQuoteParams = secondTransactionOrder.getWiseRequestParams();
  }

  const hasFXRates = exchangeRateInOrgCurrency !== 1;

  return (
    <React.Fragment>
      {showTitle && (
        <h5>Amounts details</h5>
      )}
      <div className="d-flex mb-1">
        <span className={classes.discreet}>Service orders</span>
        <CustomTooltip
          placement="right"
          title="The accumulated total of all service orders"
        />
        <span className="pl-4 ml-auto">
          <NumberTpl value={contractorCharges} prefix={currencySymbol} />
        </span>
      </div>
      { invoiceAmounts.hasLicenceFee() && (
        <div className="d-flex mb-1">
          <span className={classes.discreet}>Licence fee</span>
          <span className="pl-4 ml-auto">
            <NumberTpl value={licenceFee} prefix={currencySymbol} />
          </span>
        </div>
      )}
      { invoiceAmounts.hasSubscriptionFee() && (
        <div className="d-flex mb-1">
          <span className={classes.discreet}>Subscription fee</span>
          <span className="pl-4 ml-auto">
            <NumberTpl value={subscriptionFee} prefix={currencySymbol} />
          </span>
        </div>
      )}
      { invoiceAmounts.hasProcessingFee() && (
        <div className="d-flex mb-1">
          <span className={classes.discreet}>Processing fee</span>
          <span className="pl-4 ml-auto">
            <NumberTpl value={processingFee} prefix={currencySymbol} />
          </span>
        </div>
      )}
      <div className="d-flex mb-1">
        <span className={classes.discreet}>Vat</span>
        <span className="pl-4 ml-auto text-">
          <NumberTpl value={vat} prefix={currencySymbol} />
        </span>
      </div>
      <div className="d-flex mb-1 mt-3">
        <span className={classes.discreet}>Total</span>
        <strong className="pl-4 ml-auto">
          <NumberTpl value={total} prefix={currencySymbol} />
        </strong>
      </div>
      {exchangeRate && exchangeRate !== 1 && (
        <React.Fragment>
          <hr />
          <h5>REQUESTED CURRENCY</h5>
          <div className="d-flex mb-1">
            <span className={classes.discreet}>Total</span>
            <span className="pl-4 ml-auto">
              <NumberTpl value={targetTotal} prefix={targetCurrencySymbol} />
            </span>
          </div>
          <div className="d-flex mb-1">
            <span className={`${classes.discreet} d-flex align-items-center`}>
              FX rate
              <CustomTooltip
                title={(
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>Exchange rate</strong>
                            <div>{`${targetCurrencySymbol}/${currencySymbol}`}</div>
                          </TableCell>
                          <TableCell><strong>Markup</strong></TableCell>
                          <TableCell>
                            <strong>Total rate</strong>
                            <div>{`${targetCurrencySymbol}/${currencySymbol}`}</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{exchangeRate}</TableCell>
                          <TableCell>{`${exchangeRateMarkup * 100}%`}</TableCell>
                          <TableCell>{targetExchangeRate}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              />
            </span>
            <span className="pl-4 ml-auto">
              {`${targetExchangeRate} ${targetCurrencySymbol}/${currencySymbol}`}
            </span>
          </div>
          <div className="d-flex mb-3">
            <span className={classes.discreet}>
              FX rate markup
            </span>
            <span className="pl-4 ml-auto">
              <NumberTpl
                value={exchangeRateMarkup * 100}
                suffix="%"
                decimals={2}
              />
            </span>
          </div>
        </React.Fragment>
      )}
      <hr />
      <h5>ORGANIZATION CURRENCY</h5>
      <div className="d-flex mb-1">
        <span className={classes.discreet}>
          Contractor charges on service order day
        </span>
        <span className="pl-4 ml-auto">
          <NumberTpl value={contractorChargesOrgCurrency} prefix={orgCurrencySymbol} />
        </span>
      </div>
      <div className="d-flex mb-1">
        <span className={classes.discreet}>Amount invoiced to client</span>
        <span className="pl-4 ml-auto">
          <NumberTpl value={orgNetValue} prefix={orgCurrencySymbol} />
        </span>
      </div>
      <hr />
      {hasFXRates && (
        <React.Fragment>
          <h5>FX Rates</h5>
          {serviceOrderToOrgFXRatesAverages?.map(({
            orgCurrency: _orgCurrency,
            serviceOrderCurrency: _serviceOrderCurrency,
            fxRateCurrencyPairAvg,
          }) => (
            <div className="d-flex mb-1" key={_serviceOrderCurrency}>
              <span className={classes.discreet}>
                Average FX rate of service orders
              </span>
              <span className="pl-4 ml-auto">
                {`${fxRateCurrencyPairAvg} ${CURRENCY_SYMBOL[_orgCurrency]}/${CURRENCY_SYMBOL[_serviceOrderCurrency]}`}
              </span>
            </div>
          ))}
          <div className="d-flex mb-1">
            <span className={classes.discreet}>
              FX rate up-to-date
            </span>
            <span className="pl-4 ml-auto">
              {`${exchangeRateInOrgCurrency} ${orgCurrencySymbol}/${currencySymbol}`}
            </span>
          </div>
          <hr />
        </React.Fragment>
      )}
      {
        !isOutbound && !isEmpty(transactionRetries) && (
          <div className="d-flex">
            <span className={classes.discreet}>
              Amount to be paid to contractor
            </span>
            <span className="pl-4 ml-auto">
              <NumberTpl
                value={toBePaidToContractor}
                decimals={2}
                prefix={CURRENCY_SYMBOL[toBePaidToContractorCurrency]}
              />
            </span>
          </div>
        )
      }
      {
        showTransactionRetries && (
          <React.Fragment>
            {
              !isEmpty(firstAttemptQuoteParams) && (
                <div className="d-flex">
                  <span className={classes.discreet}>
                    Amount to be paid (Spot FX)
                  </span>
                  <span className="pl-4 ml-auto">
                    <NumberTpl
                      value={firstAttemptQuoteParams?.targetAmount}
                      decimals={2}
                      prefix={CURRENCY_SYMBOL[firstAttemptQuoteParams?.targetCurrency]}
                    />
                  </span>
                </div>
              )
            }
            {
              !isEmpty(secondAttemptQuoteParams) && (
                <div className="d-flex">
                  <span className={classes.discreet}>
                    Amount to be paid (Spot FX)
                  </span>
                  <span className="pl-4 ml-auto">
                    <NumberTpl
                      value={secondAttemptQuoteParams?.targetAmount}
                      decimals={2}
                      prefix={CURRENCY_SYMBOL[secondAttemptQuoteParams?.targetCurrency]}
                    />
                  </span>
                </div>
              )
            }
          </React.Fragment>
        )
      }
      {loadingRetries && <div>Loading transaction retries...</div>}
    </React.Fragment>
  );
};

AmountsDetails.propTypes = {
  amounts: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
  invoice: PropTypes.object.isRequired,
};

AmountsDetails.defaultProps = {
  showTitle: true,
};

const InvoiceAmountsField = ({ showTitle, record }) => {
  if (!record || isEmpty(record)) {
    return null;
  }
  if (!get(record, 'amounts') || isEmpty(get(record, 'amounts'))) {
    return null;
  }
  const { amounts } = record;
  return (
    <AmountsDetails amounts={amounts} showTitle={showTitle} invoice={record} />
  );
};

InvoiceAmountsField.propTypes = {
  record: PropTypes.object,
  showTitle: PropTypes.bool,
};

InvoiceAmountsField.defaultProps = {
  record: {},
  showTitle: true,
};

export default InvoiceAmountsField;
