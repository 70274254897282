/* globals File, FormData */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { TIN_IRS_CODE_DESCRIPTION } from 'accounts/assets/js/constants';
import {
  completeStepDS, fetchSetupStepsDS, selectSetupSteps,
} from 'accounts/assets/js/ducks/account';
import {
  orgGetStartedUrl, profileGetTaxInformationApiUrl, profileUpdateTaxInformationApiUrl,
} from 'accounts/urls';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { fetchViewDS } from 'core/assets/js/ducks/view';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { extractAddressComponent, parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import UserUSTaxFilingsList from 'finance/assets/js/components/UserUSTaxFilingsList.jsx';
import { US_TAX_FORM_TYPE_LABEL, US_TAX_FORM_TYPE_VALUES } from 'finance/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import CompanyForm from 'settings/assets/js/components/CompanyForm.jsx';

const getTINNameError = (value, propertyName) => {
  if (
    !value
    || (
      typeof value === 'string'
      && (
        value.length === 0
        || value.length > 40
        || !/^[a-z0-9&\- ]+$/i.test(value)
      )
    )
  ) {
    return `${propertyName} must be a string of 40 characters or less and only contain a-z, A-Z, `
      + '0-9, &, - and spaces';
  }
  return null;
};

const getTINError = (value, propertyName) => {
  if (!value || (typeof value === 'string' && !/^\d{9}$/i.test(value))) {
    return `${propertyName} must be 9 digits`;
  }
  return null;
};

const ProfileCompanyInformationTab = ({ history }) => {
  // Check if is coming from get started page
  let fromGetStarted = false;
  if (history?.location?.state?.fromGetStarted) {
    fromGetStarted = true;
  }

  const dispatch = useDispatch();

  const componentName = ProfileCompanyInformationTab.GetComponentName();

  const activeOrg = useSelector(selectActiveOrg);
  const apiUrl = profileGetTaxInformationApiUrl(activeOrg.alias);
  const { hasLoaded, item } = fetchDataHook({ componentName, url: apiUrl });

  const steps = useSelector(selectSetupSteps);

  if (!hasLoaded) {
    return <SettingsPageSkeleton />;
  }

  const {
    company,
    extras: {
      hasOrgWithoutVatSet,
      hasRaisedWorksheet,
      latestTINValidationRequest,
      latestUSTaxFormRejected,
      orgsWithPendingWorksheets,
      usTaxFilings,
      usTaxForms,
    } = {},
  } = item;

  const reloadTaxInformation = () => dispatch(fetchViewDS({ componentName, url: apiUrl }));

  const isIncorporated = company?.is_incorporated;
  const tin = company?.[isIncorporated ? 'tax_identification_number' : 'social_security_number'];
  const tinName = company?.[isIncorporated ? 'name' : 'full_legal_name'];
  const tinPropertyNames = isIncorporated
    ? 'Company Name and Tax Identification Number'
    : 'Full Legal Name and Social Security Number';

  const tinDetails = (
    <ul className="bullet-list">
      <li>{tinName}</li>
      <li>{tin}</li>
    </ul>
  );

  return (
    <>
      {tin && tinName && latestTINValidationRequest?.isInvalid && (
        <TDSystemMessage
          type={BS_STYLE.DANGER}
          className="mb-4"
          title="Your US tax details are invalid"
        >
          Your current US tax details are invalid:
          {tinDetails}
          {latestTINValidationRequest.irsCode && (
            TIN_IRS_CODE_DESCRIPTION[latestTINValidationRequest.irsCode]
          )}
          {!latestTINValidationRequest.irsCode && latestTINValidationRequest.validationError}
        </TDSystemMessage>
      )}
      {tin && tinName && latestTINValidationRequest?.isPending && (
        <TDSystemMessage
          type={BS_STYLE.INFO}
          className="mb-4"
          title="Your US tax details are being verified"
        >
          {`We are validating your ${tinPropertyNames}:`}
          {tinDetails}
        </TDSystemMessage>
      )}
      <CompanyForm
        fromGetStarted={fromGetStarted}
        hasOrgWithoutVatSet={!!hasOrgWithoutVatSet}
        hasRaisedWorksheet={hasRaisedWorksheet}
        initialValues={company}
        onSubmit={async values => {
          const errors = {};
          if (![true, false].includes(values.is_incorporated)) {
            errors.is_incorporated = 'Please select if you are incorporated';
          }
          const usTaxFormSelected = values.usTaxForm instanceof File;
          const companyAddressIsUS = (
            values.address && extractAddressComponent(values.address, 'country_code') === 'US'
          );
          if (values.is_incorporated) {
            if (!values.name) {
              errors.name = 'Please enter your company name';
            }
            if (!values.address) {
              errors.address = 'Please enter your company address';
            }
            const validateTINDetails = activeOrg.user_us_tax_number_required && companyAddressIsUS;
            if (validateTINDetails) {
              const tinNameError = getTINNameError(values.name, 'Name');
              if (tinNameError) {
                errors.name = tinNameError;
              }
            }
            if (
              validateTINDetails || (
                typeof values.tax_identification_number === 'string'
                && values.tax_identification_number.length > 0
              )
            ) {
              const tinError = getTINError(
                values.tax_identification_number, 'Tax identification number',
              );
              if (tinError) {
                errors.tax_identification_number = tinError;
              }
            }
          } else {
            if (![true, false].includes(values.isUSTaxpayer)) {
              errors.isUSTaxpayer = 'Please select';
            }
            if (!values.full_legal_name) {
              errors.full_legal_name = 'Please enter your full legal name';
            }
            const validateTINDetails = activeOrg.user_us_tax_number_required && values.isUSTaxpayer;
            if (validateTINDetails) {
              const tinNameError = getTINNameError(values.full_legal_name, 'Full legal name');
              if (tinNameError) {
                errors.full_legal_name = tinNameError;
              }
            }
            if (
              validateTINDetails || (
                typeof values.social_security_number === 'string'
                && values.social_security_number.length > 0
              )
            ) {
              const tinError = getTINError(
                values.social_security_number, 'Social security number',
              );
              if (tinError) {
                errors.social_security_number = tinError;
              }
            }
          }
          const usTaxFormTypeSelected = US_TAX_FORM_TYPE_VALUES.includes(values.usTaxFormType);
          if (usTaxFormTypeSelected && !usTaxFormSelected) {
            errors.usTaxForm = (
              `Please select your ${US_TAX_FORM_TYPE_LABEL[values.usTaxFormType]} form PDF`
            );
          }
          if (
            !usTaxFormSelected
            && activeOrg.user_us_tax_form_required
            && (
              (values.is_incorporated && companyAddressIsUS)
              || (!values.is_incorporated && values.isUSTaxpayer)
            )
          ) {
            if (usTaxForms.length === 0) {
              errors.usTaxFormRequired = (
                'This organization requires that you upload your IRS Taxpayer Identification Form'
              );
            } else if (latestUSTaxFormRejected) {
              errors.usTaxFormRequired = (
                'This organization has rejected your latest IRS Taxpayer Identification Form, '
                + 'please upload a new one'
              );
            }
          }
          if (Object.keys(errors).length > 0) {
            return errors;
          }
          try {
            const companyDetailsStep = ((steps && steps.userSetupSteps) || []).find(step => (
              step.name === 'CompanyDetailsStep'
            ));
            const alreadyCompleted = companyDetailsStep && companyDetailsStep.completed;
            const formData = new FormData();
            Object.keys(values).forEach(key => {
              const value = values[key];
              if (
                (
                  !values.is_incorporated
                  && !values.isUSTaxpayer
                  && key === 'social_security_number'
                )
                || (
                  values.is_incorporated
                  && extractAddressComponent(values.address, 'country_code') !== 'US'
                  && key === 'tax_identification_number'
                )
                || (values.is_incorporated && key === 'full_legal_name')
                || (
                  !values.is_incorporated
                  && ['logo', 'name', 'registration_number', 'address'].includes(key)
                )
                || (!usTaxFormTypeSelected && key === 'usTaxForm')
              ) {
                return;
              }
              if (key === 'address') {
                formData.append(key, JSON.stringify(value));
                return;
              }
              formData.append(key, value);
            });
            const { data: { pendingTINValidation } } = await axios.put(
              profileUpdateTaxInformationApiUrl(activeOrg.alias), formData,
            );
            if (pendingTINValidation) {
              toastr.info(
                'Pending TIN validation',
                [
                  'We have updated your taxation details, but we need some more time to validate ',
                  `your ${tinPropertyNames}`,
                ].join(''),
              );
            } else {
              toastr.success('Well Done!', 'Taxation details updated successfully.');
              if (!alreadyCompleted) {
                await completeStepDS(dispatch, activeOrg.alias, 'CompanyDetailsStep');
                history.push({
                  pathname: orgGetStartedUrl(activeOrg.alias),
                  state: { moveToNextStep: true },
                });
              }
            }
            await dispatch(fetchSetupStepsDS());
            reloadTaxInformation();
            return null;
          } catch (error) {
            return parseAxiosErrorForFinalForm(error);
          }
        }}
        reloadTaxInformation={reloadTaxInformation}
        orgsWithPendingWorksheets={orgsWithPendingWorksheets}
        usTaxForms={usTaxForms}
      />
      <UserUSTaxFilingsList usTaxFilings={usTaxFilings} />
    </>
  );
};

ProfileCompanyInformationTab.GetComponentName = () => 'ProfileCompanyInformationTab';

ProfileCompanyInformationTab.propTypes = {
  history: routerHistorySpec.isRequired,
};

export default withRouter(ProfileCompanyInformationTab);
