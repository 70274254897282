import { pick } from 'lodash';
import { Money } from 'td-finance-ts';

import { BANK_ACCOUNT_TYPE } from 'settings/assets/js/constants';
import { truncatePaymentReferenceForCurrency } from 'finance/assets/js/lib/utils';
import { assertAllKeysPresent } from 'core/assets/js/lib/utils';
import InvoiceRateMap from 'finance/assets/js/lib/InvoiceRateMap';
import TransactionRateMap from 'finance/assets/js/lib/TransactionRateMap';
import TransactionAmounts from 'finance/assets/js/lib/TransactionAmounts';
import TransactionAction from 'finance/assets/js/lib/TransactionAction';
import TransactionOrder from 'finance/assets/js/lib/TransactionOrder';

/**
 * A class to encapsulate all details for ordering a remote transaction
 */
class TransactionIntent {
  constructor({
    transactionMode, bankType, reference, recipientId, dimBankAccountId,
    transactionOrder, expectedFee,
    invoicedAmount, invoicedCurrency,
    owedAmount, owedCurrency,
    rateMapAtInvoiceTime: serializedRateMapAtInvoiceTime,
    rateMapAtTransactionTime: serializedRateMapAtTransactionTime,
    isFeeChargedInFutureStep,
  } = {}) {
    assertAllKeysPresent({
      transactionMode, reference, bankType, dimBankAccountId,
      transactionOrder, expectedFee,
      invoicedAmount, invoicedCurrency,
      serializedRateMapAtInvoiceTime,
      serializedRateMapAtTransactionTime,
      isFeeChargedInFutureStep,
    });

    if (bankType === BANK_ACCOUNT_TYPE.TRANSFERWISE && typeof recipientId === 'undefined') {
      throw new Error('recipientId is required');
    }

    if (!(transactionOrder instanceof TransactionOrder)) {
      // eslint-disable-next-line no-param-reassign
      transactionOrder = new TransactionOrder(transactionOrder);
    }

    const rateMap = serializedRateMapAtTransactionTime instanceof TransactionRateMap
      ? serializedRateMapAtTransactionTime
      : new TransactionRateMap(serializedRateMapAtTransactionTime);

    const rateMapAtInvoiceTime = serializedRateMapAtInvoiceTime instanceof InvoiceRateMap
      ? serializedRateMapAtInvoiceTime
      : new InvoiceRateMap(serializedRateMapAtInvoiceTime);

    this.details = {
      transactionMode,
      bankType,
      dimBankAccountId,
      reference,
      recipientId,
      transactionOrder,
      expectedFee,
      invoicedMoney: new Money(invoicedAmount, invoicedCurrency),
      owedMoney: new Money(owedAmount, owedCurrency),
      rateMapAtInvoiceTime,
      rateMapAtTransactionTime: rateMap,
      isFeeChargedInFutureStep,
    };
  }

  serialize() {
    const {
      transactionOrder, invoicedMoney, owedMoney, rateMapAtInvoiceTime, rateMapAtTransactionTime,
    } = this.details;
    return {
      ...pick(this.details, [
        'transactionMode',
        'bankType',
        'dimBankAccountId',
        'reference',
        'recipientId',
        'expectedFee',
        'isFeeChargedInFutureStep',
      ]),
      transactionOrder: transactionOrder.serialize(),
      invoicedAmount: invoicedMoney.toString(),
      invoicedCurrency: invoicedMoney.getCurrency(),
      owedAmount: owedMoney.toString(),
      owedCurrency: owedMoney.getCurrency(),
      rateMapAtInvoiceTime: rateMapAtInvoiceTime.serialize(),
      rateMapAtTransactionTime: rateMapAtTransactionTime.serialize(),
    };
  }

  getSourceCurrency() {
    return this.getTransactionOrder().getSourceCurrency();
  }

  getTargetCurrency() {
    return this.getTransactionOrder().getTargetCurrency();
  }

  getBankType() {
    const { bankType } = this.details;
    return bankType;
  }

  getDimBankAccountId() {
    const { dimBankAccountId } = this.details;
    return dimBankAccountId;
  }

  getRecipientId() {
    const { recipientId } = this.details;
    return recipientId;
  }

  getReference() {
    const { reference } = this.details;
    return reference;
  }

  getTruncatedReference() {
    return truncatePaymentReferenceForCurrency(
      this.getReference(),
      this.getTargetCurrency().toLowerCase(),
    );
  }

  getTransactionMode() {
    const { transactionMode } = this.details;
    return transactionMode;
  }

  getTransactionOrder() {
    const { transactionOrder } = this.details;
    return transactionOrder;
  }

  getExpectedFee() {
    const { expectedFee } = this.details;
    return expectedFee;
  }

  getExpectedTransactionAction() {
    const {
      transactionOrder, expectedFee, rateMapAtTransactionTime, isFeeChargedInFutureStep,
    } = this.details;
    const expectedTransactionAction = TransactionAction.fromTransactionOrder({
      transactionOrder,
      expectedRate: rateMapAtTransactionTime.getRate(
        transactionOrder.getSourceCurrency(),
        transactionOrder.getTargetCurrency(),
      ),
      expectedFee: isFeeChargedInFutureStep ? '0.00' : expectedFee,
    });
    return expectedTransactionAction;
  }

  getExpectedTransactionAmounts() {
    const {
      transactionMode, invoicedMoney,
      rateMapAtInvoiceTime, rateMapAtTransactionTime,
    } = this.details;
    const expectedTransactionAction = this.getExpectedTransactionAction();
    const expectedTransactionAmounts = TransactionAmounts.fromExpectedTransactionAction({
      transactionMode,
      expectedTransactionAction,
      rateMapAtTransactionTime,
      serializedInvoiceAmounts: {
        ...rateMapAtInvoiceTime.serialize(),
        total: invoicedMoney.toString(),
      },
    });
    return expectedTransactionAmounts;
  }

  isConsistentWith(otherTransactionAmounts) {
    const { rateMapAtInvoiceTime } = this.details;
    const otherOutgoingMoney = otherTransactionAmounts.getOutgoingMoney();

    const expectedTransactionAmounts = this.getExpectedTransactionAmounts();
    return rateMapAtInvoiceTime.convert({
      money: expectedTransactionAmounts.getOutgoingMoney(),
      toCurrency: otherOutgoingMoney.getCurrency(),
    }).eq(otherOutgoingMoney);
  }
}

export default TransactionIntent;
