import React from 'react';
import PropTypes from 'prop-types';

const AutosizeInput = ({ ...props }) => {
  const { onChange, value, ...other } = props;

  return (
    <input
      onChange={onChange}
      type="text"
      style={{ width: `${value.length || 0}ch` }}
      value={value}
      data-testid="autosize-input"
      {...other}
    />
  );
};

AutosizeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default AutosizeInput;
