import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TimeAgo from 'timeago-react';

import { ICON } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { projectTaskAssignmentUrl } from 'projects/urls';

const DiscussionCard = ({
  item,
  projectId,
}) => {
  const {
    id: taskId,
    lastMessageAt,
    newMessagesCount,
    participantsCount,
    title,
  } = item;
  const activeOrg = useSelector(selectActiveOrg);

  return (
    <li
      className="discussion-card__container"
      data-testid="discussion-card"
    >
      <Link
        className={`
          roles-field__list-item
          d-flex
          mt-3
          p-4
          w-100
        `}
        to={projectTaskAssignmentUrl(activeOrg.alias, projectId, taskId, '')}
      >
        <div className="d-flex align-items-center w-100">
          <i className={`${ICON.LIST_CHECK} rounded-circle mr-4`} />
          <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between w-100">
            <div>
              <p className="discussion-card__name m-0">{title}</p>
              <p className="discussion-card__info m-0">
                {participantsCount}
                {' '}
                participants
              </p>
              <p className="discussion-card__info m-0">
                Last message:
                {' '}
                {lastMessageAt ? <TimeAgo datetime={lastMessageAt} live={false} /> : 'Never'}
              </p>
            </div>
            <div className="discussion-card__new-messages mt-3 mt-sm-0">
              New messages:
              <span className={`px-3 py-2 ml-3 rounded ${newMessagesCount > 0 ? 'active' : ''}`}>
                {newMessagesCount}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};

DiscussionCard.propTypes = {
  item: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
};

export default DiscussionCard;
