import React from 'react';
import PropTypes from 'prop-types';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import {
  CURRENCIES_WITHOUT_DECIMALS, CURRENCY_BY_SYMBOL, CURRENCY_VALUES,
} from 'core/assets/js/constants';
import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';


const MoneyInputField = ({
  additionalError,
  className,
  currency,
  'data-testid': dataTestId,
  decimals,
  disabled,
  input,
  label,
  max,
  meta: { error, pristine, submitError },
  min,
  muteErrorMessage,
  placeholder,
  prefix,
  required,
  step,
  sublabel,
  suffix,
}) => {
  let finalDecimals = decimals;
  let finalCurrency = currency;
  let finalPrefix = prefix;

  if (!currency && (prefix || suffix)) {
    finalCurrency = CURRENCY_BY_SYMBOL[prefix || suffix];
  }
  const noDecimals = CURRENCIES_WITHOUT_DECIMALS.includes(finalCurrency);
  if (noDecimals) {
    finalDecimals = 0;
  }
  if (finalCurrency && !suffix && !prefix) {
    finalPrefix = CURRENCY_SYMBOL[finalCurrency];
  }

  const showAdditionalError = pristine && additionalError;
  const hasError = (submitError || error || showAdditionalError) && !muteErrorMessage;
  const classNames = ['form-group'];

  if (className) {
    classNames.push(className);
  }

  if (hasError) {
    classNames.push('has-error');
  }

  let field = (
    <input
      className="form-control"
      placeholder={placeholder}
      autoComplete="off"
      disabled={disabled}
      min={min}
      max={max}
      step={step}
      {...input}
      type="number"
      onBlur={(e) => {
        // Money input accepts only positive values
        if (e.target.value < 0) {
          e.target.value = 0;
        }
        let newValue = parseFloat(e.target.value).toFixed(finalDecimals);
        if (noDecimals && newValue.toString().includes('.')) {
          newValue = newValue.split('.')[0];
        }
        e.target.value = newValue;
        input.onChange(e);
        input.onBlur();
      }}
      // Prevent scrolling on the input, to prevent accidental increment/decrement
      onWheel={e => e.target.blur()}
      id={`field-${input.name}`}
    />
  );

  if (finalPrefix || suffix) {
    field = (
      <div className="input-group">
        {finalPrefix && (
          <div className="input-group-prepend">
            <span className="input-group-text">{finalPrefix}</span>
          </div>
        )}
        {field}
        {suffix && (
          <div className="input-group-append">
            <span className="input-group-text">{suffix}</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={classNames.join(' ')} data-testid={dataTestId}>
      <TDLabel name={input.name} label={label} required={required} sublabel={sublabel} />
      {showAdditionalError && !muteErrorMessage && (
        <span className="help-block mt-3">{additionalError}</span>
      )}
      {field}
      {error && <span className="help-block d-inline-block mt-3">{error}</span>}
      {hasError && <span className="help-block d-inline-block mt-3">{submitError}</span>}
    </div>
  );
};

MoneyInputField.propTypes = {
  additionalError: PropTypes.string,
  className: PropTypes.string,
  currency: PropTypes.oneOf(CURRENCY_VALUES),
  'data-testid': PropTypes.string,
  decimals: PropTypes.number,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: finalFormFieldLabelSpec,
  max: PropTypes.number,
  meta: finalFormFieldMetaSpec,
  min: PropTypes.number,
  muteErrorMessage: PropTypes.bool,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  required: PropTypes.bool,
  step: PropTypes.number,
  sublabel: finalFormFieldLabelSpec,
  suffix: PropTypes.string,
};

MoneyInputField.defaultProps = {
  additionalError: '',
  className: null,
  currency: null,
  'data-testid': 'money-input-field',
  decimals: 2,
  disabled: false,
  label: '',
  max: null,
  meta: {
    error: '',
    pristine: true,
    submitError: '',
  },
  min: 0,
  muteErrorMessage: false,
  placeholder: '',
  prefix: null,
  required: false,
  step: 0.01,
  suffix: null,
  sublabel: null,
};

export default withField(MoneyInputField);
