
import ReportsDownloadsView from 'admin/assets/js/resources/reports/ReportsDownloadsView.jsx';
import { REPORTS_TABS } from 'admin/assets/js/constants';

const ReportsSignNowView = ({ ...props }) => {
  return ReportsDownloadsView({
    ...props,
    selectedTab: REPORTS_TABS.SIGNNOW,
  });
};

export default ReportsSignNowView;
