/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Grid, Typography,
  Popover, Icon,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import InvoiceSingleAmountField from 'admin/assets/js/resources/invoices/InvoiceSingleAmountField.jsx';
import CustomTooltip from 'admin/assets/js/components/CustomToolTip.jsx';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import BankFeesTableAnalysis from 'admin/assets/js/resources/invoices/BankFeesTableAnalysis.jsx';
import SalesTaxRechargeTableAnalysis from 'admin/assets/js/resources/invoices/SalesTaxRechargeTableAnalysis.jsx';

const InvoiceOutboundAmountsPreview = ({ record, classes }) => {
  if (!record) {
    return null;
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const [salesTaxRechargeAnchorEl, setSalesTaxRechargeAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isSalesTaxRechargeTableOpen = Boolean(salesTaxRechargeAnchorEl);
  const bankFeesPopoverId = open ? 'bank-fees-popover' : undefined;
  const salesTaxRechargePopoverId = salesTaxRechargeAnchorEl
    ? 'sales-tax-recharge-popover'
    : undefined;

  const invoiceAmounts = new InvoiceAmounts(record.amounts);

  const currency = invoiceAmounts.getCurrency();
  const currencySymbol = CURRENCY_SYMBOL[currency];
  const targetCurrency = invoiceAmounts.getTargetCurrency();
  const exchangeRateTotal = invoiceAmounts.getExchangeRate();
  const spotExchangeRate = invoiceAmounts.getSpotExchangeRate();
  const exchangeRateMarkup = invoiceAmounts.getExchangeRateMarkup();
  const bankFeeTotal = invoiceAmounts.getBankFee();
  const vatPercent = invoiceAmounts.getVatPercent();
  const contractorCharges = invoiceAmounts.getContractorCharges();
  const vat = invoiceAmounts.getVat();
  const billable = invoiceAmounts.getBillable();
  const fee = invoiceAmounts.getFee();
  const total = invoiceAmounts.getTotal();
  const targetVat = invoiceAmounts.getTargetVat();
  const targetTotal = invoiceAmounts.getTargetTotal();
  const salesTaxRecharge = invoiceAmounts.getSalesTaxRecharge();
  const salesTaxRechargeAnalysis = invoiceAmounts.getSalesTaxRechargeAnalysis();
  const childrenBankFees = invoiceAmounts.getChildrenBankFees();
  const childrenBankFeesAnalysis = invoiceAmounts.getChildrenBankFeesAnalysis();

  const hasBankFeeTotal = !!bankFeeTotal && !new Money(bankFeeTotal, currency).isZero();

  const hasSalesTaxRecharge = !!salesTaxRecharge && !new Money(salesTaxRecharge, currency).isZero();

  const handleOpenBankFeePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseBankFeePopover = () => {
    setAnchorEl(null);
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.column}>
        <Typography
          style={{ display: 'flex', alignItems: 'center' }}
          variant="subtitle2"
          color="textSecondary"
        >
          <span>Contractor charges billed to TD</span>
          <CustomTooltip
            title={(
              <div>
                The sum of approved worksheets and expenses of the current billing cycle,
                which were billed to TalentDesk via &quot;INBOUND&quot; invoices.
                The latter indicates that the organisation invoicing mode is
                &quot;VIA&quot;. This amount is 0 in case of organisations with invoicing
                mode &quot;BILL_PAYMENTS&quot; because even though we execute
                payments on their behalf, the contractor charges are
                not billed to TalentDesk, but directly to the client.
                <br />
                <br />
                Note, this amount is calculated using the exchange rate
                at the time of service order creation ( when it differs from the org
                currency ), and therefore may not match the actual invoice total which
                may use a different exchange rate, one at invoice generation time.
              </div>
            )}
          />
        </Typography>
        <Typography variant="subtitle2">
          <NumberTpl value={contractorCharges} prefix={currencySymbol} />
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.column}>
        <Typography
          style={{ display: 'flex', alignItems: 'center' }}
          variant="subtitle2"
          color="textSecondary"
        >
          <span>Billable</span>
          <CustomTooltip
            title={(
              <div>
                The sum of approved worksheets of the current month.
                This amount is used in order to calculate the payment
                processing fees charged to the client. Notice that
                the expenses amounts are excluded from the calculation
                of the Billable. In other words, we don&apos;t apply payment
                processing fees on expenses.
              </div>
            )}
          />
        </Typography>
        <Typography variant="subtitle2">
          <InvoiceSingleAmountField amount={billable} currency={currency} />
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.column}>
        <Typography
          style={{ display: 'flex', alignItems: 'center' }}
          variant="subtitle2"
          color="textSecondary"
        >
          <span>Payment processing fees</span>
          <CustomTooltip
            title={`These are the fees we charge to the client for processing the contractor
                   payments on their behalf. It's calculated by applying the payment processing
                   fee percentage, as saved in the organisation's processing fees scheme,
                   on the "Billable". Notice that currently we only charge payment processing
                   fees at the end of the month.`}
          />
        </Typography>
        <Typography variant="subtitle2">
          <InvoiceSingleAmountField amount={fee} currency={currency} />
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.column}>
        <Typography
          style={{ display: 'flex', alignItems: 'center' }}
          variant="subtitle2"
          color="textSecondary"
        >
          <span>Bank fees recharged</span>
          <CustomTooltip
            title="These are the bank fees our payment aggregators charge us when we
                    execute payments to contractors on behalf of our client.
                    These bank fees are being recharged back to the client.
                    However, moving forward we are absorbing all of the bank fees and
                    so it's expected this amount to be 0."
          />
          {!isEmpty(childrenBankFeesAnalysis) && (
            <React.Fragment>
              <Icon
                aria-describedby={bankFeesPopoverId}
                onClick={handleOpenBankFeePopover}
                fontSize="small"
              >
                visibility
              </Icon>
              <Popover
                id={bankFeesPopoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseBankFeePopover}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <BankFeesTableAnalysis
                  childrenBankFeesAnalysis={childrenBankFeesAnalysis}
                  classes={classes}
                  currency={currency}
                />
              </Popover>
            </React.Fragment>
          )}
        </Typography>
        <Typography variant="subtitle2">
          <InvoiceSingleAmountField amount={childrenBankFees} currency={currency} />
        </Typography>
      </Grid>
      {
        hasSalesTaxRecharge && (
          <Grid item xs={12} className={classes.column}>
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="subtitle2"
              color="textSecondary"
            >
              <span>Sales tax recharged</span>
              <CustomTooltip
                title="The sales tax which is being charged to TalentDesk by non UK VAT
                   registered entities (e.g sole traders or companies), and then recharged
                   by TalentDesk to its clients."
              />
              {!isEmpty(salesTaxRechargeAnalysis) && (
                <React.Fragment>
                  <Icon
                    aria-describedby={salesTaxRechargePopoverId}
                    onClick={
                      event => setSalesTaxRechargeAnchorEl(event.currentTarget)
                    }
                    fontSize="small"
                  >
                    visibility
                  </Icon>
                  <Popover
                    id={salesTaxRechargePopoverId}
                    open={isSalesTaxRechargeTableOpen}
                    anchorEl={salesTaxRechargeAnchorEl}
                    onClose={
                      () => setSalesTaxRechargeAnchorEl(null)
                    }
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <SalesTaxRechargeTableAnalysis
                      salesTaxRechargeAnalysis={salesTaxRechargeAnalysis}
                      classes={classes}
                      currency={currency}
                    />
                  </Popover>
                </React.Fragment>
              )}
            </Typography>
            <Typography variant="subtitle2">
              <InvoiceSingleAmountField amount={salesTaxRecharge} currency={currency} />
            </Typography>
          </Grid>
        )
      }
      <Grid item xs={12} className={classes.column}>
        <Typography
          style={{ display: 'flex', alignItems: 'center' }}
          variant="subtitle2"
          color="textSecondary"
        >
          <span>{`VAT ${vatPercent}%`}</span>
        </Typography>
        <Typography variant="subtitle2">
          <InvoiceSingleAmountField amount={vat} currency={currency} />
        </Typography>
      </Grid>
      {
        targetCurrency !== currency && targetVat && (
          <Grid item xs={12} className={classes.column}>
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="subtitle2"
              color="textSecondary"
            />
            <Typography variant="subtitle2">
              <InvoiceSingleAmountField amount={targetVat} currency={targetCurrency} />
            </Typography>
          </Grid>
        )
      }
      <Grid item xs={12} className={`${classes.column} ${classes.totalRow}`}>
        <Typography
          style={{ display: 'flex', alignItems: 'center' }}
          variant="subtitle2"
          color="textSecondary"
        >
          Total
        </Typography>
        <Typography variant="subtitle2">
          <InvoiceSingleAmountField amount={total} currency={currency} />
        </Typography>
      </Grid>
      { hasBankFeeTotal && (
        <Grid item xs={12} className={classes.column}>
          <Typography
            style={{ display: 'flex', alignItems: 'center' }}
            variant="subtitle2"
            color="textSecondary"
          >
            Bank fee
          </Typography>
          <Typography variant="subtitle2">
            <InvoiceSingleAmountField amount={bankFeeTotal} currency={currency} />
          </Typography>
        </Grid>
      )}
      { currency && targetCurrency && (currency !== targetCurrency) && (
        <React.Fragment>
          <Grid item xs={12} className={classes.column}>
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="subtitle2"
              color="textSecondary"
            >
              Total (local)
            </Typography>
            <Typography variant="subtitle2">
              <InvoiceSingleAmountField amount={targetTotal} currency={targetCurrency} />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="subtitle2"
              color="textSecondary"
            >
              Initial exchange rate
            </Typography>
            <Typography variant="subtitle2">
              {spotExchangeRate}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="subtitle2"
              color="textSecondary"
            >
              Exchange rate applied markup
            </Typography>
            <Typography variant="subtitle2">
              {`${exchangeRateMarkup * 100}%`}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="subtitle2"
              color="textSecondary"
            >
              Total exchange rate
            </Typography>
            <Typography variant="subtitle2">
              {exchangeRateTotal}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="subtitle2"
              color="textSecondary"
            >
              Local currency
            </Typography>
            <Typography variant="subtitle2">
              {targetCurrency}
            </Typography>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

InvoiceOutboundAmountsPreview.propTypes = {
  record: PropTypes.object,
};

InvoiceOutboundAmountsPreview.defaultProps = {
  record: {},
};

export default InvoiceOutboundAmountsPreview;
