import { pick } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import RatingScore from 'core/assets/js/components/RatingScore.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDDropChevronButton from 'core/assets/js/components/TDDropChevronButton.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import {
  DATETIME_FORMAT_ISO, ICON, IMG_SIZE, ORDERING_DIRECTION, USER_TYPE,
} from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { listUpdateItemAC } from 'core/assets/js/ducks/list';
import axios from 'core/assets/js/lib/tdAxios';
import { calculateDuration } from 'core/assets/js/lib/utils';
import { downloadFileApiUrl } from 'files/urls';
import UserGroupAvatar from 'people/assets/js/components/UserGroupAvatar.jsx';
import { orgUserProfileUrl, userGroupManageUrl } from 'people/urls';
import {
  PROJECT_OPPORTUNITY_INVITEES_STATUS,
  PROJECT_OPPORTUNITY_INVITEES_STATUS_CLASS,
  PROJECT_OPPORTUNITY_INVITEES_STATUS_LABEL,
  PROJECT_OPPORTUNITY_INVITEES_STATUS_VALUES,
  PROJECT_TAB_INVITEES_COMPONENT_NAME,
} from 'projects/assets/js/constants';
import { opportunityInvitee } from 'projects/assets/js/lib/objectSpecs';
import {
  cancelOpportunityInvitationApiUrl, getProjectOpportunityInviteesApiUrl,
} from 'projects/urls';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';

const InviteeCard = ({
  componentName,
  item: {
    allowedActions,
    avatarFileId,
    createdAt,
    defaultRateAmount,
    defaultRateCurrency,
    defaultRateUnit,
    groupIsDynamic,
    groupMembersCount,
    id,
    isUser,
    name,
    status,
    userNumReviews,
    userRating,
    userJobTitle,
  },
}) => {
  const { id: projectId, orgAlias } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const invitedAgoString = [
    'Invited',
    calculateDuration(
      createdAt,
      new Date().toISOString(),
      { expectedFormat: DATETIME_FORMAT_ISO, format: 'humanize' },
    ),
    'ago',
  ].join(' ');
  const profileUrl = isUser ? orgUserProfileUrl(orgAlias, USER_TYPE.PROVIDER, id) : null;
  const dropdown = !isUser ? null : (
    <TDDropChevronButton>
      <Dropdown.Item onClick={() => history.push(profileUrl)}>
        View profile
      </Dropdown.Item>
      {allowedActions.canCancel && (
        <Dropdown.Item
          onClick={() => {
            try {
              axios.put(cancelOpportunityInvitationApiUrl(orgAlias, projectId, id));
              toastr.success('Well Done!', `${name}'s invitation has been cancelled`);
              dispatch(listUpdateItemAC(
                id,
                {
                  allowedActions: { ...allowedActions, canCancel: false },
                  status: PROJECT_OPPORTUNITY_INVITEES_STATUS.CANCELLED,
                },
                componentName,
              ));
            } catch (err) {
              toastr.error('Oh Snap!', err.response?.data?._error || err.message);
            }
          }}
        >
          Cancel invitation
        </Dropdown.Item>
      )}
    </TDDropChevronButton>
  );

  return (
    <Card className="project-invitees-card">
      <div className="row p-4">
        <div
          className={(
            `col-12 col-md-${isUser ? '4' : '7'} d-flex align-items-center justify-content-between `
            + 'mb-4 mb-md-0'
          )}
        >
          <Link
            className="d-flex align-items-center justify-content-start text-decoration-none"
            to={isUser ? profileUrl : userGroupManageUrl(orgAlias, id)}
          >
            {isUser && (
              <ProfilePic
                alt={name}
                size={[IMG_SIZE.MEDIUM, IMG_SIZE.MEDIUM]}
                url={avatarFileId ? downloadFileApiUrl(avatarFileId) : null}
              />
            )}
            {!isUser && (
              <UserGroupAvatar avatarFileId={avatarFileId} isDynamic={groupIsDynamic} name={name} />
            )}
            <div className="d-flex flex-column ml-4 provider-details">
              <div className="font-weight-bold name">{name}</div>
              {isUser && userJobTitle && (
                <div className="discreet truncate" title={userJobTitle}>{userJobTitle}</div>
              )}
              {!isUser && (
                <div className="discreet">{`${groupIsDynamic ? 'Dynamic' : 'Standard'} group`}</div>
              )}
              <div className="discreet">{invitedAgoString}</div>
            </div>
          </Link>
          <div className="d-inline d-md-none">{dropdown}</div>
        </div>
        {isUser && (
          <>
            <div
              className={(
                'col-12 col-md-3 d-flex align-items-center justify-content-start '
                + 'justify-content-md-center status-container'
              )}
            >
              <StatusTag
                label={PROJECT_OPPORTUNITY_INVITEES_STATUS_LABEL[status]}
                statusClass={PROJECT_OPPORTUNITY_INVITEES_STATUS_CLASS[status]}
              />
            </div>
            <div
              className={(
                'col-12 col-md-5 d-flex align-items-center justify-content-between mt-4 mt-md-0 '
                + 'extra-container'
              )}
            >
              <div className="d-flex align-items-center">
                <RatingScore
                  classNames="discreet mr-5"
                  numReviews={userNumReviews}
                  rating={userRating}
                />
                {defaultRateAmount && (
                  <div className="rate p-3 mr-5">
                    <RateAmount
                      amount={defaultRateAmount}
                      showRateUnitDiscreetly
                      unit={defaultRateUnit}
                      symbol={CURRENCY_SYMBOL[defaultRateCurrency]}
                    />
                  </div>
                )}
              </div>
              <div className="d-none d-md-inline">{dropdown}</div>
            </div>
          </>
        )}
        {!isUser && (
          <div
            className={(
              'col-12 col-md-5 d-flex align-items-center justify-content-start extra-container'
            )}
          >
            <div className="members-count">
              <i className={`${ICON.USER} mr-3`} />
              {`${groupMembersCount} member${groupMembersCount > 1 ? 's' : ''}`}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

InviteeCard.propTypes = {
  componentName: PropTypes.string.isRequired,
  item: opportunityInvitee.isRequired,
};

const ProjectTabInvitees = ({ filtersOpen, onFiltersToggle }) => {
  const location = useLocation();
  const params = useParams();
  const { id: projectId, orgAlias } = params;

  const componentName = ProjectTabInvitees.GetComponentName(params);
  const { hasLoaded, isLoading, items, pagination } = fetchDataHook({
    componentName, duck: 'list', url: getProjectOpportunityInviteesApiUrl(orgAlias, projectId),
  });

  const loading = !hasLoaded || isLoading;

  return (
    <>
      <div className="row">
        <SearchFinalForm
          initialValues={pick(queryString.parse(location.search), ['kw', 'ordering', 'status'])}
          searchSpec={{
            defaultOrdering: { direction: ORDERING_DIRECTION.DESC, sortBy: 'createdAt' },
            filters: [{
              fieldComponent: SelectableListFilterField,
              label: 'Status',
              multiple: true,
              options: PROJECT_OPPORTUNITY_INVITEES_STATUS_VALUES.map(value => ({
                text: PROJECT_OPPORTUNITY_INVITEES_STATUS_LABEL[value], value,
              })),
              paramName: 'status',
            }],
            orderingOptions: [{ text: 'Invitation date', value: 'createdAt' }],
            searchTerm: {
              component: TextInputField,
              paramName: 'kw',
              placeholder: 'Search by provider or group name',
            },
          }}
          onFiltersToggle={onFiltersToggle}
          filtersOpen={filtersOpen}
        />
      </div>
      {!filtersOpen && (
        <>
          {loading && <ProjectCardSkeleton />}
          {!loading && (
            <TDList
              cardItem={{ component: InviteeCard, props: { componentName } }}
              emptyListMessage="No invitations found."
              items={items}
              pagination={pagination}
            />
          )}
        </>
      )}
    </>
  );
};

ProjectTabInvitees.GetComponentName = () => PROJECT_TAB_INVITEES_COMPONENT_NAME;

ProjectTabInvitees.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withFilters(ProjectTabInvitees);
