import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import DetailsRows from 'core/assets/js/components/DetailsRows.jsx';
import ParseBool from 'core/assets/js/components/ParseBool.jsx';
import { DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { BANK_ACCOUNT_TYPE } from 'settings/assets/js/constants';
import {
  getTWAllowedDetails,
  getTwDetailLabel,
  getTWDetailsAddress,
} from 'finance/assets/js/lib/utils';

const PeoplePaymentDetailsTab = ({ paymentMethod }) => {
  let detailsRows = [];

  if (typeof paymentMethod === 'boolean') {
    detailsRows.push({
      label: 'Payment Method Selected',
      value: <ParseBool>{paymentMethod}</ParseBool>,
    });
  } else {
    detailsRows = [
      {
        label: 'Created',
        value: moment(paymentMethod.created_at).format(DATE_FORMAT_DEFAULT),
      },
      {
        label: 'Bank name',
        value: paymentMethod.bank_name,
      },
      {
        label: 'Payee reference message',
        value: paymentMethod.custom_reference,
      },
    ];
    const isPaypal = paymentMethod.bank_account_type === BANK_ACCOUNT_TYPE.PAYPAL;
    const isPayoneer = paymentMethod.bank_account_type === BANK_ACCOUNT_TYPE.PAYONEER;
    const isTransferWise = paymentMethod.bank_account_type === BANK_ACCOUNT_TYPE.TRANSFERWISE
      || paymentMethod.bank_account_type === BANK_ACCOUNT_TYPE.TRANSFERWISE_NO_VALIDATION;
    /*
        TODO the commented out fields are not returned by BankAccountSerializer, so we either
        need to have the API return them, or change what we want to display in the front-end
      */
    if (isPaypal) {
      detailsRows.push({
        label: 'PayPal',
        value: paymentMethod.handle,
      });
    } else if (isPayoneer) {
      /* detailsRows.push({
        label: 'Payoneer',
        value: paymentMethod.payoneerPayeeId,
      }); */
    } else if (isTransferWise) {
      detailsRows.push({
        label: 'Beneficiary',
        value: paymentMethod.recipient.accountHolderName,
      });
      const detailsToShow = getTWAllowedDetails(
        paymentMethod.recipient.details,
        paymentMethod.recipient.currency,
      );
      Object.keys(detailsToShow).forEach(key => {
        const detailLabel = getTwDetailLabel(key, paymentMethod.recipient.currency.toLowerCase());
        const value = detailsToShow[key];
        detailsRows.push({
          label: detailLabel,
          value: key === 'address' ? getTWDetailsAddress(value) : value,
        });
      });
    } else {
      // const hasAccountNumber = !!paymentMethod.account_number;
      detailsRows = detailsRows.concat([
        /* {
          label: 'Bank country',
          value: getCountryName(paymentMethod.country_code),
        },
        {
          label: 'Wire transfer number',
          value: paymentMethod.wire_transfer_number,
        },
        {
          label: hasAccountNumber ? 'Account number' : 'IBAN',
          value: hasAccountNumber ? paymentMethod.account_number : paymentMethod.iban,
        }, */
        {
          label: 'Beneficiary',
          value: paymentMethod.beneficiary,
        },
        /* {
          label: 'Address',
          value: paymentMethod.address && paymentMethod.address.description,
        }, */
      ]);
    }
  }

  return (
    <div className="tab-content w-100 p-4 provider-payment-details">
      <div className="mb-3 mt-n2">
        <div className="provider-payment-details-meta">
          <DetailsRows prefix="provider-payment-details-meta" rows={detailsRows} />
        </div>
      </div>
    </div>
  );
};

PeoplePaymentDetailsTab.propTypes = {
  paymentMethod: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

PeoplePaymentDetailsTab.defaultProps = {
  paymentMethod: {},
};

export default PeoplePaymentDetailsTab;
