import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  TASK_ASSIGNMENT_STATUS,
  TASK_ASSIGNMENT_STATUS_LABEL,
  TASKS_DEFAULT_ORDERING,
  TASK_STATUS,
  TASK_STATUS_LABEL,
  TASK_PENDING_APPROVAL,
} from 'projects/assets/js/constants';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import DateRangePickerFilterField from 'core/assets/js/components/FinalFormFilterFields/DateRangePickerFilterField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import { ICON, ORDERING_DIRECTION } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const TaskSearch = ({ customFieldFilters, filtersOpen, isManager, onFiltersToggle }) => {
  const activeOrg = useSelector(selectActiveOrg);
  const statusOptions = Object.values(TASK_STATUS).map(value => ({
    text: TASK_STATUS_LABEL[value], value,
  }));
  if (activeOrg.task_manual_completion) {
    statusOptions.push({ text: 'Pending approval', value: TASK_PENDING_APPROVAL });
  }

  const assignmentStatusOptions = Object.values(TASK_ASSIGNMENT_STATUS).map(value => ({
    text: TASK_ASSIGNMENT_STATUS_LABEL[value],
    value,
  }));

  const archiveOptions = [{
    text: 'Yes',
    value: 1,
  }, {
    text: 'No',
    value: 0,
  }];

  const taskOwnershipFilter = {
    label: 'Task Ownership',
    paramName: 'owned',
    fieldComponent: SelectableListFilterField,
    multiple: false,
    options: [
      { text: 'Show all tasks', value: 0 },
      { text: 'Show the tasks I own', value: 1 },
    ],
  };

  const taskStatusFilter = {
    label: 'Assignment status',
    paramName: 'status',
    fieldComponent: SelectableListFilterField,
    multiple: true,
    options: statusOptions,
  };

  const assignmentStatusFilter = {
    label: 'Status',
    paramName: 'assignment_status',
    fieldComponent: SelectableListFilterField,
    multiple: true,
    options: assignmentStatusOptions,
  };

  const searchSpec = {
    customFieldFilters,
    searchTerm: {
      placeholder: 'Search...',
      paramName: 'kw',
      component: TextInputField,
    },
    defaultOrdering: TASKS_DEFAULT_ORDERING,
    orderingOptions: [
      {
        text: 'Created date', value: 'created_at', filterDefaultDirection: ORDERING_DIRECTION.DESC,
      },
      { text: 'Deadline', value: 'deadline', filterDefaultDirection: ORDERING_DIRECTION.DESC },
      { text: 'Title', value: 'title', filterDefaultDirection: ORDERING_DIRECTION.ASC },
    ],
    filters: [
      {
        label: 'Sort By',
        paramName: 'ordering',
        fieldComponent: SelectableListFilterField,
        multiple: false,
        isOrdering: true,
        options: [
          {
            text: (
              <span>
                Created date
                {' '}
                <i className={ICON.SORTING_ASC} />
              </span>
            ),
            value: JSON.stringify({
              direction: 'ASC',
              sortBy: 'created_at',
            }),
          },
          {
            text: (
              <span>
                Created date
                {' '}
                <i className={ICON.SORTING_DESC} />
              </span>
            ),
            value: JSON.stringify({
              direction: 'DESC',
              sortBy: 'created_at',
            }),
          },
          {
            text: (
              <span>
                Deadline
                {' '}
                <i className={ICON.SORTING_ASC} />
              </span>
            ),
            value: JSON.stringify({
              direction: 'ASC',
              sortBy: 'deadline',
            }),
          },
          {
            text: (
              <span>
                Deadline
                {' '}
                <i className={ICON.SORTING_DESC} />
              </span>
            ),
            value: JSON.stringify({
              direction: 'DESC',
              sortBy: 'deadline',
            }),
          },
          {
            text: (
              <span>
                Title
                {' '}
                <i className={ICON.SORTING_ASC} />
              </span>
            ),
            value: JSON.stringify({
              direction: 'ASC',
              sortBy: 'title',
            }),
          },
          {
            text: (
              <span>
                Title
                {' '}
                <i className={ICON.SORTING_DESC} />
              </span>
            ),
            value: JSON.stringify({
              direction: 'DESC',
              sortBy: 'title',
            }),
          },
        ],
      },
      isManager ? taskStatusFilter : assignmentStatusFilter,
      {
        label: 'Archived',
        paramName: 'archived',
        fieldComponent: SelectableListFilterField,
        options: archiveOptions,
      },
      isManager ? taskOwnershipFilter : null,
      {
        label: 'Deadline',
        paramName: 'deadline',
        fieldComponent: DateRangePickerFilterField,
      },
    ].filter(f => !!f),
  };

  return (
    <SearchFinalForm
      filtersOpen={filtersOpen}
      onFiltersToggle={onFiltersToggle}
      searchSpec={searchSpec}
    />
  );
};

TaskSearch.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  customFieldFilters: PropTypes.arrayOf(PropTypes.object),
  isManager: PropTypes.bool,
};

TaskSearch.defaultProps = {
  customFieldFilters: [],
  isManager: false,
};

export default TaskSearch;
