/* globals google */
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { toastr } from 'react-redux-toastr';

import { convertGoogleMapsPlaceNewToOld } from 'core/assets/js/lib/utils';

/**
 * Format the google maps Place, to match the structure we have been storing in the DB
 * from react-geosuggest
 *
 * @param {String} description - The autocomplete description
 * @param {Object} place - The Google Place entity
 * @returns {Object}
 */
const placeToOption = (description, place) => {
  const convertedPlaceJSON = convertGoogleMapsPlaceNewToOld(place.toJSON());
  const result = { description, gmaps: { ...convertedPlaceJSON }, label: description };
  if (convertedPlaceJSON.place_id) {
    result.placeId = convertedPlaceJSON.place_id;
  }
  if (convertedPlaceJSON.geometry?.location) {
    result.location = convertedPlaceJSON.geometry?.location;
  }
  return result;
};

const TDGeoSuggest = ({
  disabled, initialValue, inputClassName, onChange, onSuggestSelect, types,
}) => {
  const [value, setValue] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);

  const onValueChange = useCallback(
    debounce(
      async newValue => {
        if (!newValue) {
          return;
        }
        if (!google?.maps?.places) {
          toastr.error('Oh Snap!', 'Google Maps Places is not available');
          return;
        }
        const sessionToken = new google.maps.places.AutocompleteSessionToken();
        try {
          const response = await google.maps.places.AutocompleteSuggestion
            .fetchAutocompleteSuggestions({
              includedPrimaryTypes: types,
              input: newValue,
              sessionToken,
            });
          setSuggestions(response.suggestions.reduce(
            (acc, suggestion) => {
              if (suggestion.placePrediction) {
                acc.push(suggestion);
              }
              return acc;
            },
            [],
          ));
        } catch (e) {
          toastr.error('Oh Snap!', e.message);
        }
      },
      500,
    ),
    [],
  );

  return (
    <div className="td-geo-suggest">
      <input
        className={inputClassName}
        disabled={disabled}
        onChange={event => {
          setValue(event.target.value);
          onChange({}); // Unset the currently selected address, as the typed in value has changed
          setSuggestions([]);
          onValueChange(event.target.value);
        }}
        type="text"
        value={value}
      />
      {suggestions.length > 0 && (
        <div className="options-container w-100">
          {suggestions.map(suggestion => {
            const description = suggestion.placePrediction.text.toString();
            return (
              <div
                className="clickable option p-3"
                key={suggestion.placePrediction.placeId}
                onClick={async () => {
                  try {
                    let place = suggestion.placePrediction.toPlace();
                    ({ place } = await place.fetchFields({
                      fields: [
                        'addressComponents',
                        'adrFormatAddress',
                        'attributions',
                        'displayName',
                        'formattedAddress',
                        'googleMapsURI',
                        'iconBackgroundColor',
                        'id',
                        'location',
                        'photos',
                        'svgIconMaskURI',
                        'types',
                        'utcOffsetMinutes',
                        'viewport',
                      ],
                    }));
                    const option = placeToOption(description, place);
                    onChange(option);
                    onSuggestSelect(option);
                    setValue(description);
                    setSuggestions([]);
                  } catch (e) {
                    toastr.error('Oh Snap!', e.message);
                  }
                }}
              >
                {description}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

TDGeoSuggest.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSuggestSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
};

TDGeoSuggest.defaultProps = {
  disabled: false,
  initialValue: '',
  inputClassName: null,
  placeholder: '',
  types: null,
};

export default TDGeoSuggest;
