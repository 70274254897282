import Big from 'big.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { keyBy, groupBy, get } from 'lodash';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import DocumentSelectField from 'core/assets/js/components/FinalFormFields/DocumentSelectField.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import EmploymentStatusIndicator from 'people/assets/js/components/EmploymentStatusIndicator.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import RatingScore from 'core/assets/js/components/RatingScore.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import TaskRateSelection from 'projects/assets/js/components/TaskRateSelection.jsx';
import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import { CURRENCY, IMG_SIZE, ICON } from 'core/assets/js/constants';
import { RATE_UNIT_FORMAT, RATE_SUGGEST_VALUE, RATE_UNIT } from 'rates/assets/js/constants';
import { getDisplayRate } from 'rates/assets/js/lib/utils';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { userCardSpec, orgSpec } from 'organizations/assets/js/lib/objectSpecs';

class TaskAssignmentFieldSetItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRateMaxBillingQuantity: false,
    };

    this.getFieldName = this.getFieldName.bind(this);
    this.handleShowRateMaxBillingQuantity = this.handleShowRateMaxBillingQuantity.bind(this);
  }

  /**
   * Returns the prefixed field's name
   * @param {String} name The field name to apply the prefix to
   * @returns {String}
   */
  getFieldName(name) {
    const { fieldPrefix } = this.props;
    return fieldPrefix ? `${fieldPrefix}.${name}` : name;
  }

  /**
   * Returns the maximum billable amount
   * calculating maximum billing quantity, times to selected rate amount
   * @returns {React.ReactElement} maximum billing amount with currency
   */
  getMaximumBillable() {
    const { currency, rates, values } = this.props;
    const rateValueKey = this.getFieldName('rate_id');
    const selectedRateId = get(values, rateValueKey);
    const rateMaxBillingQuantityKey = this.getFieldName('rate_max_billing_quantity');
    const rateMaxBillingQuantity = get(values, rateMaxBillingQuantityKey);

    if (!selectedRateId || !rateMaxBillingQuantity) {
      return null;
    }

    const isSuggestingNewRate = selectedRateId === RATE_SUGGEST_VALUE;
    if (isSuggestingNewRate) {
      const suggestingRateValueKey = this.getFieldName('rate');
      const suggestingRate = get(values, suggestingRateValueKey);

      if (!suggestingRate) {
        return null;
      }

      const maxBillingAmount = Big(suggestingRate).times(rateMaxBillingQuantity).toFixed(2);
      return <NumberTpl prefix={CURRENCY_SYMBOL[currency]} value={maxBillingAmount} />;
    }

    const selectedRate = rates.find(({ id }) => id === selectedRateId);

    if (!selectedRate) {
      return null;
    }

    const selectedRateAmount = selectedRate.amount;
    const maxBillingAmount = Big(selectedRateAmount).times(rateMaxBillingQuantity).toFixed(2);

    return <NumberTpl prefix={CURRENCY_SYMBOL[selectedRate.currency]} value={maxBillingAmount} />;
  }

  /**
   * Return selected or suggesting rate unit
   * @returns {String}
   */
  getSelectedRateUnit() {
    const { change, values, rates } = this.props;
    const { showRateMaxBillingQuantity } = this.state;
    const rateValueKey = this.getFieldName('rate_id');
    const selectedRateId = get(values, rateValueKey);

    if (!selectedRateId) {
      return false;
    }
    const selectedRate = rates.find(({ id }) => id === selectedRateId);

    if (selectedRate) {
      return RATE_UNIT_FORMAT[selectedRate.unit].unit;
    }

    const rateUnitValueKey = this.getFieldName('rate_unit');
    const selectedRateUnit = get(values, rateUnitValueKey);
    if (selectedRateUnit === RATE_UNIT.FIXED) {
      const rateMaxBillingQuantityValueKey = this.getFieldName('rate_max_billing_quantity');
      change(rateMaxBillingQuantityValueKey, undefined);
      if (showRateMaxBillingQuantity) {
        this.setState({
          showRateMaxBillingQuantity: false,
        });
      }
    }
    return RATE_UNIT_FORMAT[selectedRateUnit].unit;
  }

  /**
   * Returns the toggle value to show or hide maximum billing quantity field
   * @returns {Boolean}
   */
  handleShowRateMaxBillingQuantity() {
    const { showRateMaxBillingQuantity } = this.state;
    const { change } = this.props;
    this.setState({
      showRateMaxBillingQuantity: !showRateMaxBillingQuantity,
    });

    if (showRateMaxBillingQuantity) {
      const rateMaxBillingQuantityValueKey = this.getFieldName('rate_max_billing_quantity');
      change(rateMaxBillingQuantityValueKey, undefined);
    }
  }

  render() {
    const {
      activeOrg,
      addedDirectly,
      currency,
      defaultRateUnit,
      fieldPrefix,
      onRemoveItem,
      provider: {
        currencySymbol,
        isEmployee,
        numReviews,
        profileUrl,
        rate,
        rateUnit,
        rating,
        user: { profile },
        userRole: { ofType: userType },
      },
      rates,
    } = this.props;
    const { showRateMaxBillingQuantity } = this.state;
    const selectedRateUnit = this.getSelectedRateUnit();
    const hasCompleteRate = currencySymbol && parseFloat(rate) > 0.00;
    const userRate = hasCompleteRate
      ? (
        <StatusTag
          statusClass="default"
          hideTitle
          hideDot
          label={getDisplayRate(rate, rateUnit, { currency: currencySymbol })}
        />
      )
      : '';
    return (
      <Card className="card--light-gray">
        <Card.Body className="position-relative">
          <div className="row d-flex align-items-center position-relative">
            <div className="user-item__basic-info col-12 col-md-auto d-flex">
              {profileUrl ? (
                <Link
                  to={profileUrl}
                  title={profile.name}
                >
                  <ProfilePic
                    url={profile.avatar}
                    alt={profile.name}
                    size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                  />
                </Link>
              ) : (
                <React.Fragment>
                  <ProfilePic
                    url={profile.avatar}
                    alt={profile.name}
                    size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                  />
                </React.Fragment>
              )}
              <div className="col-10 col-md-auto pl-3">
                {profileUrl ? (
                  <Link
                    to={profileUrl}
                    title={profile.name}
                    className="user-item__title truncate"
                  >
                    {profile.name}
                    <EmploymentStatusIndicator
                      userType={userType}
                      isEmployee={isEmployee}
                    />
                  </Link>
                ) : (
                  <React.Fragment>
                    {profile.name}
                    <EmploymentStatusIndicator
                      userType={userType}
                      isEmployee={isEmployee}
                    />
                  </React.Fragment>
                )}
                <div className="user-item__extra discreet d-flex align-items-center col-auto px-0">
                  <div className="truncate">
                    {profile.jobTitle && (
                      <span className="truncate" title={profile.jobTitle}>
                        {profile.jobTitle}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-auto mt-3 mt-md-0 user-item__details ml-0 ml-md-auto">
              <RatingScore
                rating={rating}
                numReviews={numReviews}
              />
              {hasCompleteRate && userRate}
            </div>

            {onRemoveItem && (
              <a onClick={onRemoveItem} className="close mr-4">
                <i className={ICON.CROSS_CIRCLE} />
              </a>
            )}
          </div>

          <TextInputField
            name={this.getFieldName('user_id')}
            type="hidden"
          />

          <TextAreaField
            name={this.getFieldName('message')}
            label="Invitation Message"
            placeholder="Write a personalized message that will be included in the invitation email"
          />

          <TaskRateSelection
            rates={rates}
            label="Rate"
            fieldPrefix={fieldPrefix}
            currencySymbol={CURRENCY_SYMBOL[currency]}
            defaultRateUnit={rateUnit || defaultRateUnit}
          />

          {selectedRateUnit !== RATE_UNIT_FORMAT[RATE_UNIT.FIXED].unit && (
            <>
              <span
                className="cursor-pointer d-flex align-items-center"
                data-testid="task-assignment-capped-toggle"
                onClick={this.handleShowRateMaxBillingQuantity}
              >
                <TDSwitch
                  selected={showRateMaxBillingQuantity}
                />
                <span className="ml-3">
                  Capped?
                </span>
              </span>
              <p className="mt-2 mb-5 pb-2">
                If a cap is set the provider will not be able to submit a worksheet
                claim more than the specified capped number of units
              </p>
            </>
          )}

          {showRateMaxBillingQuantity && (
            <div className="row" data-testid="task-assignment-capped-section">
              <div className="col-8 col-md-4">
                <InputNumberField
                  className="mb-0"
                  name={this.getFieldName('rate_max_billing_quantity')}
                  placeholder="Capped value"
                  step={0.5}
                />
              </div>
              <div className="col-4 col-md-2">
                <p className="mt-2">
                  {/* {RATE_UNIT_FORMAT[rateUnit].unit} */}
                  {selectedRateUnit}
                </p>
              </div>
              <div className="col-12 col-md-6">
                <p className="info-pill bg-pale-gray">
                  Maximum billable
                  <span className="info-pill--value" data-testid="maximum-billable-value">
                    {this.getMaximumBillable()}
                  </span>
                </p>
              </div>
            </div>
          )}
          {!addedDirectly && (
            <DocumentSelectField
              className="mt-2"
              name={this.getFieldName('documents')}
              required={activeOrg.require_legal_documents}
              sublabel={`Do you require invited team members to review and agree to any legal
                documents before obtaining access to the task?`}
            />
          )}
        </Card.Body>
      </Card>
    );
  }
}

TaskAssignmentFieldSetItem.propTypes = {
  activeOrg: orgSpec.isRequired,
  addedDirectly: PropTypes.bool.isRequired,
  currency: PropTypes.oneOf(Object.values(CURRENCY)).isRequired,
  provider: userCardSpec.isRequired,
  rates: PropTypes.arrayOf(PropTypes.object),
  fieldPrefix: PropTypes.string,
  defaultRateUnit: PropTypes.number.isRequired,
  onRemoveItem: PropTypes.func,
  values: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

TaskAssignmentFieldSetItem.defaultProps = {
  fieldPrefix: null,
  rates: [],
  onRemoveItem: null,
};

const TaskAssignmentFieldSet = ({
  addedDirectly,
  change,
  defaultRateUnit,
  fields,
  onRemoveItem,
  providers,
  rates,
  values,
  activeOrg,
}) => {
  const grouppedProviders = keyBy(providers, 'user.id');
  const grouppedRates = groupBy(rates, 'user_id');

  return fields.map((fieldName, index) => {
    const field = fields.value[index];
    const userId = field.user_id;

    if (!userId || !grouppedProviders[userId]) {
      return null;
    }

    return (
      <TaskAssignmentFieldSetItem
        addedDirectly={addedDirectly}
        currency={field.currency}
        key={`task-assignment-fields-${userId}`}
        provider={grouppedProviders[userId]}
        rates={grouppedRates[userId]}
        fieldPrefix={fieldName}
        defaultRateUnit={defaultRateUnit}
        onRemoveItem={() => onRemoveItem(field.p_id)}
        values={values}
        change={change}
        activeOrg={activeOrg}
      />
    );
  });
};

TaskAssignmentFieldSet.propTypes = {
  activeOrg: orgSpec.isRequired,
  addedDirectly: PropTypes.bool.isRequired,
  providers: PropTypes.arrayOf(userCardSpec),
  fields: PropTypes.object,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
  meta: PropTypes.object,
  onRemoveItem: PropTypes.func,
  defaultRateUnit: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

TaskAssignmentFieldSet.defaultProps = {
  provider: [],
  fields: [],
  rates: [],
  meta: {},
  onRemoveItem: null,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

export default connect(mapStateToProps)(TaskAssignmentFieldSet);
