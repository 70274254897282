import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { formatDate } from 'core/assets/js/lib/utils';
import withModalForm from 'core/assets/js/components/withModalForm.jsx';
import { refreshFinancePendingCountsDS } from 'core/assets/js/ducks/pendingCount';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import {
  ACCESS_CONTROL_ALLOWED_ACTIONS as ALLOWED_ACTIONS,
  BS_STYLE,
  DATETIME_FORMAT_HUMAN_FRIENDLY_2,
  ICON,
  PROJECT_TABS,
} from 'core/assets/js/constants';
import { projectMemberAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import ReasonForm from 'core/assets/js/components/ReasonForm.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import PurchaseOrdersTable from 'finance/assets/js/components/PurchaseOrdersTable.jsx';
import { fetchFinancePurchaseOrderDS } from 'finance/assets/js/data-services/view';
import {
  financePurchaseOrderApproveDS, financePurchaseOrderRejectDS, financePurchaseOrderCancelDS,
} from 'finance/assets/js/data-services/form';
import ReportViewLayout from 'finance/assets/js/components/ReportViewLayout.jsx';
import ReportLogo from 'finance/assets/js/components/ReportLogo.jsx';
import { purchaseOrderSpec } from 'finance/assets/js/lib/objectSpecs';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { profileSpec } from 'people/assets/js/lib/objectSpecs';
import { PURCHASE_ORDER_STATUS_LABEL } from 'projects/assets/js/constants';
import PurchaseOrderCancelForm from 'finance/assets/js/components/PurchaseOrderCancelForm.jsx';
import ReportProjectLink from 'finance/assets/js/components/ReportProjectLink.jsx';
import ReportDetails from 'finance/assets/js/components/ReportDetails.jsx';
import { followBackUrlIfPresent } from 'finance/assets/js/lib/utils';
import PurchaseOrdersView from 'finance/assets/js/PurchaseOrdersView.jsx';
import ProjectTabPurchaseOrders from 'projects/assets/js/components/projectTabs/ProjectTabPurchaseOrders.jsx';
import { fetchOutstandingCountsDS } from 'projects/assets/js/data-services/view';
import ProjectView from 'projects/assets/js/ProjectView.jsx';
import { projectViewUrl } from 'projects/urls';

class PurchaseOrderView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchFinancePurchaseOrderDS({
        orgAlias: params.orgAlias, id: params.id, url, componentName, authedAxios,
      })),
    ]);
  }

  static GetComponentName() {
    return 'PurchaseOrderView';
  }

  constructor(props) {
    super(props);

    this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
    this.onSubmitApprove = this.onSubmitApprove.bind(this);
    this.onSubmitReject = this.onSubmitReject.bind(this);
    this.onSubmitCancel = this.onSubmitCancel.bind(this);
  }

  /**
   * PO Cancel - form submit success callback
   */
  onSubmitSuccess() {
    const { match: { params }, dispatch, history } = this.props;
    const componentName = this.constructor.GetComponentName();

    dispatch(fetchFinancePurchaseOrderDS({
      orgAlias: params.orgAlias,
      id: params.id,
      componentName,
    }));

    // Update finance pending counts.
    dispatch(refreshFinancePendingCountsDS({
      orgAlias: params.orgAlias,
    }));

    followBackUrlIfPresent(history);
  }

  /**
   * Approval dialong on submit handler.
   */
  onSubmitApprove() {
    const { match: { params }, dispatch } = this.props;
    const componentName = this.constructor.GetComponentName();

    dispatch(financePurchaseOrderApproveDS({
      componentName,
      orgAlias: params.orgAlias,
      documentId: params.id,
    }));
  }

  /**
   * Reject dialong on submit handler.
   * @param {Object} values - form values.
   */
  onSubmitReject(values) {
    const { match: { params }, dispatch } = this.props;
    const componentName = this.constructor.GetComponentName();

    dispatch(financePurchaseOrderRejectDS({
      data: values,
      componentName,
      orgAlias: params.orgAlias,
      documentId: params.id,
    }));
  }

  /**
   * Cancel dialog on submit handler.
   * @param {Object} values - form values.
   */
  async onSubmitCancel(values) {
    const { dispatch, location: { search }, match: { params }, organization } = this.props;
    const componentName = this.constructor.GetComponentName();

    const projectUrlRegex = new RegExp(
      `^${projectViewUrl(organization.alias, '[0-9]+', PROJECT_TABS.PURCHASE_ORDERS)}$`,
    );
    const { backUrl } = queryString.parse(search);
    const backUrlIsProjectPage = projectUrlRegex.test(backUrl);

    await dispatch(financePurchaseOrderCancelDS({
      componentName,
      data: values,
      documentId: params.id,
      listingComponentName: (
        backUrlIsProjectPage ? ProjectTabPurchaseOrders : PurchaseOrdersView
      ).GetComponentName(),
      orgAlias: params.orgAlias,
    }));

    if (backUrlIsProjectPage) {
      // Update the project's outstanding counts, so the Project CTA reflects this PO having been
      // cancelled
      const projectId = backUrl.split('/')[3];
      await dispatch(fetchOutstandingCountsDS({
        componentName: ProjectView.GetComponentName(), id: projectId, orgAlias: params.orgAlias,
      }));
    }
  }

  render() {
    const { match, report, organization, accessControl, history } = this.props;
    const reports = [report];
    const { permissions } = report;
    const status = PURCHASE_ORDER_STATUS_LABEL[report.status];
    const componentName = this.constructor.GetComponentName();

    const ApproveForm = withModalForm({
      heading: 'Approve budget request',
      confirmLabel: 'Approve',
      confirmStyle: BS_STYLE.SUCCESS,
      testId: 'purchase-order-view-approve-modal',
    })(() => (
      <React.Fragment>
        Are you sure you want to approve this budget request?
      </React.Fragment>
    ));

    const approveModalData = permissions?.canReview ? {
      key: 'purchaseOrderApproveForm',
      btnLabel: 'Approve',
      btnVariant: BS_STYLE.SUCCESS,
      btnIcon: ICON.CHECKMARK,
      btnClassName: 'text-success',
      Form: (
        <ApproveForm
          onSubmit={this.onSubmitApprove}
          onSubmitSuccess={() => followBackUrlIfPresent(history)}
        />
      ),
    } : null;

    const RejectForm = withModalForm({
      heading: 'Reject budget request',
      confirmLabel: 'Reject',
      confirmStyle: BS_STYLE.DANGER,
      testId: 'purchase-order-view-reject-modal',
    })(ReasonForm);

    const rejectModalData = permissions?.canReview ? {
      key: 'purchaseOrderRejectForm',
      btnLabel: 'Reject',
      btnVariant: BS_STYLE.DANGER,
      btnIcon: ICON.CROSS,
      btnClassName: 'text-danger',
      Form: (
        <RejectForm
          onSubmit={this.onSubmitReject}
          onSubmitSuccess={this.onSubmitSuccess}
          question="Are you sure you want to reject this budget request?"
        />
      ),
    } : null;

    const po_accessControl = accessControl[ALLOWED_ACTIONS.PURCHASE_ORDER];
    const canCancelPO = report && po_accessControl && po_accessControl.canBeCancelled;

    const CancelForm = withModalForm({
      heading: `Cancel budget request #${report.id}`,
      confirmLabel: 'Cancel budget request',
      confirmStyle: BS_STYLE.DANGER,
      cancelLabel: 'Close',
      testId: 'purchase-order-view-cancel-modal',
    })(PurchaseOrderCancelForm);

    const cancelModalData = !canCancelPO ? null : {
      key: 'POCancelForm',
      btnLabel: (
        <span>
          Cancel
          <span className="d-none d-md-inline"> budget request</span>
        </span>
      ),
      btnVariant: BS_STYLE.SECONDARY,
      btnClassName: 'text-danger',
      Form: (
        <CancelForm
          po={report}
          componentName={componentName}
          documentId={match.params.id}
          orgAlias={match.params.orgAlias}
          onSubmitSuccess={this.onSubmitSuccess}
          onSubmit={this.onSubmitCancel}
          question="Are you sure you want to cancel this budget request?"
        />
      ),
    };

    const detailsRows = [
      { label: 'Project Ref',
        value: (
          <ReportProjectLink
            projectId={report.projectId}
            orgAlias={match.params.orgAlias}
            projectReference={report.externalProjectId ? `${report.projectReference} (${report.externalProjectId})` : report.projectReference}
            allowedActions={accessControl[ALLOWED_ACTIONS.PROJECT_MEMBER]}
          />
        ),
      },
      { label: 'Budget request no', value: report.id },
      {
        label: 'Date',
        value: (
          report.createdAtISO
            ? formatDate(report.createdAtISO, DATETIME_FORMAT_HUMAN_FRIENDLY_2)
            : report.createdAt
        ),
      },
      { label: 'Status', value: report.statusLabel },
    ];

    return (
      <ReportViewLayout
        reportType={REPORT_TYPES.PURCHASE_ORDER}
        actions={[
          approveModalData,
          rejectModalData,
          cancelModalData,
        ]}
        heading={report.projectReference && `${report.projectReference}_${report.id}`}
        status={status}
        report={report}
      >
        <TDApiConnected duck="view" component={this.constructor} blockingLoading>
          <div className="finance-report__head row">
            <ReportLogo
              className="col-12 col-md-6 col-lg-8 mb-4 mb-md-0"
              company={organization}
            />

            <div className="finance-report__meta col-12 col-md-6 col-lg-4">
              <ReportDetails rows={detailsRows} />
            </div>
          </div>

          <hr />

          <PurchaseOrdersTable
            list={reports}
            emptyText="No budget request found"
          />

          <div className="mt-4 finance-report__organization-footer-info mt-5">
            <strong>{organization.name}</strong>
            <br />
            {organization.email}
            {' '}
            {organization.website}
          </div>
        </TDApiConnected>
      </ReportViewLayout>
    );
  }
}

PurchaseOrderView.propTypes = {
  accessControl: PropTypes.shape({
    [ALLOWED_ACTIONS.PROJECT_MEMBER]: projectMemberAllowedActionsSpec,
    [ALLOWED_ACTIONS.PURCHASE_ORDER]: PropTypes.object,
  }),
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  organization: orgSpec,
  profile: profileSpec.isRequired,
  report: purchaseOrderSpec,
};

PurchaseOrderView.defaultProps = {
  organization: {},
  report: {},
  accessControl: {
    [ALLOWED_ACTIONS.PROJECT_MEMBER]: {},
    [ALLOWED_ACTIONS.PURCHASE_ORDER]: {},
  },
};

const mapStateToProps = (state, props) => {
  const viewState = getViewState(state, PurchaseOrderView.GetComponentName());

  return {
    match: props.match,
    history: props.history,
    organization: selectActiveOrg(state),
    report: viewState.item,
    accessControl: viewState.extras,
    profile: selectProfile(state),
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PurchaseOrderViewConnect = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderView);

export default withRouter(PurchaseOrderViewConnect);
