import React, { useCallback, useMemo } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import {
  linkToRecord,
  Button as RAButton,
  Link,
  useGetOne,
} from 'react-admin';
import { Button, Icon, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import { useStyles as useThemeStyles } from 'admin/assets/js/layout/themes';
import { twTransferUrl } from 'services/urls';
import {
  TRANSACTION_METHOD,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from 'finance/assets/js/constants';
import { transactionHasHighFees, getTransactionLimitAmount } from 'admin/assets/js/lib/utils';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { formatDate } from 'core/assets/js/lib/utils';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import CommentTransactionButton from 'admin/assets/js/components/buttons/CommentTransactionButton.jsx';
import TransactionAmounts from 'finance/assets/js/lib/TransactionAmounts';
import TransactionIntent from 'finance/assets/js/lib/TransactionIntent';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import CustomTooltip from 'admin/assets/js/components/CustomToolTip.jsx';
import TransactionStatus from 'admin/assets/js/components/TransactionStatus.jsx';

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const useLocalStyles = makeStyles(() => ({
  container: {
    marginBottom: '30px',
  },
}));


const TransactionDetailsField = ({ record, readOnly, showCreateFallbackCTA, isDeferred }) => {
  const classes = {
    ...useThemeStyles(),
    ...useLocalStyles(),
  };

  const {
    reviewed_by: reviewerId,
    approved_by: approverId,
  } = record;
  const { data: reviewerData } = useGetOne('full_users', reviewerId, { enabled: !!reviewerId });
  const { data: approverData } = useGetOne('full_users', approverId, { enabled: !!approverId });

  let intent;

  if (record.intent) {
    intent = new TransactionIntent(record.intent);
  }

  const {
    id, type, status, raised_at: raisedAt, tw_transfer_id: transferId,
    method_label: methodLabel, descriptor, comment,
    latest_error_reason: latestErrorReason,
    tw_fx_rate_expires: fxRateExpiry,
    detracted_amount: detractedAmount,
    detracted_currency: detractedCurrency,
    method, wasSWIFTForced, time_to_reconcile: timeToReconcile,
    cancel_reason: cancellationReason,
  } = record || {};
  const errorMessage = useMemo(() => {
    // only show error for failed transactions
    if (latestErrorReason && [TRANSACTION_STATUS.FAILED].includes(status)) {
      try {
        const errorObj = JSON.parse(latestErrorReason);
        return errorObj
          && ((Array.isArray(errorObj) && errorObj[0].message) || errorObj?._meta?.message);
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }

    return null;
  }, [latestErrorReason, status]);

  const hasFxRateExpired = useMemo(() => fxRateExpiry && moment().isAfter(fxRateExpiry),
    [fxRateExpiry]);

  const openWise = useCallback(() => {
    WINDOW_OPEN(`https://wise.com/transactions/activities/by-resource/transfer/${transferId}`);
  }, [transferId]);

  // warn - early returns must be after hooks !!!
  if (!record) {
    return null;
  }

  const amounts = new TransactionAmounts(record.amounts);
  const url = twTransferUrl(transferId);
  const isPending = [TRANSACTION_STATUS.PENDING, TRANSACTION_STATUS.EXECUTED].includes(status);

  const outgoingCurrency = amounts.getOutgoingCurrency().toLowerCase();
  const sourceSymbol = CURRENCY_SYMBOL[outgoingCurrency];
  const targetSymbol = CURRENCY_SYMBOL[amounts.getTargetCurrency().toLowerCase()];
  const orgCurrencySymbol = CURRENCY_SYMBOL[amounts.getOrgCurrency().toLowerCase()];

  const weSendInOrgCurrency = amounts.getNetOutgoingAmountInOrgCurrency();
  const weSend = amounts.getNetOutgoingAmount();
  const weSendWithFees = amounts.getOutgoingAmount();
  const weSendLabel = type === TRANSACTION_TYPE.OUTBOUND ? 'we send' : 'client sent';

  const weReceive = amounts.getTargetAmount();
  const weReceiveLabel = type === TRANSACTION_TYPE.OUTBOUND ? 'contractor will receive' : 'we receive';

  const accountHolderName = get(record, 'dimTransferwiseRecipient.details.accountHolderName');

  const transferShowPage = linkToRecord('/dim_transferwise_transfers', record.tw_transfer_id, 'show');
  const totalFee = amounts.getTotalFee();
  const hasHighFees = transactionHasHighFees(totalFee, outgoingCurrency);

  const transferAmountCurrency = amounts.getTargetCurrency();
  const transferAmount = new Money(amounts.getTargetAmount(), transferAmountCurrency).toString();
  const allocatedMoney = new Money(
    (detractedAmount || 0),
    (detractedCurrency || transferAmountCurrency),
  );
  const transferMoney = new Money(transferAmount, transferAmountCurrency);
  const allocatedPercent = transferMoney.isZero()
    ? 0
    : allocatedMoney.div(transferMoney).toNumber();

  const feeProportion = new Money(totalFee, outgoingCurrency)
    .mul(allocatedPercent).toString({ humanizeAmount: true, withSymbol: true });


  // depending on intent
  const weRequested = type === TRANSACTION_TYPE.OUTBOUND
    ? amounts.getOutgoingAmount()
    : null;
  const expectedFee = (intent && intent.getExpectedFee()) || '0.00';
  const shouldProviderPayFees = amounts.shouldProviderPayFees();
  const hasFeeFluctuation = expectedFee !== amounts.getTotalFee();
  const isConsistent = !intent || intent.isConsistentWith(amounts);

  const formatDateWhenEmpty = (str, placeHolder) => (str ? formatDate(str, dateFormat)
    : placeHolder);

  const canCancelTransfer = !readOnly && isPending && transferId && !isDeferred;

  return (
    <div className={`${classes.container} ${hasFxRateExpired ? classes.expiredfx : ''}`}>
      <div>
        {transferId ? (
          <h5>
            #
            <Link to={`/transactions/${id}/details`}>
              {id}
            </Link>
            {' '}
            {methodLabel}
            {' '}
            (#
            <Link to={transferShowPage}>{record.tw_transfer_id}</Link>
            )
            {method === TRANSACTION_METHOD.TRANSFERWISE && (
              <RAButton
                variant="text"
                color="primary"
                onClick={openWise}
                title="Open transfer in Wise dashboard"
              >
                <Icon>launch</Icon>
              </RAButton>
            )}
            <br />
            <span style={{ fontSize: '0.8em' }}>{record.quote_id}</span>
          </h5>
        ) : (
          <h5>
            #
            <Link to={`/transactions/${id}/details`}>
              {id}
            </Link>
            {' '}
            {methodLabel}
            {' '}
            <span style={{ textTransform: 'none' }}>
              (
              {descriptor}
              )
            </span>
            {method === TRANSACTION_METHOD.TRANSFERWISE && (
              <RAButton
                variant="text"
                color="primary"
                onClick={openWise}
                title="Open transfer in Wise dashboard"
              >
                <Icon>launch</Icon>
              </RAButton>
            )}
          </h5>
        )}
        <CommentTransactionButton comment={comment} transactionId={id} />
        {isDeferred && (
          <span className={classes.discreet}>Transaction is linked to a group of invoices</span>
        )}
        {record.manually_recorded && (
          <div className="d-flex">
            <span className={classes.discreet}>Manually recorded transaction</span>
            {record.status === TRANSACTION_STATUS.SUCCEEDED && (
              <span className="pl-4 ml-auto mb-1">
                <span className="mr-1">
                  <BulkApiActionButton
                    label="Refund"
                    title="Refund transaction"
                    confirmLabel="Refund transaction"
                    content="Are you sure you want to mark this transaction as refunded?"
                    ids={[record.id]}
                    resource="invoices"
                    action="refund-manual-transactions"
                    variant="outlined"
                    color="default"
                  />
                </span>
                {!isDeferred && (
                  <BulkApiActionButton
                    label="Cancel"
                    title="Cancel transaction"
                    confirmLabel="Cancel transaction"
                    content="Are you sure you want to mark this transaction as cancelled?"
                    ids={[record.id]}
                    resource="invoices"
                    action="cancel-manual-transactions"
                    variant="outlined"
                    color="default"
                  />
                )}
              </span>
            )}
          </div>
        )}
        <div className="d-flex">
          <span className={classes.discreet}>status</span>
          <span className="pl-4 ml-auto">
            <TransactionStatus status={status} />
          </span>
        </div>
        {status === TRANSACTION_STATUS.CANCELLED && (
          <div className="d-flex">
            <span className={classes.discreet}>cancellation reason</span>
            <span className={`${classes.info} pl-4 ml-auto`}>
              {cancellationReason || '-'}
            </span>
          </div>
        )}
        {accountHolderName && (
          <div className="d-flex">
            <span className={classes.discreet}>account holder</span>
            <span className={`${classes.info} pl-4 ml-auto`}>
              {accountHolderName}
            </span>
          </div>
        )}
        <div className="d-flex">
          <span className={classes.discreet}>raised at</span>
          <span className="pl-4 ml-auto">
            {formatDate(raisedAt, dateFormat)}
          </span>
        </div>
        {weRequested && (
          <div className="d-flex">
            <span className={classes.discreet}>
              requested
            </span>
            <span className="pl-4 ml-auto">
              <NumberTpl
                value={weRequested}
                prefix={sourceSymbol}
              />
            </span>
          </div>
        )}
        <div className="d-flex">
          <span className={classes.discreet}>{weSendLabel}</span>
          <strong className="pl-4 ml-auto">
            <NumberTpl
              value={weSend}
              prefix={sourceSymbol}
            />
          </strong>
          {amounts.hasDifferentBalance() && (
            <span className="ml-1">
              {' '}
              (
              <NumberTpl
                value={weSendInOrgCurrency}
                prefix={orgCurrencySymbol}
              />
              )
            </span>
          )}
        </div>
        <div className="d-flex">
          <span className={classes.discreet}>fee proportion</span>
          <div className="d-flex ml-auto text-dark p-1">
            <span className="pl-2">
              {feeProportion}
            </span>
          </div>
        </div>
        <div className="d-flex">
          <span className={classes.discreet}>total fees</span>
          <div className={`d-flex ml-auto ${hasHighFees ? `${classes.bgWarning} text-dark p-1` : ''}`}>
            {hasHighFees && (
              <CustomTooltip
                title={(
                  <div>
                    {`
                      Transaction's amount was highlighted as high fee, as it exceeds
                      ${new Money(getTransactionLimitAmount(outgoingCurrency), outgoingCurrency).toString({ humanizeAmount: true, withSymbol: true })}.
                    `}
                  </div>
                )}
              />
            )}
            <span className="pl-2">
              <NumberTpl value={totalFee} prefix={sourceSymbol} />
            </span>
            {hasFeeFluctuation && (
              <span className="ml-1">
                (estimated
                {' '}
                <NumberTpl
                  value={expectedFee}
                  prefix={sourceSymbol}
                />
                )
              </span>
            )}
          </div>
        </div>
        <div className="d-flex">
          <span className={classes.discreet}>
            {weSendLabel}
            {' '}
            (including fees)
          </span>
          <span className={`${isConsistent ? classes.success : classes.danger} pl-4 ml-auto`}>
            <NumberTpl
              value={weSendWithFees}
              prefix={sourceSymbol}
            />
          </span>
        </div>
        {amounts.getTargetRate() !== 1 && (
          <div className="d-flex">
            <span className={classes.discreet}>
              FX rate
              {' '}
              {amounts.getTargetExchangeRateCode()}
            </span>
            <span className="pl-4 ml-auto">
              {amounts.getTargetRate()}
            </span>
          </div>
        )}
        <div className="d-flex">
          <span className={classes.discreet}>FX rate expiry</span>
          <span className="pl-4 ml-auto">
            {(fxRateExpiry && status === TRANSACTION_STATUS.EXECUTED) ? formatDate(fxRateExpiry, dateFormat) : '-'}
          </span>
        </div>
        <div className="d-flex">
          <span className={classes.discreet}>{weReceiveLabel}</span>
          <strong className="pl-4 ml-auto">
            <NumberTpl
              value={weReceive}
              prefix={targetSymbol}
            />
          </strong>
        </div>
        <div className="d-flex">
          <span className={classes.discreet}>contractor pays fees</span>
          <span className="pl-4 ml-auto">
            {shouldProviderPayFees ? 'yes' : 'no'}
          </span>
        </div>
        <div className="d-flex">
          <span className={classes.discreet}>reconciled within</span>
          <span className="pl-4 ml-auto">
            {timeToReconcile || '-'}
          </span>
        </div>
        { wasSWIFTForced && (
          <div className="d-flex">
            <span className={classes.discreet}>SWIFT network</span>
            <span className="pl-4 ml-auto">
              FORCED
            </span>
          </div>
        )}
        <div className="d-flex">
          <span className={classes.discreet}>reviewed by</span>
          <span className="pl-4 ml-auto">
            <Link to={`/full_users/${record?.reviewed_by}/show`}>{reviewerData?.profile?.name}</Link>
          </span>
        </div>
        <div className="d-flex">
          <span className={classes.discreet}>reviewed at</span>
          <span className="pl-4 ml-auto">{formatDateWhenEmpty(record.reviewed_at, '-')}</span>
        </div>
        <div className="d-flex">
          <span className={classes.discreet}>approved by</span>
          <span className="pl-4 ml-auto">
            <Link to={`/full_users/${record?.approved_by}/show`}>{approverData?.profile?.name}</Link>
          </span>
        </div>
        <div className="d-flex">
          <span className={classes.discreet}>approved at</span>
          <span className="pl-4 ml-auto">{formatDateWhenEmpty(record.approved_at, '-')}</span>
        </div>
        {errorMessage && (
          <div className="d-flex">
            <span className={classes.discreet}>error</span>
            <span className="pl-4 ml-auto text-danger">
              {errorMessage}
            </span>
          </div>
        )}
        {!readOnly && comment && (
          <React.Fragment>
            <strong>Comments</strong>
            <div className="p-3" style={{ backgroundColor: '#e8e8e8' }}>
              <div className="d-flex">
                <span className="text-dark">{comment}</span>
              </div>
            </div>
          </React.Fragment>
        )}
        {showCreateFallbackCTA && (
          <BulkApiActionButton
            variant="contained"
            label="Process payment via Wise"
            title="Create Wise transfer"
            content="A new Wise transfer will be created for this invoice.  Are you sure you wish to do this?"
            confirm
            ids={[record.invoice_id]}
            resource="invoices"
            action="force-create-wise-transaction"
            cancelLabel="Close"
          />
        )}
      </div>
      {canCancelTransfer && (
        <div className="pt-2 mt-2 d-flex">
          <BulkApiActionButton
            label="Cancel"
            title={`Cancel transfer #${transferId}`}
            content={`
              Transfer ${transferId} will get cancelled. Proceed with caution.
            `}
            confirm
            icon={<Icon>cancel</Icon>}
            ids={[transferId]}
            resource="dim_transferwise_transfers"
            action="cancel-trw-transfers"
            cancelLabel="Close"
          />
          <Button
            style={{ color: '#FFF' }}
            className="pt-10 ml-auto"
            variant="contained"
            color="primary"
            href={url}
            target="_blank"
          >
            Pay
          </Button>
        </div>
      )}
    </div>
  );
};

TransactionDetailsField.propTypes = {
  record: PropTypes.object,
  readOnly: PropTypes.bool,
  showCreateFallbackCTA: PropTypes.bool,
  isDeferred: PropTypes.bool,
};

TransactionDetailsField.defaultProps = {
  record: {},
  readOnly: false,
  showCreateFallbackCTA: false,
  isDeferred: false,
};

export default TransactionDetailsField;
