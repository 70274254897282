import ReportsDownloadsView from 'admin/assets/js/resources/reports/ReportsDownloadsView.jsx';
import { REPORTS_TABS } from 'admin/assets/js/constants';

const List = ({ ...props }) => {
  return ReportsDownloadsView({
    ...props,
    selectedTab: REPORTS_TABS.DOWNLOADS,
  });
};

// eslint-disable-next-line import/prefer-default-export
export { List };
